import { keyframes } from 'styled-components/macro';

export const fadeIn = keyframes`
  from {
    opacity : 0;
  }
  to {
    opacity : 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity : 1;
  }

  to {
    opacity : 0;
  }
`;

export const scaleFadeIn = keyframes`
from {
    opacity : 0;
    transform : scale(0);
  }

  to {
    opacity : 1;
    transform : scale(1);
  }
`;

export const loadingAnimation = keyframes`
  0%   { background-position: 0 0; }
    100% { background-position: 50px 50px; }
`;

export const rotate360deg = keyframes`
   0%   { transform: rotate(0deg); }
    100% { transform: rotate(3600deg); }
`;
