import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function SearchChatBox({
  style = {},
  fill = '#8F8F8F',
  width = '100%',
  className = '',
  viewBox = '0 0 738 694',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M1.6100122070312182,303.9100164794922 c-0.54,-9.16 -1.07,-18.31 -1.61,-27.47 c0,0 0,-0.010000000000000002 0,-0.010000000000000002 c0.13,-1.23 0.37000000000000005,-2.46 0.37000000000000005,-3.6900000000000004 c-0.04000000000000001,-17.35 1.58,-34.55 5.08,-51.53 c14.39,-69.9 50.38,-126.14 107.7,-168.52 c33.68,-24.9 71.43,-40.73 112.71,-48.02 c6.19,-1.09 12.4,-2.04 18.6,-3.06 c0,0 0,0 0.010000000000000002,0 c9.16,-0.54 18.31,-1.07 27.47,-1.61 c0,0 0,0 0.010000000000000002,0 c3.87,0.19000000000000003 7.74,0.43000000000000005 11.61,0.55 c35.58,1.11 69.58,8.92 101.91,23.44 c103.53,46.49 168.01,152.48 161.76,265.8 c-1.52,27.54 -7.21,54.27 -17.14,80.14 c-2.2800000000000002,5.94 -4.73,11.82 -7.38,17.73 c-4.75,10.57 -4.71,13.21 5.3,20.76 c47.05,41.82 94.06,83.67 141.15,125.45 c51,47.48 60.39,54.86 64.72,69.79 c1.21,3.66 2.06,7.45 3.07,11.18 c0,0 0,0.010000000000000002 0,0.010000000000000002 l0.7600000000000001,13.04 c0,0.010000000000000002 0,0.010000000000000002 0,0.020000000000000004 c-0.17,0.8700000000000001 -0.4,1.7400000000000002 -0.49000000000000005,2.63 c-2.72,26.42 -15.98,45.58 -39.92,57.03 c-5.38,2.5700000000000003 -11.38,3.85 -17.1,5.73 c0,0 -0.010000000000000002,0 -0.010000000000000002,0 l-13.72,0.8 c-0.010000000000000002,0 -0.020000000000000004,0 -0.020000000000000004,0 c-0.43000000000000005,-0.14 -0.8500000000000001,-0.38000000000000006 -1.28,-0.41000000000000003 c-17.07,-1.01 -31.4,-7.98 -44.07,-19.31 c-8.43,-7.53 -136.66,-121.68 -180.03,-160.27 c-0.17,-0.15000000000000002 -12.48,-14.15 -26.99,-5.21 c-56.89,35.07 -119.61,46.09 -186.06,34.84 c-67.83,-11.48 -123.5,-44.7 -166.97,-97.93 c-29.56,-36.2 -48.23,-77.59 -56.38,-123.65 C3.5700122070312545,316.1000164794922 2.620012207031209,310.00001647949216 1.6100122070312182,303.9100164794922 C1.6100122070312182,303.9200164794922 1.6100122070312182,303.9200164794922 1.6100122070312182,303.9100164794922 z"
      />
      <path
        fill="#FFFFFF"
        d="M69.0200122070313,285.7300164794922 c6.21,112.21 103.06,199.36 214.83,193.32 c114.38,-6.19 201.28,-102.5 195.06,-216.19 c-6.21,-113.5 -103.1,-200.16 -216.69,-193.8 C149.33001220703125,75.37001647949216 62.750012207031205,172.4700164794922 69.0200122070313,285.7300164794922 z"
      />
    </svg>
  );
}
