import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import { isNotEmptyArray } from '../../utils/arrays';

export default function useGetConversationPerMemberDataByOwnerId(conversation, loggedInUser) {
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [notification, setNotification] = useState<boolean>(true);
  const [isTyping, toggleTyping] = useState<boolean>(false);
  const [isHidden, toggleHidden] = useState<boolean>(false);

  useEffect(() => {
    if (conversation && conversation.id) {
      const unsubscribe = db
        .collection(collections.conversationsPerMember)
        .where('conversationId', '==', conversation.id)
        .where('ownerId', '==', loggedInUser.id)
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            const conversationPerMemberData: any = snapshot.docs.map(x => x.data());
            if (isNotEmptyArray(conversationPerMemberData)) {
              setNotification(conversationPerMemberData[0].notification);
              setUnreadCount(conversationPerMemberData[0].unreadCount);
              toggleTyping(conversationPerMemberData[0].isTyping);
              toggleHidden(conversationPerMemberData[0].isHidden ? conversationPerMemberData[0].isHidden : false);
            }
          }
          return () => {
            unsubscribe();
          };
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  return { unreadCount: unreadCount, notification: notification, isTyping: isTyping, isHidden: isHidden };
}
