import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as LinkRouterDOM } from 'react-router-dom';
import { AccountSettingsStyled } from '../../AccountSettings/styled';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import Flex from '../../CommonComponents/Flex';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { currentLang } from '../../utils/language';

const { HeaderMessage } = AccountSettingsStyled;
const { Span, Link } = TypographyStyled;

export default function Notification() {
  const { t } = useTranslation();
  const [storageCapacity] = useState({
    storageLimit: 5,
    storageSize: 0,
  });

  const storageCapacityPercentage = (storageCapacity.storageSize / storageCapacity.storageLimit) * 100;

  const headerColor = () => {
    if (storageCapacityPercentage < 90) {
      return '#FBCB0A';
    } else if (storageCapacityPercentage <= 100) {
      return '#FF3737';
    } else {
      return '';
    }
  };
  const headerState = () => {
    if (storageCapacityPercentage < 90) {
      return 'warning';
    } else if (storageCapacityPercentage <= 100) {
      return 'error';
    } else {
      return '';
    }
  };

  if (storageCapacityPercentage > 90) {
    return (
      <Flex container justifyContent="center" width="100%">
        <HeaderMessage state={headerState()}>
          <IconContainer iconName="error-circle" iconWidth="40px" iconFill={headerColor()} />
          <Flex container>
            {currentLang === 'en' ? (
              <Span size="md" fontColor="#444444">
                {t(
                  storageCapacityPercentage < 90
                    ? 'YourStorageIsAlmostFullYouWontBeAbleToSendAnyFilesOnceYouveReachedYourStoragePlanToManageGoToYour'
                    : 'YourCurrentStoragePlanIsAlreadyFullYouCannotSendAnymoreFilesToManageGoToYour'
                )}
                &nbsp;
                <LinkRouterDOM to="/accountSettings" target="_blank">
                  <Link size="md" fontColor="#444444" textDecoration="underline">
                    {t('Settings')}
                  </Link>
                </LinkRouterDOM>
              </Span>
            ) : (
              <Span size="md" fontColor="#444444">
                {t(
                  storageCapacityPercentage < 90
                    ? 'YourStorageIsAlmostFullYouWontBeAbleToSendAnyFilesOnceYouveReachedYourStoragePlanToManageGoToYour.1'
                    : 'YourCurrentStoragePlanIsAlreadyFullYouCannotSendAnymoreFilesToManageGoToYour.1'
                )}
                <br />
                {t(
                  storageCapacityPercentage < 90
                    ? 'YourStorageIsAlmostFullYouWontBeAbleToSendAnyFilesOnceYouveReachedYourStoragePlanToManageGoToYour.2'
                    : 'YourCurrentStoragePlanIsAlreadyFullYouCannotSendAnymoreFilesToManageGoToYour.2'
                )}
                <LinkRouterDOM to="/accountSettings" target="_blank">
                  <Link size="md" fontColor="#444444" textDecoration="underline">
                    {t('YourStorageIsAlmostFullYouWontBeAbleToSendAnyFilesOnceYouveReachedYourStoragePlanToManageGoToYour.3')}
                  </Link>
                </LinkRouterDOM>
                {t(
                  storageCapacityPercentage < 90
                    ? 'YourStorageIsAlmostFullYouWontBeAbleToSendAnyFilesOnceYouveReachedYourStoragePlanToManageGoToYour.4'
                    : 'YourCurrentStoragePlanIsAlreadyFullYouCannotSendAnymoreFilesToManageGoToYour.4'
                )}
              </Span>
            )}
          </Flex>
          <Flex></Flex>
        </HeaderMessage>
      </Flex>
    );
  } else {
    return <></>;
  }
}
