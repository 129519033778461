import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function AddMessage({
  style = {},
  fill = '#bdbdbd',
  width = '100%',
  className = '',
  viewBox = '0 0 46 46',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g transform="translate(2.637 0)">
          <path
            d="M38.52,14.063a20.64,20.64,0,0,1-.455,24.044A19.5,19.5,0,0,1,26.29,45.669a19.079,19.079,0,0,1-13.052-1.759A19.659,19.659,0,0,1,3.777,33.485a20.112,20.112,0,0,1-.35-14.519A19.81,19.81,0,0,1,26.01,5.772,3.187,3.187,0,0,0,25.577,6.8a7.091,7.091,0,0,0-.125,1.781c0,.2-.021.264-.246.226a15.5,15.5,0,0,0-3.491-.273A17.489,17.489,0,0,0,16.32,9.615a16.644,16.644,0,0,0-6.758,4.75,17.284,17.284,0,0,0-2.54,3.919A17.888,17.888,0,0,0,5.4,26.724a16.555,16.555,0,0,0,.567,3.553,17.817,17.817,0,0,0,1.285,3.4,16.835,16.835,0,0,0,12.343,9.053,14.638,14.638,0,0,0,3.112.209,17.175,17.175,0,0,0,5.757-1.218,16.624,16.624,0,0,0,4.593-2.782A17.051,17.051,0,0,0,37.2,33.651a17.376,17.376,0,0,0,1.723-5.534c.075-.55.1-1.1.133-1.649.042-.6-.008-1.193-.017-1.785A14.586,14.586,0,0,0,38.7,22.1a18.6,18.6,0,0,0-1.247-3.8,4.072,4.072,0,0,0-.271-.494c-.092-.145-.05-.217.088-.311a2.8,2.8,0,0,0,1.193-2.245C38.491,14.88,38.508,14.51,38.52,14.063Z"
            transform="translate(-2.239 -0.119)"
            fill={fill}
          />
          <path
            d="M31.659,6.288c.763,0,1.527-.013,2.286.009.275.009.367-.051.367-.358C34.3,4.426,34.3,2.914,34.3,1.406A1.848,1.848,0,0,1,34.433.7,1.011,1.011,0,0,1,35.3.047,6.872,6.872,0,0,1,36.9.055c.713.107.968.5.984,1.3.033,1.534.017,3.067,0,4.6,0,.277.071.349.342.349,1.5-.013,3.008,0,4.509-.013a1.9,1.9,0,0,1,.6.1.855.855,0,0,1,.567.631,4.791,4.791,0,0,1,.05,2.036c-.108.6-.542.907-1.285.907-1.481,0-2.962.009-4.443,0-.271,0-.346.064-.342.345.013,1.512.008,3.025,0,4.533a1.432,1.432,0,0,1-.025.328c-.175.763-.471,1-1.256,1-.38,0-.763,0-1.143,0a1.11,1.11,0,0,1-1.172-1.2c0-1.423,0-2.846,0-4.269,0-.733,0-.733-.692-.733-1.36,0-2.724-.009-4.084,0A1.184,1.184,0,0,1,28.2,8.7a6.629,6.629,0,0,1,.075-1.6.948.948,0,0,1,.993-.809C30.07,6.284,30.862,6.288,31.659,6.288Z"
            transform="translate(-3.32 -0.005)"
            fill={fill}
          />
          <path
            d="M34.628,25.255h-20.8a1.193,1.193,0,0,1-1.176-1.2h0a1.193,1.193,0,0,1,1.176-1.2h20.8a1.193,1.193,0,0,1,1.176,1.2h0A1.193,1.193,0,0,1,34.628,25.255Z"
            transform="translate(-2.673 -0.491)"
            fill={fill}
          />
          <path
            d="M30.352,30.4H11.405a1.193,1.193,0,0,1-1.176-1.2h0A1.193,1.193,0,0,1,11.405,28H30.352a1.193,1.193,0,0,1,1.176,1.2h0A1.193,1.193,0,0,1,30.352,30.4Z"
            transform="translate(-2.572 -0.6)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
