import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import path from 'path';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { ImageStyled } from '../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../CommonComponents/Typography/Typography.style';
import { ThreadChatListStyled } from './styled';
import Flex from '../CommonComponents/Flex';
import EmojiPicker from '../CommonComponents/EmojiPicker';
import Modal from '../CommonComponents/Modal';
import { toggleThreadConversationView } from '../Navigation/actions';
import { THREAD_CONVERSATION_VIEW } from '../Common/enums';
import { ThreadListData } from '../Common/interfaces/thread';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { sendThreadMessage } from './actions/sendThreadMessage';
import useGetReplies from './useGetReplies';
import { isNotEmptyArray } from '../utils/arrays';
import ThreadMessage from './ThreadMessage';
import Mention from '../Mention';
import { addThreadMember } from './actions/addThreadMember';
import IconContainer from '../CommonComponents/Decoration/IconContainer';
import { ChannelData } from '../Common/interfaces/channel';
import { findConversationInListById } from '../utils/conversation';
import Content from '../Conversation/Body/Message/Content';
import { bytesToMegaBytes, renameFile } from '../utils/files';
import useSendFile from '../Common/customHooks/useSendFile';

const { UserAvatar } = ImageStyled;
const { Span, Paragraph } = TypographyStyled;
const { ScrollableContainer, MessageInputText, InputContainer, OtherInput, HorizontalLine, MessageBox } = ThreadChatListStyled;

const ThreadChatList: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uploadFileToStorageThread } = useSendFile();
  const parentThread: ThreadListData = useSelector(state => state.threadReducer.parentThread);
  const chatList: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);
  const [activeConversation, setActiveConversation] = useState<ChannelData>(
    findConversationInListById(chatList, parentThread.conversationId)
  );
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const [composeValue, setComposeValue] = useState('');
  const [isReactionShown, setIsReactionShown] = useState(false);
  const [threadMessages, setThreadMessages] = useState<any>([]);
  const composeTextFieldRef: any = useRef();
  const sendFileRef: any = useRef();
  const { replies, isLoading } = useGetReplies(parentThread.childMessage.id);

  useEffect(() => {
    setActiveConversation(findConversationInListById(chatList, parentThread.conversationId));
  }, [parentThread.conversationId, chatList]);

  useEffect(() => {
    setThreadMessages(replies);
  }, [replies]);

  const handleChange = e => {
    setComposeValue(e);
  };

  const handleAddThreadMember = (messageId: string, composeValue: any) => {
    if (composeValue.indexOf('@') === -1) {
      return null;
    }
    const seletedMemberIndex = composeTextFieldRef.current.state.activeIndex;
    const selectedMemberId = composeTextFieldRef.current.props.children[seletedMemberIndex].key;
    const requestBody = {
      messageId: messageId,
      threadMemberId: selectedMemberId,
    };

    dispatch(addThreadMember(requestBody));
  };

  const handleSubmit = () => {
    if (composeValue.length <= 1000) {
      if (parentThread.childMessage.id && composeValue.length !== 0 && !composeValue.match('^\\s+$')) {
        const requestBody = {
          id: `fakeId_${Math.random()}`,
          conversationId: parentThread.conversationId,
          messageId: parentThread.childMessage.id,
          userId: loggedInUser.id,
          userName: loggedInUser.displayName,
          content: {
            text: composeValue,
          },
          status: {
            isUpdated: false,
          },
        };
        handleAddThreadMember(parentThread.childMessage.id, composeValue);
        dispatch(sendThreadMessage(requestBody)).then(() => setComposeValue(''));
        setThreadMessages(oldMessages => [...oldMessages, requestBody]);
        setComposeValue('');
      }
    }
  };

  const handleAttachClick = () => sendFileRef.current.click();

  const handleUploadFile = e => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      uploadFile(file);
      sendFileRef.current.value = [];
    }
  };

  const handlePaste = e => {
    if (e.clipboardData.files[0]) {
      uploadFile(e.clipboardData.files[0]);
    }
  };

  const uploadFile = file => {
    const mb = bytesToMegaBytes(file.size);
    if (mb > 500) {
      Swal.fire({
        icon: 'error',
        iconHtml: '!',
        text: t('TheFileYouAreTryingToSendExceedsThe500MbUploadLimit'),
        confirmButtonText: t('Ok'),
        cancelButtonText: t('Cancel'),
        showCancelButton: true,
        showConfirmButton: false,
        width: '635px',
        customClass: {
          popup: 'file-too-large-popup-class',
          content: 'forgot-password-content-class',
          cancelButton: 'file-too-large-cancel-button-class',
        },
      });
    } else {
      Swal.fire({
        title: t('ChangeTheFileName'),
        input: 'text',
        inputValue: path.basename(file.name, path.extname(file.name)),
        showCancelButton: true,
        confirmButtonText: t('Upload'),
        cancelButtonText: t('Cancel'),
        showLoaderOnConfirm: true,
        preConfirm: newFileName => {
          const renamedFile = renameFile(file, newFileName + path.extname(file.name));
          return uploadFileToStorageThread(renamedFile, activeConversation.id, parentThread.childMessage.id).catch(err => {
            Swal.showValidationMessage(`Request failed: ${err.response.data.message}`);
          });
        },
      });
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        handleSubmit();
      }
    }
  };

  const toggleEmoji = e => {
    if (parentThread.childMessage && parentThread.childMessage.id) {
      e.preventDefault();
      setIsReactionShown(isReactionShown => !isReactionShown);
    }
  };

  const handleEmojiSelect = e => {
    if (parentThread.childMessage && parentThread.childMessage.id) {
      setComposeValue(oldValue => oldValue + e.native);
    }
  };

  const handleCloseIcon = () => {
    dispatch(toggleThreadConversationView(THREAD_CONVERSATION_VIEW.HIDDEN));
  };

  return (
    <Flex height="100%" borderLeft="1px solid #e1e1e1">
      <Flex container height="10%" alignItems="center" justifyContent="space-between">
        <Flex padding="10px">
          <Paragraph size="lg" fontColor="#444444">
            {t('Thread')}
          </Paragraph>
          <Paragraph size="md" fontColor="#444444">
            {parentThread.conversationName}
          </Paragraph>
        </Flex>
        <IconContainer onClick={handleCloseIcon} padding="10px" iconName="close-circle" iconWidth="30px" />
      </Flex>
      <ScrollableContainer>
        <Flex width="100%" height="100%">
          <Flex width="100%" margin="0px 0px 20px">
            <MessageBox>
              <Flex container>
                <UserAvatar src={parentThread.threadSender.profileImageUrl} width="45" height="45" alt="" />
              </Flex>
              <Flex width="100%" margin="0px 0px 0px 5px">
                <Flex container>
                  <Span size="md" fontColor="#444444" fontWeight="bold" margin="0px 15px 0px 5px">
                    {parentThread.threadSender.displayName}
                  </Span>
                  <Span size="sm" fontColor="#939393">
                    <TimeAgo datetime={new Date(parentThread.childMessage.dateCreated)} live={false} />
                  </Span>
                </Flex>
                <Paragraph size="md" fontColor="#444444" margin="10px 0px 0px 0px">
                  <Content message={parentThread.childMessage} user={parentThread.threadSender} isRecursive={true} />
                </Paragraph>
              </Flex>
            </MessageBox>
            {isLoading && (
              <Flex>
                <Span fontColor="#cacaca" size="md" center>
                  {t('Loading')}
                </Span>
              </Flex>
            )}
            {isNotEmptyArray(threadMessages) && (
              <Flex container justifyContent="space-around" alignItems="center" width={'100%'}>
                <HorizontalLine style={{ width: '35%' }} />
                <Span fontColor="#cacaca" size="md" center>
                  {threadMessages.length} {t('Replies')}
                </Span>
                <HorizontalLine style={{ width: '35%' }} />
              </Flex>
            )}
            {isNotEmptyArray(threadMessages) &&
              threadMessages.map(reply => <ThreadMessage key={reply.id} threadData={reply} parentThread={parentThread} />)}
            <Flex padding="10px">
              {(activeConversation && !activeConversation.isBlocked) || activeConversation === undefined ? (
                <MessageInputText>
                  <InputContainer>
                    <Mention
                      mentionRef={composeTextFieldRef}
                      mentionVal={composeValue}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      onPaste={handlePaste}
                    />
                  </InputContainer>
                  <OtherInput>
                    <Flex container width="120px" alignItems="center" justifyContent="space-between">
                      <IconContainer
                        onClick={handleAttachClick}
                        iconName="attach"
                        iconWidth="25px"
                        iconFill="white"
                        iconFillOnMouseEnter="#CACACA"
                      />
                      <input ref={sendFileRef} type="file" onChange={handleUploadFile} style={{ display: 'none' }} />
                    </Flex>
                    <Flex container width="150px" alignItems="center" justifyContent="space-between">
                      <Modal visible={isReactionShown} setModalVisibility={() => setIsReactionShown(false)} dimmed={false} />
                      <Flex container alignItems="flex-end" height="100%">
                        <Span
                          size="sm"
                          fontColor={composeValue.length > 1000 ? '#FF3737' : '#444444'}
                        >{`${composeValue.length} / 1000`}</Span>
                      </Flex>
                      <IconContainer onClick={toggleEmoji} iconName="smiley" iconWidth="25px">
                        {isReactionShown && (
                          <Flex position="relative" transform="translate(0, -80px)" handleOnClick={e => e.stopPropagation()}>
                            <EmojiPicker handleEmojiSelect={handleEmojiSelect} />
                          </Flex>
                        )}
                      </IconContainer>
                      <IconContainer
                        onClick={handleSubmit}
                        iconName="send"
                        iconWidth="25px"
                        iconFill={composeValue.length > 1 ? '#84FFFF' : '#CACACA'}
                      />
                    </Flex>
                  </OtherInput>
                </MessageInputText>
              ) : (
                <Flex width="100%" container justifyContent="center">
                  <Span size="lg" fontColor="#444444">
                    {t('YouCannotSendMessageToThisFriend')}
                  </Span>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </ScrollableContainer>
    </Flex>
  );
};

export default ThreadChatList;
