import React, { FC, ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CreateChannelHeaderStyled } from './styled';
import { isNotEmptyArray } from '../../utils/arrays';
import { DecorationStyled } from '../../CommonComponents/Decoration/Decoration.style';
import { FriendData } from '../../Common/interfaces/friend';
import { ChannelData } from '../../Common/interfaces/channel';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import Flex from '../../CommonComponents/Flex';
import useGetActiveConversation from '../../Common/customHooks/useGetActiveConversation';

const { BorderedContainer, Container, SearchFriendInput } = CreateChannelHeaderStyled;
const { AnimatedContainer } = DecorationStyled;

interface Props {
  groupExist?: boolean;
  createChannel: any;
  inviteFriend: any;
  setSelectedFriend: any;
}

const SearchFriend: FC<Props> = ({ groupExist, createChannel, inviteFriend, setSelectedFriend }): ReactElement => {
  const createChannelState = useSelector(state => state.createChannelReducer);
  const activeConversation: ChannelData = useGetActiveConversation();
  const {
    friendList,
    members,
    createMessageIsLoading,
    inviteFriendsIsLoading,
  }: {
    friendList: Array<FriendData>;
    members: Array<FriendData>;
    createMessageIsLoading: boolean;
    inviteFriendsIsLoading: boolean;
  } = createChannelState;
  const [name, handleSearchUserField] = useState('');

  useEffect(() => {
    if (isNotEmptyArray(friendList)) {
      const response = friendList.filter((friend: FriendData) => friend.friendDisplayName.toLowerCase().includes(name.toLowerCase()));
      setSelectedFriend(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, friendList]);

  const handleClick = () => {
    if (!createMessageIsLoading || !inviteFriendsIsLoading) {
      if (groupExist) {
        if (activeConversation.isDirectMessage) {
          createChannel();
        } else {
          inviteFriend();
        }
      } else {
        createChannel();
      }
    }
  };

  return (
    <Container>
      <BorderedContainer>
        <IconContainer iconName="search" iconWidth="45px" />
        <SearchFriendInput
          type="text"
          disabled={createMessageIsLoading || inviteFriendsIsLoading}
          placeholder="Type the name here"
          onChange={e => handleSearchUserField(e.target.value)}
          value={name}
        />
        <Flex handleOnClick={!createMessageIsLoading || !inviteFriendsIsLoading ? handleClick : () => null}>
          {createMessageIsLoading || inviteFriendsIsLoading ? (
            <AnimatedContainer style={{ height: '45px', width: '45px' }}>
              <IconContainer iconName="loading" iconWidth="45px" />
            </AnimatedContainer>
          ) : (
            <IconContainer iconName="select" iconWidth="45px" iconFill={isNotEmptyArray(members) ? '#08E9FE' : '#BDBDBD'} />
          )}
        </Flex>
      </BorderedContainer>
    </Container>
  );
};

export default SearchFriend;
