import * as Yup from 'yup';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('InvalidEmailAddress')
    .required('EmailIsRequired'),
  password: Yup.string()
    .required('PasswordIsRequired')
    // .matches(passwordRegex, 'PasswordValidation1')
    //TODO: NEXT DEPLOYMENT CHANGE 6 -> 8
    .min(6, 'PasswordConsist8MoreCharacters'),
});

export const UserProfileValidationSchema = Yup.object().shape({
  displayName: Yup.string().required('Required'),
});

export const ChangePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('OldPasswordIsRquired'),
  newPassword: Yup.string()
    .required('NewPasswordIsRequired')
    .notOneOf([Yup.ref('oldPassword')], 'OldPasswordAndNewPasswordIsSame')
    .matches(passwordRegex, 'PasswordValidation1'),
  confirmNewPassword: Yup.string()
    .required('ConfirmNewPasswordisrequired')
    .oneOf([Yup.ref('newPassword'), null], 'ConfirmPasswordDoesNotMatchTheNewPassword'),
});
