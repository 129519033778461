import {
  FILTER_MESSAGES_BY_NAME,
  CLEAR_ALL_CONVERSATION_MESSAGES,
  PUSH_CONVERSATION_TO_CONVERSATION_LIST,
  UPDATE_CONVERSATION_LAST_MESSAGE,
  UPDATE_CONVERSATION_UNREAD_COUNT,
  UPDATE_CONVERSATION_TYPING_MEMBERS,
  UPDATE_CONVERSATION_LIST,
  UPDATE_CONVERSATION_IN_LIST,
  GET_ALL_CONVERSATIONS_ERROR,
  GET_ALL_CONVERSATIONS_LOADING,
  GET_ALL_CONVERSATIONS_SUCCESS,
} from '../actions/actionTypes';
import { ChannelData } from '../../Common/interfaces/channel';

const initialState = {
  getAllMessagesHasError: false,
  getAllMessageIsLoading: false,
  isFilteredByName: false,
  messages: [],
  filteredMessages: [],
};

const filterMessagesByName = (name: string, list) => list.filter(conversation => conversation.name.toLowerCase().includes(name));

const sortMessages = (messages: Array<ChannelData>) => {
  return messages.sort((a, b) => {
    if (a.lastMessage && b.lastMessage) {
      const dateA: any = new Date(a.lastMessage.dateCreated);
      const dateB: any = new Date(b.lastMessage.dateCreated);
      return dateB - dateA;
    } else return 0;
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONVERSATIONS_ERROR:
      return {
        ...state,
        getAllMessagesHasError: true,
      };
    case GET_ALL_CONVERSATIONS_LOADING:
      return {
        ...state,
        getAllMessageIsLoading: true,
      };
    case GET_ALL_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        getAllMessageIsLoading: false,
        messages: action.payload,
      };
    case UPDATE_CONVERSATION_LIST:
      return {
        ...state,
        messages: action.conversationList,
      };
    case UPDATE_CONVERSATION_IN_LIST:
      return {
        ...state,
        messages: state.messages.map((conversation: any) => {
          if (conversation.id === action.conversation.id) {
            conversation = action.conversation;
          }
          return conversation;
        }),
      };
    case FILTER_MESSAGES_BY_NAME:
      const filteredMessages = filterMessagesByName(action.name, [...state.messages]);
      return {
        ...state,
        isFilteredByName: action.name ? true : false,
        filteredMessages: filteredMessages,
      };
    case PUSH_CONVERSATION_TO_CONVERSATION_LIST:
      let newConversationList: any = [...state.messages];
      newConversationList.push(action.conversation);
      return {
        ...state,
        messages: newConversationList,
      };
    case UPDATE_CONVERSATION_LAST_MESSAGE:
      const messages1 = state.messages.map((message: any) => {
        if (message.conversationId === action.conversationId) {
          message.lastMessage = action.lastMessage;
        }
        return message;
      });
      sortMessages(messages1);
      return {
        ...state,
        messages: sortMessages(messages1),
      };
    case UPDATE_CONVERSATION_UNREAD_COUNT:
      return {
        ...state,
        messages: state.messages.map((message: ChannelData) => {
          if (message.id === action.conversationId) {
            message.unreadCount = action.unreadCount;
          }
          return message;
        }),
      };
    case UPDATE_CONVERSATION_TYPING_MEMBERS:
      return {
        ...state,
        messages: state.messages.map((message: ChannelData) => {
          if (message.id === action.conversationId) {
            message.typingMembers = action.typingMembers;
          }
          return message;
        }),
      };
    case CLEAR_ALL_CONVERSATION_MESSAGES:
      return initialState;
    default:
      return state;
  }
};
