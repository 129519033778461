import {
  FETCH_FRIENDS_ERROR,
  FETCH_FRIENDS_LOADING,
  FETCH_FIENDS_SUCCESS,
  FILTER_FRIENDS_BY_NAME,
  GET_USER_ERROR,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_FRIEND_INFO,
  GET_USER_INFO,
  ADD_USER_ERROR,
  ADD_USER_LOADING,
  ADD_USER_SUCCESS,
  FAVORITE_USER_ERROR,
  FAVORITE_USER_LOADING,
  BLOCK_USER_ERROR,
  BLOCK_USER_LOADING,
  UPDATE_FRIEND_STATUS,
  UPDATE_FRIEND_DISPLAY_NAME_ERROR,
  UPDATE_FRIEND_DISPLAY_NAME_LOADING,
  UPDATE_FRIEND_DISPLAY_NAME_SUCCESS,
  CLEAR_FRIENDS_REDUCER,
  UPDATE_FRIEND_LIST,
  UPDATE_FRIEND_IN_LIST,
} from '../actions/actionTypes';
import { FriendData } from '../../Common/interfaces/friend';

const initialState = {
  friendList: [],
  filteredFriends: [],
  isFilteredByName: false,
  fetchFriendsHasError: false,
  fetchFriendsIsLoading: false,
  getFriendIsLoading: false,
  getFriendHasError: false,
  addUserIsLoading: false,
  addUserHasError: false,
  friend: {},
  user: {},

  //Handle Favorite User
  isHandleFavoriteUserLoading: false,
  isHandleFavoriteUserHasError: false,

  //Handle Block User
  isHandleBlockUserLoading: false,
  isHandleBlockUserHasError: false,

  //Handle Update Friend's Display name
  isHandleUpdateFriendDisplayNameLoading: false,
  isHandleUpdateFriendDisplayNameHasError: false,
};

const filterFriendsByName = (name: string, list) => list.filter(friend => friend.friendDisplayName.toLowerCase().includes(name));

const updateFriendDataInsideFriendList = (friendData, friendList) => {
  const index = friendList.findIndex(friend => friend.friendId === friendData.friendId);
  friendList[index] = friendData;
  return friendList;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FRIENDS_LOADING:
      return {
        ...state,
        fetchFriendsIsLoading: action.isLoading,
      };
    case FETCH_FIENDS_SUCCESS:
      return {
        ...state,
        friendList: action.payload,
        fetchFriendsIsLoading: false,
      };
    case FETCH_FRIENDS_ERROR:
      return {
        ...state,
        fetchFriendsHasError: action.hasError,
        fetchFriendsIsLoading: false,
      };
    case FILTER_FRIENDS_BY_NAME:
      const filteredFriends = filterFriendsByName(action.name, [...state.friendList]);
      return {
        ...state,
        isFilteredByName: action.name ? true : false,
        filteredFriends: filteredFriends,
      };
    case GET_USER_LOADING:
      return {
        ...state,
        getFriendIsLoading: action.isLoading,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        getFriendIsLoading: false,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        getFriendHasError: action.hasError,
        getFriendIsLoading: false,
      };
    case GET_FRIEND_INFO:
      return {
        ...state,
        friend: action.friend,
      };
    case GET_USER_INFO:
      return {
        ...state,
        user: action.user,
      };
    case ADD_USER_ERROR:
      return {
        ...state,
        addUserError: action.hasError,
        addUserIsLoading: false,
      };
    case ADD_USER_LOADING:
      return {
        ...state,
        addUserIsLoading: action.isLoading,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUserIsLoading: false,
      };
    case UPDATE_FRIEND_STATUS:
      let user: any = { ...state.friend };
      user.friendStatus = action.friendStatus;
      const updatedFriendList = state.friendList.map((friend: FriendData) => {
        if (friend.friendId === user.friendId) {
          friend.friendStatus = user.friendStatus;
        }
        return friend;
      });
      localStorage.setItem('userFriendList', JSON.stringify(updatedFriendList));
      return {
        ...state,
        friend: user,
        friendsList: updatedFriendList,
        isHandleFavoriteUserLoading: false,
      };
    case FAVORITE_USER_ERROR:
      return {
        ...state,
        isHandleFavoriteUserHasError: action.hasError,
      };
    case FAVORITE_USER_LOADING:
      return {
        ...state,
        isHandleFavoriteUserLoading: action.isLoading,
      };
    case BLOCK_USER_ERROR:
      return {
        ...state,
        isHandleBlockUserHasError: action.hasError,
      };
    case BLOCK_USER_LOADING:
      return {
        ...state,
        isHandleBlockUserLoading: action.isLoading,
      };
    case UPDATE_FRIEND_DISPLAY_NAME_ERROR:
      return {
        ...state,
        isHandleUpdateFriendDisplayNameHasError: action.hasError,
        isHandleUpdateFriendDisplayNameLoading: false,
      };
    case UPDATE_FRIEND_DISPLAY_NAME_LOADING:
      return {
        ...state,
        isHandleUpdateFriendDisplayNameLoading: action.isLoading,
      };
    case UPDATE_FRIEND_DISPLAY_NAME_SUCCESS:
      const friendCopy: any = { ...state.friend };
      friendCopy.friendDisplayName = action.displayName;
      return {
        ...state,
        friend: friendCopy,
        friendList: updateFriendDataInsideFriendList(friendCopy, [...state.friendList]),
        isHandleUpdateFriendDisplayNameLoading: false,
      };
    case UPDATE_FRIEND_LIST:
      return {
        ...state,
        friendList: action.friendList,
      };
    case UPDATE_FRIEND_IN_LIST:
      return {
        ...state,
        friendList: state.friendList.map((friend: FriendData) => {
          if (friend.friendId === action.friendNewInfo.friendId) {
            friend = action.friendNewInfo;
          }
          return friend;
        }),
      };
    case CLEAR_FRIENDS_REDUCER:
      return initialState;
    default:
      return state;
  }
};
