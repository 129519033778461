import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from './styled';

export default function KnowMore() {
  const { t } = useTranslation();
  return (
    <Container>
      <p className="title">{t('NeedToKnowMore')}</p>
      <Link to="/">
        <button type="button" className="demo">
          {t('Demo')}
        </button>
      </Link>
    </Container>
  );
}
