import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function GradientCheck({ style = {}, width = '100%', className = '', viewBox = '0 0 1080 1080' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="539.88" y1="9.094947e-13" x2="539.88" y2="1081.4517">
            <stop offset="0" stopColor="#50FAC3" />
            <stop offset="1" stopColor="#30CBFB" />
          </linearGradient>
          <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="559.8218" y1="-0.3333" x2="559.8218" y2="1081.1184">
            <stop offset="0" stopColor="#50FAC3" />
            <stop offset="1" stopColor="#30CBFB" />
          </linearGradient>
          <path
            fill="url(#SVGID_1_)"
            d="M586.4,1080c-26.6,0-53.1,0-79.7,0c-3.7,0.2-7.3-0.5-11-0.7c-7.3-0.5-14.7-1.2-22-1.9
			c-20.1-2.1-39.9-5.5-59.5-10.1c-61.7-14.5-118.8-39.5-171.3-74.9C137.9,921.5,60.3,816.1,23.7,694.6c-8-26.7-13.9-53.8-17.7-81.4
			c-2.1-15.2-3.6-30.5-4.2-45.8c-0.1-1.4,0.1-2.9-0.5-4.2c-0.3-0.5-0.8-0.5-1.2-0.5c0-14.4,0-28.8,0-43.2c0.7,0.1,1.3-0.2,1.4-0.9
			c0.2-1.1,0.3-2.2,0.3-3.3c0.5-11.7,1.6-23.3,2.9-34.9c2.1-18.3,5.2-36.4,9.2-54.3c13-57.7,34.6-112,65.1-162.7
			c42.6-70.9,98-129.6,167-175.3C298.7,52.9,356,28.4,417.7,14.2C438.3,9.5,459,5.9,480,3.8c9.2-0.9,18.5-1.7,27.7-2.4
			c7.3-0.6,14.7-0.8,22-1.3c7.7,0,15.4,0,23,0c3.5-0.3,7,0.4,10.5,0.7c5.7,0.4,11.5,0.7,17.2,1C596.3,2.7,612,4.5,627.7,7
			c29.4,4.7,58.3,11.7,86.4,21.4C808,60.7,887.4,114.5,952,190c54,63.1,91,135.2,111.5,215.8c5.2,20.5,9.2,41.2,12,62.1
			c1.6,12,3.2,24.1,3.3,36.3c0,1.1-0.1,2.3,0.2,3.3c0.8,2.3,0.6,4.7,0.6,7.1c0,19.2,0,38.4,0,57.6c0,1.3,0.3,2.6-0.1,3.8
			c-1.1,3.8-0.7,7.6-1,11.4c-0.1,1.4-0.1,2.9-0.2,4.3c-1,9.4-2,18.8-3.4,28.1c-3.4,23.9-8.4,47.5-15.2,70.7
			c-26.9,92.8-74.9,173-144.2,240.3c-67.1,65.2-146,109.9-236.7,133.3c-24,6.2-48.4,10.6-73.1,13.1c-5.9,0.6-11.8,1.1-17.7,1.7
			C587.5,1079,586.6,1078.9,586.4,1080z M545.2,1020.7c17.4,0.1,34.8-0.3,52.2-2c19.1-1.9,38.1-4.9,56.8-9.2
			c49.4-11.4,95.5-30.6,138.4-57.6c85.3-53.9,148.2-127.4,189.1-219.5c22.6-50.9,35.6-104.3,39.2-159.9c1.4-21.4,1.2-42.8-0.4-64.3
			c-1.5-19.8-4-39.4-7.8-58.9c-9.4-48.4-25.9-94.3-49.6-137.5c-43.4-78.9-104.1-141.3-181.6-187c-68.1-40.2-141.7-62-220.8-64.9
			c-20.2-0.7-40.3-0.3-60.4,1.4c-19.1,1.6-38.1,4.2-56.9,8.1c-61,12.6-117.5,36.3-168.9,71.5c-79.8,54.7-138.2,127.1-176.2,216
			c-21.5,50.3-34.1,102.8-37.4,157.4c-1.8,29.3-0.6,58.5,3.3,87.6c3.6,26.4,9.2,52.3,17.1,77.6c25,79.9,67.5,149.1,127.1,207.8
			c43.6,42.9,93.4,76.5,150,100c53.5,22.2,109.2,33.7,167.2,33.4C532,1020.7,538.6,1020.7,545.2,1020.7z"
          />
          <path
            fill="url(#SVGID_2_)"
            d="M328,463.2c21.8,0.3,40.3,8.3,55.8,23.3c10.1,9.8,20.1,19.7,30,29.7c19.5,19.6,39,39.3,58.5,58.9
			c1.7,1.7,3.4,3.4,5.3,4.9c3.5,2.7,6.5,2.7,10.1,0.1c1.8-1.3,3.4-2.9,5-4.5c22.2-22.3,44.4-44.7,66.7-67c40-40.1,80-80.1,120-120.2
			c18.3-18.3,36.7-36.6,55-54.9c10.8-10.7,23.5-18.1,38.2-21.9c36.5-9.3,76.5,8.6,90.6,48c3.2,9.1,5.2,18.5,5.2,28.2
			c0.1,13.3-3.4,25.6-10.3,36.9c-3.6,5.9-7.9,11.2-12.8,16.1c-20.3,20.4-40.6,40.8-60.9,61.2c-49.4,49.5-98.8,98.9-148.2,148.3
			c-32.9,32.9-65.9,65.7-98.8,98.6c-14.4,14.4-31.8,21.9-51.9,22.6c-22.1,0.8-41.5-6.5-57.3-22.2c-15.4-15.2-30.7-30.4-45.9-45.7
			c-34.9-35-69.8-69.9-104.7-104.9c-2.9-2.9-5.9-5.9-8.5-9.1c-13.6-16.7-19.6-35.8-17.5-57.2c3.5-36.1,31.3-62.4,62.2-68
			C318.6,463.7,323.3,463.4,328,463.2z"
          />
        </g>
      </g>
    </svg>
  );
}
