import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Clipboard({
  style = {},
  fill = '#444444',
  width = '100%',
  className = '',
  viewBox = '0 0 786 934',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M510.97000129342086,934.0400579786301 c-127.09,0.04000000000000002 -254.17,0.08000000000000004 -381.26,0.11000000000000004 c-1.6700000000000006,-0.56 -3.3,-1.34 -5.01,-1.6500000000000006 c-61.9,-11.48 -101.73,-47.53 -119.8,-107.7 c-1.98,-6.59 -3.18,-13.41 -4.74,-20.12 c-0.05000000000000003,-175.73 -0.11000000000000004,-351.47 -0.1600000000000001,-527.2 c0.53,-1.3800000000000001 1.32,-2.72 1.54,-4.14 c11.47,-73.36 71.32,-125.25 145.29,-125.38 c115.78,-0.2 231.57,-0.30000000000000016 347.35,-0.08000000000000004 c80.84,0.15000000000000008 145.85,64.54 146.03,145.11 c0.37000000000000016,165.32 0.37000000000000016,330.63 0.1600000000000001,495.95 c-0.08000000000000004,66 -44.55,122.92 -108.34,140.06 C525.0500012934208,930.87005797863 517.9900012934208,932.37005797863 510.97000129342086,934.0400579786301 zM73.0400012934208,541.0700579786301 c0.02000000000000001,80.86 0.02000000000000001,161.72 0.08000000000000004,242.58 c0.04000000000000002,46.74 30.93,77.52 77.81,77.52 c112.78,-0.010000000000000005 225.56,-0.04000000000000002 338.34,-0.1 c47.48,-0.02000000000000001 78.19,-30.67 78.19,-78.07 c-0.02000000000000001,-161.42 -0.07,-322.84 -0.15000000000000008,-484.26 c-0.02000000000000001,-47.39 -30.75,-77.96 -78.28,-77.95 c-112.48,0.010000000000000005 -224.96,0.05000000000000003 -337.43,0.1 c-48.14,0.02000000000000001 -78.63,30.47 -78.63,78.51 C72.98000129342074,379.96005797863006 73.02000129342082,460.52005797863006 73.0400012934208,541.0700579786301 z"
      />
      <path
        fill={fill}
        d="M786.3600012934207,704.11005797863 c-1.2,2.77 -2.31,5.58 -3.6100000000000003,8.31 c-7.07,14.82 -23.49,23.35 -39.13,20.38 c-16.94,-3.21 -29.26,-17.14 -30.15,-34.2 c-0.18000000000000008,-3.33 -0.07,-6.68 -0.07,-10.03 c-0.06000000000000003,-179.93 -0.07,-359.85 -0.19000000000000009,-539.78 c-0.03000000000000002,-39.43 -25.05,-69.11 -63.11,-75.06 c-5.36,-0.8400000000000003 -10.9,-0.7300000000000003 -16.36,-0.7300000000000003 c-130.99,0 -261.99,0.08000000000000004 -392.98,0 c-5.99,0 -12.27,-0.48000000000000015 -17.92,-2.27 c-13.8,-4.38 -23.39,-17.96 -24,-32.41 c-0.6300000000000003,-14.79 7.46,-28.27 20.83,-34.6 c2.72,-1.29 5.53,-2.4 8.3,-3.59 c142.9,-0.04000000000000002 285.79,-0.09000000000000007 428.69,-0.13 c1.6700000000000006,0.56 3.31,1.3800000000000001 5.03,1.6400000000000001 c58.25,8.7 105.37,51.26 120.08,108.59 c1.6400000000000001,6.38 2.98,12.83 4.45,19.25 C786.2400012934208,321.02005797863006 786.3000012934208,512.5700579786301 786.3600012934207,704.11005797863 z"
      />
      <path
        fill={fill}
        d="M319.35000129342075,482.0300579786301 c-61.99,0.02000000000000001 -123.98,0.06000000000000003 -185.97,0.010000000000000005 c-4.22,0 -8.620000000000001,-0.010000000000000005 -12.61,-1.1600000000000001 c-8.89,-2.55 -14.96,-11.51 -14.88,-20.75 c0.08000000000000004,-9.72 6.38,-18.17 16.13,-20.66 c4.04,-1.03 8.4,-1.13 12.620000000000001,-1.13 c123.68,-0.09000000000000007 247.35,-0.12000000000000002 371.03,-0.1600000000000001 c2.13,0 4.26,-0.08000000000000004 6.38,0.03000000000000002 c13.37,0.7000000000000003 22.82,10.22 22.47,22.59 c-0.3400000000000003,12.11 -9.53,21.02 -22.81,21.05 c-64.12,0.17 -128.23,0.11000000000000004 -192.35,0.13 C319.35000129342075,482.00005797863 319.35000129342075,482.0200579786301 319.35000129342075,482.0300579786301 z"
      />
      <path
        fill={fill}
        d="M320.5500012934208,337.58005797863007 c61.69,-0.02000000000000001 123.38,-0.04000000000000002 185.07,-0.05000000000000003 c3.04,0 6.1,-0.13 9.11,0.18000000000000008 c11.18,1.18 19.6,10.38 19.75,21.4 c0.1600000000000001,11.36 -8.51,20.95 -20.05,22.09 c-2.11,0.21000000000000008 -4.25,0.1 -6.38,0.1 c-125.2,0.04000000000000002 -250.4,0.08000000000000004 -375.61,0.11000000000000004 c-2.43,0 -4.8900000000000015,0.12000000000000002 -7.28,-0.18000000000000008 c-11.04,-1.3800000000000001 -19.37,-10.89 -19.3,-21.85 c0.08000000000000004,-10.96 8.52,-20.36 19.6,-21.57 c3.01,-0.3300000000000002 6.07,-0.17 9.11,-0.17 C196.56000129342078,337.6200579786301 258.5600012934208,337.60005797863005 320.5500012934208,337.58005797863007 z"
      />
      <path
        fill={fill}
        d="M403.6900012934208,539.26005797863 c35.84,-0.010000000000000005 71.68,-0.19000000000000009 107.51,0.05000000000000003 c17.42,0.12000000000000002 28.06,15.44 21.49,30.28 c-3.16,7.15 -8.59,11.73 -16.48,12.77 c-3.9,0.51 -7.87,0.6200000000000003 -11.8,0.6300000000000003 c-67.12,0.06000000000000003 -134.24,0.07 -201.36,0.08000000000000004 c-3.03,0 -6.09,0.05000000000000003 -9.1,-0.23 c-12.68,-1.2 -21.08,-10.05 -20.97,-21.96 c0.11000000000000004,-12.29 9.06,-21.35 22.29,-21.47 c29.46,-0.28 58.92,-0.11000000000000004 88.38,-0.13 C390.33000129342076,539.25005797863 397.0100012934208,539.25005797863 403.6900012934208,539.26005797863 z"
      />
    </svg>
  );
}
