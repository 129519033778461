import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Twitter({
  fill = '#30ccfe',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 35 35',
}: IconProps): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} className={className} style={style} height={width} viewBox={viewBox}>
      <g transform="translate(-55.623 0)">
        <path
          d="M95.2,36.045A17.5,17.5,0,1,1,112.758,18.7,17.527,17.527,0,0,1,95.2,36.045ZM111.168,18.7A15.911,15.911,0,1,0,95.053,34.458,15.941,15.941,0,0,0,111.168,18.7Z"
          transform="translate(-22.135 -1.045)"
          fill="#fff"
        />
        <path
          d="M112.167,19.7A15.912,15.912,0,1,1,96.361,3.634,15.945,15.945,0,0,1,112.167,19.7ZM89.293,14.426a39.29,39.29,0,0,0,.064,5.255c.142,1.071,1.48,1.692,1.429,3.134-.02.581,1.353,1.211,2.3,1.987l-3.992,1.371c5.461,3.332,12.437.048,13.906-6.55.422-1.9.819-3.8,1.155-5.362a22.247,22.247,0,0,1-2.862-.739,3.366,3.366,0,0,0-4.618,2.088c-.186.606-.252,1.249-.5,2.5C93.765,16.82,91.618,15.672,89.293,14.426Z"
          transform="translate(-23.133 -2.044)"
          fill={fill}
        />
        <path
          d="M94.791,20.46l6.885,3.685c.243-1.253.309-1.9.5-2.5a3.366,3.366,0,0,1,4.618-2.088,22.106,22.106,0,0,0,2.862.739c-.337,1.565-.733,3.466-1.155,5.362-1.469,6.6-8.445,9.883-13.906,6.55,1.3-.447,2.608-.895,3.992-1.371-.944-.776-2.317-1.406-2.3-1.987.051-1.442-1.287-2.063-1.429-3.134A39.022,39.022,0,0,1,94.791,20.46Z"
          transform="translate(-28.631 -8.079)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
