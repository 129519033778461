import styled from 'styled-components/macro';

const media = {
  extraSmall: '@media(max-width: 600px)',
  small: '@media(min-width: 600px)',
  medium: '@media(min-width: 768px)',
  large: '@media(min-width: 992px)',
  extraLarge: '@media(min-width: 1200px)',
};

export const Container = styled.div`
  background-color: #f0f0f0;
  font-family: Montserrat-Light !important;
  padding: 30px 0px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;

  .group {
    ${media.medium} {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
    }
  }

  .group-3 {
    margin: 15px;
    ${media.medium} {
      width: 50%;
    }
  }

  .subgroup-title {
    color: #727272;
    font-weight: bold;
    margin: 15px;
    ${media.extraSmall} {
      font-size: 11px;
    }
    ${media.small} {
      font-size: 11px;
    }
    ${media.medium} {
      font-size: 12px;
    }
    ${media.large} {
      font-size: 15px;
    }
    ${media.extraLarge} {
      font-size: 15px;
    }
  }
  .subgroup-item {
    color: #b2b2b2;
    margin: 15px;
    transition: 300ms;
    text-decoration: none;
    display: block;
    ${media.extraSmall} {
      font-size: 11px;
    }
    ${media.small} {
      font-size: 11px;
    }
    ${media.medium} {
      font-size: 12px;
    }
    ${media.large} {
      font-size: 15px;
    }
    ${media.extraLarge} {
      font-size: 15px;
    }
    :hover {
      cursor: pointer;
      color: #727272;
    }
  }
`;
