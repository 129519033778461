import styled, { keyframes } from 'styled-components/macro';

const scrollDownFadeIn = keyframes`
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0%)
    opacity: 1;
  }
`;

const media = {
  extraSmall: '@media(max-width: 600px)',
  small: '@media(min-width: 600px)',
  medium: '@media(min-width: 768px)',
  large: '@media(min-width: 992px)',
  extraLarge: '@media(min-width: 1200px)',
};

export const Container = styled.div`
  font-family: Montserrat-Light !important;
  background: #49ecc9; /* Old browsers */
  background: -moz-linear-gradient(left, #22ebba 0%, #21e2c1 20%, #1fd4cb 45%, #1dc8d5 55%, #1bbedf 80%, #18a9f0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #22ebba 0%,
    #21e2c1 20%,
    #1fd4cb 45%,
    #1dc8d5 55%,
    #1bbedf 80%,
    #18a9f0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #22ebba 0%, #21e2c1 20%, #1fd4cb 45%, #1dc8d5 55%, #1bbedf 80%, #18a9f0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#22ebba', endColorstr='#18a9f0',GradientType=0 ); /* IE6-9 */
  height: 100%;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
  }

  .navigation a {
    font-family: Montserrat-Light !important;
    text-decoration: none;
    color: white;
    font-size: 15px;
    padding: 10px 25px;
    display: none;
    ${media.large} {
      display: block;
    }
  }

  .navigation .active {
    border-radius: 40px;
    background-color: #3091b1;
    transition-duration: 500ms;
  }

  .burger-container {
    display: block;
    ${media.large} {
      display: none;
    }
  }

  .language-container {
    display: none;
    width: 120px;
    ${media.large} {
      display: flex;
    }
    align-items: center;
    span {
      font-family: Montserrat-Light !important;
      color: white;
      font-size: 15px;
      font-weight: bold;
      padding: 0px 10px;
    }
  }
  .burger-options-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: white;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    animation: ${scrollDownFadeIn} 300ms ease-in-out;
    .burger-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a {
        font-family: Montserrat-Light !important;
        color: #727272;
        text-decoration: none;
        font-size: 15px;
        font-weight: bold;
        padding: 20px;
        width: 50%;
        text-align: center;
      }
      p {
        font-family: Montserrat-Light !important;
        color: #727272;
        text-decoration: none;
        font-size: 15px;
        font-weight: bold;
        padding: 20px;
        width: 50%;
        text-align: center;
      }
      .with-border-bottom {
        border-bottom: 2px solid #b2b2b2;
      }
    }
  }
`;
