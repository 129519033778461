import styled from 'styled-components/macro';
import { Display } from '../../../Common/interfaces';

const Container = styled.div`
  display: ${(props: Display) => (props && props.visible ? 'block' : 'none')};
  margin: 0px 0px 0px 22px;
  height: 20px;
`;

export const IsTypingStyled = { Container };
