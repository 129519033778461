import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function CloseCircle({
  style = {},
  fill = '#8F8F8F',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M540,36c278.4,0,504,225.6,504,504s-225.6,504-504,504S36,818.4,36,540S261.6,36,540,36 M540,6
			c-72.1,0-142,14.1-207.9,42c-63.6,26.9-120.7,65.4-169.7,114.4c-49,49-87.5,106.1-114.4,169.7C20.1,398,6,467.9,6,540
			s14.1,142,42,207.9c26.9,63.6,65.4,120.7,114.4,169.7c49,49,106.1,87.5,169.7,114.4c65.9,27.9,135.8,42,207.9,42
			s142-14.1,207.9-42c63.6-26.9,120.7-65.4,169.7-114.4c49-49,87.5-106.1,114.4-169.7c27.9-65.9,42-135.8,42-207.9
			s-14.1-142-42-207.9c-26.9-63.6-65.4-120.7-114.4-169.7c-49-49-106.1-87.5-169.7-114.4C682,20.1,612.1,6,540,6L540,6z"
          />
        </g>
      </g>
      <g transform="translate(8.939 8.94)">
        <path
          fill={fill}
          d="M718.3,357.4L718.3,357.4c20.3,21.8,19.1,56-2.7,76.3L426,703.8c-21.8,20.3-56,19.1-76.3-2.7
		l0,0c-20.3-21.8-19.1-56,2.7-76.3L642,354.7C663.8,334.4,698,335.6,718.3,357.4z"
        />
        <path
          fill={fill}
          d="M712.2,707.4L712.2,707.4c-21.1,21.1-55.3,21.1-76.4,0l-280-280c-21.1-21.1-21.1-55.3,0-76.4
		l0,0c21.1-21.1,55.3-21.1,76.4,0l280,280C733.3,652.2,733.3,686.4,712.2,707.4z"
        />
      </g>
    </svg>
  );
}
