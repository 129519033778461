import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmptyArray } from '../../utils/arrays';
import { deleteFile } from '../../Conversation/actions/deleteFile';
import { FilesData } from '../../Common/interfaces/files';
import { FriendData } from '../../Common/interfaces/friend';
import { checkFileType } from '../../utils/files';
import useGetMessageDataById from '../../Common/customHooks/useGetMessageDataById';
import FilePreview from '../../CommonComponents/FilePreview';
import { ChannelData } from '../../Common/interfaces/channel';
import { toggleDimmedModal } from '../../Navigation/actions';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';

const getUser = (conversation: ChannelData, userId: string) => {
  if (conversation && isNotEmptyArray(conversation.members)) {
    const user = conversation.members.filter(member => member.id === userId);
    if (isNotEmptyArray(user)) {
      return user[0];
    }
  }
};

const SharedFilePreview = ({ file, conversation }) => {
  const dispatch = useDispatch();
  const message = useGetMessageDataById(file.id);
  const fileType = checkFileType(file.fileType);
  const [selectedFile, setSelectedFile] = useState<FilesData | any>();
  const [user] = useState<FriendData | any>(() => getUser(conversation, file.userId));
  const isDeletingFile: boolean = useSelector(state => state.conversationReducer.deleteFileIsLoading);

  const renderPreview = (fileType: string | undefined, permalink: string) => {
    if (fileType === 'image') {
      return <img className="rounded-xl" alt="..." src={permalink} height="100%" width="100%" />;
    } else if (fileType === 'video') {
      return <video className="rounded-xl" src={permalink} height="100%" width="100%" />;
    } else if (fileType === 'audio') {
      return <IconContainer iconName="audio" iconWidth="75px" />;
    } else if (fileType === 'presentation') {
      return <IconContainer iconName="ppt" iconWidth="200px" />;
    } else if (fileType === 'pdf') {
      return <IconContainer iconName="pdf" iconWidth="200px" />;
    } else if (fileType === 'spreadsheet') {
      return <IconContainer iconName="spreadsheet" iconWidth="200px" />;
    } else if (fileType === 'doc') {
      return <IconContainer iconName="doc" iconWidth="200px" />;
    } else {
      return <IconContainer iconName="unknown-file" iconWidth="200px" />;
    }
  };

  const handleDeleteFile = async () => {
    await dispatch(deleteFile(selectedFile, conversation.id));
    setSelectedFile(undefined);
  };

  const handleViewFile = file => {
    setSelectedFile(file);
    dispatch(toggleDimmedModal(true));
  };

  const handleCloseViewFile = () => {
    setSelectedFile(undefined);
    dispatch(toggleDimmedModal(false));
  };

  return (
    <div className="col-4 position-static">
      {message && (
        <>
          <FilePreview
            isPreviewOpen={selectedFile && selectedFile.id === file.id && message}
            togglePreview={setSelectedFile}
            messageId={file.id}
            senderId={user.id}
            senderName={user.displayName}
            senderProfileImage={user.profileImageUrl}
            permalink={file.permalink}
            lastDateUpdated={message.lastDateUpdated}
            fileType={file.fileType}
            fileName={file.fileName}
            isDeletingFile={isDeletingFile}
            handleCloseViewFile={handleCloseViewFile}
            handleDeleteFile={handleDeleteFile}
            isBlocked={conversation.isBlocked}
          />
          <div
            className="h-200px w-200px mt-2 pointer"
            data-toggle="modal"
            data-target={'#filePreview' + message.id}
            onClick={() => handleViewFile(file)}
          >
            {renderPreview(fileType, file.permalink)}
          </div>
        </>
      )}
    </div>
  );
};

export default SharedFilePreview;
