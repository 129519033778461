import axios from 'axios';
import { REMOVE_MEMBER_FROM_GROUP_ERROR, REMOVE_MEMBER_FROM_GROUP_LOADING, REMOVE_MEMBER_FROM_GROUP_SUCCESS } from '../actionTypes';

export const isLoading = (bool: boolean) => {
  return {
    type: REMOVE_MEMBER_FROM_GROUP_ERROR,
    isLoading: bool,
  };
};

export const hasError = () => {
  return {
    type: REMOVE_MEMBER_FROM_GROUP_LOADING,
    hasError: true,
  };
};
export const isSuccess = (newObj, groupId) => {
  return {
    type: REMOVE_MEMBER_FROM_GROUP_SUCCESS,
    memberId: newObj.memberId,
    groupId: groupId,
  };
};

export const removeMemberFromGroup = (requestBody, groupId) => {
  return async dispatch => {
    dispatch(isLoading(true));
    return await axios
      .delete(process.env.REACT_APP_API_URL + `/conversations/members/${groupId}`, {
        data: requestBody,
      })
      .then(
        response => {
          dispatch(isSuccess(requestBody, groupId));
        },
        err => {
          dispatch(hasError());
        }
      );
  };
};
