import axios from 'axios';
import {
  GET_USER_NOTIFICATION_CONFIG_ERROR,
  GET_USER_NOTIFICATION_CONFIG_LOADING,
  GET_USER_NOTIFICATION_CONFIG_SUCCESS,
} from '../actionTypes';

export function hasError() {
  return {
    type: GET_USER_NOTIFICATION_CONFIG_ERROR,
  };
}

export function isLoading() {
  return {
    type: GET_USER_NOTIFICATION_CONFIG_LOADING,
  };
}

export function isSuccess(payload) {
  localStorage.setItem('userSettings', JSON.stringify(payload));
  return {
    type: GET_USER_NOTIFICATION_CONFIG_SUCCESS,
    payload: payload,
  };
}

export function getUserNotificationConfig(userId: string, isBrowserNotificationGranted: boolean) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios.get(process.env.REACT_APP_API_URL + `/users/settings/${userId}`).then(
      response => {
        if (!isBrowserNotificationGranted) {
          response.data.userSettings.messagePreview = false;
          response.data.userSettings.pushNotification = false;
        }
        dispatch(isSuccess(response.data.userSettings));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
}
