import React from 'react';
import { Formik, Field } from 'formik';
import { ChangePasswordValidationSchema } from '../../../utils/validation_schema';
import Flex from '../../../CommonComponents/Flex';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { ChangePasswordInput } from '../../../CommonComponents/Input';
import { useTranslation } from 'react-i18next';

const { Span } = TypographyStyled;

export default function ChangePasswordForm({ onSubmit }) {
  const { t } = useTranslation();
  return (
    <>
      <Flex container flexDirection="column" alignItems="center">
        <Span size="xxl" fontWeight="bold" fontColor="#444444" margin="25px">
          {t('ChangePassword')}
        </Span>
        <Span size="xl" fontColor="#444444" margin="25px">
          {t('YouMayNowEnterYourNewPassword')}
        </Span>
      </Flex>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        onSubmit={data => onSubmit(data)}
        validationSchema={ChangePasswordValidationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <div className="container d-flex justify-content-center align-items-center flex-column">
            <div className="form-group col-xs-12 col-lg-6">
              <Field name="oldPassword" type="password" as={ChangePasswordInput} label="OldPassword" inputType="password" />
            </div>
            <div className="form-group col-xs-12 col-lg-6">
              <Field name="newPassword" type="password" as={ChangePasswordInput} label="NewPassword" inputType="password" />
            </div>
            <div className="form-group col-xs-12 col-lg-6">
              <Field name="confirmNewPassword" type="password" as={ChangePasswordInput} label="ConfirmNewPassword" inputType="password" />
            </div>
            <div className="form-group col-xs-12 col-lg-6">
              <button
                className={`btn btn-${isValid ? 'primary' : 'secondary text-white'} p-2 w-100 rounded-pill`}
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                {t('UpdatePassword')}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
