import styled from 'styled-components/macro';

const Container = styled.div`
  flex: 0 1 115px;
  height: 115px;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
`;

const SearchBarContainer = styled.div`
  flex: 0 1 60px;
  height: 60px;
  display: flex;
  background-color: #ebebeb;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
`;

const SearchInputMask = styled.div`
  display: flex;
  background-color: #ffffff;
  border: none;
  border-radius: 30px;
  align-items: center;
  outline: none;
  padding: 0px 5px;
  margin: 0px 15px 0px 0px;
  text-align: center;
  width: 100%;
`;

const SearchBarInput = styled.input`
  font-size: 20px;
  border: none;
  height: 40px;
  width: 100%;
  margin: 0px 5px;
`;

const SharedFileListContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  flex-direction: column;
  display: flex;
`;

export const SharedFilesStyled = {
  Container,
  SearchBarContainer,
  SearchInputMask,
  SearchBarInput,
  SharedFileListContainer,
};
