import { isNotEmptyArray } from '../arrays';

export const handleReactionsArray = (emoji, message, loggedInUserId) => {
  let mappedReactions: any = [];
  const colonName = ':' + emoji.short_names[emoji.short_names.length - 1] + ':';

  if (message && message.reactions) {
    let reactions: any = [...message.reactions];

    if (isNotEmptyArray(reactions)) {
      //reaction found in a message, checking if the reaction has similar reaction to the message
      let emojiNotFoundInReactions = false;
      let emojiWasRemoved = false;
      let userWithEmojiFound = false;
      mappedReactions = reactions.map(reaction => {
        if (reaction.name === colonName) {
          //found a same reaction, checking if the user is including in the list
          if (reaction.users.includes(loggedInUserId)) {
            //found a user, removing reaction to the list
            emojiWasRemoved = true;

            reaction.count = reaction.count - 1;
            reaction.users.splice(reaction.users.indexOf(loggedInUserId), 1);
          } else {
            userWithEmojiFound = true;
            //user not found, adding reaction to the list
            reaction.count = reaction.count + 1;
            reaction.users.push(loggedInUserId);
          }
        } else {
          emojiNotFoundInReactions = true;
        }
        return reaction;
      });
      if (emojiNotFoundInReactions && !emojiWasRemoved && !userWithEmojiFound) {
        //No reaction found on message, adding reaction to the list
        mappedReactions.push({
          count: 1,
          name: colonName,
          users: [loggedInUserId],
        });
      }
    } else {
      //No reaction found on message, adding reaction to the list
      mappedReactions.push({
        count: 1,
        name: colonName,
        users: [loggedInUserId],
      });
    }
  } else {
    //No reaction found on message, adding reaction to the list
    mappedReactions.push({
      count: 1,
      name: colonName,
      users: [loggedInUserId],
    });
  }
  return mappedReactions.filter(reaction => reaction.count !== 0);
};
