import axios from 'axios';
import { UPDATE_GROUP_NAME_ERROR, UPDATE_GROUP_NAME_LOADING, UPDATE_GROUP_NAME_SUCCESS } from '../actionTypes';

export const isLoading = (bool: boolean) => {
  return {
    type: UPDATE_GROUP_NAME_LOADING,
    isLoading: bool,
  };
};

export const hasError = () => {
  return {
    type: UPDATE_GROUP_NAME_ERROR,
    hasError: true,
  };
};
export const isSuccess = newObj => {
  return {
    type: UPDATE_GROUP_NAME_SUCCESS,
    newObj: newObj,
  };
};

export const updateGroupName = (requestBody, groupId) => {
  return async dispatch => {
    dispatch(isLoading(true));
    return await axios.put(process.env.REACT_APP_API_URL + `/conversations/${groupId}`, requestBody).then(
      response => {
        dispatch(isSuccess(response.data.conversations));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
};
