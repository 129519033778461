import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SECOND_PANEL_VIEW, THIRD_PANEL_VIEW } from '../../Common/enums';
import { changeSecondPanelView, changeThirdPanelView } from '../../Navigation/actions';
import GroupList from '../../ChannelList';
import ChatList from '../../ChatList';
import { isNotEmptyArray, removeElementInsideArray } from '../../utils/arrays';
import { SidePanelBodyStyled } from './styled';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import FriendList from '../../FriendList';
import { useTranslation } from 'react-i18next';
import Flex from '../../CommonComponents/Flex';
import { FriendData } from '../../Common/interfaces/friend';
import ThreadList from '../../ThreadList';
import FavoriteList from '../../FavoriteList';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';

const { ConversationThread, NewMessageContainer } = SidePanelBodyStyled;
const { UserAvatar } = ImageStyled;
const { Span } = TypographyStyled;

const SidePanelBody: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigationState = useSelector(state => state.navigationReducer);
  const members: Array<FriendData> = useSelector(state => state.createChannelReducer.members);
  const threads: any = useSelector(state => state.threadReducer.threads);
  const { secondPanelView, thirdPanelView }: { secondPanelView: SECOND_PANEL_VIEW; thirdPanelView: THIRD_PANEL_VIEW } = navigationState;
  const [threadUnreadCount, setThreadUnreadCount] = useState(0);

  const renderCreateChannelCard = () => {
    if (isNotEmptyArray(members)) {
      const MAX_MEMBERS = 3;
      return members.map((member, index) => {
        if (index < MAX_MEMBERS) {
          return (
            <UserAvatar
              key={member.friendId}
              src={member.friendProfileImageUrl}
              alt=""
              width="45"
              height="45"
              style={{ transform: `translate(-${index}0px, 0px)` }}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return <UserAvatar src={process.env.PUBLIC_URL + '/assets/images/users/default.jpg'} alt="" width="40" height="40" />;
    }
  };

  const handleConversationThreadCardClick = () => {
    if (secondPanelView === SECOND_PANEL_VIEW.THREAD_VIEW) {
      dispatch(changeSecondPanelView(SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW));
    } else {
      dispatch(changeSecondPanelView(SECOND_PANEL_VIEW.THREAD_VIEW));
    }
  };

  useEffect(() => {
    let numberOfUnreadCount = 0;
    if (isNotEmptyArray(threads)) {
      const arrayOfUnreadCount = threads.map(threads => threads.unreadCount);
      const modArray = removeElementInsideArray(arrayOfUnreadCount, undefined);
      numberOfUnreadCount = numberOfUnreadCount + modArray.reduce((acc, val) => acc + val, 0);
    }
    setThreadUnreadCount(numberOfUnreadCount);
  }, [threads]);

  return (
    <>
      <div
        style={{
          display:
            secondPanelView === SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW || secondPanelView === SECOND_PANEL_VIEW.THREAD_VIEW
              ? 'block'
              : 'none',
        }}
      >
        <ConversationThread isActive={secondPanelView === SECOND_PANEL_VIEW.THREAD_VIEW} onClick={handleConversationThreadCardClick}>
          <IconContainer
            margin="0px 15px 0px 0px"
            iconName="thread"
            iconFill={secondPanelView === SECOND_PANEL_VIEW.THREAD_VIEW ? '#8c8c8c' : '#BDBDBD'}
            iconWidth="45px"
          />
          <Span fontColor="#444444" size="lg" fontWeight={threadUnreadCount > 0 ? 'bolder' : 'normal'}>
            {t('Thread')}
          </Span>
          {threadUnreadCount > 0 && (
            <Flex
              container
              alignItems="center"
              justifyContent="center"
              height="25px"
              width="25px"
              margin="20px"
              background="red"
              borderRadius="50%"
            >
              <Span fontColor="white" size="md">
                {threadUnreadCount}
              </Span>
            </Flex>
          )}
        </ConversationThread>
        <NewMessageContainer visible={thirdPanelView === THIRD_PANEL_VIEW.CREATE_CHANNEL}>
          <Flex container alignItems="center" handleOnClick={() => dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CREATE_CHANNEL))}>
            {renderCreateChannelCard()}
            <Span fontColor="#444444" size="lg" margin="0px 0px 0px 25px">
              {t('NewMessage')}
            </Span>
          </Flex>
          <IconContainer onClick={() => dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION))} iconName="close" iconWidth="30px" />
        </NewMessageContainer>
        <div style={{ display: secondPanelView === SECOND_PANEL_VIEW.THREAD_VIEW ? 'block' : 'none' }}>
          <ThreadList />
        </div>
        <div style={{ display: secondPanelView === SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW ? 'block' : 'none' }}>
          <ChatList />
        </div>
      </div>
      <div style={{ display: secondPanelView === SECOND_PANEL_VIEW.CHANNEL_FRIEND_VIEW ? 'block' : 'none' }}>
        <FavoriteList />
        <GroupList />
        <FriendList />
      </div>
    </>
  );
};

export default SidePanelBody;
