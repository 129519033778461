import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function ChevronLeft({ style = {}, width = '100%', className = '', viewBox = '0 0 42 42' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(181.894 510.965) rotate(180)">
        <g transform="translate(159.596 468)">
          <line x2="30.034" transform="translate(0 21.237) rotate(-45)" fill="none" stroke="#b2b2b2" strokeWidth="3" />
          <line x2="30.034" transform="translate(0 20.667) rotate(45)" fill="none" stroke="#b2b2b2" strokeWidth="3" />
        </g>
      </g>
    </svg>
  );
}
