import {
  SET_PARENT_THREAD,
  SET_THREAD_ACTIVE_CONVERSATION_ID,
  SEND_THREAD_MESSAGE_LOADING,
  SEND_THREAD_MESSAGE_ERROR,
  SEND_THREAD_MESSAGE_SUCCESS,
  GET_THREAD_LIST_LOADING,
  GET_THREAD_LIST_ERROR,
  GET_THREAD_LIST_SUCCESS,
  UPDATE_THREAD_LIST,
  UPDATE_THREAD_IN_LIST,
} from '../actions/actionTypes';

const initialState = {
  parentThread: {},
  threadActiveConversationId: '',
  sendMessageIsLoading: false,
  sendMessageHasError: false,
  getThreadsIsLoading: false,
  getThreadsHasError: false,
  threads: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PARENT_THREAD:
      return {
        ...state,
        parentThread: action.parentThread,
      };
    case SET_THREAD_ACTIVE_CONVERSATION_ID:
      return {
        ...state,
        threadActiveConversationId: action.conversationId,
      };
    case SEND_THREAD_MESSAGE_LOADING:
      return {
        ...state,
        sendMessageIsLoading: action.isLoading,
      };
    case SEND_THREAD_MESSAGE_ERROR:
      return {
        ...state,
        sendMessageHasError: action.hasError,
      };
    case SEND_THREAD_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageIsSuccess: false,
      };
    case GET_THREAD_LIST_LOADING:
      return {
        ...state,
        getThreadsIsLoading: true,
      };
    case GET_THREAD_LIST_ERROR:
      return {
        ...state,
        getThreadsHasError: true,
      };
    case GET_THREAD_LIST_SUCCESS:
      return {
        ...state,
        getThreadsIsLoading: false,
        threads: action.payload,
      };
    case UPDATE_THREAD_LIST:
      return {
        ...state,
        threads: action.threadList,
      };
    case UPDATE_THREAD_IN_LIST:
      return {
        ...state,
        threads: state.threads.map((thread: any) => {
          if (thread.parentMessageId === action.thread.id) {
            thread.thread = action.thread;
          }
          return thread;
        }),
      };
    default:
      return state;
  }
};
