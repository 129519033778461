import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function ArrowDown({
  fill = '#FFFFFF',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 550 500',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        stroke={fill}
        strokeWidth="30"
        strokeMiterlimit="10"
        d="M149.4,215.4c0.3-4.3,4.7-7.6,9.7-7.3c2.3,0.1,4.3,1,5.6,2.4L275,306.7l0,0l110.3-96.3c1.5-1.3,3.8-2.4,6.3-2.4
	c5,0,9,3.5,9,7.9c0,2.2-1,4.2-2.8,5.5L281.3,323.1c-1.5,1.5-3.8,2.4-6.3,2.4s-4.8-0.9-6.3-2.4L151.9,221.4
	C150.3,220,149.2,217.8,149.4,215.4z"
      />
    </svg>
  );
}
