import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ChangePasswordForm from './ChangePasswordForm';
import Flex from '../../CommonComponents/Flex';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { UserData } from '../../Common/interfaces/user';
import { changeUserPassword } from '../actions';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { AccountSettingsStyled } from '../styled';

const { HeaderMessage } = AccountSettingsStyled;
const { Span } = TypographyStyled;

export default function ChangePassword() {
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const [headerState, setHeaderState] = useState({
    state: '',
    message: '',
  });

  const handleChangePassword = async data => {
    const requestBody = {
      email: loggedInUser.email,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    return await changeUserPassword(requestBody)
      .then(res => {
        if (res.data.ok) {
          setHeaderState({
            state: 'success',
            message: res.data.message,
          });
        }
      })
      .catch(err =>
        setHeaderState({
          state: 'error',
          message: err.response.data.message,
        })
      );
  };

  return (
    <Flex container flexDirection="column" alignItems="center">
      {headerState.message && (
        <HeaderMessage state={headerState.state}>
          <IconContainer iconName={headerState.state === 'success' ? 'gradient-check' : ''} iconWidth="45px" />
          <Span size="lg" fontColor="#444444">
            {headerState.message}
          </Span>
          <Flex></Flex>
        </HeaderMessage>
      )}
      <ChangePasswordForm onSubmit={handleChangePassword} />
    </Flex>
  );
}
