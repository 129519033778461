import styled, { keyframes, css } from 'styled-components/macro';

interface SkeletonProps {
  height?: number;
  wrapperHeight?: number;
  w70?: boolean;
  w30?: boolean;
}

const keyframesFullView = keyframes`
  100% {
    width: 100%;
  }
`;

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`;

const shimmerAnimation = css`
  background: linear-gradient(to right, #d2d2d2 4%, #bbb 25%, #d2d2d2 36%);
  background-size: 80vw 100%;
  animation: ${keyframesShimmer} 2s infinite linear;
`;

const SkeletonWrapper = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: space-around;
  height: ${(props: SkeletonProps) => props?.wrapperHeight || 70}px;
  width: 0px;
  margin-bottom: 10px;
  color: #eff1f3;
  animation: ${keyframesFullView} 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const SkeletonAvatar = styled.div`
  height: 45px;
  width: 60px;
  border-radius: 50%;
  margin-right: 10px;
  ${shimmerAnimation}
`;

const SkeletonArea = styled.div`
  display: block;
  width: 100%;
`;

const Skeleton = styled.div`
  height: ${(props: SkeletonProps) => props?.height || 15}px;
  ${({ w70 }) => w70 && 'width: 70%;'}
  ${({ w30 }) => w30 && 'width: 30%;'}
  background: #777;
  margin-bottom: 5px;
  border-radius: 5px;
  ${shimmerAnimation}
`;
export const SkeletonStyled = {
  SkeletonWrapper,
  SkeletonAvatar,
  SkeletonArea,
  Skeleton,
};
