import React from 'react';
import { Formik, Field } from 'formik';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { ButtonStyled } from '../../../CommonComponents/Button/Button.style';
import Flex from '../../../CommonComponents/Flex';
import { CustomSelect } from '../../../CommonComponents/Input';
import { currentLang } from '../../../utils/language';
import { useTranslation } from 'react-i18next';

const { Span } = TypographyStyled;
const { SolidColorButton } = ButtonStyled;

export default function LanguageForm({ onSubmit }) {
  const { t } = useTranslation();
  return (
    <>
      <Flex container justifyContent="center">
        <Span size="xxl" fontWeight="bold" fontColor="#444444" margin="25px">
          {t('ChangeLanguageSettings')}
        </Span>
      </Flex>
      <Formik
        initialValues={{
          language: currentLang,
        }}
        onSubmit={data => onSubmit(data)}
      >
        {({ handleSubmit, isSubmitting, isValid, dirty, ...formikProps }) => (
          <Flex width="50%">
            <Flex width="100%" container justifyContent="space-between" alignItems="center" margin="50px 0px">
              <Span size="lg" fontColor="#444444">
                {t('SelectLanguage')}
              </Span>
              <Field
                name="language"
                type="select"
                as={CustomSelect}
                formik={formikProps}
                inputType="select"
                width="50%"
                options={[
                  { label: 'English', value: 'en' },
                  { label: 'Japanese', value: 'ja' },
                ]}
              />
            </Flex>
            <Flex width="100%" container justifyContent="center">
              <SolidColorButton
                color="white"
                backgroundColor="#03E9FF"
                width="100%"
                padding="10px"
                type="button"
                fontSize="25"
                borderRadius="10px"
                onClick={() => handleSubmit()}
              >
                {t('UpdateLanguageSettings')}
              </SolidColorButton>
            </Flex>
          </Flex>
        )}
      </Formik>
    </>
  );
}
