import { useState, useEffect } from 'react';
import axios from 'axios';
import { FilesData } from '../interfaces/files';

export default function useGetAllExchangedFilesByConversationId(conversationId: string): [Array<FilesData>, boolean] {
  const [files, setFiles] = useState<Array<FilesData>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (conversationId) {
      setFiles([]);
      setIsLoading(true);
      axios
        .get(process.env.REACT_APP_API_URL + `/files`, {
          params: {
            conversationId: conversationId,
          },
        })
        .then(response => {
          if (response && response.data && response.data.files) {
            setFiles(response.data.files);
          }
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
        });
    }
  }, [conversationId]);

  return [files, isLoading];
}
