import React from 'react';
import Routes from './routes';
import { ThemeProvider } from 'styled-components/macro';
import Theme from './Styled';
import { GlobalStyle } from './config/globalStyle';
import useReactGa from './Common/customHooks/useReactGa';
import './customStyles.css';

function App() {
  useReactGa();

  return (
    <div className="App">
      <GlobalStyle />
      <ThemeProvider theme={Theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
