import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, FirstPanel, SecondPanel, ThirdPanel, Button } from './styled';
const laptopImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Flaptopx2.png?alt=media&token=79d3efb8-3a91-47f1-b458-16a9bc2bed79';
const phoneImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fphone-small.png?alt=media&token=2343f264-0e8c-4b91-9484-377ce87d5f88';

export default function Default() {
  const { t, i18n } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container>
      <FirstPanel>
        <div className="left-panel">
          <img src={laptopImage} alt="laptop" />
        </div>
        <div className="right-panel">
          <p className="panel-title">{t('TheSimplest')}</p>
          <p className="panel-sub-title">{t('TheSimplestContext.1')}</p>
          <p className="panel-sub-title">{t('TheSimplestContext.2')}</p>
          <p className="panel-sub-title">{t('TheSimplestContext.3')}</p>
          <p className="panel-sub-title">{t('TheSimplestContext.4')}</p>
          <p className="panel-sub-title">{t('TheSimplestContext.5')}</p>
          <br />
          <Link to="/">
            <Button>{t('StartNow').toUpperCase()}</Button>
          </Link>
        </div>
      </FirstPanel>
      <SecondPanel>
        <div className="left-panel">
          <img src={phoneImage} alt="phone" className="img-fluid" />
        </div>
        <div className="right-panel">
          <p className="panel-title">{t('MostExciting')}</p>
          {i18n.language === 'en' ? (
            <p className="panel-sub-title">{t('MostExcitingContext')}</p>
          ) : (
            <>
              <p className="panel-sub-title">{t('MostExcitingContext.1')}</p>
              <p className="panel-sub-title">{t('MostExcitingContext.2')}</p>
              <p className="panel-sub-title">{t('MostExcitingContext.3')}</p>
              <p className="panel-sub-title">{t('MostExcitingContext.4')}</p>
            </>
          )}
          <Link to="/lp/features" onClick={scrollToTop}>
            <Button>{t('Features').toUpperCase()}</Button>
          </Link>
        </div>
      </SecondPanel>
      <ThirdPanel>
        <div className="container left-container">
          <p className="message-title">{t('Expression')}</p>
          <p className="message-text">{t('ExpressionContext')}</p>
        </div>
        <div className="container middle-container">
          <p className="message-title">{t('Flexibility')}</p>
          {i18n.language === 'en' ? (
            <>
              <p className="message-text">{t('FlexibilityContext.1')}</p>
              <p className="message-text">{t('FlexibilityContext.2')}</p>
            </>
          ) : (
            <p className="message-text">{t('FlexibilityContext')}</p>
          )}
        </div>
        <div className="container right-container">
          <p className="message-title">{t('Serenity')}</p>
          <p className="message-text">{t('SerenityContext')}</p>
        </div>
      </ThirdPanel>
    </Container>
  );
}
