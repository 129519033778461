import axios from 'axios';
import { FAVORITE_USER_ERROR, FAVORITE_USER_LOADING, UPDATE_FRIEND_STATUS } from '../actionTypes';

export function favoriteUserError(bool: boolean, errMessage: string) {
  return {
    type: FAVORITE_USER_ERROR,
    hasError: bool,
    errMessage: errMessage,
  };
}

export function favoriteUserLoading(bool: boolean) {
  return {
    type: FAVORITE_USER_LOADING,
    isLoading: bool,
  };
}

export function favoriteUserSuccess(friendStatus) {
  return {
    type: UPDATE_FRIEND_STATUS,
    friendStatus: friendStatus,
  };
}

export function favoriteUser(formData, friendId: string) {
  return async dispatch => {
    dispatch(favoriteUserLoading(true));
    return await axios.put(process.env.REACT_APP_API_URL + `/friends/${friendId}/favorite`, formData).then(
      response => {
        if (response.data) {
          dispatch(favoriteUserSuccess(response.data.friend.friendStatus));
        }
      },
      err => {
        dispatch(favoriteUserError(true, err.response));
      }
    );
  };
}
