import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserData } from '../Common/interfaces/user';
import { isNotEmptyArray } from '../utils/arrays';
import ThreadCard from './ThreadCard';
import useGetAllThreadsByOwnerId from '../Common/customHooks/useGetAllThreadsByOwnerId';
import { updateThreadList } from '../Thread/actions';
import { ThreadListData } from '../Common/interfaces/thread';

const ThreadList: FC = () => {
  const dispatch = useDispatch();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const threads: Array<ThreadListData> = useSelector(state => state.threadReducer.threads);
  const threadsPerMember = useGetAllThreadsByOwnerId(loggedInUser.id);

  useEffect(() => {
    dispatch(updateThreadList(threadsPerMember));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadsPerMember]);

  return <>{isNotEmptyArray(threads) && threads.map(thread => <ThreadCard key={thread.id} threadRawData={thread} />)}</>;
};

export default ThreadList;
