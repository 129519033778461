import { SET_PARENT_THREAD, UPDATE_THREAD_LIST, UPDATE_THREAD_IN_LIST, SET_THREAD_ACTIVE_CONVERSATION_ID } from './actionTypes';
import { ThreadListData } from '../../Common/interfaces/thread';

export const setParentThread = (parentThread: ThreadListData) => {
  return {
    type: SET_PARENT_THREAD,
    parentThread,
  };
};

export const setThreadConversationId = (conversationId: string) => {
  return {
    type: SET_THREAD_ACTIVE_CONVERSATION_ID,
    conversationId,
  };
};

export const updateThreadList = threadList => {
  return {
    type: UPDATE_THREAD_LIST,
    threadList,
  };
};

export const updateThreadInList = thread => {
  return {
    type: UPDATE_THREAD_IN_LIST,
    thread,
  };
};
