import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../CommonComponents/Flex';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';

const { Span, Link } = TypographyStyled;

export default function UsageSituationForm({ onSubmit, usageSituation, storageCapacity }) {
  const { t } = useTranslation();

  const determineStorageColor = () => {
    const percentage = (storageCapacity.storageSize / storageCapacity.storageLimit) * 100;
    if (percentage <= 50) {
      return '#0BE9A3';
    } else if (percentage <= 70) {
      return '#30CCFE';
    } else if (percentage <= 80) {
      return '#FDDC5B';
    } else {
      return '#FF3737';
    }
  };

  return (
    <>
      <Flex container flexDirection="column" alignItems="center" width="75%">
        <Span size="xxl" fontWeight="bold" fontColor="#444444" margin="25px">
          {t('UsageStituation')}
        </Span>
        <Span size="xl" fontColor="#444444" margin="25px" center>
          {t('AllSharedFilesTheseIncludesImagesVideosFilesEtcWillBeAutomaticallyDeletedAfterTwoWWeeksFromItWasSent')}
        </Span>
      </Flex>
      <Flex container margin="25px" width="75%" justifyContent="space-between">
        <Flex>
          {usageSituation ? (
            <Link size="xxl" fontColor="#CACACA" textDecoration="underline" onClick={onSubmit}>
              {t('TurnOff')}
            </Link>
          ) : (
            <Link size="xxl" fontColor="#09BBFF" textDecoration="underline" onClick={onSubmit}>
              {t('TurnOn')}
            </Link>
          )}
          {usageSituation && (
            <Flex margin="20px 0px">
              <Span size="lg" fontColor="#CACACA" center>
                {t('TurnOFFToNotDeleteSharedFilesAutomatically')}
              </Span>
            </Flex>
          )}
          <Flex margin="20px 0px">
            <Span size="lg" fontColor="#444444" center>
              {t(usageSituation ? 'CurrentStatusOn' : 'CurrentStatusOff')}
            </Span>
          </Flex>
        </Flex>
        <Flex>
          <Flex>
            <Span size="xxl" fontColor={determineStorageColor()} center>
              {`${storageCapacity.storageSize}GB / ${storageCapacity.storageLimit}GB`}
            </Span>
          </Flex>
          <Flex margin="20px 0px">
            <Span size="md" fontColor="#444444" center>
              {`${t('CurrentStatus')}: ${((storageCapacity.storageSize / storageCapacity.storageLimit) * 100).toFixed(2)}%`}
            </Span>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
