import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Logout({ style = {}, width = '100%', className = '', viewBox = '0 0 747 785' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#42BCF0"
        d="M353.9000244140625,784.7480979919434 c-105.7,-0.4 -196.7,-37.7 -272.7,-111.2 c-23.5,-22.7 -26.8,-47.6 -8.9,-66.2 c17.6,-18.3 42.5,-15.4 66.3,7.1 c74.3,69.9 175.1,106.5 274.6,84.1 c137.3,-30.9 220.5,-116.7 247,-248 c26.3,-130.1 -37,-262 -154.8,-327.9 c-115.2,-64.5 -260.7,-47.3 -358.2,42.4 c-5.2,4.8 -10,10.1 -15.2,14.8 c-19.5,17.6 -42.5,18 -59.3,1.4 c-15.8,-15.6 -17.6,-43.7 1.8,-59.1 c37.9,-30.2 75.5,-64.2 119,-83.5 c152,-67.4 297.5,-47 422.8,64 c113.2,99.9 157.4,254 115.4,399.1 c-46.1,159 -157.7,252.4 -322.7,280.4 c-7.5,1.3 -19.9,2.6 -27.5,2.7 C372.4000244140625,784.9480979919433 363.2000244140625,784.7480979919434 353.9000244140625,784.7480979919434 z"
      />
      <path
        fill="#42BCF0"
        d="M280.2000244140625,181.24809799194338 l-1.6,102.7 c0,8.3 6.6,15 14.9,15 c59.2,0.1 161.3,0.9 209.5,-0.5 c22.2,-0.7000000000000002 30.2,5 29.5,28.3 c-1.4,46.4 -0.7000000000000002,92.8 -0.5,141.8 c0,10.8 -8.8,19.8 -19.7,19.8 c-18.8,0 -36.8,0 -54.8,0 c-31.5,0 -127,0.8 -158.5,-0.3000000000000001 c-16.8,-0.6000000000000002 -22,5.7 -21.3,21.9 c1.1,26.4 0.3000000000000001,52.8 0.3000000000000001,85.6 c-91.4,-69.2 -189.2,-136 -278,-203.2 C88.4000244140625,325.34809799194335 188.00002441406252,251.14809799194336 280.2000244140625,181.24809799194338 z"
      />
    </svg>
  );
}
