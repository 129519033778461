import React, { ReactElement } from 'react';
import Wifi from './Wifi';
import World from './World';
import Reaction from './Reaction';
import Sticker from './Sticker';
import Thread from './Thread';
import Select from './Select';
import Close from './Close';
import CloseSearchMessageList from './CloseSearchMessageList';
import Copy from './Copy';
import EditMessage from './EditMessage';
import Facebook from './Facebook';
import Line from './Line';
import Option from './Option';
import SearchChatBox from './SearchChatBox';
import Search from './Search';
import Kebab from './Kebab';
import UnCheck from './UnCheck';
import AddCircle from './AddCircle';
import Notification from './Notification';
import Logout from './Logout';
import Logo from './Logo';
import MessageList from './MessageList';
import FriendList from './FriendList';
import MessageOption from './MessageOption';
import Camera from './camera';
import AddMessage from './AddMessage';
import Favorite from './Favorite';
import Ban from './Ban';
import Attach from './Attach';
import Bold from './Bold';
import ColorPalette from './ColorPalette';
import Smiley from './Smiley';
import Clipboard from './Clipboard';
import GradientCheck from './GradientCheck';
import ErrorCircle from './ErrorCircle';
import ArrowDown from './ArrowDown';
import CloseCircle from './CloseCircle';
import Sound from './Sound';
import Send from './Send';
import Download from './Download';
import Upload from './Upload';
import Delete from './Delete';
import DownloadWithBorder from './DownloadWithBorder';
import Ppt from './Ppt';
import Pdf from './Pdf';
import SpreadSheet from './Spreadsheet';
import UnknownFile from './UnknownFile';
import Doc from './Doc';
import Gallery from './Galllery';
import Loading from './Loading';
import Back from './Back';
import Multiply from './Multiply';
import Check from './Check';
import Settings from './Settings';
import Mute from './Mute';
import Audio from './Audio';
import ChevronRight from './ChevronRight';
import ChevronLeft from './ChevronLeft';
import Bullet from './Bullet';
import BulletDash from './BulletDash';
import AndroidPlaystore from './AndroidPlaystore';
import IosPlaystore from './IosPlaystore';
import Twitter from './Twiiter';

export interface IconProps {
  name?: string;
  style?: object;
  fill?: string;
  stroke?: string;
  border?: string;
  width?: string;
  height?: string;
  className?: string;
  viewBox?: string;
}

export default function Icon(props: IconProps): ReactElement | null {
  switch (props.name) {
    case 'add-circle':
      return <AddCircle {...props} />;
    case 'wifi':
      return <Wifi {...props} />;
    case 'world':
      return <World {...props} />;
    case 'reaction':
      return <Reaction {...props} />;
    case 'sticker':
      return <Sticker {...props} />;
    case 'thread':
      return <Thread {...props} />;
    case 'select':
      return <Select {...props} />;
    case 'uncheck':
      return <UnCheck {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'close-search-message-list':
      return <CloseSearchMessageList {...props} />;
    case 'copy':
      return <Copy {...props} />;
    case 'edit-message':
      return <EditMessage {...props} />;
    case 'facebook':
      return <Facebook {...props} />;
    case 'line':
      return <Line {...props} />;
    case 'option':
      return <Option {...props} />;
    case 'search-chat-box':
      return <SearchChatBox {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'kebab':
      return <Kebab {...props} />;
    case 'notification':
      return <Notification {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'logo':
      return <Logo {...props} />;
    case 'message-list':
      return <MessageList {...props} />;
    case 'message-option':
      return <MessageOption {...props} />;
    case 'friend-list':
      return <FriendList {...props} />;
    case 'camera':
      return <Camera {...props} />;
    case 'add-message':
      return <AddMessage {...props} />;
    case 'favorite':
      return <Favorite {...props} />;
    case 'ban':
      return <Ban {...props} />;
    case 'attach':
      return <Attach {...props} />;
    case 'bold':
      return <Bold {...props} />;
    case 'color-palette':
      return <ColorPalette {...props} />;
    case 'smiley':
      return <Smiley {...props} />;
    case 'clipboard':
      return <Clipboard {...props} />;
    case 'gradient-check':
      return <GradientCheck {...props} />;
    case 'error-circle':
      return <ErrorCircle {...props} />;
    case 'arrow-down':
      return <ArrowDown {...props} />;
    case 'close-circle':
      return <CloseCircle {...props} />;
    case 'sound':
      return <Sound {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'download':
      return <Download {...props} />;
    case 'download-with-border':
      return <DownloadWithBorder {...props} />;
    case 'upload':
      return <Upload {...props} />;
    case 'delete':
      return <Delete {...props} />;
    case 'ppt':
      return <Ppt {...props} />;
    case 'pdf':
      return <Pdf {...props} />;
    case 'spreadsheet':
      return <SpreadSheet {...props} />;
    case 'audio':
      return <Audio {...props} />;
    case 'unknown-file':
      return <UnknownFile {...props} />;
    case 'doc':
      return <Doc {...props} />;
    case 'gallery':
      return <Gallery {...props} />;
    case 'loading':
      return <Loading {...props} />;
    case 'back':
      return <Back {...props} />;
    case 'multiply':
      return <Multiply {...props} />;
    case 'check':
      return <Check {...props} />;
    case 'settings':
      return <Settings {...props} />;
    case 'mute':
      return <Mute {...props} />;
    case 'chevron-left':
      return <ChevronLeft {...props} />;
    case 'chevron-right':
      return <ChevronRight {...props} />;
    case 'bullet':
      return <Bullet {...props} />;
    case 'bullet-dash':
      return <BulletDash {...props} />;
    case 'android-playstore':
      return <AndroidPlaystore {...props} />;
    case 'ios-playstore':
      return <IosPlaystore {...props} />;
    case 'twitter':
      return <Twitter {...props} />;
    default:
      return null;
  }
}
