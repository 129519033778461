import {
  FILTER_GROUPS_BY_NAME,
  GET_GROUP_INFO,
  CLEAR_CHANNEL_REDUCER,
  UPDATE_GROUP_OBJECT,
  UPDATE_CHANNEL_LIST,
  UPDATE_CHANNEL_IN_LIST,
} from './actionTypes';
import { ChannelData } from '../../Common/interfaces/channel';

export function filterChannelsByName(name: string) {
  return {
    type: FILTER_GROUPS_BY_NAME,
    name: name,
  };
}

export function getGroupInfo(selectedGroup: ChannelData) {
  return {
    type: GET_GROUP_INFO,
    group: selectedGroup,
  };
}

export function clearChannelReducer() {
  return {
    type: CLEAR_CHANNEL_REDUCER,
  };
}

export function updateGroupObject(groupObject) {
  return {
    type: UPDATE_GROUP_OBJECT,
    groupObject: groupObject,
  };
}

export function updateChannelList(channelList) {
  return {
    type: UPDATE_CHANNEL_LIST,
    channelList,
  };
}

export function updateChannelInList(channel) {
  return {
    type: UPDATE_CHANNEL_IN_LIST,
    channel,
  };
}
