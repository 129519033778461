import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { Container } from './styled';

export default function Footer() {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const redirectToLagoonTwitter = () => {
    window.open('https://twitter.com/LAGOONOfficial1');
  };

  return (
    <Container>
      <div className="group group-1">
        <div className="subgroup">
          <p className="subgroup-title">{t('Lagoon')}</p>
          <Link to="/lp/download" onClick={scrollToTop} className="subgroup-item">
            {t('Download')}
          </Link>
          <Link to="/lp/features" onClick={scrollToTop} className="subgroup-item">
            {t('Features')}
          </Link>
        </div>
        <div className="subgroup">
          <p className="subgroup-title">{t('Company')}</p>
          <a href="https://www.x-climb.com/" target="_blank" rel="noopener noreferrer" className="subgroup-item">
            X-Climb
          </a>
        </div>
      </div>
      <div className="group group-2">
        <div className="subgroup">
          <p className="subgroup-title">{t('Download').toUpperCase()}</p>
          <a
            href="https://play.google.com/store/apps/details?id=com.xclimb.messaging_app&hl=en"
            target="_blank"
            rel="noopener noreferrer"
            className="subgroup-item"
          >
            {t('Android')}
          </a>
          <a href="https://apps.apple.com/us/app/id1480710365" target="_blank" rel="noopener noreferrer" className="subgroup-item">
            {t('IOS')}
          </a>
        </div>
        <div className="subgroup">
          <p className="subgroup-title">{t('Legal').toUpperCase()}</p>
          <a href="https://www.x-climb.com/privacypolicy_en.html" target="_blank" rel="noopener noreferrer" className="subgroup-item">
            {t('PrivacyPolicy')}
          </a>
          <Link to="/termsOfUse" target="_blank" rel="noopener noreferrer" className="subgroup-item">
            {t('TermsOfUse')}
          </Link>
        </div>
      </div>
      <div className="group group-3">
        <IconContainer iconName="twitter" iconWidth="35px" onClick={redirectToLagoonTwitter} />
      </div>
    </Container>
  );
}
