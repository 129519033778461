export const checkFileType = (type: string) => {
  switch (type) {
    case 'art':
    case 'blp':
    case 'bmp':
    case 'bti':
    case 'cd5':
    case 'cit':
    case 'cpt':
    case 'cr2':
    case 'clip':
    case 'cpl':
    case 'dds':
    case 'dib':
    case 'djvu':
    case 'egt':
    case 'exif':
    case 'gif':
    case 'grf':
    case 'icns':
    case 'ico':
    case 'jpg':
    case 'jfif':
    case 'jng':
    case 'jpeg':
    case 'jp2':
    case 'jps':
    case 'lbm':
    case 'max':
    case 'miff':
    case 'mng':
    case 'msp':
    case 'nitf':
    case 'otb':
    case 'pbm':
    case 'pc1':
    case 'pc2':
    case 'pc3':
    case 'pcf':
    case 'pcx':
    case 'pdn':
    case 'pgm':
    case 'pi1':
    case 'pi2':
    case 'pi3':
    case 'pict':
    case 'png':
    case 'pnm':
    case 'pns':
    case 'ppm':
    case 'psb':
    case 'psd':
    case 'psp':
    case 'px':
    case 'pxm':
    case 'pxr':
    case 'qfx':
    case 'raw':
    case 'rle':
    case 'sct':
    case 'tiff':
    case 'tif':
    case 'vtf':
    case 'xbm':
    case 'xcf':
    case 'xpm':
    case 'zif':
    case 'pdd':
    case 'pct':
      return 'image';
    case 'aaf':
    case '3gp':
    case 'asf':
    case 'avchd':
    case 'avi':
    case 'bik':
    case 'cam':
    case 'collab':
    case 'dat':
    case 'dsh':
    case 'dvr-ms':
    case 'flv':
    case 'm1v':
    case 'fla':
    case 'flr':
    case 'sol':
    case 'm4v':
    case 'mkv':
    case 'wrap':
    case 'mov':
    case 'mpeg':
    case 'thp':
    case 'mp4':
    case 'mxf':
    case 'roq':
    case 'nsv':
    case 'rm':
    case 'svi':
    case 'smi':
    case 'smk':
    case 'swf':
    case 'wmv':
    case 'wtv':
    case 'yuv':
    case 'webm':
      return 'video';
    case 'gslides':
    case 'key':
    case 'odp':
    case 'otp':
    case 'pez':
    case 'pot':
    case 'pps':
    case 'ppt':
    case 'pptx':
    case 'prz':
    case 'sdd':
    case 'shf':
    case 'show':
    case 'shw':
    case 'slp':
    case 'sspss':
    case 'sti':
    case 'sxi':
    case 'thmx':
    case 'watch':
      return 'presentation';
    case '123':
    case 'ab2':
    case 'ab3':
    case 'aws':
    case 'bcsv':
    case 'clf':
    case 'cell':
    case 'csv':
    case 'gsheet':
    case 'numbers':
    case 'gnumeric':
    case 'lcw':
    case 'ods':
    case 'ots':
    case 'qpw':
    case 'sdc':
    case 'slk':
    case 'stc':
    case 'sxc':
    case 'tab':
    case 'vc':
    case 'wk1':
    case 'wk3':
    case 'wk4':
    case 'wks':
    case 'wq1':
    case 'xlk':
    case 'xls':
    case 'xlsb':
    case 'xlsm':
    case 'xlsx':
    case 'xlr':
    case 'xlt':
    case 'xltm':
    case 'xlw':
    case '_xls':
      return 'spreadsheet';
    case 'ac3':
    case 'amr':
    case 'mp1':
    case 'mp2':
    case 'mp3':
    case 'spx':
    case 'gsm':
    case 'wma':
    case 'aac':
    case 'mpc':
    case 'vqf':
    case 'swa':
    case 'vox':
    case 'voc':
    case 'dwd':
    case 'smp':
    case 'ogg':
      return 'audio';
    case 'abw':
    case 'acl':
    case 'afp':
    case 'ami':
    case 'amigaguide':
    case 'ans':
    case 'asc':
    case 'aww':
    case 'ccf':
    case 'cwk':
    case 'dbk':
    case 'dita':
    case 'doc':
    case 'docm':
    case 'docx':
    case 'dot':
    case 'dotx':
    case 'epub':
    case 'ezw':
    case 'fdx':
    case 'ftm':
    case 'ftx':
    case 'gdoc':
    case 'html':
    case 'hwp':
    case 'hwpml':
    case 'log':
    case 'lwp':
    case 'mbp':
    case 'md':
    case 'me':
    case 'mcw':
    case 'mobi':
    case 'nb':
    case 'nbp':
    case 'neis':
    case 'odm':
    case 'odoc':
    case 'odt':
    case 'osheet':
    case 'ott':
    case 'omm':
    case 'pages':
    case 'pap':
    case 'pdax':
    case 'quox':
    case 'radix':
    case 'rtf':
    case 'rpt':
    case 'sdw':
    case 'se':
    case 'stw':
    case 'sxw':
    case 'tex':
    case 'info':
    case 'troff':
    case 'uof':
    case 'uoml':
    case 'via':
    case 'wpd':
    case 'wps':
    case 'wpt':
    case 'wrd':
    case 'wrf':
    case 'wri':
    case 'xhtml':
    case 'xml':
    case 'xps':
      return 'doc';
    case 'pdf':
      return 'pdf';
    default:
      return 'unknown';
  }
};

export const bytesToMegaBytes = bytes => bytes / (1024 * 1024);
export const bytesToKiloBytes = bytes => bytes / 1024;

export const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};
