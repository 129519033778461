import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Loading({
  fill = '#08E9FE',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M1082.7,540.9c1.8,298.4-245.9,547.5-553.2,540.8c-291.7-6.3-535.7-249.6-528-555.3
			C8.7,235.3,251.9-7.8,557.3,0.2C847.4,7.7,1084,247.3,1082.7,540.9z M1082.7,540.9c1.8,298.4-245.9,547.5-553.2,540.8
			c-291.7-6.3-535.7-249.6-528-555.3C8.7,235.3,251.9-7.8,557.3,0.2C847.4,7.7,1084,247.3,1082.7,540.9z"
          />
        </g>
      </g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="739.012"
        y1="199.9185"
        x2="739.012"
        y2="964.826"
        gradientTransform="matrix(1 0 0 1 0 2)"
      >
        <stop offset="0" stopColor="#FFFFFF" />
        <stop offset="0.667" stopColor="#8AFAFF" />
        <stop offset="1" stopColor="#55F7FF" />
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M956.8,537.5c1.1,205.8-147.5,381.2-348.1,413.7c-21.8,3.5-44,4.4-66.1,5.9c-9.3,0.7-18.4-3.5-20.1-12.8
	c-1.3-6.8,0.7-15.9,4.9-21.2c3.2-4.2,11.9-6,18.3-6.2c47.1-1.1,92.8-9,135.9-27.8c135.4-59.3,215.6-162,232.2-309.1
	c20.9-184.6-95.6-352.6-275.1-401.3c-30.1-8.2-62-9.8-93.2-13.5c-15.9-1.9-24.5-7.8-24.2-20.5c0.2-12.6,9.5-20,24.9-19.9
	c165.6,1.3,318.5,105.6,380.4,259.2C946.6,434.4,957.2,486.6,956.8,537.5z"
      />
      <path
        fill="#F8FFFE"
        d="M308.2,200.8c11.1,0.1,19.6,8.7,19.6,20.1c0,11.1-8.6,19.8-19.8,20c-10.7,0.2-20.2-9.1-20.5-19.8
	C287.3,209.8,296.7,200.5,308.2,200.8z"
      />
      <path
        fill="#88FFFC"
        d="M266.8,805.7c0.1,10.8-8.7,20.1-19.6,20.5c-11.4,0.4-20.8-9-20.7-20.4c0.1-11.1,9-19.7,20-19.7
	C257.9,786.2,266.5,794.6,266.8,805.7z"
      />
      <path
        fill="#6EFFFF"
        d="M327.9,861.4c0.2,11.4-8.1,20.1-19.4,20.4c-11,0.2-20.4-8.4-20.9-19.3c-0.6-11,9.2-21.2,20.2-21.2
	C318.6,841.3,327.7,850.4,327.9,861.4z"
      />
      <path
        fill="#F3FFFD"
        d="M246.4,296.1c-11.4,0-19.9-8.3-20-19.6c-0.1-11.1,8.4-20,19.5-20.5c11.3-0.3,20.9,9.1,20.8,20.5
	C266.5,287.4,257.5,296.1,246.4,296.1z"
      />
      <path
        fill="#E9FFFB"
        d="M217.4,342.8c0.1,11.1-8.4,20-19.5,20.2c-11.4,0.2-20.7-9.1-20.5-20.5c0.1-10.8,9.4-19.9,20.2-19.8
	C208.4,322.8,217.3,331.8,217.4,342.8z"
      />
      <path
        fill="#9CFFFA"
        d="M197.6,759.3c-10.8-0.3-20-9.6-19.9-20.2c0-11.4,9.5-20.4,21.1-19.9c11.1,0.4,19.3,9.3,19,20.7
	C217.5,751,208.6,759.7,197.6,759.3z"
      />
      <path
        fill="#55F7FF"
        d="M458.8,948.7c-10.8,0.2-20-8.7-20.5-19.6c-0.3-11.4,8.7-20.5,20.4-20.5c11,0,19.8,8.9,19.8,19.9
	C478.6,939.1,469.5,948.5,458.8,948.7z"
      />
      <path
        fill="#DCFFFA"
        d="M183.8,419.1c-0.1,10.7-9.3,20-19.9,20.1c-10.8,0.1-20.7-10.1-20.2-21.1c0.3-10.7,9.7-19.5,20.7-19.1
	C175.5,399.2,183.8,407.9,183.8,419.1z"
      />
      <path
        fill="#CCFFF9"
        d="M147.3,480c11.1,0.2,19.8,9.2,19.7,20.1c-0.2,11.1-10.3,20.5-21.4,20c-10.8-0.6-19-9.6-18.7-21
	C127.3,487.7,135.9,479.7,147.3,480z"
      />
      <path
        fill="#FFFFFF"
        d="M458.5,133.5c10.7,0.2,19.8,9.6,19.7,20.4c0,11.4-9.6,20.4-21.2,19.8c-11-0.6-19.3-9.6-19-20.8
	C438.4,141.9,447.5,133.2,458.5,133.5z"
      />
      <path
        fill="#F8FFFE"
        d="M379.7,199.1c-11.4,0.1-20.1-8.4-20.4-19.7c-0.2-11,8.9-20.4,19.7-20.5c10.7-0.1,20.2,9.1,20.5,19.9
	C399.7,189.9,390.8,199,379.7,199.1z"
      />
      <path
        fill="#52F7FF"
        d="M379.8,922.7c-11.3,0.1-20.2-8.7-20.2-19.7s9.1-20,20-20c11.3,0,20,8.6,20,19.8
	C399.8,914,391.1,922.7,379.8,922.7z"
      />
      <path
        fill="#AEFFF9"
        d="M183.9,663.5c0,11.6-8.3,19.9-19.8,19.8c-11.1-0.1-19.9-8.9-20-20c0-10.8,9.3-20.2,20-20.1
	C175.1,643.3,183.9,652.3,183.9,663.5z"
      />
      <path
        fill="#C0FFF9"
        d="M167.1,582.3c0.2,11-8.6,20-19.8,20.4c-11.4,0.3-19.9-8-20.2-19.5c-0.2-11.5,8-20.1,19.4-20.4
	C157.5,562.6,166.8,571.4,167.1,582.3z"
      />
    </svg>
  );
}
