import axios from 'axios';
import { setupAxiosInterceptors } from '../../config/axios';
import {
  INIT,
  SIGN_IN_LOADING,
  SIGN_IN_HAS_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_LOADING,
  SIGN_OUT_HAS_ERROR,
  SIGN_OUT_SUCCESS,
  SIGN_UP_LOADING,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  UPDATE_USER_INFO,
} from './actionTypes';
import { AuthProps } from '../../Common/interfaces';
import { UserData } from '../../Common/interfaces/user';

interface SignInProps {
  ok: boolean;
  accessToken: string;
  user: UserData;
}

export const init = () => {
  return dispatch => {
    if (
      localStorage.getItem('isAuthenticated') !== null &&
      localStorage.getItem('loggedInUser') !== null &&
      localStorage.getItem('token') !== null &&
      localStorage.getItem('isDemoAccount') !== null
    ) {
      setupAxiosInterceptors();
      dispatch({
        type: INIT,
        isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
        user: JSON.parse(localStorage.getItem('loggedInUser') || '{}'),
        token: localStorage.getItem('token'),
        isDemoAccount: localStorage.getItem('isDemoAccount') === 'true',
      });
    } else {
      dispatch({
        type: INIT,
        isAuthenticated: null,
        loggedInUserUID: null,
        token: null,
        isDemoAccount: null,
      });
    }
  };
};

export const signInIsLoading = (bool: boolean) => {
  return {
    type: SIGN_IN_LOADING,
    isLoading: bool,
  };
};

export const signInSuccess = (payload: SignInProps) => {
  localStorage.setItem('token', payload.accessToken);
  localStorage.setItem('loggedInUser', JSON.stringify(payload.user));
  localStorage.setItem('isAuthenticated', 'true');
  localStorage.setItem('isDemoAccount', 'false');
  setupAxiosInterceptors();
  return {
    type: SIGN_IN_SUCCESS,
    token: payload.accessToken,
    user: payload.user,
  };
};

export const signInHasError = (bool: boolean) => {
  return {
    type: SIGN_IN_HAS_ERROR,
    hasError: bool,
  };
};

export const signInUser = (formData: AuthProps | null) => {
  return async dispatch => {
    const signUpMethod = formData?.providerId ? '/signUpViaSNS' : '/signInWeb';
    dispatch(signInIsLoading(true));
    return await axios.post(process.env.REACT_APP_API_URL + signUpMethod, formData).then(
      response => {
        dispatch(signInSuccess(response.data));
        return response.data;
      },
      err => {
        dispatch(signInHasError(true));
        return err.response.data;
      }
    );
  };
};

export function signUpError(bool: boolean, errMessage: string) {
  return {
    type: SIGN_UP_ERROR,
    hasError: bool,
    errMessage: errMessage,
  };
}

export function signUpLoading(bool: boolean) {
  return {
    type: SIGN_UP_LOADING,
    isLoading: bool,
  };
}

export function signUpSuccess(message: string) {
  setupAxiosInterceptors();
  return {
    type: SIGN_UP_SUCCESS,
    message: message,
  };
}

export function signUpUser(formData: AuthProps) {
  return async dispatch => {
    dispatch(signUpLoading(true));
    return await axios.post(process.env.REACT_APP_API_URL + `/signUpWeb`, formData).then(
      response => {
        dispatch(signUpSuccess(response.data.message));
        return response.data;
      },
      err => {
        dispatch(signUpError(true, err.response.data.message));
        return err.response.data;
      }
    );
  };
}

export const signOutIsLoading = (bool: boolean) => {
  return {
    type: SIGN_OUT_LOADING,
    isLoading: bool,
  };
};

export const signOutSuccess = payload => {
  localStorage.removeItem('token');
  localStorage.removeItem('loggedInUser');
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('isDemoAccount');
  setupAxiosInterceptors();
  return {
    type: SIGN_OUT_SUCCESS,
    message: payload.message,
  };
};

export const signOutHasError = (bool: boolean) => {
  return {
    type: SIGN_OUT_HAS_ERROR,
    hasError: bool,
  };
};

export const signOutUser = (formData: AuthProps) => {
  return async dispatch => {
    dispatch(signOutIsLoading(true));
    return await axios.post(process.env.REACT_APP_API_URL + '/signOut', formData).then(
      response => {
        dispatch(signOutSuccess(response.data));
      },
      err => {
        dispatch(signOutHasError(true));
      }
    );
  };
};

export const signOutDemoUser = () => {
  return async dispatch => {
    dispatch(signOutIsLoading(true));
    return await axios.delete(process.env.REACT_APP_API_URL + '/anonymousDeletion').then(
      response => {
        dispatch(signOutSuccess(response.data));
      },
      err => {
        dispatch(signOutHasError(true));
      }
    );
  };
};

export const updateUserInfoLocalStorage = userInfo => {
  localStorage.setItem('loggedInUser', JSON.stringify(userInfo));
  return {
    type: UPDATE_USER_INFO,
    userInfo,
  };
};

export const userForgotPassword = async requestBody => {
  return await axios.post(process.env.REACT_APP_API_URL + `/resetPassword`, requestBody);
};

export const userResendActivationLink = async requestBody => {
  return await axios.post(process.env.REACT_APP_API_URL + `/verificationRetry`, requestBody);
};
