import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import { isNotEmptyArray } from '../../utils/arrays';

export default function useGetAllFriendsByOwnerId(ownerId) {
  const [friends, setFriends] = useState<Array<any>>([]);

  useEffect(() => {
    if (ownerId) {
      const unsubscribe = db
        .collection(collections.friends)
        .where('ownerId', '==', ownerId)
        .onSnapshot(snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            setFriends(snapshot.docs.map(friend => friend.data()));
          }
          return () => {
            unsubscribe();
          };
        });
    }
  }, [ownerId]);

  return friends;
}
