import styled from 'styled-components/macro';

const Container = styled.div`
  background: #f4f5f7;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

interface SubItemProps {
  isActive: boolean;
}

const SubItem = styled.div`
  transition: 300ms;
  padding: 15px 30px;
  background-color: ${(props: SubItemProps) => props.isActive && ' #e9ebef'};
  &:hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

export const SideMenuStyled = { Container, SubItem };
