import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Flex from '../../CommonComponents/Flex';
import { currentLang } from '../../utils/language';
import LanguageForm from './LanguageForm';
import { useTranslation } from 'react-i18next';
import { setAccountSettingsLanguage } from '../actions';

export default function Language() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(currentLang);
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);

  useEffect(() => {
    i18n.changeLanguage(language);
    setAccountSettingsLanguage({ lang: language }, loggedInUser.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, i18n]);

  const handleLanguageFormSubmit = value => {
    setLanguage(value.language);
  };

  return (
    <Flex container flexDirection="column" alignItems="center">
      <LanguageForm onSubmit={handleLanguageFormSubmit} />
    </Flex>
  );
}
