import React, { FC, ReactElement, useState, useEffect } from 'react';
import { isChrome } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import { LoginValidationSchema } from '../../utils/validation_schema';
import { TextInput } from '../../CommonComponents/Input';
import { useTranslation } from 'react-i18next';
import { AuthProps } from '../../Common/interfaces';
import { signInUser, signUpUser, userForgotPassword, userResendActivationLink } from '../actions';
import Swal from 'sweetalert2';
import { handleTokenRequest } from '../../firebase/pushNotification';
import AuthFormFooter from './AuthFormFooter';
import useReactGa from '../../Common/customHooks/useReactGa';

// eslint-disable-next-line @typescript-eslint/no-this-alias
const globalContext = window;

const AuthForm: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sendEGAEvent } = useReactGa();
  const currentLang = globalContext && globalContext.localStorage.i18nextLng;
  const [formState, setFormState] = useState('signIn');
  const [formValues, setFormValues] = useState<any>();
  const [signInHasError, setSignInHasError] = useState({
    ok: true,
    message: '',
  });
  const [resendLinkElement, setResendLinkElement] = useState<any>(document.getElementById('#resendEmail'));

  useEffect(() => {
    if (!isChrome) {
      displayNonChromeBrowserPopUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChrome]);

  const preSubmit = async (values: AuthProps) => {
    if (isChrome) {
      setFormValues(values);
      if (formState === 'signIn') {
        const deviceToken = await handleTokenRequest();
        const response = await dispatch(signInUser({ lang: currentLang, deviceToken, ...values }));
        setSignInHasError(response);
        if (response.ok) {
          if (process.env.REACT_APP_CLIENT_ENV === 'production') {
            sendEGAEvent('User Action', 'Logged In', `id: ${response.user.id}`);
          }
        }
      } else {
        const response = await dispatch(signUpUser({ lang: currentLang, ...values }));
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: t('WelcomeToLagoon'),
            text: t('BeforeWeContinueAVerificationLinkHasBeenSentToYourEmailAddress'),
            showConfirmButton: false,
            footer:
              currentLang === 'en'
                ? `<span style="margin: 0px 15px; text-align: center">${t(
                    'ifYouHaventReceivedAnEmailPleaseCheckYourSpamjunkFolderOrClickHereTo'
                  )}<a id="resendEmail" href="#">Resend</a></span>`
                : `<span style="text-align: center">${t(
                    'ifYouHaventReceivedAnEmailPleaseCheckYourSpamjunkFolderOrClickHereTo1'
                  )}<a id="resendEmail" href="#">${t('LinkToReSend')}</a>${t(
                    'ifYouHaventReceivedAnEmailPleaseCheckYourSpamjunkFolderOrClickHereTo2'
                  )}</span>`,
            width: '635px',
            customClass: {
              popup: 'forgot-password-popup-class',
              title: 'sign-up-title-class',
              content: 'sign-up-content-class',
              footer: 'sign-up-footer-class',
            },
          });
          setResendLinkElement(document.getElementById('resendEmail'));
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: response.message,
            showConfirmButton: false,
            width: '635px',
            customClass: {
              popup: 'forgot-password-popup-class',
              title: 'sign-up-title-class',
              content: 'sign-up-content-class',
            },
          });
        }
      }
      return true;
    } else {
      displayNonChromeBrowserPopUp();
    }
  };

  const resendActivationLink = () => {
    const requestBody = {
      lang: currentLang,
      email: formValues.email,
      password: formValues.password,
    };
    userResendActivationLink(requestBody);
  };

  if (resendLinkElement) {
    if (!resendLinkElement.getAttribute('hasOnClick')) {
      resendLinkElement.setAttribute('hasOnClick', true);
      resendLinkElement.addEventListener('click', resendActivationLink);
    }
  }

  const handleForgotPassword = () => {
    Swal.fire({
      title: t('ForgotPassword'),
      text: t('EnterYourEmailAddressAndWillSendYouALinkToResetYourPassword'),
      input: 'email',
      inputPlaceholder: t('EmailAddress'),
      width: '635px',
      confirmButtonText: t('Submit').toUpperCase(),
      showLoaderOnConfirm: true,
      customClass: {
        popup: 'forgot-password-popup-class',
        title: 'forgot-password-title-class',
        content: 'forgot-password-content-class',
        input: 'forgot-password-input-class',
        confirmButton: 'swal-confirm-secondary-button-class',
      },
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: email => {
        const requestBody = {
          email: email,
          lang: currentLang,
        };
        return userForgotPassword(requestBody)
          .then(res => {
            Swal.fire({
              title: t('CheckInYourEmail'),
              imageUrl: `${process.env.PUBLIC_URL}/assets/icons/mail.png`,
              imageWidth: 100,
              imageHeight: 70,
              imageAlt: 'mail',
              text: res.data.message,
              width: '635px',
              confirmButtonText: t('Ok'),
              customClass: {
                popup: 'forgot-password-popup-success-class',
                image: 'forgot-password-image-success-class',
                title: 'forgot-password-title-class',
                content: 'forgot-password-content-class',
                confirmButton: 'swal-confirm-secondary-button-class',
              },
            });
          })
          .catch(err => {
            Swal.showValidationMessage(`Request failed: ${err.response.data.message}`);
          });
      },
    });
  };

  const displayNonChromeBrowserPopUp = () => {
    Swal.fire({
      icon: 'error',
      title: t('ForTheMeantimeLAGOONWebWorksBestInGoogleChrome'),
      confirmButtonText: t('Ok'),
      width: '635px',
      customClass: {
        popup: 'non-chrome-users-pop-up-class',
        confirmButton: 'swal-confirm-primary-button-class',
      },
    });
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={data => preSubmit(data)}
      validationSchema={LoginValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center justify-content-evenly">
          <Field
            name="email"
            type="input"
            as={TextInput}
            label={t('EmailAddress')}
            inputType="text"
            disabled={isSubmitting}
            className="my-3"
          />
          <Field
            name="password"
            type="input"
            as={TextInput}
            label={t('Password')}
            inputType="password"
            disabled={isSubmitting}
            className="my-3"
          />
          <p className="regular-text w-100 text-right mb-4" onClick={handleForgotPassword}>
            {t('ForgotPassword')}
          </p>
          <button className="login-button py-2 my-3" onClick={() => setFormState('signIn')} disabled={isSubmitting}>
            {t('Login')}
          </button>
          <button className="create-account-button py-2 my-3" onClick={() => setFormState('signUp')} disabled={isSubmitting}>
            {t('SignUp')}
          </button>
          {!signInHasError.ok && (
            <div className="d-flex justify-content-center bg-danger p-3 w-100">
              <span className="regular-text w-100 text-center">
                {signInHasError.message === 'Error: The password is invalid or the user does not have a password.'
                  ? t('ErrorThepasswordisinvalidortheuserdoesnothaveapassword')
                  : signInHasError.message ===
                    'Error: There is no user record corresponding to this identifier. The user may have been deleted.'
                  ? t('ErrorThereisnouserrecordcorrespondingtothisidentifierTheusermayhavebeendeleted')
                  : signInHasError.message === 'Unauthorized, Email is not yet verified'
                  ? t('UnauthorizedEmailisnotyetverified')
                  : null}
                {signInHasError.message === 'Unauthorized, Email is not yet verified' && (
                  <span className="pointer regular-text text-decoration-underline" onClick={resendActivationLink}>
                    {t('ClickHereToResendTheActivationEmail')}
                  </span>
                )}
              </span>
            </div>
          )}
          <AuthFormFooter />
        </form>
      )}
    </Formik>
  );
};

export default AuthForm;
