import React, { FC, ReactElement, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyArray } from '../../utils/arrays';
import { ConversationBodyStyled } from './styled';
import Message from './Message';
import NoMessage from './NoMessage';
import useGetAllMessagesByConversationId from '../../Common/customHooks/useGetAllMessagesByConversationId';
import { setConvesationMessageList } from '../actions';
import { MessageData } from '../../Common/interfaces/message';
import SkeletonLoading from '../../CommonComponents/Skeleton';

const { ScrollViewContainer } = ConversationBodyStyled;

const ConversationBody: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const activeConversationId: string = useSelector(state => state.conversationReducer.activeConversationId);
  const isFiltered: boolean = useSelector(state => state.conversationReducer.isFiltered);
  const getConversationMessagesIsLoading: boolean = useSelector(state => state.conversationReducer.getConversationMessagesIsLoading);
  const conversationMessages: Array<MessageData> = useSelector(state => state.conversationReducer.conversationMessages);
  const { messages, isLoading, setNextPage } = useGetAllMessagesByConversationId(activeConversationId);

  const observer: any = useRef();
  const showMoreref: any = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setNextPage(oldLimit => oldLimit + 20);
        }
      },
      {
        threshold: 0,
        rootMargin: '50px',
      }
    );
    if (node) observer.current.observe(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setConvesationMessageList(messages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isFiltered, activeConversationId]);

  return (
    <ScrollViewContainer className="scrollviewer-container">
      {getConversationMessagesIsLoading || isLoading ? (
        <SkeletonLoading count={8} isWithAvatar />
      ) : isNotEmptyArray(conversationMessages) ? (
        <>
          {conversationMessages.map((message, key) => {
            return (
              <Message
                key={message.msgSenderIdentifier ? message.msgSenderIdentifier : message.id}
                message={message}
                messages={conversationMessages}
                messageKey={key}
              />
            );
          })}
          <div ref={showMoreref} />;
        </>
      ) : (
        <NoMessage />
      )}
    </ScrollViewContainer>
  );
};

export default ConversationBody;
