import axios from 'axios';
import { SIGN_IS_USING_DEMO_ACCOUNT_LOADING, SIGN_IS_USING_DEMO_ACCOUNT_ERROR, SIGN_IS_USING_DEMO_ACCOUNT_SUCCESS } from '../actionTypes';
import { setupAxiosInterceptors } from '../../../config/axios';

const isLoading = (bool: boolean) => {
  return {
    type: SIGN_IS_USING_DEMO_ACCOUNT_LOADING,
    isLoading: bool,
  };
};

const hasError = () => {
  return {
    type: SIGN_IS_USING_DEMO_ACCOUNT_ERROR,
    hasError: true,
  };
};
const isSuccess = (user, token) => {
  localStorage.setItem('token', token);
  localStorage.setItem('loggedInUser', JSON.stringify(user));
  localStorage.setItem('isDemoAccount', 'true');
  localStorage.setItem('isAuthenticated', 'true');
  setupAxiosInterceptors();
  return {
    type: SIGN_IS_USING_DEMO_ACCOUNT_SUCCESS,
    user: user,
  };
};

const signInUsingDemoAccount = () => {
  return async dispatch => {
    dispatch(isLoading(true));
    return await axios.post(process.env.REACT_APP_API_URL + `/anonymousRegistration`).then(
      response => {
        dispatch(isSuccess(response.data.user, response.data.accessToken));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
};

export default signInUsingDemoAccount;
