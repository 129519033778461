import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Facebook({
  style = {},
  fill = '#09BBFF',
  width = '100%',
  className = '',
  viewBox = '0 0 668 668',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle fill={fill} cx="334" cy="334" r="334" />
      <path
        fill="#FFFFFF"
        d="M372.53,335.26 c0,54.52 -0.010000000000000002,107.27 0,160.02 c0.010000000000000002,22.17 -0.21000000000000002,44.34 0.22000000000000003,66.5 c0.18000000000000002,9.14 -3.25,12.52 -12.38,12.38 c-25.68,-0.37000000000000005 -51.37,-0.48000000000000004 -77.04,0.05000000000000001 c-10.25,0.21000000000000002 -13.36,-3.55 -13.31,-13.57 c0.33000000000000007,-70.73 0.19000000000000003,-141.46 0.2,-212.18 c0,-14.08 0,-13.94 -13.79,-13.54 c-8.79,0.25 -17.59,0.06000000000000001 -26.38,0.010000000000000002 c-6.01,-0.030000000000000006 -8.69,-2.77 -8.620000000000001,-9.13 c0.26,-22.17 0.29000000000000004,-44.34 0.010000000000000002,-66.5 c-0.08000000000000002,-6.79 2.34,-9.67 9.16,-9.48 c8.79,0.25 17.59,0.020000000000000004 26.38,0.08000000000000002 c13.58,0.08000000000000002 13.32,0.11000000000000001 13.53,-13.9 c0.30000000000000004,-20.37 -0.19000000000000003,-40.86 1.8,-61.08 c4.5600000000000005,-46.23 39.37,-77.84 85.96,-79.31 c25.3,-0.8 50.61,-0.99 75.91,-1.8 c8.91,-0.29000000000000004 12.63,2.7600000000000002 12.37,12.18 c-0.56,20.39 -0.6000000000000001,40.83 -0.010000000000000002,61.22 c0.25,8.82 -3.5300000000000002,10.83 -11.22,10.8 c-13.7,-0.07 -27.45,-0.17 -41.09,0.9 c-13.57,1.07 -21.1,9.21 -21.83,22.54 c-0.6900000000000002,12.620000000000001 -0.43000000000000005,25.3 -0.9600000000000001,37.93 c-0.30000000000000004,7.2 2.18,9.91 9.76,9.64 c16.52,-0.5800000000000001 33.06,-0.30000000000000004 49.6,-0.23 c14.03,0.06000000000000001 14.63,0.44000000000000006 13.48,14.07 c-1.7600000000000002,21 -4.33,41.92 -5.99,62.93 c-0.5900000000000001,7.39 -3.93,9.63 -10.75,9.55 C409.68000000000006,335.13 391.80999999999995,335.26 372.53,335.26 z"
      />
    </svg>
  );
}
