import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function ErrorCircle({
  fill = '#FF3737',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          fill={fill}
          d="M542.3,1079.8C244.9,1087.9,0.6,835.5,0.3,540.7c-0.2-288.2,241.6-565,584.5-539
		c261.1,19.7,493.7,237.8,495.1,539.1C1081.4,833.8,841.6,1084.2,542.3,1079.8z M541.1,1020.2c264.5,8.4,482.4-222.3,480.2-481.2
		c-2.3-263.6-210.2-459.8-438.7-478.2C274.8,35.9,59.5,287.8,59.2,540.1C58.9,799.3,278.9,1030.6,541.1,1020.2z"
        />
        <path
          fill={fill}
          d="M594.3,450.5c0-38.6,0.5-77.2-0.2-115.8c-0.5-30-23.2-53.5-50.3-53.5c-31.1-0.1-50.1,15.8-51.1,51.6
		c-2,77.9-3.2,156.1,0.6,233.9c1.8,36,19.4,49.6,42.5,49.5c36.6-0.1,58.1-19.6,58.3-56.9C594.5,523,594.3,486.8,594.3,450.5z
		 M538.5,800.3c37.5,0,55.7-16,55.8-49.4c0.1-31-25.2-57.8-54.1-57.2c-29.4,0.6-56.8,28-56.1,56.1
		C484.7,777.6,509.2,800.3,538.5,800.3z"
        />
        <path
          fill={fill}
          d="M594.3,450.5c0,36.2,0.2,72.5,0,108.7c-0.3,37.4-21.7,56.8-58.3,56.9c-23.2,0.1-40.8-13.5-42.5-49.5
		c-3.8-77.8-2.6-156-0.6-233.9c0.9-35.8,20-51.7,51.1-51.6c27,0.1,49.8,23.5,50.3,53.5C594.7,373.3,594.3,411.9,594.3,450.5z"
        />
        <path
          fill={fill}
          d="M538.5,800.3c-29.4,0-53.8-22.7-54.5-50.6c-0.7-28.1,26.8-55.5,56.1-56.1c28.9-0.6,54.2,26.2,54.1,57.2
		C594.2,784.3,576.1,800.3,538.5,800.3z"
        />
      </g>
    </svg>
  );
}
