import { UPDATE_NOTIFICATION_SOUND, TOGGLE_NOTIFICATION_MODAL, NOTIFICATION_INIT } from './actionTypes';

export const notificationInit = () => {
  const userConfig = localStorage.getItem('userSettings');
  if (userConfig && JSON.parse(userConfig).id !== '') {
    return {
      type: NOTIFICATION_INIT,
      userConfig: JSON.parse(userConfig),
    };
  } else {
    return {
      type: NOTIFICATION_INIT,
      userConfig: {
        id: '',
        soundNotifications: {
          isSoundOne: true,
          isSoundTwo: false,
          isSoundFour: false,
          isSoundThree: false,
          isSoundNone: false,
        },
        pushNotification: false,
        messagePreview: false,
      },
    };
  }
};

export const updateNotificationSound = (notifSound: any) => {
  return {
    type: UPDATE_NOTIFICATION_SOUND,
    notifSound,
  };
};

export const toggleNotificationModal = (toggle: boolean) => {
  return {
    type: TOGGLE_NOTIFICATION_MODAL,
    toggle,
  };
};
