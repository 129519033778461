import axios from 'axios';
import { GET_ALL_CONVERSATIONS_ERROR, GET_ALL_CONVERSATIONS_LOADING, GET_ALL_CONVERSATIONS_SUCCESS } from '../actionTypes';

export function hasError() {
  return {
    type: GET_ALL_CONVERSATIONS_ERROR,
  };
}

export function isLoading() {
  return {
    type: GET_ALL_CONVERSATIONS_LOADING,
  };
}

export function isSuccess(payload) {
  localStorage.setItem('userChatList', JSON.stringify(payload));
  return {
    type: GET_ALL_CONVERSATIONS_SUCCESS,
    payload: payload,
  };
}

export function getAllConversations(requestBody, loggedInUserId: string) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios
      .post(process.env.REACT_APP_API_URL + `/conversation/list`, requestBody, {
        params: {
          ownerId: loggedInUserId,
        },
      })
      .then(
        response => {
          if (response.data) {
            dispatch(isSuccess(response.data.conversations));
          }
        },
        err => {
          dispatch(hasError());
        }
      );
  };
}
