import React, { FC, ReactElement, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { FriendStyled } from '../FriendDetails/styled';
import { ButtonStyled } from '../../CommonComponents/Button/Button.style';
import { changeFourthPanelView } from '../../Navigation/actions';
import { FOURTH_PANEL_VIEW } from '../../Common/enums';
import Flex from '../../CommonComponents/Flex';
import { copyToClipBoard } from '../../utils/strings';
import { timeout } from '../../utils/timings';
import { useTranslation } from 'react-i18next';
import { addUser } from '../actions/addUser';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { DecorationStyled } from '../../CommonComponents/Decoration/Decoration.style';

const { Span } = TypographyStyled;
const { FriendContainer, ProfileImage } = FriendStyled;
const { LightButton } = ButtonStyled;
const { AnimatedContainer } = DecorationStyled;

const UserDetails: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const user = useSelector(state => state.friendReducer.user);
  const addUserIsLoading: boolean = useSelector(state => state.friendReducer.addUserIsLoading);
  const createMessageIsLoading: boolean = useSelector(state => state.createChannelReducer.createMessageIsLoading);
  const [isMouseOverImage, setImageHoverState] = useState(false);
  const [isCopyTextVisible, toggleCopyTextVisible] = useState(false);

  const handleClose = () => dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN));

  const handleAddUser = () => {
    const requestBody = {
      ownerId: loggedInUser.id,
      friendId: user.id,
    };
    dispatch(addUser(requestBody)).then(() => handleClose());
  };

  const handleCopyButton = async () => {
    copyToClipBoard(user.id);
    toggleCopyTextVisible(true);
    await timeout(2000);
    toggleCopyTextVisible(false);
  };

  return (
    <FriendContainer>
      <Flex
        container
        handleHover={() => setImageHoverState(true)}
        handleUnHover={() => setImageHoverState(false)}
        flexDirection="row-reverse"
      >
        <ProfileImage src={user.profileImageUrl} alt="" />
        {isMouseOverImage && (
          <IconContainer
            position="absolute"
            padding="20px"
            onClick={handleClose}
            iconName="close"
            iconWidth="35px"
            iconStyle={{ opacity: '0', transition: '300ms' }}
            iconStyleOnMouseEnter={{ opacity: '1', transition: '300ms' }}
          />
        )}
      </Flex>
      <Flex>
        <Flex margin="35px">
          <Flex container justifyContent="space-between" alignItems="center" margin="0px 0px 15px 0px">
            <Span size="xl" fontWeight="bolder" fontColor="#444444">
              {user.displayName}
            </Span>
          </Flex>
          <Flex container alignItems="center" margin="0px 0px 20px 0px">
            <Span size="md" fontColor="#444444">
              ID: {user.id}
            </Span>
            {isCopyTextVisible ? (
              <Span size="md" fontColor="444444" fadeIn style={{ margin: '0px 0px 0px 20px' }}>
                {t('Copied')}
              </Span>
            ) : (
              <IconContainer margin="0px 0px 0px 20px" onClick={handleCopyButton} iconName="clipboard" iconWidth="20px" />
            )}
          </Flex>
          <Flex container>
            <LightButton
              width="80%"
              borderRadius="15px"
              fontSize="xl"
              disabled={addUserIsLoading || createMessageIsLoading}
              onClick={handleAddUser}
            >
              {t('AddUser')}
              {addUserIsLoading || createMessageIsLoading ? (
                <AnimatedContainer style={{ height: '35px', width: '35px', marginLeft: '10px' }}>
                  <IconContainer
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    iconName="loading"
                    iconWidth="35px"
                    iconFill="transparent"
                  />
                </AnimatedContainer>
              ) : (
                <> </>
              )}
            </LightButton>
          </Flex>
        </Flex>
      </Flex>
    </FriendContainer>
  );
};

export default UserDetails;
