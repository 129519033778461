import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  :hover {
    cursor: pointer;
  }
`;

const Text = styled.span`
  color: white;
  font-size: 25px;
  border: 1px solid #cacaca;
  border-radius: 35px;
  padding: 10px 50px;
  text-align: center;
`;

export const DropzoneStyled = { Container, Text };
