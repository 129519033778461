import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { ThreadMessageStyled } from './styled';
import Flex from '../../CommonComponents/Flex';
import { isNotEmptyArray } from '../../utils/arrays';
import { ThreadData, ThreadListData } from '../../Common/interfaces/thread';
import ThreadOption from './ThreadOption';
import { UserData } from '../../Common/interfaces/user';
import { sendReactionToThreadMessage } from '../../Common/actions';
import Reactions from '../../Conversation/Body/Message/Reactions';
import { handleReactionsArray } from '../../utils/message';
import { SELECTED_MESSAGE_OPTION } from '../../Common/enums';
import Content from '../../Conversation/Body/Message/Content';

const { UserAvatar } = ImageStyled;
const { MessageBox } = ThreadMessageStyled;

interface Props {
  threadData: ThreadData;
  parentThread: ThreadListData;
}

const ThreadMessage: FC<Props> = ({ threadData, parentThread }) => {
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const [isThreadOptionVisible, toggleThreadOption] = useState(false);
  const [editThreadMessage, toggleEditThread] = useState(false);
  const [, setIsMessageOptionVisible] = useState(false);
  const [selectedEmojis, setSelectedEmojis]: any[] = useState([]);
  const [, setActiveMessageOption] = useState(SELECTED_MESSAGE_OPTION.NONE);

  const getLastMessageUserImageURL = () => {
    if (isNotEmptyArray(parentThread.conversationMembers)) {
      const user = parentThread.conversationMembers.filter(member => member.id === threadData.userId);
      if (isNotEmptyArray(user)) return user[0].profileImageUrl;
      else return '';
    } else return '';
  };

  const getUserDisplayName = () => {
    if (isNotEmptyArray(parentThread.conversationMembers)) {
      const user = parentThread.conversationMembers.filter(member => member.id === threadData.userId);
      if (isNotEmptyArray(user)) {
        if (user[0].id === loggedInUser.id) {
          return loggedInUser.displayName;
        } else {
          return user[0].displayName;
        }
      } else return '';
    } else return '';
  };

  const handleOnHover = () => {
    toggleThreadOption(true);
  };

  const handleOnUnHover = () => {
    toggleThreadOption(false);
  };

  useEffect(() => {
    toggleThreadOption(false);
  }, [selectedEmojis]);

  useEffect(() => {
    if (isNotEmptyArray(threadData.reactions)) {
      setSelectedEmojis(threadData.reactions);
    }
  }, [threadData.reactions]);

  const handleEmojiSelect = async emoji => {
    setSelectedEmojis(handleReactionsArray(emoji, threadData, loggedInUser.id));
    handleReactionsArray(emoji, threadData, loggedInUser.id);
    toggleThreadOption(false);
    const requestBody = {
      threadId: threadData.id,
      messageId: threadData.messageId,
      reactions: handleReactionsArray(emoji, threadData, loggedInUser.id),
    };
    await sendReactionToThreadMessage(requestBody);
  };

  return (
    <MessageBox onMouseEnter={handleOnHover} onMouseLeave={handleOnUnHover}>
      <Flex container>
        <UserAvatar src={getLastMessageUserImageURL()} width="45" height="45" alt="" />
      </Flex>
      <Flex width="100%" margin="0px 0px 0px 5px">
        <Content
          isRecursive={false}
          message={threadData}
          user={{
            id: threadData.userId,
            displayName: getUserDisplayName(),
            profileImageUrl: getLastMessageUserImageURL(),
          }}
          isBlocked={false}
          isEditMessageActive={editThreadMessage}
          setIsMessageOptionVisible={setIsMessageOptionVisible}
          setActiveMessageOption={setActiveMessageOption}
          toggleEditMessage={toggleEditThread}
        />
        {threadData.reactions && <Reactions reactions={selectedEmojis} members={parentThread.conversationMembers} />}
      </Flex>
      {isThreadOptionVisible && !parentThread.conversationIsBlocked && (
        <ThreadOption thread={threadData} toggleEditThread={toggleEditThread} handleEmojiSelect={handleEmojiSelect} />
      )}
    </MessageBox>
  );
};

export default ThreadMessage;
