import React, { FC, ReactElement, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OptionStyled } from './styled';
import { TypographyStyled } from '../../Typography/Typography.style';
import { updateMessageById } from '../../../Common/actions';
import { copyToClipBoard } from '../../../utils/strings';
import { Display } from '../../../Common/interfaces';
import { MessageData } from '../../../Common/interfaces/message';
import { UserData } from '../../../Common/interfaces/user';
import { useTranslation } from 'react-i18next';
import { SELECTED_MESSAGE_OPTION } from '../../../Common/enums';
import { deleteFile } from '../../../Conversation/actions/deleteFile';

const { OptionContainer, OptionItem, OptionDivider } = OptionStyled;
const { Span } = TypographyStyled;

interface Props {
  visible: boolean;
  message: MessageData;
  isRecursive: boolean;
  optionsPlacement: string;
  hideOption: (toggle: boolean) => void;
  setActiveMessageOption: (option: SELECTED_MESSAGE_OPTION) => void;
}

const OtherOption: FC<Display & Props> = ({
  visible,
  message,
  hideOption,
  setActiveMessageOption,
  isRecursive,
  optionsPlacement,
}): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const [isDeleting, setIsDeleting] = useState(false);
  const [OptionOpneText, setOptionOneText] = useState('CopyMessage');

  const handleDeleteMessage = async e => {
    e.stopPropagation();
    setIsDeleting(true);
    if (message.content.type.isAttachment) {
      await dispatch(deleteFile(message.id, message.conversationId));
    } else {
      const requestBody = {
        messageId: message.id,
        text: message.content.text,
        isDeleted: true,
        isUpdated: true,
      };
      await updateMessageById(requestBody);
    }
    setIsDeleting(false);
    hideOption(false);
    setActiveMessageOption(SELECTED_MESSAGE_OPTION.NONE);
  };

  const copyMessageText = () => {
    copyToClipBoard(message.content.text);
    setOptionOneText('Copied');
    setTimeout(() => setOptionOneText('CopyMessage'), 1000);
  };

  return (
    <OptionContainer
      visible={visible}
      isOwnMessage={message.userId === loggedInUser.id}
      isRecursive={isRecursive}
      placement={optionsPlacement}
    >
      <OptionItem onClick={copyMessageText}>
        <Span size="md" fontColor="#5E5E5E" fontWeight="bold">
          {t(OptionOpneText)}
        </Span>
      </OptionItem>
      {message.userId === loggedInUser.id && (
        <>
          <OptionDivider />
          <OptionItem>
            <Span
              size="md"
              fontColor={isDeleting ? '#5E5E5E' : '#FF3737'}
              fontWeight="bold"
              onClick={e => !isDeleting && handleDeleteMessage(e)}
            >
              {t(isDeleting ? 'Deleting' : 'DeleteMessage')}
            </Span>
          </OptionItem>
        </>
      )}
    </OptionContainer>
  );
};

export default OtherOption;
