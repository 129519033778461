import { useState, useEffect } from 'react';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import db from '../../firebase/firebase';
import { isNotEmptyArray } from '../../utils/arrays';
import { ChannelData } from '../interfaces/channel';
import useGetActiveConversation from './useGetActiveConversation';

export default function useGetAllUsersThatTyping(conv, loggedInUser) {
  const activeConversationFromRedux: ChannelData = useGetActiveConversation();
  const [conversation, setConversation] = useState(conv);
  const [typingUsers, setTypingUsers] = useState([]);

  useEffect(() => {
    setConversation(conv);
    setTypingUsers([]);
  }, [conv]);

  useEffect(() => {
    if (activeConversationFromRedux && activeConversationFromRedux.id === conversation.id) {
      const unsubscribe = db
        .collection(collections.conversationsPerMember)
        .where('conversationId', '==', conversation.id)
        .onSnapshot(querySnapshot => {
          if (isNotEmptyArray(querySnapshot.docs)) {
            const conversationPerMemberData: any = querySnapshot.docs.map(x => x.data());
            if (isNotEmptyArray(conversationPerMemberData)) {
              if (conversation.id === conversationPerMemberData[0].conversationId) {
                const loggedInUserIndex = conversationPerMemberData.findIndex(c => c.ownerId === loggedInUser.id);
                conversationPerMemberData.splice(loggedInUserIndex, 1);
                let modifiedList = conversationPerMemberData.map(member => {
                  const users = conversation.members.filter(memberInfo => memberInfo.id === member.ownerId);
                  if (isNotEmptyArray(users)) {
                    return {
                      user: users[0],
                      isTyping: member.isTyping,
                    };
                  } else return null;
                });
                setTypingUsers(modifiedList);
              }
            }
          }
          return () => {
            unsubscribe();
            setTypingUsers([]);
          };
        });
    } else {
      setTypingUsers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, conversation.id, activeConversationFromRedux]);

  return typingUsers;
}
