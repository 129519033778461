import React, { useEffect, useState, FC, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyArray } from '../utils/arrays';
import { TypographyStyled } from '../CommonComponents/Typography/Typography.style';
import Flex from '../CommonComponents/Flex';
import { useTranslation } from 'react-i18next';
import { getAllChannelList } from './actions/getAllChannelList';
import { ChannelData } from '../Common/interfaces/channel';
import ChannelCard from './ChannelCard';

const { Span } = TypographyStyled;

const GroupList: FC = (): ReactElement => {
  const { t } = useTranslation();
  const groupState = useSelector(state => state.groupReducer);
  const {
    groupList,
    filteredChannels,
    isFilteredByName,
    isLoading,
  }: { groupList: Array<ChannelData>; filteredChannels: Array<ChannelData>; isFilteredByName: boolean; isLoading: boolean } = groupState;
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const dispatch = useDispatch();
  const [list, setList] = useState<Array<ChannelData>>(groupList);

  useEffect(() => {
    dispatch(getAllChannelList(loggedInUser.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFilteredByName) {
      setList(filteredChannels);
    } else {
      setList(groupList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilteredByName, groupList]);

  return (
    <>
      {isNotEmptyArray(list) && !isLoading && (
        <Flex margin="10px 0px 0px 30px">
          <Span size="xl" fontColor="#444444" fontWeight="bold">
            {t('Groups')} {!!list.length && list.length}
          </Span>
        </Flex>
      )}
      {isNotEmptyArray(list) && list.map(channel => <ChannelCard key={channel.id} channel={channel} />)}
    </>
  );
};

export default GroupList;
