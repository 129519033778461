import React from 'react';
import Mentions from 'rc-mentions';
import { isNotEmptyArray } from '../utils/arrays';
import { ImageStyled } from '../CommonComponents/Image/Image.style';
import { Styled } from './styled';
import './textarea.css';
import useGetActiveConversation from '../Common/customHooks/useGetActiveConversation';

interface Props {
  mentionRef?: any;
  mentionVal?: any;
  disabled?: boolean;
  onFocus?: () => void;
  onPaste?: (e: any) => void;
  onChange?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  placement?: 'top' | 'bottom' | undefined;
}

const { UserAvatar } = ImageStyled;
const { ImageContainer } = Styled;

const { Option } = Mentions;

const MentionUser = (props: Props) => {
  const { mentionRef, mentionVal, onFocus, onChange, onKeyPress, onPaste, placement, disabled } = props;
  const activeConversation = useGetActiveConversation();

  const targetConvoMembers =
    activeConversation &&
    isNotEmptyArray(activeConversation.members) &&
    activeConversation.members.reduce((options, member) => {
      options.push({
        id: member.id,
        displayName: member.displayName,
        profileImageUrl: member.profileImageUrl,
      });
      return options;
    }, []);

  const options =
    activeConversation &&
    isNotEmptyArray(targetConvoMembers) &&
    targetConvoMembers.map(({ id, displayName, profileImageUrl }) => {
      return (
        <Option key={id} value={displayName} style={{ display: 'flex', alignItems: 'center', padding: '0px 10px' }}>
          <ImageContainer>
            <UserAvatar src={profileImageUrl} height="30" width="30" />
          </ImageContainer>
          <span>{displayName}</span>
        </Option>
      );
    });

  return (
    <Mentions
      ref={mentionRef}
      value={mentionVal}
      disabled={disabled}
      onFocus={onFocus}
      onChange={onChange}
      onPaste={onPaste}
      onKeyPress={onKeyPress}
      placeholder="Aa"
      className="rc-mentions"
      placement={placement}
      autoFocus
    >
      {options}
    </Mentions>
  );
};

export default MentionUser;
