import axios from 'axios';
import { DELETE_FILE_ERROR, DELETE_FILE_LOADING, DELETE_FILE_SUCCESS } from '../actionTypes';

export function hasError() {
  return {
    type: DELETE_FILE_ERROR,
  };
}

export function isLoading() {
  return {
    type: DELETE_FILE_LOADING,
  };
}

export function isSuccess(payload) {
  return {
    type: DELETE_FILE_SUCCESS,
    payload: payload,
  };
}

export function deleteFile(fileId: string, conversationId: string) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios
      .delete(process.env.REACT_APP_API_URL + `/files/${conversationId}`, {
        data: {
          fileId: fileId,
        },
      })
      .then(
        response => {
          dispatch(isSuccess(response.data.message));
        },
        err => {
          dispatch(hasError());
        }
      );
  };
}
