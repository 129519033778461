import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Audio({
  style = {},
  fill = '#444B54',
  width = '100%',
  className = '',
  viewBox = '0 0 159.9 200',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M15.5,0.1c31,0,61.9,0,92.9,0c0.3,0.9,1.1,1.5,1.6,2.1c16.3,18.9,32.6,37.9,48.9,56.8c0.7,0.8,1.1,1.5,1.1,2.6
	c0,39.1,0,78.3,0,117.4c0,11.9-9.2,21-21.1,21c-39.8,0.1-79.6,0.1-119.4,0.1c-4.1,0-7.7-1.3-10.9-3.9c-4-3.3-6.4-7.5-7.7-12.5
	c-1.2-4.3-0.7-8.7-0.9-13.1c4.4,0,8.8,0,13.1,0c0,0.9,0,1.7,0,2.5c0.2,7.7,5.1,12.5,12.8,12.5c13.4,0,26.8,0,40.1,0
	c22.8,0,45.5,0,68.3,0c7.3,0,12.6-4.9,12.7-11.7c0.4-33.2,0.8-66.5,1.3-99.7c0-1.4-0.3-1.8-1.7-1.8c-7.7,0.1-15.5,0-23.2,0
	c-14.2,0-24.3-10.2-24.4-24.5c0-10.8,0-21.6,0-32.5c0-1.5-0.4-1.8-1.8-1.8c-8,0.1-16,0-24,0c-16,0-32,0-48,0
	c-6.3,0-11.6,4.5-11.9,10.3c-0.4,5.5-0.4,141.1-0.2,146.7c-4.4,0.2-8.7,0.3-13.1,0c0-7.1,0.1-144.2,0.3-151.3
	c0.2-7,3.5-12.4,9.2-16.5C11.2,1.8,13.4,1.1,15.5,0.1z"
      />
      <path fill="none" stroke="#FF0000" strokeMiterlimit="10" d="M80,100.1" />
      <path
        fill="#E74C3C"
        d="M115.2,77.8H44.8c-1.8,0-3.2,1.4-3.2,3.2v57.6c0,1.8,1.4,3.2,3.2,3.2h70.4c1.8,0,3.2-1.4,3.2-3.2V81
	C118.4,79.3,117,77.8,115.2,77.8z"
      />
      <path
        fill="#FFFFFF"
        d="M91.2,112.5l-19.7,9.8c-1.5,0.7-3.3,0.1-4-1.4c-0.2-0.4-0.3-0.8-0.3-1.3V100c0-1.7,1.4-3,3-3
	c0.4,0,0.9,0.1,1.3,0.3l19.6,9.8c1.5,0.8,2.1,2.6,1.3,4C92.2,111.8,91.7,112.2,91.2,112.5z"
      />
    </svg>
  );
}
