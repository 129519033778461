import {
  CHANGE_SECOND_PANEL_VIEW,
  CHANGE_THIRD_PANEL_VIEW,
  CHANGE_FOURTH_PANEL_VIEW,
  TOGGLE_THREAD_CONVERSATION_VIEW,
  TOGGLE_DIMMED_MODAL,
} from './actionTypes';
import { SECOND_PANEL_VIEW, THIRD_PANEL_VIEW, FOURTH_PANEL_VIEW, THREAD_CONVERSATION_VIEW } from '../../Common/enums';

export function changeSecondPanelView(view: SECOND_PANEL_VIEW) {
  return {
    type: CHANGE_SECOND_PANEL_VIEW,
    view: view,
  };
}

export function changeThirdPanelView(view: THIRD_PANEL_VIEW) {
  return {
    type: CHANGE_THIRD_PANEL_VIEW,
    view: view,
  };
}

export function changeFourthPanelView(view: FOURTH_PANEL_VIEW) {
  return {
    type: CHANGE_FOURTH_PANEL_VIEW,
    view: view,
  };
}

export function toggleThreadConversationView(view: THREAD_CONVERSATION_VIEW) {
  return {
    type: TOGGLE_THREAD_CONVERSATION_VIEW,
    view: view,
  };
}

export function toggleDimmedModal(toggle: boolean) {
  return {
    type: TOGGLE_DIMMED_MODAL,
    toggle,
  };
}
