import {
  SET_ACTIVE_CONVERSATION_ID,
  SET_CONVERSATION_MESSAGES,
  CLEAR_ALL_MESSAGES,
  ADD_MESSAGE_TO_CONVERSATION_MESSAGES,
  TOGGLE_IS_FILTERED,
  TOGGLE_PREVIEW_FILE,
  UPDATE_ACTIVE_CONVERSATION,
} from './actionTypes';
import { ChannelData } from '../../Common/interfaces/channel';

export const setActiveConversationId = (conversationId: string) => {
  return {
    type: SET_ACTIVE_CONVERSATION_ID,
    conversationId,
  };
};

export const setConvesationMessageList = conversationMessages => {
  return {
    type: SET_CONVERSATION_MESSAGES,
    conversationMessages: conversationMessages,
  };
};

export const clearConversationReducer = () => {
  return {
    type: CLEAR_ALL_MESSAGES,
  };
};

export const addMessageToConversationMessages = message => {
  let tempMessage = { ...message };
  tempMessage.id = `fakeId_${Math.random()}`;
  tempMessage.dateCreated = Date.now();
  tempMessage.lastDateUpdated = Date.now();
  tempMessage.enableEdit = false;
  tempMessage.status = {
    isOriginal: true,
    isUpdated: false,
    isDeleted: false,
  };
  tempMessage.isDummy = false;

  return {
    type: ADD_MESSAGE_TO_CONVERSATION_MESSAGES,
    tempMessage,
  };
};

export const toggleIsFiltered = (toggle: boolean) => {
  return {
    type: TOGGLE_IS_FILTERED,
    toggle,
  };
};

export const togglePreviewFile = (toggle: boolean) => {
  return {
    type: TOGGLE_PREVIEW_FILE,
    toggle,
  };
};

export const updateActiveConversation = (conversation: ChannelData) => {
  return {
    type: UPDATE_ACTIVE_CONVERSATION,
    conversation,
  };
};
