import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { UserData } from '../../../../Common/interfaces/user';
import Flex from '../../../../CommonComponents/Flex';
import { ImageStyled } from '../../../../CommonComponents/Image/Image.style';

const { UserAvatar } = ImageStyled;

interface Props {
  isRecursive: boolean;
  imageUrl: string;
  handleClick: () => void;
  senderId: string;
}

const Avatar: FC<Props> = ({ isRecursive, imageUrl, handleClick, senderId }) => {
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const isOwnSpace = senderId === loggedInUser.id;
  return (
    <Flex container height="100%" handleOnClick={handleClick}>
      {isRecursive ? (
        <Flex width="45px" height="0px" />
      ) : (
        <UserAvatar src={isOwnSpace ? loggedInUser.profileImageUrl : imageUrl} width="45px" height="45px" alt="" />
      )}
    </Flex>
  );
};

export default Avatar;
