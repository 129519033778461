import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Logo({ style = {}, width = '100%', className = '', viewBox = '0 0 616 614' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#fff"
        d="M585.6 307a278 278 0 01-186.5 262 289.3 289.3 0 01-68.8 14.2 295.5 295.5 0 01-67.4-2.9 290.2 290.2 0 01-58.1-16.3l-2.8-1.1-1.2-.5a296.2 296.2 0 01-62.6-36.8 331.7 331.7 0 01-25-22.2A297.6 297.6 0 0192 479.7c-3-3.6-2.6-3.3-5.7-7.3A277 277 0 0134 268.8a292.7 292.7 0 0119.5-70.3A294.7 294.7 0 0189.7 137l1.3-1.6a306 306 0 0120-22.6c9.1-9.2 18.8-17.8 29-25.7l2.2-1.7a253.3 253.3 0 0111.9-8.3 294.2 294.2 0 0147.6-25.8l2.6-1a290.3 290.3 0 0168-18 343.2 343.2 0 017.7-.8 296.2 296.2 0 0162.7.5c.9 0 5 .6 2.1.2a386.4 386.4 0 0114.8 2.5A289.2 289.2 0 01417.4 52l1.9.8 4.2 2A297.5 297.5 0 01478 87.6c1.8 1.4-1.7-1.4 0 0l2.6 2a296.4 296.4 0 0147.3 47.9c1.4 1.8-1.4-1.8 0 0l1.2 1.6a276.6 276.6 0 0114.5 21 292 292 0 0118.3 34.4l2.2 4.8c-1.2-2.5.4 1.1.7 2a283.6 283.6 0 0118.4 68l.1.8.2 1.4a389.7 389.7 0 011.4 14.4c.5 7 .7 14 .7 21 0 7.9 7 15.4 15 15 8.1-.4 15-6.6 15-15a308.2 308.2 0 00-47.1-163.6 308.9 308.9 0 00-122.7-111A307.6 307.6 0 00117.3 67 307.7 307.7 0 0020 202.3a308 308 0 00-5.2 194 310.5 310.5 0 00116.3 161.1 306.6 306.6 0 00368.5-10.1 306 306 0 00116-240.3c0-7.8-6.9-15.4-15-15-8.1.4-15 6.6-15 15z"
      />

      <circle cx="277.9" cy="254.3" r="126.8" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="15" />

      <path
        fill="#fff"
        d="M212 232.4c-.8 0-1.4-.5-1.7-1.4-.6-2.5-3.4-8.8-13.1-25.2-2.8-4.7-4-6.8-4.6-8.1-.7-1.7-.5-2.7.7-3.6l1.1-1h-.5c-2.2 0-3.8-.7-4.8-2-2.4-3-11.4-15.7-11.8-16.3-21-33.3-69.5-59.7-70-60h-.2l-6-5.9h4.2c5.9 0 58.5 1 95.1 37.2 34.1 33.8 18.6 74.9 16.7 79.5-2.8 6.8-4.4 6.8-5 6.8z"
      />

      <path
        fill="#fff"
        d="M105.3 110.4c5.7 0 57.9 1 94 36.8 36.8 36.3 15.8 83.7 12.8 83.7-.2 0-.3 0-.3-.3-1.3-4.7-8-16.6-13.3-25.6-5.3-9-5.3-9-4.3-9.7 1-.8 5.2-4.9 5.2-4.9s-2.9 1.2-5.5 1.2c-1.5 0-2.8-.3-3.7-1.4-2.3-3-11.7-16.2-11.7-16.2-21.4-34-70.5-60.5-70.5-60.5l-3.3-3.1h.6m0-3h-.6l-7.2.1 5.2 5 3.2 3.2.3.3.4.2c.5.2 48.6 26.5 69.4 59.4v.1c.5.5 9.5 13.2 11.9 16.3.6.8 1.6 1.7 3.3 2.2-.7 1.1-.7 2.5 0 4 .6 1.5 2 3.6 4.7 8.3a137.4 137.4 0 0113 24.9 3.3 3.3 0 003.2 2.5c1.7 0 3.6-1 6.4-7.8a78.1 78.1 0 005-34.8 72.2 72.2 0 00-22-46.2c-37-36.7-90.3-37.7-96.2-37.7z"
      />

      <path
        fill="#fff"
        d="M568.4 163a181 181 0 00-30.8-43.2A166 166 0 00443 67a179 179 0 00-82.2 7.6c-5.3 1.7-4.4 9.7 1.3 9.8a73.7 73.7 0 019.1.7l1.8.3c.9 0-1.3-.2-.1 0l1 .1a197.7 197.7 0 0161.2 19.9 209 209 0 0162.2 49.2 266.9 266.9 0 0151 94.9c16 50.7 20.5 104.4 19.6 157.4-.1 8.2-.4 16.3-.8 24.5-.2 5 6.9 6.6 9.3 2.5a198 198 0 0019.7-49.6 289.6 289.6 0 005.2-126.9c-5.9-33-17.5-64.7-32.9-94.5-3-5.7-11.5-.7-8.6 5 38.8 75.2 49.7 166.6 15.6 245.5a130 130 0 01-7.6 15.5l9.3 2.5c2.7-56.4 0-114-14.6-168.7a294.4 294.4 0 00-50.2-105.5 220 220 0 00-64-56 210.6 210.6 0 00-76.7-26c-2.8-.4-6.3-1.2-9-.8h-.5l1.4 9.8c42-13.4 86.8-12.6 125.7 9.5a183.2 183.2 0 0170.6 74.2c2.8 5.8 11.4.8 8.6-5z"
      />

      <path
        fill="#fff"
        d="M488 147.5a166.6 166.6 0 018 16.2c1 1.9 1.8 3.7 2.6 5.6l1.3 2.9.6 1.6a417.4 417.4 0 0123.6 74 345 345 0 017.4 101.2 275 275 0 01-33.8 112A328.2 328.2 0 01396 569c-6.2 4.3-12.5 8.4-18.9 12.3-6 3.7-2 12.3 4.6 11 16.4-3.2 32.5-9.6 47.7-16.4a305 305 0 00120.5-97.5c8-10.8 16.1-22.3 21.8-34.5 8-17.5 7.7-36.3 7.4-55-.6-39-4.6-78-13.4-116-10.1-43.3-27.1-86.5-56.6-120.4-3.7-4.3-7.6-8.3-11.7-12.2-5.6-5.4-14 3.1-8.5 8.5 31.8 30.3 50.4 71.5 61.7 113.4 10.2 37.5 14.9 76.4 16.2 115.2.5 14 1.6 28.9-.4 42.8-2 14.3-9.4 26.3-17.1 38.2a295.8 295.8 0 01-116.8 102.9c-17 8.2-35.3 15.9-54 19.4l4.7 11c45-28 86-63.7 115-108.4 23-35.2 37.7-75.3 43.4-116.8 4.9-35.9 3.3-72.3-3.1-107.8-5-27.3-12.7-54.1-23-79.9-4.9-12.3-9.9-25.5-17-36.8l-.2-.5c-3.8-6.7-14.2-.7-10.4 6z"
      />

      <path
        fill="#fff"
        d="M472 129.7c5 8.5 8.9 17.7 12.4 26.9a301 301 0 0118.2 71.2c8.3 65.9-8.3 133.6-50.7 185.4A267 267 0 01330 495.1a242.2 242.2 0 01-127 9.6c-58.2-12.2-107.8-47.8-147.2-91.3-6.3-7-12.3-14.1-18.1-21.4-4-5-11.1 2-7.1 7C70.3 448.6 121 491.7 182.9 510c44.5 13 92 11.8 136.4-1A285.1 285.1 0 00444 437a250.5 250.5 0 0070.4-186 292.2 292.2 0 00-32.8-124.3l-1-2c-3.2-5.5-11.8-.5-8.6 5.1z"
      />

      <path
        fill="#fff"
        d="M262.6 517s-34 12-149.5-20.5c0 0 6.5 19.5 67.5 69.5 0 0-118-59-145-155s-13-184 6-221 47-75 47-75 18 4 9 17-34 40-46 69-32 94.7-6 171c29 85 113.4 142.8 217 145z"
      />

      <path fill="#fff" d="M162.6 551s194 102 328-78c0 0-65.5 106.7-116 118-34.6 7.8-167 11-212-40z" />
      <path fill="#fff" d="M111.6 493s38.4 79.1 165 87l5 4-47 8-98-42-30-50-6-19 11 12z" />

      <circle fill="#fff" cx="117.6" cy="128" r="13" />
      <circle fill="#fff" cx="104.6" cy="138" r="13" />
      <circle fill="#fff" cx="71.6" cy="113" r="13" />
      <circle fill="#fff" cx="107.6" cy="115" r="13" />

      <path
        fill="#fff"
        d="M119.2 119.4s63.2 2.7 67.7 4.4c0 0 .9 1-1-2.6l-2.6-5.4s4.5 6.3 6.2 7.2c1.8.8 37.4.8 49 6.2 0 0 2.6.9 3.5 1.8 1 .9 2.7-11.6-34.7-32-37.3-20.5-93.4.8-104 5.3-10.8 4.4 8 14.2 8 14.2l8 .9z"
      />
      <path
        fill="#fff"
        d="M108 112.2c5.5-7.6 27.8-43-9.8-66.8-3.2-2-6.7-3.4-10.4-4.3-8.2-2-24.5-5.2-38-2.3-.7.1-.8 1.2-.1 1.5C56 43 74.3 51.5 80.4 58.5 87.8 66.9 89 68 90 68h3.8c1 0 1.6 1.2 1 2-.9 1.2-.8 2.9.3 3.9 3.8 3.6 11.5 13.6 7.7 32.9 0 .3 0 .6.2 1l3 4.3c.5.8 1.6.8 2 0z"
      />
      <path
        fill="#fff"
        d="M96.3 110.2s-23-13.3-33.9-15.6l.1-5.9-2.1 5S22.4 74 0 70.6c0 0 15.5-28 63.3-8.8 47.9 19 44.8 40.4 44.5 43.9s-11.5 4.6-11.5 4.6z"
      />
      <path
        fill="#fff"
        d="M113.1 496.5s65.7 108.3 240 74.9c0 0 151.5-39.4 175.5-203.4 0 0 17-62-24-187l9.5 11 24.4 126.8L533 381l-39.5 98.4-68.2 74.7-83.8 32.9-73 2-115.8-32-67.2-69 2-7 25.5 15.5z"
      />
    </svg>
  );
}
