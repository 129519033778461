import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavigationStyled } from './styled';
import { changeSecondPanelView, toggleThreadConversationView, changeFourthPanelView } from './actions';
import { SECOND_PANEL_VIEW, THREAD_CONVERSATION_VIEW, FOURTH_PANEL_VIEW } from '../Common/enums';
import NotificationSettings from '../Notification';
import Logout from './Logout';
import Icon from '../CommonComponents/Icon';
import Flex from '../CommonComponents/Flex';
import { DecorationStyled } from '../CommonComponents/Decoration/Decoration.style';
import IconContainer from '../CommonComponents/Decoration/IconContainer';
import { UserData } from '../Common/interfaces/user';
import { getAllChannelList } from '../ChannelList/actions/getAllChannelList';

const { NavigationIconWrapper } = NavigationStyled;
const { DefaultBackground } = DecorationStyled;

const Navigation: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const secondPanelView: SECOND_PANEL_VIEW = useSelector(state => state.navigationReducer.secondPanelView);
  const isMessageListView = secondPanelView === SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW || secondPanelView === SECOND_PANEL_VIEW.THREAD_VIEW;
  const isFriendListView = secondPanelView === SECOND_PANEL_VIEW.CHANNEL_FRIEND_VIEW;

  return (
    <DefaultBackground>
      <Flex container flexDirection="column" width="90px" height="100%" padding="35px 0px 35px 0px">
        <Flex container flexDirection="column" margin="0px 0px 115px 0px" width="100%">
          <IconContainer
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              dispatch(changeSecondPanelView(SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW));
            }}
            iconName="logo"
            iconWidth="60px"
          />
        </Flex>
        <Flex container flexDirection="column" alignItems="flex-end" justifyContent="flex-start" height="100%">
          <Flex margin="0px 0px 65px 0px">
            <NavigationIconWrapper
              view={isMessageListView}
              onClick={() => {
                dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN));
                dispatch(changeSecondPanelView(SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW));
                dispatch(toggleThreadConversationView(THREAD_CONVERSATION_VIEW.HIDDEN));
              }}
            >
              <Icon
                name={'message-list'}
                width={'40px'}
                fill={isMessageListView ? 'white' : '#46D8D8'}
                style={{
                  borderRadius: '50%',
                  padding: '10px',
                  backgroundColor: isMessageListView ? '#21A3A5' : 'white',
                  transform: 'translate(0px, 1px)',
                }}
              />
            </NavigationIconWrapper>
          </Flex>
          <Flex margin="0px 0px 65px 0px">
            <NavigationIconWrapper
              view={isFriendListView}
              onClick={() => {
                dispatch(changeSecondPanelView(SECOND_PANEL_VIEW.CHANNEL_FRIEND_VIEW));
                dispatch(getAllChannelList(loggedInUser.id));
              }}
            >
              <Icon
                name={'friend-list'}
                width={'40px'}
                fill={isFriendListView ? 'white' : '#46D8D8'}
                style={{
                  borderRadius: '50%',
                  padding: '10px',
                  backgroundColor: isFriendListView ? '#21A3A5' : 'white',
                  transform: 'translate(0px, 1px)',
                }}
              />
            </NavigationIconWrapper>
          </Flex>
          <NotificationSettings />
          <Flex container height="100%" alignItems="flex-end">
            <Logout />
          </Flex>
        </Flex>
      </Flex>
    </DefaultBackground>
  );
};

export default Navigation;
