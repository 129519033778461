import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function AndroidPlaystore({
  style = {},
  fill = '#fff',
  stroke = '#707070',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 145.297 42',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="145.297" height="42" transform="translate(-0.365 0.5)" fill={fill} stroke={stroke} stroke-width="1" />
        </clipPath>
      </defs>
      <g transform="translate(0.365 -0.5)" clip-path="url(#clip-path)">
        <g transform="translate(0.049 3.083)">
          <g>
            <path
              d="M93.533,70.158l17.34,17.34q-8.579,8.552-17.213,17.177a2.7,2.7,0,0,1-1.108-2.379V72.41A2.842,2.842,0,0,1,93.533,70.158Z"
              transform="translate(-92.552 -69.508)"
              fill="#fff"
            />
            <path
              d="M116.7,81.638c-1.87,1.87-3.668,3.668-5.447,5.429q-8.579-8.579-17.231-17.231a2.808,2.808,0,0,1,2.742.254c2.506,1.471,5.03,2.905,7.554,4.358,3.94,2.27,7.862,4.539,11.8,6.809C116.3,81.384,116.485,81.511,116.7,81.638Z"
              transform="translate(-92.353 -69.586)"
              fill="#fff"
            />
            <path
              d="M138.086,84.521c-3.141-2.451-6.155-1.634-7.826-.218a6.184,6.184,0,0,0-1.652,7.517,5.99,5.99,0,0,0,6.464,3.287,4.963,4.963,0,0,0,4.321-4.449h-5.356V88.352h7.88a7.833,7.833,0,0,1-1.344,6.028,8.272,8.272,0,0,1-12.656.436,7.779,7.779,0,0,1-1.925-8.316,8.271,8.271,0,0,1,6.736-5.72,8.027,8.027,0,0,1,7.081,2.143C139.248,83.449,138.685,83.958,138.086,84.521Z"
              transform="translate(-88.097 -68.092)"
              fill="#fff"
            />
            <path
              d="M116.482,91.227c-1.235.708-2.524,1.453-3.813,2.2q-8.035,4.63-16.088,9.3a3.324,3.324,0,0,1-2.669.182c5.829-5.774,11.53-11.421,17.213-17.068C112.923,87.668,114.684,89.429,116.482,91.227Z"
              transform="translate(-92.368 -67.39)"
              fill="#fff"
            />
            <path
              d="M164.1,97.405c.908,1.634,2.252,2.233,3.74,1.743a3.039,3.039,0,0,0,1.87-3.577,9.449,9.449,0,0,1-2.143.962,5.136,5.136,0,0,1-5.865-4.013,5.542,5.542,0,0,1,3.922-6.555,4.2,4.2,0,0,1,4.085,1.017c.036-.291.073-.508.109-.781h2.288v2.923c0,2.215.036,4.43,0,6.646a6.574,6.574,0,0,1-.962,3.722,5.266,5.266,0,0,1-7.481.835,23.545,23.545,0,0,1-1.689-1.961C162.756,98,163.409,97.714,164.1,97.405Zm2.833-9.369a3.275,3.275,0,0,0-.018,6.537,3.026,3.026,0,0,0,2.941-3.268C169.783,89.089,168.385,87.982,166.932,88.036Z"
              transform="translate(-83.225 -67.395)"
              fill="#fff"
            />
            <path
              d="M188.656,81.019a59.047,59.047,0,0,1,7.19.345,4.585,4.585,0,0,1,3.759,4.993,4.871,4.871,0,0,1-4.648,4.648c-1.271.091-2.542.018-3.886.018v6.028h-2.415C188.656,91.75,188.656,86.467,188.656,81.019Zm2.469,2.324V88.7c1.217,0,2.4.018,3.577,0a2.826,2.826,0,0,0,2.56-2.651,2.637,2.637,0,0,0-2.233-2.687C193.722,83.253,192.433,83.343,191.125,83.343Z"
              transform="translate(-79.565 -68.04)"
              fill="#fff"
            />
            <path
              d="M109.62,86.05c1.8-1.8,3.65-3.668,5.556-5.574,1.925,1.126,3.9,2.2,5.792,3.45a2.682,2.682,0,0,1-.254,4.376c-1.852,1.144-3.777,2.2-5.629,3.268Z"
              transform="translate(-90.245 -68.114)"
              fill="#fff"
            />
            <path
              d="M212.354,96.405h-2.27V95.062a6.662,6.662,0,0,0-.49.472,4.074,4.074,0,0,1-6.282-.8,3.314,3.314,0,0,1,1.6-4.666,5.724,5.724,0,0,1,4.975-.036,2.131,2.131,0,0,0-1.634-1.888,2.521,2.521,0,0,0-3.069.981c-.726-.291-1.434-.581-2.179-.89a3.626,3.626,0,0,1,2.887-2.27c2.088-.4,4.031-.109,5.393,1.689a5.1,5.1,0,0,1,1.017,2.56c.163,1.8.054,3.613.054,5.429C212.354,95.861,212.354,96.1,212.354,96.405Zm-2.6-4.321a4.377,4.377,0,0,0-3.432-.309c-.926.327-1.289.817-1.2,1.634a1.547,1.547,0,0,0,1.6,1.162C208.214,94.644,209.267,93.809,209.757,92.084Z"
              transform="translate(-77.642 -67.393)"
              fill="#fff"
            />
            <path
              d="M146.055,96.9a5.511,5.511,0,0,1,0-11.022,5.511,5.511,0,1,1,0,11.022Zm-3.014-5.7a3.057,3.057,0,1,0,3.178-3.178A3.081,3.081,0,0,0,143.04,91.2Z"
              transform="translate(-86.061 -67.383)"
              fill="#fff"
            />
            <path
              d="M156.5,96.9a5.5,5.5,0,1,1,5.52-5.574A5.325,5.325,0,0,1,156.5,96.9Zm-3-5.665A3.043,3.043,0,1,0,156.572,88,3.1,3.1,0,0,0,153.5,91.237Z"
              transform="translate(-84.649 -67.381)"
              fill="#fff"
            />
            <path
              d="M185.256,94.485a5.374,5.374,0,0,1-9.787-2.052,5.384,5.384,0,0,1,3.7-6.337,4.763,4.763,0,0,1,5.647,2.615c.2.4.345.835.545,1.326-2.433,1.035-4.812,2.034-7.19,3.032,1.053,2.124,3.3,2.179,5.32.163C184.04,93.613,184.6,94.013,185.256,94.485Zm-2.8-5.356a2.234,2.234,0,0,0-2.705-1,2.823,2.823,0,0,0-1.925,2.942C179.373,90.418,180.917,89.764,182.46,89.129Z"
              transform="translate(-81.358 -67.38)"
              fill="#fff"
            />
            <path
              d="M216.527,93.1c.835-2.179,1.67-4.231,2.415-6.3a.877.877,0,0,1,1.053-.69c.563.054,1.144.018,1.834.018-.49,1.126-.944,2.179-1.4,3.232-1.6,3.722-3.214,7.445-4.812,11.185-.218.49-.454.726-1.017.672-.545-.054-1.108-.018-1.761-.018.6-1.307,1.071-2.6,1.743-3.777a3.166,3.166,0,0,0,.036-3.159c-1.18-2.469-2.215-5.011-3.3-7.517-.073-.163-.127-.345-.218-.617.781,0,1.507-.036,2.215.018.2.018.454.309.545.508.835,2.015,1.652,4.049,2.469,6.065A2.674,2.674,0,0,0,216.527,93.1Z"
              transform="translate(-76.532 -67.354)"
              fill="#fff"
            />
            <path d="M172.164,81.115h2.342V97.021h-2.342Z" transform="translate(-81.793 -68.027)" fill="#fff" />
            <path d="M201.8,97.037h-2.342V81.131H201.8Z" transform="translate(-78.106 -68.025)" fill="#fff" />
            <path
              d="M159.608,75.451A4.413,4.413,0,1,1,164,79.9,4.366,4.366,0,0,1,159.608,75.451Zm4.34-3.377a3.367,3.367,0,0,0-3.178,3.5,3.263,3.263,0,1,0,6.519-.182A3.34,3.34,0,0,0,163.948,72.073Z"
              transform="translate(-83.491 -69.39)"
              fill="#fff"
            />
            <path
              d="M175.267,79.681a1.263,1.263,0,0,1-1.616-.726c-1.144-1.943-2.4-3.831-3.613-5.738-.036-.054-.091-.109-.2-.254v6.718h-1.017V71.238a1.372,1.372,0,0,1,1.761.726c1,1.8,2.143,3.523,3.232,5.284.073.109.145.218.309.472V71.238h1.126C175.267,74.052,175.267,76.848,175.267,79.681Z"
              transform="translate(-82.245 -69.371)"
              fill="#fff"
            />
            <path
              d="M133.319,72.473c-.254.218-.508.418-.763.636a3.24,3.24,0,0,0-4.158-.272,3.533,3.533,0,0,0-1.035,4.267,3.589,3.589,0,0,0,3.759,1.8,2.631,2.631,0,0,0,2.252-2.6h-3.014V75.179h3.995a3.775,3.775,0,0,1-2.252,4.376,4.464,4.464,0,0,1-4.285-7.79A4.161,4.161,0,0,1,133.319,72.473Z"
              transform="translate(-88.051 -69.39)"
              fill="#fff"
            />
            <path
              d="M139.755,78.547v1.126H134.8V71.23h4.957v.944h-3.813v2.633h3.5V75.86h-3.523v2.687Z"
              transform="translate(-86.843 -69.363)"
              fill="#fff"
            />
            <path
              d="M142.579,72.21h-2.342v-.98h5.9v.962h-2.415v7.481c-.418.018-.744.036-1.144.073Z"
              transform="translate(-86.108 -69.363)"
              fill="#fff"
            />
            <path d="M154.675,79.73h-1v-7.5h-2.451V71.214H157v.962h-2.324Z" transform="translate(-84.623 -69.365)" fill="#fff" />
            <path d="M150.187,79.712H149.1v-8.5h1.089Z" transform="translate(-84.91 -69.365)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  );
}
