import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyArray } from '../../../utils/arrays';
import { MessageStyled } from './styled';
import MessageOption from '../../../CommonComponents/MessageOption';
import { SELECTED_MESSAGE_OPTION, FOURTH_PANEL_VIEW } from '../../../Common/enums';
import { MessageData } from '../../../Common/interfaces/message';
import { ChannelData, MembersData } from '../../../Common/interfaces/channel';
import { getDifferenceDurationBetweenDate, getWeekDay, getMonth } from '../../../utils/timings';
import Replies from './Replies';
import Avatar from './Avatar';
import Content from './Content';
import Reactions from './Reactions';
import { sendReactionToMessage } from '../../../Common/actions';
import { UserData } from '../../../Common/interfaces/user';
import { getUserInfo, getFriendInfo } from '../../../FriendList/actions';
import { changeFourthPanelView } from '../../../Navigation/actions';
import { FriendData } from '../../../Common/interfaces/friend';
import { findUserInFriendList } from '../../../utils/friendList';
import { handleReactionsArray } from '../../../utils/message';
import { findUserInsideConversationMembers } from '../../../utils/conversation';
import Modal from '../../../CommonComponents/Modal';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { useTranslation } from 'react-i18next';
import useGetActiveConversation from '../../../Common/customHooks/useGetActiveConversation';

const { MesssageContainer, DateContainer } = MessageStyled;
const { Span } = TypographyStyled;

interface Props {
  message: MessageData;
  messageKey: number;
  messages: Array<MessageData>;
}

const Message: React.FC<Props> = ({ message, messageKey, messages }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const friendList: Array<FriendData> = useSelector(state => state.friendReducer.friendList);
  const activeConversation: ChannelData = useGetActiveConversation();
  const [isMessageOptionVisible, setIsMessageOptionVisible] = useState(false);
  const [selectedEmojis, setSelectedEmojis]: any[] = useState([]);
  const [activeMessageOption, setActiveMessageOption] = useState(SELECTED_MESSAGE_OPTION.NONE);
  const [sameUserPreviousMessage, setSameUserPreviousMessage] = useState(true);
  const [isEditMessageActive, toggleEditMessage] = useState(false);
  const [nextDate, setNextDate] = useState('');
  const [user, setUser] = useState<MembersData>({
    displayName: '',
    id: '',
    profileImageUrl: '',
  });

  useEffect(() => {
    const today: any = new Date();
    const messageDate: any = new Date(message.dateCreated);
    if (isNotEmptyArray(messages) && messageKey < messages.length - 1) {
      const sameSenderBeforeMessage = messages[messageKey + 1].userId === message.userId;
      const { diffMins } = getDifferenceDurationBetweenDate(messages[messageKey + 1].dateCreated, message.dateCreated);
      const isDifferenceGreaterThan4Minutes = diffMins >= 4;
      const previousMessageDate = new Date(messages[messageKey + 1].dateCreated);
      setSameUserPreviousMessage(sameSenderBeforeMessage && !isDifferenceGreaterThan4Minutes);

      if (previousMessageDate.getDate() !== messageDate.getDate()) {
        if (messageDate.getDate() === today.getDate()) {
          setNextDate(t('Today'));
        } else if (messageDate.getDate() === today.getDate() - 1) {
          setNextDate(t('Yesterday'));
        } else {
          if (i18n.language === 'en') {
            setNextDate(`${getMonth(messageDate.getMonth())} ${messageDate.getDate()}, ${getWeekDay(messageDate.getDay())}`);
          } else {
            setNextDate(`${t(getMonth(messageDate.getMonth()))}${messageDate.getDate()}(${t(getWeekDay(messageDate.getDay()))})`);
          }
        }
      } else {
        setNextDate('');
      }
    } else if (isNotEmptyArray(messages) && messageKey === messages.length - 1) {
      setSameUserPreviousMessage(false);
      if (messageDate.getDate() === today.getDate()) {
        setNextDate(t('Today'));
      } else if (messageDate.getDate() === today.getDate() - 1) {
        setNextDate(t('Yesterday'));
      } else {
        if (i18n.language === 'en') {
          setNextDate(`${getMonth(messageDate.getMonth())} ${messageDate.getDate()}, ${getWeekDay(messageDate.getDay())}`);
        } else {
          setNextDate(`${t(getMonth(messageDate.getMonth()))}${messageDate.getDate()}(${t(getWeekDay(messageDate.getDay()))})`);
        }
      }
    } else {
      setSameUserPreviousMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageKey, messages]);

  useEffect(() => {
    if (activeConversation && isNotEmptyArray(activeConversation.members)) {
      setUser(findUserInsideConversationMembers(message.userId, activeConversation.members));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation]);

  useEffect(() => {
    setIsMessageOptionVisible(false);
    setActiveMessageOption(SELECTED_MESSAGE_OPTION.NONE);
  }, [selectedEmojis]);

  useEffect(() => {
    if (isNotEmptyArray(message.reactions)) {
      setSelectedEmojis(message.reactions);
    } else {
      setSelectedEmojis([]);
    }
  }, [message.reactions]);

  const handleEmojiSelect = async emoji => {
    setSelectedEmojis(handleReactionsArray(emoji, message, loggedInUser.id));
    handleReactionsArray(emoji, message, loggedInUser.id);
    setIsMessageOptionVisible(false);
    setActiveMessageOption(SELECTED_MESSAGE_OPTION.NONE);
    const requestBody = {
      messageId: message.id,
      reactions: handleReactionsArray(emoji, message, loggedInUser.id),
    };
    await sendReactionToMessage(requestBody);
  };

  const handleContainerMouseLeave = () => {
    if (activeMessageOption === SELECTED_MESSAGE_OPTION.NONE) {
      setIsMessageOptionVisible(false);
    }
  };

  const handleContainerMouseEnter = () => {
    setIsMessageOptionVisible(true);
    setActiveMessageOption(SELECTED_MESSAGE_OPTION.NONE);
  };

  const handleAvatarClick = () => {
    if (isNotEmptyArray(friendList) && user.id !== loggedInUser.id) {
      const { index }: any = findUserInFriendList(user.id, friendList);
      if (index >= 0) {
        dispatch(getFriendInfo(friendList[index]));
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.FRIEND_INFO));
      } else {
        dispatch(getUserInfo(user));
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.USER_INFO));
      }
    }
  };

  return (
    <>
      <MesssageContainer
        isActive={activeMessageOption !== SELECTED_MESSAGE_OPTION.NONE}
        isRecursive={sameUserPreviousMessage}
        onMouseEnter={handleContainerMouseEnter}
        onMouseLeave={handleContainerMouseLeave}
      >
        <Avatar isRecursive={sameUserPreviousMessage} imageUrl={user.profileImageUrl} handleClick={handleAvatarClick} senderId={user.id} />
        <div className="w-75">
          <Content
            isRecursive={sameUserPreviousMessage}
            message={message}
            user={user}
            isBlocked={activeConversation && activeConversation.isBlocked}
            isEditMessageActive={isEditMessageActive}
            setIsMessageOptionVisible={setIsMessageOptionVisible}
            setActiveMessageOption={setActiveMessageOption}
            toggleEditMessage={toggleEditMessage}
          />
          {isNotEmptyArray(selectedEmojis) && (
            <div className="ml-1">
              <Reactions reactions={selectedEmojis} members={activeConversation.members} />
            </div>
          )}
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <Replies message={message} activeConversation={activeConversation} messageSender={user} />
            </div>
          </div>
        </div>
        <div className="d-flex h-100 w-25 justify-content-end">
          <Modal
            dimmed={false}
            visible={activeMessageOption !== SELECTED_MESSAGE_OPTION.NONE}
            setModalVisibility={() => {
              setIsMessageOptionVisible(false);
              setActiveMessageOption(SELECTED_MESSAGE_OPTION.NONE);
            }}
          />
          {isMessageOptionVisible && !activeConversation.isBlocked && (
            <MessageOption
              message={message}
              isRecursive={sameUserPreviousMessage}
              messageSender={user}
              activeConversation={activeConversation}
              activeMessageOption={activeMessageOption}
              handleEmojiSelect={handleEmojiSelect}
              setIsMessageOptionVisible={setIsMessageOptionVisible}
              setActiveMessageOption={setActiveMessageOption}
              toggleEditMessage={toggleEditMessage}
            />
          )}
        </div>
      </MesssageContainer>
      {nextDate && (
        <div className="w-100 d-flex justify-content-center">
          <DateContainer>
            <Span size="md" fontColor="#444444">
              {nextDate}
            </Span>
          </DateContainer>
        </div>
      )}
    </>
  );
};

export default Message;
