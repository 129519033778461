import styled from 'styled-components/macro';

const media = {
  extraSmall: '@media(max-width: 600px)',
  small: '@media(min-width: 600px)',
  medium: '@media(min-width: 768px)',
  large: '@media(min-width: 992px)',
  extraLarge: '@media(min-width: 1200px)',
};

export const Container = styled.div`
  font-family: Montserrat-Light !important;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const FirstPanel = styled.div`
  width: 100%;
  color: #727272;
  padding: 0px 50px;
  margin-bottom: 50px;
  ${media.large} {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: center;
    align-items: center;
  }
  .right-panel {
    ${media.extraSmall} {
      text-align: center;
    }
    ${media.small} {
      text-align: center;
    }
    ${media.medium} {
      text-align: center;
    }
    ${media.large} {
      text-align: right;
      width: 50%;
    }
    ${media.extraLarge} {
      text-align: right;
      width: 35%;
    }
    button {
      margin-bottom: 50px;
    }
  }
  .panel-title {
    font-family: Montserrat-Light !important;
    margin-bottom: 25px;
    ${media.extraSmall} {
      font-size: 25px;
    }
    ${media.small} {
      font-size: 25px;
    }
    ${media.medium} {
      font-size: 40px;
    }
    ${media.large} {
      font-size: 50px;
    }
    ${media.extraLarge} {
      font-size: 60px;
    }
  }
  .panel-sub-title {
    font-family: Montserrat-Light !important;
    opacity: 0.5;
    margin-bottom: 15px;
    ${media.extraSmall} {
      font-size: 10px;
    }
    ${media.small} {
      font-size: 10px;
    }
    ${media.medium} {
      font-size: 15px;
    }
    ${media.large} {
      font-size: 20px;
    }
    ${media.extraLarge} {
      font-size: 25px;
    }
  }
  .left-panel {
    display: flex;
    justify-content: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
  button {
    font-family: Montserrat-Light !important;
    margin: 30px 0px !important;
  }
`;

export const SecondPanel = styled.div`
  background-color: #dedede;
  width: 100%;
  color: #727272;
  ${media.large} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .right-panel {
    font-family: Montserrat-Light !important;
    ${media.extraSmall} {
      text-align: center;
      padding: 0px 50px;
    }
    ${media.small} {
      text-align: center;
      padding: 0px 50px;
    }
    ${media.medium} {
      text-align: center;
      padding: 0px 50px;
    }
    ${media.large} {
      text-align: left;
      padding: 0px 50px;
      width: 50%;
    }
    ${media.extraLarge} {
      text-align: left;
      padding: 0px 100px;
      width: 50%;
    }
  }
  .panel-title {
    font-family: Montserrat-Light !important;
    margin-bottom: 25px;
    ${media.extraSmall} {
      font-size: 25px;
    }
    ${media.small} {
      font-size: 25px;
    }
    ${media.medium} {
      font-size: 40px;
    }
    ${media.large} {
      font-size: 50px;
    }
    ${media.extraLarge} {
      font-size: 60px;
    }
  }
  .panel-sub-title {
    font-family: Montserrat-Light !important;
    opacity: 0.5;
    margin-bottom: 15px;
    ${media.extraSmall} {
      font-size: 10px;
    }
    ${media.small} {
      font-size: 10px;
    }
    ${media.medium} {
      font-size: 15px;
    }
    ${media.large} {
      font-size: 20px;
    }
    ${media.extraLarge} {
      font-size: 25px;
    }
  }
  .left-panel {
    display: flex;
    justify-content: center;
  }
  img {
    z-index: 0;
    transform: translateY(-30px);
    ${media.extraSmall} {
      width: 350px;
    }
    ${media.small} {
      width: 350px;
    }
    ${media.medium} {
      width: 400px;
    }
    ${media.large} {
      width: 500px;
    }
    ${media.extraLarge} {
      width: 600px;
    }
    height: 100%;
  }
  button {
    font-family: Montserrat-Light !important;
    margin: 30px 0px !important;
  }
`;

export const ThirdPanel = styled.div`
  width: 100%;
  ${media.large} {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
  .container {
    text-align: center;
    margin: 170px 0px;
    ${media.medium} {
      margin: 170px 35px;
    }
  }
  .message-title {
    margin-bottom: 35px;
    font-family: Montserrat-Light !important;
    color: #30ccfe;
    ${media.extraSmall} {
      font-size: 25px;
    }
    ${media.small} {
      font-size: 25px;
    }
    ${media.medium} {
      font-size: 20px;
    }
    ${media.large} {
      font-size: 50px;
    }
    ${media.extraLarge} {
      font-size: 60px;
    }
  }
  .message-text {
    font-family: Montserrat-Light !important;
    color: #727272;
    opacity: 0.5;
    ${media.extraSmall} {
      font-size: 10px;
    }
    ${media.small} {
      font-size: 10px;
    }
    ${media.medium} {
      font-size: 20px;
    }
    ${media.large} {
      font-size: 20px;
    }
    ${media.extraLarge} {
      font-size: 20px;
    }
  }
  .left-container {
    ${media.large} {
      text-align: left;
    }
  }
  .middle-container {
    ${media.large} {
      text-align: center;
    }
  }
  .right-container {
    ${media.large} {
      text-align: right;
    }
  }
`;

export const Button = styled.button`
  font-family: Montserrat-Light !important;
  background-color: transparent;
  color: #30ccfe;
  border-radius: 50px;
  font-weight: bolder;
  outline: none;
  ${media.extraSmall} {
    height: 35px;
    width: 150px;
    font-size: 10px;
    border: 1px solid #30ccfe;
  }
  ${media.small} {
    height: 35px;
    width: 150px;
    font-size: 10px;
    border: 1px solid #30ccfe;
  }
  ${media.medium} {
    height: 70px;
    width: 305px;
    font-size: 20px;
    border: 2px solid #30ccfe;
  }
  ${media.large} {
    height: 80px;
    width: 350px;
    font-size: 25px;
    border: 3px solid #30ccfe;
  }
  ${media.extraLarge} {
    height: 80px;
    width: 350px;
    font-size: 25px;
    border: 3px solid #30ccfe;
  }
  transition: box-shadow 300ms;
  :hover {
    cursor: pointer;
  }
`;
