import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function SpreadSheet({ style = {}, width = '100%', className = '', viewBox = '0 0 159.9 200' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#08743B"
        d="M15.5,0c31,0,61.9,0,92.9,0c0.3,0.9,1.1,1.5,1.6,2.1C126.3,21,142.6,40,158.9,58.9c0.7,0.8,1.1,1.5,1.1,2.6
	c0,39.1,0,78.3,0,117.4c0,11.9-9.2,21-21.1,21C99.1,200,59.3,200,19.5,200c-4.1,0-7.7-1.3-10.9-3.9c-4-3.3-6.4-7.5-7.7-12.5
	c-1.2-4.3-0.7-8.7-0.9-13.1c4.4,0,8.8,0,13.1,0c0,0.9,0,1.7,0,2.5c0.2,7.7,5.1,12.5,12.8,12.5c13.4,0,26.8,0,40.1,0
	c22.8,0,45.5,0,68.3,0c7.3,0,12.6-4.9,12.7-11.7c0.4-33.2,0.8-66.5,1.3-99.7c0-1.4-0.3-1.8-1.7-1.8c-7.7,0.1-15.5,0-23.2,0
	c-14.2,0-24.3-10.2-24.4-24.5c0-10.8,0-21.6,0-32.5c0-1.5-0.4-1.8-1.8-1.8c-8,0.1-16,0-24,0c-16,0-32,0-48,0
	c-6.3,0-11.6,4.5-11.9,10.3c-0.4,5.5-0.4,141.1-0.2,146.7c-4.4,0.2-8.7,0.3-13.1,0c0-7.1,0.1-144.2,0.3-151.3
	c0.2-7,3.5-12.4,9.2-16.5C11.2,1.7,13.4,1,15.5,0z"
      />
      <path fill="none" stroke="#FF0000" strokeMiterlimit="10" d="M80,100" />
      <text transform="matrix(1 0 0 1 23.9006 128.7313)" display="none" fill="#EF6C00" enableBackground="new" fontSize="60px">
        P
      </text>
      <path
        fill="#08743B"
        d="M56.5,75.1c2.7,0,5.4,0,7.9-0.1c3.9-0.1,5.2,0.9,7.5,3.9c2.2,3.8,4.9,7.3,7.4,10.9c0.9,1.3,0.9,1.3,1.7,0
	c3.1-4.6,6.2-9.1,9.2-13.7c0.6-0.8,1.1-1.1,2.1-1.1c3.7,0.1,7.4,0,11.2,0c0.1,0.7-0.4,1-0.7,1.5C97.7,83.8,92.6,91,87.4,98.3
	c-0.5,0.8-0.5,1.2,0,2c5.6,7.6,11.2,15.3,16.8,22.9c0.3,0.5,0.7,1,1,1.4c-0.3,0.5-0.7,0.3-1,0.3c-3.7,0-7.3,0-11,0
	c-0.8,0-1.3-0.3-1.8-1c-3.5-5-7-9.9-10.4-14.8c-0.3-0.4-0.6-0.9-1-1.4c-2.5,3.6-5,7.1-7.6,10.8c2.3,0,4.4,0.1,6.5,0
	c1.1-0.1,1.4,0.4,1.3,1.4c-0.1,1.4,0,2.7,0,4.1c0,0.6,0,1-0.8,1c-8.2,0-16.4,0-24.6,0c0-0.7,0.4-1,0.7-1.4
	c5.6-7.8,11.3-15.7,17-23.5c0.7-0.9,0.6-1.5,0-2.4c-5.2-7-10.2-14.1-15.3-21.2C57,76.1,56.6,75.8,56.5,75.1z"
      />
    </svg>
  );
}
