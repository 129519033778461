import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../CommonComponents/Flex';
import { TypographyStyled } from '../CommonComponents/Typography/Typography.style';
import { SharedFilesStyled } from './styled';
import { changeThirdPanelView } from '../Navigation/actions';
import { THIRD_PANEL_VIEW } from '../Common/enums';
import useGetAllExchangedFilesByConversationId from '../Common/customHooks/useGetAllExchangedFilesByConversationId';
import { FriendData } from '../Common/interfaces/friend';
import { ChannelData } from '../Common/interfaces/channel';
import SkeletonLoading from '../CommonComponents/Skeleton';
import SharedFilePreview from './SharedFilePreview';
import IconContainer from '../CommonComponents/Decoration/IconContainer';
import { useTranslation } from 'react-i18next';

const { Span } = TypographyStyled;
const { Container, SearchBarContainer, SearchBarInput, SearchInputMask } = SharedFilesStyled;

const SharedFiles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const friend: FriendData = useSelector(state => state.friendReducer.friend);
  const chatList: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);

  const [conversation, setConversation] = useState<ChannelData | any>();
  const [sharedFiles, isLoading] = useGetAllExchangedFilesByConversationId(conversation && conversation.id);

  const handleCloseSharedFiles = () => {
    dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
  };

  useEffect(() => {
    chatList.forEach(chat => {
      if (chat.isDirectMessage) {
        const friendIndex = chat.members.findIndex(member => member.id === friend.friendId);
        if (friendIndex >= 0) {
          setConversation(chat);
        }
      }
    });
  }, [chatList, friend.friendId]);

  return (
    <Flex container height="100%" width="100%" flexDirection="column">
      <Container>
        <Flex padding="30px 60px" container width="100%" justifyContent="space-between">
          <Span size="xl" fontColor="#444444">
            {t('SharedFiles')}
          </Span>
          <IconContainer onClick={handleCloseSharedFiles} iconName="close" iconWidth="35px" />
        </Flex>
      </Container>
      <SearchBarContainer>
        <Flex padding="0px 55px" container width="100%" justifyContent="space-around">
          <SearchInputMask>
            <IconContainer margin="0px 0px 0px 5px" iconName="search" iconWidth="20px" />
            <SearchBarInput type="text" />
          </SearchInputMask>
          <IconContainer padding="0px 15px 0px 15px" iconName="download" iconWidth="40px" />
          <IconContainer padding="0px 5px 0px 15px" iconName="upload" iconWidth="40px" />
        </Flex>
      </SearchBarContainer>
      <Flex container flex="1" height="0%" width="100%">
        {isLoading ? (
          <Flex width="100%">
            <SkeletonLoading count={9} />
          </Flex>
        ) : (
          <div className="w-100 container">
            <Flex container width="100%" padding="5px 50px" justifyContent="space-between">
              <Span size="md" fontColor="#8F8F8F">
                {sharedFiles.length} results
              </Span>
              <Span size="md" fontColor="#8F8F8F">
                Sort by: Newest
              </Span>
            </Flex>
            <div className="w-100 container row">
              {sharedFiles.map((file, index) => (
                <SharedFilePreview key={index} file={file} conversation={conversation} />
              ))}
            </div>
          </div>
        )}
      </Flex>
    </Flex>
  );
};

export default SharedFiles;
