import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function AddCircle({
  style = {},
  fill = 'transparent',
  width = '100%',
  className = '',
  viewBox = '0 0 963 963',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <g>
            <path
              fill={fill}
              d="M481.66995605468753,948.3300016784668 c-63.01,0 -124.12,-12.34 -181.64,-36.67 c-55.57,-23.5 -105.48,-57.15 -148.34,-100.02 c-42.86,-42.86 -76.51,-92.77 -100.02,-148.34 c-24.33,-57.52 -36.67,-118.64 -36.67,-181.64 s12.34,-124.12 36.67,-181.64 c23.5,-55.57 57.15,-105.48 100.02,-148.34 c42.86,-42.86 92.77,-76.51 148.34,-100.02 c57.52,-24.33 118.64,-36.67 181.64,-36.67 c63.01,0 124.12,12.34 181.64,36.67 c55.57,23.5 105.48,57.15 148.34,100.02 s76.51,92.77 100.02,148.34 c24.33,57.52 36.67,118.64 36.67,181.64 s-12.34,124.12 -36.67,181.64 c-23.5,55.57 -57.15,105.48 -100.02,148.34 s-92.77,76.51 -148.34,100.02 C605.7899560546875,936.0000016784668 544.6699560546874,948.3300016784668 481.66995605468753,948.3300016784668 z"
            />
            <path
              fill="#E1E1E1"
              d="M481.66995605468753,30.000001678466802 c60.99,0 120.14,11.94 175.8,35.48 c53.78,22.75 102.09,55.32 143.58,96.81 s74.06,89.8 96.81,143.58 c23.54,55.66 35.48,114.81 35.48,175.8 s-11.94,120.14 -35.48,175.8 c-22.75,53.78 -55.32,102.09 -96.81,143.58 s-89.8,74.06 -143.58,96.81 c-55.66,23.54 -114.81,35.48 -175.8,35.48 c-60.99,0 -120.14,-11.94 -175.8,-35.48 c-53.78,-22.75 -102.09,-55.32 -143.58,-96.81 s-74.06,-89.8 -96.81,-143.58 c-23.54,-55.66 -35.48,-114.81 -35.48,-175.8 s11.94,-120.14 35.48,-175.8 c22.75,-53.78 55.32,-102.09 96.81,-143.58 s89.8,-74.06 143.58,-96.81 C361.52995605468755,41.9400016784668 420.6799560546875,30.000001678466802 481.66995605468753,30.000001678466802 M481.66995605468753,0.0000016784667948854803 c-266.02,0 -481.67,215.65 -481.67,481.67 s215.65,481.67 481.67,481.67 S963.3299560546875,747.6900016784668 963.3299560546875,481.67000167846675 S747.6899560546874,0.0000016784667948854803 481.66995605468753,0.0000016784667948854803 L481.66995605468753,0.0000016784667948854803 z"
            />
          </g>
        </g>
      </g>
      <g>
        <rect x="447.6099853515625" y="268.6399955749512" fill="#8F8F8F" width="68.11000061035156" height="426.04998779296875" />
        <g>
          <rect
            x="939.2700309753418"
            y="838.3200378417969"
            transform="matrix(-1.83697e-16,1,-1,-1.83697e-16,1533.0162,-491.6602) "
            fill="#8F8F8F"
            width="68.11000061035156"
            height="426.04998779296875"
          />
        </g>
      </g>
    </svg>
  );
}
