import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Copy({
  style = {},
  fill = '#444444',
  width = '100%',
  className = '',
  viewBox = '0 0 1920 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M1026.92,984.96c-127.09,0.04-254.17,0.08-381.26,0.11c-1.67-0.56-3.3-1.34-5.01-1.65
        c-61.9-11.48-101.73-47.53-119.8-107.7c-1.98-6.59-3.18-13.41-4.74-20.12c-0.05-175.73-0.11-351.47-0.16-527.2
        c0.53-1.38,1.32-2.72,1.54-4.14c11.47-73.36,71.32-125.25,145.29-125.38c115.78-0.2,231.57-0.3,347.35-0.08
        c80.84,0.15,145.85,64.54,146.03,145.11c0.37,165.32,0.37,330.63,0.16,495.95c-0.08,66-44.55,122.92-108.34,140.06
        C1041,981.79,1033.94,983.29,1026.92,984.96z M588.99,591.99c0.02,80.86,0.02,161.72,0.08,242.58
        c0.04,46.74,30.93,77.52,77.81,77.52c112.78-0.01,225.56-0.04,338.34-0.1c47.48-0.02,78.19-30.67,78.19-78.07
        c-0.02-161.42-0.07-322.84-0.15-484.26c-0.02-47.39-30.75-77.96-78.28-77.95c-112.48,0.01-224.96,0.05-337.43,0.1
        c-48.14,0.02-78.63,30.47-78.63,78.51C588.93,430.88,588.97,511.44,588.99,591.99z
     M1302.31,755.03c-1.2,2.77-2.31,5.58-3.61,8.31c-7.07,14.82-23.49,23.35-39.13,20.38
        c-16.94-3.21-29.26-17.14-30.15-34.2c-0.18-3.33-0.07-6.68-0.07-10.03c-0.06-179.93-0.07-359.85-0.19-539.78
        c-0.03-39.43-25.05-69.11-63.11-75.06c-5.36-0.84-10.9-0.73-16.36-0.73c-130.99,0-261.99,0.08-392.98,0
        c-5.99,0-12.27-0.48-17.92-2.27c-13.8-4.38-23.39-17.96-24-32.41c-0.63-14.79,7.46-28.27,20.83-34.6c2.72-1.29,5.53-2.4,8.3-3.59
        c142.9-0.04,285.79-0.09,428.69-0.13c1.67,0.56,3.31,1.38,5.03,1.64c58.25,8.7,105.37,51.26,120.08,108.59
        c1.64,6.38,2.98,12.83,4.45,19.25C1302.19,371.94,1302.25,563.49,1302.31,755.03z
     M835.3,532.95c-61.99,0.02-123.98,0.06-185.97,0.01c-4.22,0-8.62-0.01-12.61-1.16
        c-8.89-2.55-14.96-11.51-14.88-20.75c0.08-9.72,6.38-18.17,16.13-20.66c4.04-1.03,8.4-1.13,12.62-1.13
        c123.68-0.09,247.35-0.12,371.03-0.16c2.13,0,4.26-0.08,6.38,0.03c13.37,0.7,22.82,10.22,22.47,22.59
        c-0.34,12.11-9.53,21.02-22.81,21.05c-64.12,0.17-128.23,0.11-192.35,0.13C835.3,532.92,835.3,532.94,835.3,532.95z
     M836.5,388.5c61.69-0.02,123.38-0.04,185.07-0.05c3.04,0,6.1-0.13,9.11,0.18
        c11.18,1.18,19.6,10.38,19.75,21.4c0.16,11.36-8.51,20.95-20.05,22.09c-2.11,0.21-4.25,0.1-6.38,0.1
        c-125.2,0.04-250.4,0.08-375.61,0.11c-2.43,0-4.89,0.12-7.28-0.18c-11.04-1.38-19.37-10.89-19.3-21.85
        c0.08-10.96,8.52-20.36,19.6-21.57c3.01-0.33,6.07-0.17,9.11-0.17C712.51,388.54,774.51,388.52,836.5,388.5z
     M919.64,590.18c35.84-0.01,71.68-0.19,107.51,0.05c17.42,0.12,28.06,15.44,21.49,30.28
        c-3.16,7.15-8.59,11.73-16.48,12.77c-3.9,0.51-7.87,0.62-11.8,0.63c-67.12,0.06-134.24,0.07-201.36,0.08
        c-3.03,0-6.09,0.05-9.1-0.23c-12.68-1.2-21.08-10.05-20.97-21.96c0.11-12.29,9.06-21.35,22.29-21.47
        c29.46-0.28,58.92-0.11,88.38-0.13C906.28,590.17,912.96,590.17,919.64,590.18z"
      />
    </svg>
  );
}
