import styled from 'styled-components/macro';

interface FriendContainerProps {
  isActive?: boolean;
  isVisible: boolean;
}

const FriendContainer = styled.div`
  background-color: ${(props: FriendContainerProps) => (props && props.isActive ? '#e9ebef' : '')};
  display: ${(props: FriendContainerProps) => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  height: 120px;
  transition-duration: 300ms;
  :hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

export const ChatMessageStyled = {
  FriendContainer,
};
