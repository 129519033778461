export const FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR';
export const FETCH_GROUPS_LOADING = 'FETCH_GROUPS_LOADING';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';

export const FILTER_GROUPS_BY_NAME = 'FILTER_GROUPS_BY_NAME';
export const UPDATE_GROUP_OBJECT = 'UPDATE_GROUP_OBJECT';

export const GET_GROUP_INFO = 'GET_GROUP_INFO';
export const CLEAR_CHANNEL_REDUCER = 'CLEAR_CHANNEL_REDUCER';

export const UPDATE_GROUP_PHOTO_ERROR = 'UPDATE_GROUP_PHOTO_ERROR';
export const UPDATE_GROUP_PHOTO_LOADING = 'UPDATE_GROUP_PHOTO_LOADING';
export const UPDATE_GROUP_PHOTO_SUCCESS = 'UPDATE_GROUP_PHOTO_SUCCESS';

export const UPDATE_GROUP_NAME_ERROR = 'UPDATE_GROUP_NAME_ERROR';
export const UPDATE_GROUP_NAME_LOADING = 'UPDATE_GROUP_NAME_LOADING';
export const UPDATE_GROUP_NAME_SUCCESS = 'UPDATE_GROUP_NAME_SUCCESS';

export const INVITE_USERS_TO_GROUPS_ERROR = 'INVITE_USERS_TO_GROUPS_ERROR';
export const INVITE_USERS_TO_GROUPS_LOADING = 'INVITE_USERS_TO_GROUPS_LOADING';
export const INVITE_USERS_TO_GROUPS_SUCCESS = 'INVITE_USERS_TO_GROUPS_SUCCESS';

export const REMOVE_MEMBER_FROM_GROUP_ERROR = 'REMOVE_MEMBER_FROM_GROUP_ERROR';
export const REMOVE_MEMBER_FROM_GROUP_LOADING = 'REMOVE_MEMBER_FROM_GROUP_LOADING';
export const REMOVE_MEMBER_FROM_GROUP_SUCCESS = 'REMOVE_MEMBER_FROM_GROUP_SUCCESS';

export const UPDATE_CHANNEL_LIST = 'UPDATE_CHANNEL_LIST';
export const UPDATE_CHANNEL_IN_LIST = 'UPDATE_CHANNEL_IN_LIST';
