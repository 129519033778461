import React, { useState, ReactElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Styled } from './styled';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import Modal from '../../CommonComponents/Modal';
import { getFriendInfo } from '../actions';
import { isUserIncludedInTheFriendList, isNotEmptyArray } from '../../utils/arrays';
import { FOURTH_PANEL_VIEW, SECOND_PANEL_VIEW } from '../../Common/enums';
import { changeFourthPanelView } from '../../Navigation/actions';
import Flex from '../../CommonComponents/Flex';
import { UserData } from '../../Common/interfaces/user';
import { FriendData } from '../../Common/interfaces/friend';
import { getUserInfo } from '../actions/getFriendInfo';
import { addUser } from '../actions/addUser';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';

const { UserPhotoAddFriend, UserAvatar } = ImageStyled;
const { Span } = TypographyStyled;
const { SearchFriendPanel, SearchFriendText, AddButton, ViewButton } = Styled;

interface Props {
  addButtonView: any;
  setFriendPopUp: any;
  friendPopUp: boolean;
}

export default function AddUser(props: Props): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState<FriendData | undefined>(undefined);
  const [isShowing, setShowing] = useState(false);
  const [isSearchTriggered, toggleSearchTriggered] = useState(false);
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const friendState = useSelector(state => state.friendReducer);
  const secondPanelView: SECOND_PANEL_VIEW = useSelector(state => state.navigationReducer.secondPanelView);
  const {
    user,
    getFriendIsLoading,
    addUserIsLoading,
    friendList,
    fetchFriendsIsLoading,
  }: {
    friendList: Array<FriendData>;
    user: FriendData;
    getFriendIsLoading: boolean;
    addUserIsLoading: boolean;
    fetchFriendsIsLoading: boolean;
  } = friendState;
  const { addButtonView, friendPopUp, setFriendPopUp } = props;

  useEffect(() => {
    setUserData(user && user.friendId ? user : undefined);
  }, [user]);

  useEffect(() => {
    setUserId('');
  }, [friendPopUp]);

  const handleSubmit = () => {
    dispatch(getUserInfo(userId));
    toggleSearchTriggered(true);
  };

  const handleAddUser = () => {
    const requestBody = {
      ownerId: loggedInUser.id,
      friendId: userData && userData.friendId,
    };
    dispatch(addUser(requestBody));
  };

  const handleViewUser = () => {
    setFriendPopUp(false);
    const user = friendList.filter(friend => friend.friendId === userData!.friendId);
    if (isNotEmptyArray(user)) {
      dispatch(getFriendInfo(user[0]));
      dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.FRIEND_INFO));
    }
  };

  const handleCloseModal = () => {
    setFriendPopUp(false);
    setUserId('');
    setUserData(undefined);
    toggleSearchTriggered(false);
  };

  const renderIcon = () => {
    if (secondPanelView === SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW || secondPanelView === SECOND_PANEL_VIEW.THREAD_VIEW)
      return <IconContainer iconName="add-message" iconWidth="50px" iconFillOnMouseEnter="#BDBDBD" />;
    else
      return (
        <IconContainer
          iconName="add-circle"
          iconWidth="50px"
          iconFillOnMouseEnter="#E1E1E1"
          iconFill={friendPopUp ? '#E1E1E1' : 'transparent'}
          iconStyle={{ transform: friendPopUp && 'translate(0px, 1px)' }}
        />
      );
  };

  const renderResult = () => {
    if (getFriendIsLoading) {
      return (
        <Flex container width="100%" alignItems="center" justifyContent="center" margin="10px 0px">
          <Span size="lg" fontColor="#444444" center margin="0px 10px">
            {t('Loading')}
          </Span>
          <IconContainer iconName="search-chat-box" iconWidth="30px" />
        </Flex>
      );
    } else {
      if (userData) {
        return (
          <Flex container alignItems="center" justifyContent="space-between" padding="35px 0px 30px 0px">
            <Flex container alignItems="center">
              <UserAvatar
                src={userData.friendProfileImageUrl}
                alt=""
                width="60"
                height="60"
                onMouseEnter={() => setShowing(true)}
                onMouseLeave={() => setShowing(false)}
              />
              {isShowing && <UserPhotoAddFriend src={userData.friendProfileImageUrl} width="375" height="310" alt="User profile image" />}
              <Span size="xl" fontColor="black" margin="0px 0px 0px 5px">
                {userData.friendDisplayName}
              </Span>
            </Flex>
            {renderButton(userData)}
          </Flex>
        );
      } else {
        if (isSearchTriggered) {
          return (
            <Flex container width="100%" justifyContent="center" margin="10px 0px">
              {t('SorryDidntFindWhatYoureLookingFor')}
            </Flex>
          );
        }
      }
    }
  };

  const renderButton = userData => {
    if (userData && userData.friendId !== loggedInUser.id) {
      if (!addUserIsLoading && !fetchFriendsIsLoading && !isUserIncludedInTheFriendList(friendList, userData.friendId)) {
        return (
          <AddButton type="button" disabled={addUserIsLoading} onClick={handleAddUser}>
            {t('Add')}
          </AddButton>
        );
      } else {
        return (
          <ViewButton type="button" disabled={addUserIsLoading} onClick={handleViewUser}>
            {t('View')}
          </ViewButton>
        );
      }
    }
  };

  return (
    <Flex container>
      <Modal visible={friendPopUp} setModalVisibility={handleCloseModal} dimmed={true} />
      <Flex margin="0px 30px 0px 20px" handleOnClick={addButtonView}>
        {renderIcon()}
      </Flex>
      {friendPopUp && (
        <SearchFriendPanel onClick={e => e.stopPropagation()}>
          <Flex container alignItems="center" justifyContent="space-between" margin="0px 0px 25px 0px">
            <Span fontWeight="bold" size="lg" fontColor="#444444">
              {t('SearchId')}
            </Span>
            <IconContainer
              onClick={() => setFriendPopUp(false)}
              iconName="close"
              iconWidth="25px"
              iconStyle={{ opacity: '1' }}
              iconStyleOnMouseEnter={{ opacity: '0.8' }}
            />
          </Flex>
          <SearchFriendText
            disabled={getFriendIsLoading}
            type="text"
            placeholder={t('FindAddNewPeople')}
            value={userId}
            onChange={e => setUserId(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && handleSubmit()}
          />
          {renderResult()}
        </SearchFriendPanel>
      )}
    </Flex>
  );
}
