import axios from 'axios';

export const getUserInfoById = async (id: string) => await axios.get(process.env.REACT_APP_API_URL + `/friends/${id}`);

export const updateMessageById = async requestBody => {
  try {
    return await axios.put(process.env.REACT_APP_API_URL + `/messages`, requestBody);
  } catch (err) {
    return err;
  }
};

export const updateThreadMessageById = async requestBody => {
  try {
    return await axios.put(process.env.REACT_APP_API_URL + `/threads`, requestBody);
  } catch (err) {
    return err;
  }
};

export const getMembersByGroupId = async (id: string) => {
  try {
    return await axios.get(process.env.REACT_APP_API_URL + `/groupChat/${id}/members`);
  } catch (err) {
    return err;
  }
};

export const clearUnreadCountForConversation = async (id: string, requestBody) => {
  try {
    return await axios.put(process.env.REACT_APP_API_URL + `/conversations/clearUnread/${id}`, requestBody);
  } catch (err) {
    return err;
  }
};

export const isUserTyping = async (requestBody, userId: string) => {
  try {
    return await axios.put(process.env.REACT_APP_API_URL + `/users/typing/${userId}`, requestBody);
  } catch (err) {
    return err;
  }
};

export const leaveGroup = async (requestBody: any, groupId: string) => {
  try {
    return await axios.delete(process.env.REACT_APP_API_URL + `/conversations/members/${groupId}`, { data: requestBody });
  } catch (err) {
    return err;
  }
};

export const sendReactionToMessage = async (requestBody: any) => {
  try {
    return await axios.put(process.env.REACT_APP_API_URL + `/messages/reactions`, requestBody);
  } catch (err) {
    return err;
  }
};

export const sendReactionToThreadMessage = async (requestBody: any) => {
  try {
    return await axios.put(process.env.REACT_APP_API_URL + `/threads/reactions`, requestBody);
  } catch (err) {
    return err;
  }
};

export const clearUnreadStatusThread = async (requestBody, loggedInUserId) => {
  try {
    return await axios.put(process.env.REACT_APP_API_URL + `/threads/clearStatus/${loggedInUserId}`, requestBody);
  } catch (err) {
    return err;
  }
};

export const downloadFile = async (url, filename) => {
  try {
    await axios.get(url, { responseType: 'arraybuffer' }).then(response => {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data]));
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } catch (e) {
    return;
  }
};
