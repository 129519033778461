import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Mute({ style = {}, width = '100%', className = '', viewBox = '0 0 1080 1080' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#444444"
        d="M730.6,4.4c-13.5-6.4-29.2-4.8-40.7,4.7L314.7,308.4l-179-0.2c-42.5,0.1-77.2,34.5-77.1,77l-0.4,308.3
	c0,42.5,34.5,77.1,76.9,77.1l179.1,0.2l374.5,300.3c7,5.5,15.6,8.4,24.1,8.4c5.7,0,11.4-1.3,16.7-3.8c13.3-6.4,21.9-19.8,21.9-34.6
	l1.1-1002C752.4,24.4,744,10.8,730.6,4.4z"
      />
      <g>
        <line fill="#333333" x1="37.2" y1="58.8" x2="1017.7" y2="1039.3" />
        <g>
          <path
            fill="#ffffff"
            d="M12.4,83.6c2.3,2.3,4.5,4.5,6.8,6.8c6.5,6.5,12.9,12.9,19.4,19.4c10.2,10.2,20.5,20.5,30.7,30.7
			c13.5,13.5,27.1,27.1,40.6,40.6c16.4,16.4,32.8,32.8,49.1,49.1c18.8,18.8,37.5,37.5,56.3,56.3c20.8,20.8,41.6,41.6,62.4,62.4
			c22.2,22.2,44.4,44.4,66.6,66.6c23.2,23.2,46.4,46.4,69.6,69.6c23.8,23.8,47.6,47.6,71.3,71.3c23.9,23.9,47.8,47.8,71.7,71.7
			c23.5,23.5,47.1,47.1,70.6,70.6c22.7,22.7,45.5,45.5,68.2,68.2c21.5,21.5,43,43,64.5,64.5c19.8,19.8,39.5,39.5,59.3,59.3
			c17.6,17.6,35.2,35.2,52.8,52.8c15,15,30,30,44.9,44.9c11.9,11.9,23.8,23.8,35.7,35.7c8.4,8.4,16.7,16.7,25.1,25.1
			c4.4,4.4,8.8,8.8,13.1,13.1c0.5,0.5,1.1,1.1,1.6,1.6c13,13,36.7,13.9,49.5,0c12.8-14,13.8-35.7,0-49.5c-2.3-2.3-4.5-4.5-6.8-6.8
			c-6.5-6.5-12.9-12.9-19.4-19.4c-10.2-10.2-20.5-20.5-30.7-30.7c-13.5-13.5-27.1-27.1-40.6-40.6c-16.4-16.4-32.8-32.8-49.1-49.1
			c-18.8-18.8-37.5-37.5-56.3-56.3c-20.8-20.8-41.6-41.6-62.4-62.4c-22.2-22.2-44.4-44.4-66.6-66.6c-23.2-23.2-46.4-46.4-69.6-69.6
			c-23.8-23.8-47.6-47.6-71.3-71.3c-23.9-23.9-47.8-47.8-71.7-71.7c-23.5-23.5-47.1-47.1-70.6-70.6c-22.7-22.7-45.5-45.5-68.2-68.2
			c-21.5-21.5-43-43-64.5-64.5c-19.8-19.8-39.5-39.5-59.3-59.3c-17.6-17.6-35.2-35.2-52.8-52.8c-15-15-30-30-44.9-44.9
			c-11.9-11.9-23.8-23.8-35.7-35.7C93.4,65.6,85,57.2,76.7,48.8c-4.4-4.4-8.8-8.8-13.1-13.1c-0.5-0.5-1.1-1.1-1.6-1.6
			c-13-13-36.7-13.9-49.5,0C-0.4,48-1.4,69.7,12.4,83.6L12.4,83.6z"
          />
        </g>
      </g>
      <g>
        <line fill="#333333" x1="21.2" y1="20.8" x2="1061.2" y2="1060.8" />
        <g>
          <path
            fill="#444444"
            d="M7,35c2.4,2.4,4.8,4.8,7.2,7.2c6.9,6.9,13.7,13.7,20.6,20.6c10.9,10.9,21.8,21.8,32.7,32.7
			c14.4,14.4,28.7,28.7,43.1,43.1c17.4,17.4,34.7,34.7,52.1,52.1c19.9,19.9,39.8,39.8,59.7,59.7c22,22,43.9,43.9,65.9,65.9
			c23.5,23.5,47,47,70.6,70.6c24.7,24.7,49.5,49.5,74.2,74.2c25.2,25.2,50.4,50.4,75.6,75.6c25.3,25.3,50.7,50.7,76,76
			c25,25,49.9,49.9,74.9,74.9c24.1,24.1,48.2,48.2,72.3,72.3c22.8,22.8,45.6,45.6,68.3,68.3c21.1,21.1,42.1,42.1,63.2,63.2
			c18.7,18.7,37.3,37.3,56,56c15.9,15.9,31.7,31.7,47.6,47.6c12.6,12.6,25.2,25.2,37.8,37.8c8.9,8.9,17.7,17.7,26.6,26.6
			c4.6,4.6,9.3,9.3,13.9,13.9c0.6,0.6,1.1,1.1,1.7,1.7c7.4,7.4,21,8,28.3,0c7.3-8,7.9-20.4,0-28.3c-2.4-2.4-4.8-4.8-7.2-7.2
			c-6.9-6.9-13.7-13.7-20.6-20.6c-10.9-10.9-21.8-21.8-32.7-32.7c-14.4-14.4-28.7-28.7-43.1-43.1c-17.4-17.4-34.7-34.7-52.1-52.1
			c-19.9-19.9-39.8-39.8-59.7-59.7c-22-22-43.9-43.9-65.9-65.9c-23.5-23.5-47-47-70.6-70.6c-24.7-24.7-49.5-49.5-74.2-74.2
			c-25.2-25.2-50.4-50.4-75.6-75.6c-25.3-25.3-50.7-50.7-76-76c-25-25-49.9-49.9-74.9-74.9c-24.1-24.1-48.2-48.2-72.3-72.3
			c-22.8-22.8-45.6-45.6-68.3-68.3c-21.1-21.1-42.1-42.1-63.2-63.2c-18.7-18.7-37.3-37.3-56-56c-15.9-15.9-31.7-31.7-47.6-47.6
			c-12.6-12.6-25.2-25.2-37.8-37.8C68.7,40,59.8,31.2,51,22.3C46.3,17.7,41.7,13,37,8.4c-0.6-0.6-1.1-1.1-1.7-1.7
			c-7.4-7.4-21-8-28.3,0C-0.3,14.7-0.9,27.1,7,35L7,35z"
          />
        </g>
      </g>
    </svg>
  );
}
