import { combineReducers } from 'redux';
import authReducer from '../Auth/reducers';
import friendReducer from '../FriendList/reducers';
import groupReducer from '../ChannelList/reducers';
import navigationReducer from '../Navigation/reducers';
import chatListReducer from '../ChatList/reducers';
import conversationReducer from '../Conversation/reducers';
import threadReducer from '../Thread/reducers';
import createChannelReducer from '../CreateChannel/reducers';
import notificationReducer from '../Notification/reducers';
import accountSettingsReducer from '../AccountSettings/reducers';

export default combineReducers({
  authReducer,
  friendReducer,
  groupReducer,
  navigationReducer,
  chatListReducer,
  conversationReducer,
  threadReducer,
  createChannelReducer,
  notificationReducer,
  accountSettingsReducer,
});
