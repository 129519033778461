import axios from 'axios';
import { FETCH_FRIENDS_ERROR, FETCH_FRIENDS_LOADING, FETCH_FIENDS_SUCCESS } from '../actionTypes';

export function getFriendsError(bool: boolean) {
  return {
    type: FETCH_FRIENDS_ERROR,
    hasError: bool,
  };
}

export function getFriendsLoading(bool: boolean) {
  return {
    type: FETCH_FRIENDS_LOADING,
    isLoading: bool,
  };
}

export function getFriendsSuccess(payload) {
  localStorage.setItem('userFriendList', JSON.stringify(payload));
  return {
    type: FETCH_FIENDS_SUCCESS,
    payload: payload,
  };
}

export function getFriendsById(requestBody: any, id: string) {
  return async dispatch => {
    dispatch(getFriendsLoading(true));
    return (
      await axios
        .post(process.env.REACT_APP_API_URL + `/friend/list`, requestBody, {
          params: {
            ownerId: id,
          },
        })
        .then(response => {
          if (response.data) {
            dispatch(getFriendsSuccess(response.data.friend));
          }
        }),
      err => {
        dispatch(getFriendsError(true));
      }
    );
  };
}
