import styled from 'styled-components/macro';

interface InputProps {
  width?: string;
  borderRadius?: string;
  fontSize?: string;
  textAlign?: string;
  color?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  alignSelf?: string;
  placeholderColor?: string;
}

interface InputCheckboxProps {
  height?: number;
  width?: number;
  padding?: number;
  margin?: number;
}

const InputText = styled.input`
  width: ${(props: InputProps) => props && props.width};
  background-color: ${(props: InputProps) => props && props.backgroundColor};
  padding: ${(props: InputProps) => props && props.padding};
  margin: ${(props: InputProps) => props && props.margin};
  border-radius: ${(props: InputProps) => props && props.borderRadius};
  border-style: none;
  outline: none;
  text-align: ${(props: InputProps) => props && props.textAlign};
  font-size: ${(props: InputProps) => props && props.fontSize};
  color: ${(props: InputProps) => props && props.color};
  align-self: ${(props: InputProps) => props && props.alignSelf};
  ::placeholder {
    color: ${(props: InputProps) => props && props.placeholderColor};
  }
  :focus::placeholder {
    color: transparent;
  }
`;

const InputTextWithBorder = styled(InputText)`
  border-style: solid;
  border-width: 1px;
  border-color: #cacaca;
  ::placeholder {
    color: grey;
    transition: 0.3s;
  }
  :focus::placeholder {
    opacity: 0;
    color: transparent;
  }
`;

const InputCheckbox = styled.input`
  height: ${(props: InputCheckboxProps) => props && props.height}px;
  width: ${(props: InputCheckboxProps) => props && props.width}px;
  padding: ${(props: InputCheckboxProps) => props && props.padding}%;
  margin: ${(props: InputCheckboxProps) => props && props.margin}%;
`;

export const InputStyled = {
  InputText,
  InputCheckbox,
  InputTextWithBorder,
};
