import styled from 'styled-components/macro';

interface ContainerProps {
  transform?: string;
  width?: string;
}

interface ItemProps {
  color?: string;
  background?: string;
  hoverColor?: string;
  hoverBackground?: string;
}

const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  border-radius: 5px;
  width: ${(props: ContainerProps) => props && props.width};
  background-color: white;
  border: solid 1px #cccccc;
  box-shadow: 0px 0px 3px -1px #444444;
  transform: ${(props: ContainerProps) => props && props.transform};
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: ${(props: ItemProps) => (props && props.color ? props.color : '#444444')};
  font-weight: bold;
  align-items: center;
  margin: 2px;
  padding: 5px;
  border-radius: 5px;
  background-color: ${(props: ItemProps) => (props && props.background ? props.background : 'white')};
  transition: 300ms;
  :hover {
    background-color: ${(props: ItemProps) => (props && props.hoverBackground ? props.hoverBackground : '#cccccc')};
    cursor: pointer;
    color: ${(props: ItemProps) => (props && props.hoverColor ? props.hoverColor : 'white')};
  }
`;

const Divider = styled.div`
  width: 100&;
  border-width: 1px;
  border-color: #cccccc;
  border-style: solid;
`;

export const OptionStyled = {
  Container,
  Item,
  Divider,
};
