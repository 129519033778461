import React, { FC, ReactElement, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { favoriteUser } from '../actions/favoriteUser';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { FriendStyled } from './styled';
import { ButtonStyled } from '../../CommonComponents/Button/Button.style';
import { changeFourthPanelView, changeThirdPanelView } from '../../Navigation/actions';
import { FOURTH_PANEL_VIEW, THIRD_PANEL_VIEW } from '../../Common/enums';
import Flex from '../../CommonComponents/Flex';
import { copyToClipBoard } from '../../utils/strings';
import Options from './Options';
import { FriendData } from '../../Common/interfaces/friend';
import { updateFriendDisplayName } from '../actions/updateFriendDisplayName';
import { ChannelData } from '../../Common/interfaces/channel';
import { setActiveConversationId } from '../../Conversation/actions';
import { timeout } from '../../utils/timings';
import { useTranslation } from 'react-i18next';
import SharedFiles from './SharedFiles';
import { blockUser } from '../actions/blockUser';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { hideConversation } from '../../ChatList/actions';

const { Span } = TypographyStyled;
const { FriendContainer, ProfileImage, EditableName, HorizontalLine } = FriendStyled;
const { LightButton } = ButtonStyled;

const FriendDetails: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const friendState = useSelector(state => state.friendReducer);
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const chatList: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);
  const { friend }: { friend: FriendData } = friendState;
  const [isMouseOverImage, setImageHoverState] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [name, setName] = useState(friend.friendDisplayName);
  const [isCopyTextVisible, toggleCopyTextVisible] = useState(false);
  const [conversation, setConversation] = useState<ChannelData | any>();

  useEffect(() => {
    if (friend) {
      setIsFavorite(friend.friendStatus.isFavorite);
      setIsBlocked(friend.friendStatus.isBlocked);
      if (friend.friendDisplayName !== name) {
        setName(friend.friendDisplayName);
      }
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friend]);

  useEffect(() => {
    chatList.forEach(chat => {
      if (chat.isDirectMessage) {
        const friendIndex = chat.members.findIndex(member => member.id === friend.friendId);
        if (friendIndex >= 0) {
          setConversation(chat);
        }
      }
    });
  }, [chatList, friend.friendId]);

  const handleClose = () => dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN));

  const handleFavoriteFriend = () => {
    const requestBody = {
      ownerId: loggedInUser.id,
      isFavorite: !friend.friendStatus.isFavorite,
    };
    setIsFavorite(toggle => !toggle);
    dispatch(favoriteUser(requestBody, friend.friendId));
  };

  const handleBlockFriend = () => {
    const requestBody = {
      ownerId: loggedInUser.id,
      conversationId: conversation.id,
      isBlocked: !friend.friendStatus.isBlocked,
    };
    setIsBlocked(toggle => !toggle);
    dispatch(blockUser(requestBody, friend.friendId));
  };

  const handleBlurField = () => {
    if (name) {
      const requestBody = {
        ownerId: loggedInUser.id,
        friendDisplayName: name,
      };
      dispatch(updateFriendDisplayName(requestBody, friend.friendId));
    } else {
      if (friend.friendDisplayName === friend.friendOriginalName) {
        setName(friend.friendDisplayName);
      } else {
        setName(friend.friendDisplayName);
      }
    }
  };

  const handleMessageButton = () => {
    if (conversation && conversation.id) {
      dispatch(setActiveConversationId(conversation.id));
      dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
      const requestBody = {
        conversationId: conversation.id,
        isHidden: false,
      };
      hideConversation(requestBody, loggedInUser.id);
    }
  };

  const handleCopyButton = async () => {
    copyToClipBoard(friend.friendId);
    toggleCopyTextVisible(true);
    await timeout(2000);
    toggleCopyTextVisible(false);
  };

  return (
    <FriendContainer>
      <Flex
        container
        handleHover={() => setImageHoverState(true)}
        handleUnHover={() => setImageHoverState(false)}
        flexDirection="row-reverse"
      >
        <ProfileImage src={friend.friendProfileImageUrl} alt="" />
        {isMouseOverImage && (
          <IconContainer
            position="absolute"
            padding="20px"
            onClick={handleClose}
            iconName="close"
            iconWidth="35px"
            iconStyle={{ opacity: isMouseOverImage ? '1' : '0', transition: '300ms' }}
          />
        )}
      </Flex>
      <Flex>
        <Flex margin="35px">
          <Flex container justifyContent="space-between" alignItems="center" margin="0px 0px 15px 0px">
            <EditableName
              value={name ? name : ''}
              type="text"
              onChange={e => setName(e.target.value)}
              spellCheck="false"
              onBlur={handleBlurField}
              placeholder={friend.friendOriginalName}
            />
            <Options />
          </Flex>
          {friend.friendCompanyName && (
            <Flex container alignItems="center" margin="0px 0px 15px 0px">
              <Span size="xl" fontColor="#444444">
                {friend.friendCompanyName}
              </Span>
            </Flex>
          )}
          <Flex container alignItems="center" margin="0px 0px 20px 0px">
            <Span size="md" fontColor="#444444">
              ID: {friend.friendId}
            </Span>
            {isCopyTextVisible ? (
              <Span size="md" fontColor="444444" fadeIn style={{ margin: '0px 0px 0px 20px' }}>
                {t('Copied')}
              </Span>
            ) : (
              <IconContainer onClick={handleCopyButton} iconName="clipboard" iconWidth="20px" />
            )}
          </Flex>
          <Flex container alignItems="center" width="100%" justifyContent="space-between" margin="0px 0px 20px 0px">
            {!isBlocked && (
              <LightButton width="70%" borderRadius="15px" fontSize="xl" onClick={handleMessageButton}>
                {t('Message')}
              </LightButton>
            )}
            <Flex
              width={isBlocked ? '100%' : '30%'}
              container
              alignItems="center"
              justifyContent={isBlocked ? 'flex-end' : 'space-between'}
            >
              {!isBlocked && (
                <IconContainer
                  onClick={handleFavoriteFriend}
                  margin="0px 0px 0px 5px"
                  iconName="favorite"
                  iconWidth="40px"
                  iconFill={isFavorite ? 'url(#linear-gradient)' : '#CACACA'}
                />
              )}
              <IconContainer onClick={handleBlockFriend} iconName="ban" iconWidth="40px" iconFill={isBlocked ? '#CACACA' : '#ff5069'} />
            </Flex>
          </Flex>
          <Flex container justifyContent="space-around" alignItems="center" width={'100%'}>
            <HorizontalLine style={{ width: '35%' }} />
            <Span fontColor="#cacaca" size="18" center>
              {t('SharedFiles')}
            </Span>
            <HorizontalLine style={{ width: '35%' }} />
          </Flex>
          <Flex>
            <SharedFiles conversation={conversation} />
          </Flex>
        </Flex>
      </Flex>
    </FriendContainer>
  );
};

export default FriendDetails;
