import axios from 'axios';
import { SEARCH_MESSAGES_ERROR, SEARCH_MESSAGES_LOADING, SEARCH_MESSAGES_SUCCESS } from '../actionTypes';

export function searchMessagesError(bool: boolean) {
  return {
    type: SEARCH_MESSAGES_ERROR,
    hasError: bool,
  };
}

export function searchMessagesLoading(bool: boolean) {
  return {
    type: SEARCH_MESSAGES_LOADING,
    isLoading: bool,
  };
}

export function searchMessagesSuccess(messages) {
  return {
    type: SEARCH_MESSAGES_SUCCESS,
    messages: messages,
  };
}

export function searchMessages(id: string, query: string) {
  return async dispatch => {
    dispatch(searchMessagesLoading(true));
    return axios
      .get(process.env.REACT_APP_API_URL + `/messages`, {
        params: {
          conversationId: id,
          text: query,
        },
      })
      .then(
        response => {
          dispatch(searchMessagesSuccess(response.data.messages));
        },
        err => dispatch(searchMessagesError(true))
      );
  };
}
