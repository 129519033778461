import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Kebab({
  style = {},
  fill = '#444444',
  width = '100%',
  className = '',
  viewBox = '0 0 228 974',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      fill={fill}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="113.75" cy="173.49000549316406" r="113.75" />
      <circle cx="113.75" cy="517.010009765625" r="113.75" />
      <circle cx="113.75" cy="860.52001953125" r="113.75" />
    </svg>
  );
}
