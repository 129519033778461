import styled from 'styled-components/macro';

const ConversationHeaderModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const ConversationHeaderOptionStyled = {
  ConversationHeaderModalContainer,
};
