import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function World({
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  viewBox = '0 0 340 340',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path d="M107.38355651855471,230.38388916015623 c16.14,-1.4100000000000001 35.16,-2.39 57.3,-2.52 v-52.81 h-63.41 C101.60355651855468,194.43388916015624 103.67355651855468,213.13388916015623 107.38355651855471,230.38388916015623 z" />
        </g>
        <g>
          <path d="M101.25355651855466,164.45388916015622 h63.43 v-52.8 c-22.13,-0.14 -41.14,-1.11 -57.26,-2.52 C103.69355651855471,126.42388916015625 101.57355651855471,145.11388916015625 101.25355651855466,164.45388916015622 z" />
        </g>
        <g>
          <path d="M122.5235565185547,63.84388916015624 c-5.08,10.49 -9.29,22.22 -12.61,34.84 c15.51,1.33 33.71,2.25 54.77,2.38 v-79.07 C149.3235565185547,24.813889160156236 134.2035565185547,39.70388916015625 122.5235565185547,63.84388916015624 z" />
        </g>
        <g>
          <path d="M124.16355651855469,279.00388916015623 c6.84,13.27 14.61,23.41 23.1,30.15 c5.71,4.53 11.55,7.34 17.43,8.39 v-79.09 c-21.07,0.13 -39.27,1.05 -54.79,2.38 C113.57355651855471,254.83388916015628 118.34355651855469,267.72388916015626 124.16355651855469,279.00388916015623 z" />
        </g>
        <g>
          <path d="M114.74355651855467,283.85388916015626 c-6.47,-12.55 -11.620000000000001,-26.78 -15.49,-42 c-26.54,2.81 -43.95,6.73 -53.42,9.33 c5.93,8.98 12.89,17.39 20.82,25.06 c19.81,19.17 44.1,32.21 70.43,38.13 C129.0235565185547,307.04388916015625 121.4535565185547,296.87388916015624 114.74355651855467,283.85388916015626 z" />
        </g>
        <g>
          <path d="M112.98355651855468,59.22388916015626 c7.12,-14.72 15.33,-26.08 24.11,-34.08 c-8.18,1.85 -16.21,4.4 -24.04,7.640000000000001 c-18.73,7.76 -35.45,19.13 -49.7,33.81 c-6.5600000000000005,6.76 -12.4,14.05 -17.48,21.74 c9.48,2.6100000000000003 26.88,6.52 53.37,9.32 C102.75355651855466,83.88388916015626 107.3335565185547,70.90388916015624 112.98355651855468,59.22388916015626 z" />
        </g>
        <g>
          <path d="M96.84355651855469,108.10388916015626 c-29.29,-3.14 -47.56,-7.62 -56.7,-10.35 c-11.33,20.3 -17.72,43.03 -18.54,66.7 h69.05 C90.97355651855469,145.24388916015624 93.05355651855467,126.13388916015626 96.84355651855469,108.10388916015626 z" />
        </g>
        <g>
          <path d="M90.67355651855468,175.05388916015625 h-69.07 c0.8300000000000001,23.74 7.2,46.47 18.49,66.71 c9.12,-2.72 27.4,-7.21 56.71,-10.36 C93.03355651855469,213.40388916015627 90.99355651855467,194.28388916015626 90.67355651855468,175.05388916015625 z" />
        </g>
        <g>
          <path d="M249.2835565185547,164.45388916015622 h69.07 c-0.8300000000000001,-23.74 -7.21,-46.47 -18.49,-66.71 c-9.12,2.72 -27.4,7.21 -56.71,10.36 C246.92355651855468,126.10388916015626 248.9635565185547,145.22388916015626 249.2835565185547,164.45388916015622 z" />
        </g>
        <g>
          <path d="M230.05355651855467,240.83388916015628 c-15.51,-1.33 -33.72,-2.25 -54.77,-2.38 v79.07 c15.36,-2.83 30.48,-17.72 42.16,-41.85 C222.5135565185547,265.18388916015624 226.73355651855468,253.45388916015622 230.05355651855467,240.83388916015628 z" />
        </g>
        <g>
          <path d="M226.9735565185547,280.28388916015626 c-7.13,14.72 -15.33,26.07 -24.12,34.08 c8.18,-1.85 16.21,-4.4 24.04,-7.640000000000001 c18.74,-7.76 35.46,-19.14 49.71,-33.81 c6.5600000000000005,-6.76 12.4,-14.05 17.48,-21.74 c-9.48,-2.6 -26.87,-6.52 -53.37,-9.32 C237.2035565185547,255.62388916015624 232.6335565185547,268.60388916015626 226.9735565185547,280.28388916015626 z" />
        </g>
        <g>
          <path d="M225.2135565185547,55.66388916015626 c6.47,12.55 11.620000000000001,26.78 15.49,41.99 c26.54,-2.81 43.95,-6.72 53.42,-9.33 c-5.93,-8.98 -12.89,-17.38 -20.81,-25.05 c-19.81,-19.17 -44.1,-32.21 -70.43,-38.14 C210.93355651855467,32.47388916015626 218.50355651855466,42.64388916015625 225.2135565185547,55.66388916015626 z" />
        </g>
        <g>
          <path d="M288.2735565185547,47.83388916015625 c-67.42,-65.23 -175.03,-63.54 -240.36,3.81 c-65.33,67.33 -63.64,174.8 3.77,240.03 c67.41,65.23 175.01,63.53 240.34,-3.81 C357.3635565185547,220.54388916015625 355.67355651855473,113.07388916015626 288.2735565185547,47.83388916015625 zM284.2035565185547,280.28388916015626 c-31.2,32.15 -72.68,48.31 -114.22,48.33 c-0.010000000000000002,0 -0.020000000000000004,0 -0.030000000000000006,0 c0,0 0,0 -0.010000000000000002,0 c-0.010000000000000002,0 -0.010000000000000002,0 -0.020000000000000004,0 c-39.86,0 -79.76,-14.88 -110.65,-44.76 c-63.08,-61.04 -64.66,-161.62 -3.52,-224.63 c31.19,-32.16 72.68,-48.31 114.22,-48.33 c0.010000000000000002,0 0.010000000000000002,0 0.020000000000000004,0 c0,0 0,0 0,0 c0.010000000000000002,0 0.010000000000000002,0 0.020000000000000004,0 c39.86,0 79.77,14.88 110.66,44.77 C343.76355651855465,116.71388916015624 345.3435565185547,217.28388916015626 284.2035565185547,280.28388916015626 z" />
        </g>
        <g>
          <path d="M249.30355651855467,175.05388916015625 c-0.32000000000000006,19.21 -2.39,38.33 -6.18,56.35 c29.29,3.14 47.56,7.62 56.7,10.35 c11.33,-20.29 17.71,-43.03 18.54,-66.7 H249.30355651855467 z" />
        </g>
        <g>
          <path d="M215.80355651855467,60.523889160156244 c-6.84,-13.27 -14.61,-23.41 -23.1,-30.15 c-5.71,-4.53 -11.55,-7.34 -17.42,-8.4 v79.09 c21.06,-0.14 39.27,-1.05 54.79,-2.38 C226.3835565185547,84.67388916015625 221.61355651855467,71.79388916015625 215.80355651855467,60.523889160156244 z" />
        </g>
        <g>
          <path d="M238.7135565185547,175.05388916015625 h-63.43 v52.81 c22.13,0.13 41.14,1.11 57.27,2.52 C236.2735565185547,213.08388916015628 238.3835565185547,194.39388916015628 238.7135565185547,175.05388916015625 z" />
        </g>
        <g>
          <path d="M232.5735565185547,109.13388916015626 c-16.14,1.4100000000000001 -35.16,2.39 -57.3,2.52 v52.8 h63.41 C238.36355651855467,145.07388916015623 236.2835565185547,126.37388916015624 232.5735565185547,109.13388916015626 z" />
        </g>
      </g>
    </svg>
  );
}
