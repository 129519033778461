import styled from 'styled-components/macro';

const UnreadMessage = styled.div`
  padding: 0px 15px;
  background: #3cc9f8;
  border-radius: 25px;
  height: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const Styled = {
  UnreadMessage,
};
