import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function FriendList({
  fill = '#47e4cf',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 877 938',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M877.2196089386939,153.6151964902878 c0.2,10.9 -0.8,21.7 -2.2,32.5 c-2.3,17.8 -6.2,35.2 -11.3,52.4 c-4.7,16 -10.4,31.7 -16.9,47.1 c-5.3,12.6 -11.2,24.9 -17.6,37 c-12.2,23.1 -26.3,45 -42.2,65.7 c-12,15.6 -25,30.4 -39,44.2 c-16.3,16.1 -33.9,30.7 -52.7,43.8 c-15.2,10.6 -31,20 -47.6,28.3 c-27.4,13.6 -55.9,24 -85.7,30.8 c-23.7,5.4 -47.6,10.4 -71.5,15 c-22.7,4.4 -45.4,9.3 -67.7,15.3 c-29.8,8 -58.9,17.6 -87,30.3 c-17.2,7.8 -33.9,16.7 -49.6,27.1 c-15,9.9 -29,20.9 -41.9,33.4 c-16.2,15.7 -30.2,33.2 -42.1,52.4 c-14.5,23.4 -25.2,48.5 -33.6,74.6 c-6.6,20.4 -11.6,41.2 -15.6,62.2 c-1.7000000000000002,8.9 -3.2,17.7 -4.5,26.7 c-1.3,8.8 -2.4,17.7 -3.6,26.6 c-0.8,6.1 -1.4,12.2 -1.9,18.3 c-0.30000000000000016,4 -2.1,7.3 -4.9,10.1 c-5.7,5.6 -12.7,8.9 -20.5,10.1 c-5.7,0.9 -11.4,1 -17.1,-0.5 c-8.7,-2.3 -14.5,-7.7 -17.7,-16.1 c-1.4,-3.8 -2.2,-7.7 -2.6,-11.7 c-1,-8.8 -0.2,-17.6 0.30000000000000016,-26.3 c0.2,-3 0.30000000000000016,-6.1 0.5,-9.1 c1,-14.6 3,-29 5.7,-43.4 c4,-21.5 9.6,-42.5 16.9,-63.1 c8,-22.6 17.9,-44.3 29.6,-65.2 c9.5,-16.8 20,-32.9 31.9,-48.1 c6.5,-8.4 13.4,-16.5 20.6,-24.4 c6.1,-6.7 12.5,-13.1 19.1,-19.3 c13.7,-12.9 28.2,-24.7 43.6,-35.6 c20,-14.2 41.1,-26.4 63.2,-37.2 c18.7,-9.1 37.9,-16.9 57.7,-23.4 c20.7,-6.8 41.9,-12.2 63.3,-16.1 c10.4,-1.9 20.8,-3.5 31.1,-5.4 c30.1,-5.6 59.8,-12.7 89,-22.2 c22.7,-7.4 44.8,-16.1 66,-27.1 c20.4,-10.6 39.7,-22.9 57.6,-37.5 c8.2,-6.7 16.1,-13.9 23.5,-21.5 c16.9,-17.5 31.4,-36.8 43.6,-57.9 c14.9,-25.7 26.2,-53 34.9,-81.3 c6.3,-20.5 11.3,-41.4 15.4,-62.5 c2.4,-12.3 4.5,-24.7 6.2,-37.1 c0.6000000000000003,-4 0.9,-8.1 1.8,-12.1 c2.3,-10.4 8.5,-17.7 17.7,-22.6 c5.5,-2.9 11.4,-4.4 17.6,-5.1 c6.9,-0.8 13.6,0.2 20,2.8 c10.6,4.2 16.5,12.4 18.9,23.4 c1,4.6 1.2,9.2 1.4,13.8 C877.319608938694,148.31519649028778 877.2196089386939,151.0151964902878 877.2196089386939,153.6151964902878 z"
      />
      <path
        fill={fill}
        d="M422.41960893869395,456.8151964902878 c-21.7,-0.1 -42.9,-3.4 -63.6,-10 c-25,-7.9 -47.8,-20.1 -68.7,-36 c-9.3,-7.1 -18,-14.8 -26.1,-23.2 c-15.1,-15.7 -27.8,-33.1 -38.2,-52.3 c-10,-18.6 -17.4,-38.2 -22,-58.8 c-1.9,-8.4 -3.2,-16.9 -4.1,-25.5 c-1.1,-10 -1.7000000000000002,-20.1 -1.3,-30.1 c0.30000000000000016,-10.2 1.2,-20.4 2.7,-30.5 c2.2,-15.1 5.9,-29.7 11.2,-44 c8.7,-24 21.1,-45.8 37.4,-65.5 c13.7,-16.6 29.6,-31 47.5,-42.9 c23.7,-15.6 49.4,-26.4 77.1,-32.4 c9.5,-2 19,-3.5 28.6,-4.3 c10,-0.9 19.9,-1.6 29.9,-1.2 c11.7,0.5 23.2,1.8 34.7,4 c20.9,4 40.7,10.9 59.6,20.6 c16.4,8.4 31.4,18.7 45.3,30.8 c16.4,14.3 30.5,30.4 42.3,48.7 c13.7,21.1 23.6,43.9 29.7,68.3 c2.5,10.2 4.4,20.6 5.4,31.1 c0.9,9.7 1.4,19.5 1,29.2 c-0.9,20.3 -3.9,40.2 -10,59.6 c-7.1,22.7 -17.3,43.8 -30.7,63.4 c-6.1,9 -12.9,17.5 -20.3,25.5 c-9.7,10.7 -20.3,20.4 -31.7,29.1 c-9.8,7.5 -20.2,14.1 -31,19.9 c-16.9,9.1 -34.7,15.9 -53.4,20.4 c-8.9,2.1 -17.8,3.7 -26.9,4.7 C438.619608938694,456.2151964902878 430.519608938694,456.8151964902878 422.41960893869395,456.8151964902878 zM426.719608938694,39.71519649028778 c-4.8,0 -8.1,0.1 -11.4,0.4 c-7.7,0.5 -15.3,1.1 -22.9,2.4 c-16.2,2.7 -31.9,7.3 -46.9,14.1 c-25.3,11.4 -46.9,27.6 -64.7,48.9 c-12,14.3 -21.5,30.2 -28.5,47.5 c-6.4,15.8 -10.6,32.1 -12.5,49 c-1.2,10.1 -1.8,20.2 -1.5,30.3 c0.30000000000000016,9.3 1.3,18.5 3,27.6 c4.2,23.3 12.5,45 24.8,65.3 c11.1,18.2 24.7,34.2 40.9,47.9 c15.5,13 32.5,23.5 51.2,31.1 c21,8.4 42.8,12.7 65.4,12.7 c4.7,0 9.4,-0.5 14.1,-0.8 c8.4,-0.5 16.7,-1.8 24.8,-3.7 c24,-5.6 46.1,-15.6 66.2,-30 c15.4,-11 29,-23.9 40.6,-38.8 c15.5,-19.8 26.8,-41.8 34,-65.9 c4.7,-15.8 7,-32 7.3,-48.4 c0.1,-3.5 -0.30000000000000016,-7 -0.30000000000000016,-10.5 c0.1,-7.9 -0.9,-15.6 -2.2,-23.3 c-2.7,-16.2 -7.5,-31.8 -14.6,-46.8 c-12,-25.6 -28.8,-47.5 -50.5,-65.6 c-13.4,-11.2 -28,-20.4 -44,-27.5 C475.719608938694,45.115196490287786 451.019608938694,39.71519649028778 426.719608938694,39.71519649028778 z"
      />
      <path
        fill={fill}
        d="M426.91960893869395,808.8151964902878 c77.8,0 155.6,0 233.4,0 c4.5,0 3.6,0 2.8,-3.4 c-4.2,-18.2 -9.2,-36.2 -15.1,-54 c-8.9,-26.7 -19.6,-52.7 -32.8,-77.6 c-11.5,-21.7 -24.8,-42.2 -40.7,-60.9 c-11.1,-13.1 -23.4,-25.2 -37.2,-35.5 c-2,-1.5 -3.1,-3.6 -4.2,-5.7 c-0.30000000000000016,-0.6000000000000003 -0.2,-1 0.30000000000000016,-1.5 c1.8,-1.9 3.7,-3.5 6.4,-4 c12.8,-2.4 25.5,-5.2 38.1,-8.5 c3.4,-0.9 6,-0.1 8.5,2.3 c15.6,14.8 29.4,31.2 41.7,48.8 c14.7,21 27.3,43.3 38.1,66.5 c13.9,29.7 24.8,60.4 33.7,91.9 c4.6,16.5 8.6,33.1 11.7,49.9 c1.6,8.6 2.7,17.2 4,25.8 c0.1,1 0.4,2 0.6000000000000003,3.1 c0.8,4.6 -2.5,8.3 -7.1,7.8 c-1.3,-0.1 -2.6,-0.2 -3.8,-0.2 c-172.1,0 -344.2,0 -516.3,0 c-1.4,0 -2.9,0 -4.3,-0.1 c-3.9,-0.30000000000000016 -6.5,-3.4 -5.8,-7.2 c2.2,-11.1 4.6,-22 7.6,-32.9 c0.8,-3 2.8,-4.5 5.9,-4.6 c0.9,0 1.8,0 2.6,0 C272.219608938694,808.8151964902878 349.519608938694,808.8151964902878 426.91960893869395,808.8151964902878 z"
      />
      <path
        fill={fill}
        d="M193.01960893869398,405.91519649028777 c20.3,-0.1 37.5,-4 53.6,-12.1 c3.4,-1.7000000000000002 7,-1.5 10,1.6 c6.7,6.8 14,12.9 21.6,18.5 c1.9,1.4 2.2,2.9 1.8,4.9 c-0.4,2 -1.5,3.4 -3.3,4.5 c-13.7,8.1 -28.3,14.2 -43.7,18 c-9.9,2.4 -19.9,3.8 -30,4.4 c-24.2,1.4 -47.2,-3.1 -69.4,-12.5 c-17,-7.2 -32.3,-17.2 -45.9,-29.6 c-23,-21 -39.1,-46.2 -48.5,-75.9 c-3,-9.6 -5,-19.5 -6.2,-29.5 c-1,-8.1 -1.5,-16.2 -1.2,-24.4 c0.6000000000000003,-15.5 2.9,-30.7 7.5,-45.5 c4.9,-15.9 11.9,-30.8 21.4,-44.5 c13.7,-19.7 30.8,-35.6 51.6,-47.5 c15.7,-9 32.4,-15.2 50.1,-18.8 c6.7,-1.3 13.4,-2.3 20.2,-2.7 c5.3,-0.30000000000000016 10.5,-0.7000000000000003 15.8,-0.8 c3.9,-0.1 7.4,1.1 11,2.2 c1.1,0.30000000000000016 1,0.9 0.6000000000000003,1.8 c-1.5,3.3 -3,6.5 -4.5,9.8 c-3.1,6.7 -5.9,13.7 -8.4,20.7 c-0.4,1 -0.7000000000000003,2 -1.2,2.9 c-1,1.7000000000000002 -2.4,2.8 -4.4,3 c-1,0.1 -1.9,0.30000000000000016 -2.9,0.30000000000000016 c-9.1,0.1 -17.9,1.8 -26.7,4.2 c-15.8,4.2 -30.2,11.2 -43.1,21.3 c-15.9,12.4 -27.9,27.8 -36.1,46.2 c-4.3,9.7 -7.3,19.8 -9,30.2 c-1,6 -1.6,12 -1.6,18.1 c0,2.3 0,4.6 0,7 c-0.1,13.5 2.6,26.5 7.1,39.2 c5.2,14.9 13,28.3 23.1,40.3 c10.3,12.3 22.6,22.3 36.7,30.1 c11.5,6.4 23.7,10.7 36.7,12.9 C182.01960893869398,405.3151964902878 188.619608938694,406.11519649028776 193.01960893869398,405.91519649028777 z"
      />
      <path
        fill={fill}
        d="M46.71960893869402,699.7151964902878 c0.9,0.5 1.9,0.2 2.8,0.2 c15,0 30.1,0 45.1,0 c1.6,0 3,0.30000000000000016 4.4,0.9 c1.2,0.5 1.9,1.3 2.2,2.6 c0.30000000000000016,1.8 0.30000000000000016,3.6 -0.6000000000000003,5.4 c-4.3,8.6 -8.3,17.4 -12,26.4 c-1.3,3.1 -3.7,4.7 -7.1,4.9 c-0.7000000000000003,0.1 -1.4,0 -2.2,0 c-23,0 -46.1,0 -69.1,0 c-2.6,0 -5.1,-0.1 -7.6,0.4 c-1.7000000000000002,0.30000000000000016 -1.9,0.1 -2.1,-1.6 c-0.30000000000000016,-1.8 -0.6000000000000003,-3.6 -0.5,-5.5 c0,-1.1 0.1,-2.2 0.6000000000000003,-3.2 c0.8,-1.6 0.6000000000000003,-3.4 0.8,-5.2 c0.8,-6.7 1.7000000000000002,-13.5 2.9,-20.1 c1.9,-10.8 4.3,-21.4 7.1,-32 c4.4,-16.7 9.9,-33.1 16.5,-49 c9.2,-22.1 20.4,-43 35,-62.1 c5.8,-7.6 12.1,-14.9 19,-21.6 c14.9,-14.5 31.8,-26 51.1,-33.7 c11.3,-4.5 22.9,-7.5 34.9,-9.2 c7.2,-1 14.5,-1.5 21.7,-1.6 c5.3,-0.1 10.5,0.2 15.8,0.8 c7.6,0.9 15.2,2.1 22.6,4.1 c14.6,3.9 28.1,9.9 40.8,18.2 c1.7000000000000002,1.1 3.4,2.1 4.5,3.9 c0.6000000000000003,1 0.8,1.8 0.5,2.9 c-0.5,2.2 -1.7000000000000002,3.8 -3.6,4.9 c-8.6,4.9 -16.9,10 -25,15.6 c-2.9,2 -5.9,2.2 -9,0.6000000000000003 c-11,-5.7 -22.8,-8.8 -35,-10.2 c-8,-0.9 -16.1,-0.7000000000000003 -24.1,0.2 c-10.1,1.1 -19.8,3.5 -29.2,7.3 c-11.6,4.7 -22,11.3 -31.5,19.4 c-14.3,12.3 -25.5,27 -35,43.2 c-11.1,19 -19.5,39.2 -26.1,60.1 c-3.3,10.4 -6,20.9 -8.5,31.5 C46.619608938694,698.6151964902878 46.51960893869398,699.2151964902878 46.71960893869402,699.7151964902878 z"
      />
    </svg>
  );
}
