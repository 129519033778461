import { MembersData, ChannelData } from '../../Common/interfaces/channel';
import { isNotEmptyArray } from '../arrays';

export const findUserInsideConversationMembers = (userId: string, members: Array<MembersData>): any => {
  const user = members.filter(member => member.id === userId);
  if (isNotEmptyArray(user)) {
    return user[0];
  } else {
    return {
      id: '',
      profileImageUrl: '',
      displayName: '',
    };
  }
};

export const findConversationInListById = (conversationList: Array<ChannelData>, conversationId: string): ChannelData | any => {
  if (isNotEmptyArray(conversationList)) {
    const conversationIndex = conversationList.findIndex(conversation => conversation.id === conversationId);
    if (conversationIndex >= 0) {
      return conversationList[conversationIndex];
    }
  }
};
