import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  height: 70px;
  padding: 10px 0px;
  align-items: center;
  padding: 0px 30px;
  transition-duration: 300ms;
  :hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

export const FriendCardStyled = { Container };
