import React, { useState, FC, ReactElement, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TimeAgo from 'timeago-react';
import { ChatMessageStyled } from './styled';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { THIRD_PANEL_VIEW, FOURTH_PANEL_VIEW } from '../../Common/enums';
import { changeThirdPanelView, changeFourthPanelView } from '../../Navigation/actions';
import { setActiveConversationId } from '../../Conversation/actions';
import ChatMessageOption from './Option';
import Flex from '../../CommonComponents/Flex';
import UnreadMessageCount from '../../CommonComponents/UnreadMessageCount';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { ChannelData } from '../../Common/interfaces/channel';
import { cutMessageString } from '../../utils/strings';
import { UserData } from '../../Common/interfaces/user';
import { clearUnreadCountForConversation } from '../../Common/actions';
import useGetLastMessageByConversationId from '../../Common/customHooks/useGetLastMessageByConversationId';
import useGetConversationPerMemberDataByOwnerId from '../../Common/customHooks/useGetConversationPerMemberDataByOwnerId';
import { NotificationData } from '../../Common/interfaces/notification';
import { updateConversationInList } from '../actions';
import { MessageData } from '../../Common/interfaces/message';
import SkeletonLoading from '../../CommonComponents/Skeleton';
import { FriendData } from '../../Common/interfaces/friend';
import { isNotEmptyArray } from '../../utils/arrays';
import { getDifferenceDurationBetweenDate } from '../../utils/timings';
import useGetConversationMembers from '../../Common/customHooks/useGetConversationMembers';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { useTranslation } from 'react-i18next';

const { FriendContainer } = ChatMessageStyled;
const { Paragraph } = TypographyStyled;
const { UserAvatar } = ImageStyled;

interface Props {
  message: ChannelData;
}

const ChatMessage: FC<Props> = ({ message }): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeConversationId: string = useSelector(state => state.conversationReducer.activeConversationId);
  const notificationSound: NotificationData = useSelector(state => state.notificationReducer.selectedSound);
  const friendList: Array<FriendData> = useSelector(state => state.friendReducer.friendList);
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const isOwnSpace = message.id === loggedInUser.id;
  const [isOptionVisible, toggleOptionVisibility] = useState(false);
  const [lastMessageId, setLastMessageId] = useState('');
  const [unreadCountDOM, setUnreadCountDOM] = useState(0);
  const { unreadCount, notification, isHidden } = useGetConversationPerMemberDataByOwnerId(message, loggedInUser);
  const [isConversationHidden, setConversationIsHidden] = useState(isHidden);
  const lastMessage: MessageData = useGetLastMessageByConversationId(message);
  const members = useGetConversationMembers(message.id);
  const notificationAudioRef = useRef<any>();

  useEffect(() => {
    setConversationIsHidden(isHidden);
    message.isHidden = isHidden;
    dispatch(updateConversationInList(message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden]);

  useEffect(() => {
    if (isNotEmptyArray(members)) {
      const userIsBlockedStatus = members.map(member => member.isBlocked);
      if (message.isDirectMessage) {
        message.isBlocked = userIsBlockedStatus.includes(true) ? true : false;
      }
      message.members = members;
      dispatch(updateConversationInList(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  useEffect(() => {
    if (notification === undefined) {
      message.notification = true;
    } else {
      message.notification = notification;
    }
    dispatch(updateConversationInList(message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(() => {
    if (isNotEmptyArray(friendList) && message.isDirectMessage) {
      const friendIndex = message.members.findIndex(member => member.id !== loggedInUser.id);
      const friend = message.members[friendIndex];
      if (friend) {
        const friendListIndex = friendList.findIndex(friendListInd => friendListInd.friendId === friend.id);
        const friendListFriend = { ...friendList[friendListIndex] };
        if (friendListFriend) {
          message.name = friendListFriend.friendDisplayName;
          message.conversationImageUrl = friendListFriend.friendProfileImageUrl;
          message.members = message.members.map(member => {
            if (member.id !== loggedInUser.id) {
              member.displayName = friendListFriend.friendDisplayName;
            }
            return member;
          });
          dispatch(updateConversationInList(message));
        }
      } else if (message.id === loggedInUser.id) {
        message.name = loggedInUser.displayName;
        message.conversationImageUrl = loggedInUser.profileImageUrl;
        dispatch(updateConversationInList(message));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendList]);

  useEffect(() => {
    if (unreadCount > 0 && message.id !== activeConversationId) {
      setUnreadCountDOM(unreadCount);
      message.unreadCount = unreadCount;
      dispatch(updateConversationInList(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCount]);

  useEffect(() => {
    if (lastMessage) {
      message.lastMessage = lastMessage;
      if (message.id === activeConversationId && unreadCount > 0) {
        const requestBody = {
          conversationId: message.id,
        };
        clearUnreadCountForConversation(loggedInUser.id, requestBody);
        message.unreadCount = 0;
      }
      dispatch(updateConversationInList(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  useEffect(() => {
    if (message.lastMessage && message.lastMessage.userId !== loggedInUser.id) {
      if (message.lastMessage.id !== lastMessageId) {
        const { diffSecs } = getDifferenceDurationBetweenDate(new Date(), new Date(message.lastMessage.dateCreated));
        if (diffSecs < 30 && notificationAudioRef && notificationAudioRef.current && message.notification) {
          notificationAudioRef.current.volume = 0.5;
          notificationAudioRef.current.play();
          setConversationIsHidden(false);
        }
        setLastMessageId(message.lastMessage.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message && message.lastMessage]);

  const handleClick = () => {
    setUnreadCountDOM(0);
    dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN));
    dispatch(setActiveConversationId(message.id));
    dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
    if (unreadCount > 0) {
      const requestBody = {
        conversationId: message.id,
      };
      clearUnreadCountForConversation(loggedInUser.id, requestBody);
      const newMessageObject = { ...message };
      newMessageObject.unreadCount = 0;
      dispatch(updateConversationInList(newMessageObject));
    }
  };

  const renderLastMessage = () => {
    if (lastMessage) {
      if (lastMessage.content.type.isAttachment) {
        return `${lastMessage.userName} ${t('SentAFile')}`;
      } else {
        return `${cutMessageString(lastMessage.content.text, 80)}`;
      }
    } else {
      return 'Hi!';
    }
  };

  if (message && message.lastMessage) {
    return (
      <FriendContainer
        isActive={message.id === activeConversationId}
        isVisible={!isConversationHidden}
        onClick={handleClick}
        onMouseEnter={() => toggleOptionVisibility(true)}
        onMouseLeave={() => toggleOptionVisibility(false)}
      >
        <Flex container width="90%">
          <Flex margin="0px 30px 0px 0px" width="10%">
            <UserAvatar src={isOwnSpace ? loggedInUser.profileImageUrl : message.conversationImageUrl} width="45" height="45" alt="" />
          </Flex>
          <Flex width="80%">
            <Flex container justifyContent="space-between" margin="0px 0px 5px 0px">
              <Paragraph
                fontColor="#414042"
                size="lg"
                fontWeight={unreadCountDOM > 0 ? 'bolder' : 'bold'}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '180px' }}
              >
                {isOwnSpace ? loggedInUser.displayName : message.name}
              </Paragraph>
              {notification === false && <IconContainer iconName="mute" iconWidth="20px" />}
              <UnreadMessageCount allUnreadCount={unreadCountDOM} />
            </Flex>
            <Paragraph fontColor="#414042" size="md" margin="0px 0px 5px 0px" fontWeight={unreadCountDOM > 0 ? 'bold' : ''}>
              <TimeAgo datetime={lastMessage ? lastMessage.dateCreated : new Date(message.dateCreated)} live={false} />
            </Paragraph>
            <Paragraph fontColor="#414042" size="md" fontWeight={unreadCountDOM > 0 ? 'bold' : ''}>
              <Flex maxHeight="25px" overflow="hidden">
                {renderLastMessage()}
              </Flex>
            </Paragraph>
          </Flex>
        </Flex>
        {isOptionVisible && (
          <ChatMessageOption isVisible={isOptionVisible} setConversationIsHidden={setConversationIsHidden} conversationId={message.id} />
        )}
        <audio ref={notificationAudioRef} src={notificationSound.value.path} />
      </FriendContainer>
    );
  } else {
    return <SkeletonLoading count={1} />;
  }
};

export default ChatMessage;
