import React, { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChatMessageOptionStyled } from './styled';
import Modal from '../../../CommonComponents/Modal';
import { useTranslation } from 'react-i18next';
import Flex from '../../../CommonComponents/Flex';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';
import { UserData } from '../../../Common/interfaces/user';
import { hideConversation } from '../../actions';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { ButtonStyled } from '../../../CommonComponents/Button/Button.style';

const { OptionContainer, OptionItem, Container, ConfirmationHideContainer } = ChatMessageOptionStyled;
const { Span } = TypographyStyled;
const { Button } = ButtonStyled;

interface Props {
  isVisible: boolean;
  conversationId: string;
  setConversationIsHidden: (toggle: boolean) => any;
}

const ChatMessageOption: FC<Props> = ({ isVisible, setConversationIsHidden, conversationId }): ReactElement => {
  const { t } = useTranslation();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const [isModalOpen, toggleModal] = useState(false);
  const [isConfirmationOpen, toggleConfirmation] = useState(false);

  const handleHideConversation = () => {
    const requestBody = {
      conversationId: conversationId,
      isHidden: true,
    };
    hideConversation(requestBody, loggedInUser.id);
    setConversationIsHidden(true);
    toggleModal(false);
    toggleConfirmation(false);
  };

  return (
    <Container visible={isVisible}>
      <Flex handleOnClick={e => e.stopPropagation()}>
        <IconContainer onClick={() => toggleModal(true)} iconName="kebab" iconWidth="25px" iconFill={isModalOpen ? '#707070' : '#444444'} />
        <Modal
          visible={isModalOpen}
          dimmed={false}
          setModalVisibility={() => {
            toggleModal(false);
            toggleConfirmation(false);
          }}
        />
        {isModalOpen && (
          <Flex>
            {isConfirmationOpen ? (
              <ConfirmationHideContainer>
                <Span size="lg" fontColor="#444444" center>
                  {t('Hide')}
                </Span>
                <Span size="md" fontColor="#444444" center>
                  Are you sure you want to hide this message?
                </Span>
                <Flex container width="100%" justifyContent="space-around">
                  <Button
                    onClick={() => {
                      toggleModal(false);
                      toggleConfirmation(false);
                    }}
                    width="45%"
                    fontSize="md"
                    borderRadius="20px"
                    type="button"
                    border="2px solid #CACACA"
                    color="#CACACA"
                    backgroundColor="#fff"
                  >
                    {t('Cancel').toUpperCase()}
                  </Button>
                  <Button
                    onClick={handleHideConversation}
                    width="45%"
                    fontSize="md"
                    borderRadius="20px"
                    type="button"
                    color="#fff"
                    backgroundColor="#CACACA"
                    border="none"
                  >
                    {t('Ok').toUpperCase()}
                  </Button>
                </Flex>
              </ConfirmationHideContainer>
            ) : (
              <OptionContainer onClick={() => toggleConfirmation(true)}>
                <OptionItem>{t('Hide')}</OptionItem>
              </OptionContainer>
            )}
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

export default ChatMessageOption;
