export const SET_ACTIVE_CONVERSATION_ID = 'SET_ACTIVE_CONVERSATION_ID';
export const SET_CONVERSATION_MESSAGES = 'SET_CONVERSATION_MESSAGES';
export const ADD_MESSAGE_TO_CONVERSATION_MESSAGES = 'ADD_MESSAGE_TO_CONVERSATION_MESSAGES';

export const GET_CONVERSATION_MESSAGES_ERROR = 'GET_CONVERSATION_MESSAGES_ERROR';
export const GET_CONVERSATION_MESSAGES_LOADING = 'GET_CONVERSATION_MESSAGES_LOADING';
export const GET_CONVERSATION_MESSAGES_SUCCESS = 'GET_CONVERSATION_MESSAGES_SUCCESS';

export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';
export const SEND_MESSAGE_LOADING = 'SEND_MESSAGE_LOADING';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';

export const SEND_FILE_ERROR = 'SEND_FILE_ERROR';
export const SEND_FILE_LOADING = 'SEND_FILE_LOADING';
export const SEND_FILE_SUCCESS = 'SEND_FILE_SUCCESS';

export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';
export const DELETE_FILE_LOADING = 'DELETE_FILE_LOADING';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';

export const SEARCH_MESSAGES_ERROR = 'SEARCH_MESSAGES_ERROR';
export const SEARCH_MESSAGES_LOADING = 'SEARCH_MESSAGES_LOADING';
export const SEARCH_MESSAGES_SUCCESS = 'SEARCH_MESSAGES_SUCCESS';

export const CLEAR_ALL_MESSAGES = 'CLEAR_ALL_MESSAGES';
export const TOGGLE_IS_FILTERED = 'TOGGLE_IS_FILTERED';
export const TOGGLE_PREVIEW_FILE = 'TOGGLE_PREVIEW_FILE';

export const UPDATE_ACTIVE_CONVERSATION = 'UPDATE_ACTIVE_CONVERSATION';
