import styled from 'styled-components/macro';

const media = {
  extraSmall: '@media(max-width: 600px)',
  small: '@media(min-width: 600px)',
  medium: '@media(min-width: 768px)',
  large: '@media(min-width: 992px)',
  extraLarge: '@media(min-width: 1200px)',
};

export const Container = styled.div`
  font-family: Montserrat-Light !important;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0px 20px;
  .title-container {
    font-family: Montserrat-Light !important;
    color: #727272;
    text-align: center;
    ${media.large} {
      margin-bottom: 200px;
    }
    .title {
      font-family: Montserrat-Light !important;
      font-size: 30px;
      ${media.medium} {
        font-size: 50px;
      }
      padding: 10px 0px;
    }
    .sub-title {
      font-family: Montserrat-Light !important;
      font-size: 25px;
      opacity: 0.5;
      ${media.medium} {
        font-size: 35px;
      }
      padding: 10px 0px;
    }
  }
  .img-container {
    img {
      width: 100%;
      height: auto;
    }
  }
  .button-download-container {
    ${media.large} {
      position: absolute;
      right: 0;
      transform: translateX(-50px);
    }
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .button {
      background-color: #b2b2b2;
      border-radius: 16px;
      border: none;
      height: 80px;
      width: 300px;
      padding: 20px 75px;
      margin: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      :hover {
        cursor: pointer;
      }
    }
    a {
      text-decoration: none;
    }
    .webapp {
      font-family: Montserrat-Light !important;
      font-size: 30px;
      color: white;
    }
    .desktop {
      color: white;
      .coming-soon {
        font-family: Montserrat-Light !important;
        font-size: 20px;
      }
      .desktop {
        font-family: Montserrat-Light !important;
        font-size: 30px;
      }
    }
  }
`;
