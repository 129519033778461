import {
  CHANGE_SECOND_PANEL_VIEW,
  CHANGE_THIRD_PANEL_VIEW,
  CHANGE_FOURTH_PANEL_VIEW,
  TOGGLE_THREAD_CONVERSATION_VIEW,
  TOGGLE_DIMMED_MODAL,
} from '../actions/actionTypes';
import { SECOND_PANEL_VIEW, THIRD_PANEL_VIEW, FOURTH_PANEL_VIEW, THREAD_CONVERSATION_VIEW } from '../../Common/enums';

const initialState = {
  secondPanelView: SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW,
  thirdPanelView: THIRD_PANEL_VIEW.CONVERSATION,
  fourthPanelView: FOURTH_PANEL_VIEW.HIDDEN,
  threadConversationView: THREAD_CONVERSATION_VIEW.HIDDEN,
  isDimmedModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SECOND_PANEL_VIEW:
      return {
        ...state,
        secondPanelView: action.view,
      };
    case CHANGE_THIRD_PANEL_VIEW:
      return {
        ...state,
        thirdPanelView: action.view,
      };
    case CHANGE_FOURTH_PANEL_VIEW:
      return {
        ...state,
        fourthPanelView: action.view,
      };
    case TOGGLE_THREAD_CONVERSATION_VIEW:
      return {
        ...state,
        threadConversationView: action.view,
      };
    case TOGGLE_DIMMED_MODAL:
      return {
        ...state,
        isDimmedModal: action.toggle,
      };
    default:
      return state;
  }
};
