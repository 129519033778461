//Fetch Thread Messages
export const SET_PARENT_THREAD = 'SET_PARENT_THREAD';
export const SET_THREAD_ACTIVE_CONVERSATION_ID = 'SET_THREAD_ACTIVE_CONVERSATION_ID';

export const SEND_THREAD_MESSAGE_LOADING = 'SEND_THREAD_MESSAGE_LOADING';
export const SEND_THREAD_MESSAGE_ERROR = 'SEND_THREAD_MESSAGE_ERROR';
export const SEND_THREAD_MESSAGE_SUCCESS = 'SEND_THREAD_MESSAGE_SUCCESS';

export const GET_THREAD_LIST_LOADING = 'GET_THREAD_LIST_LOADING';
export const GET_THREAD_LIST_ERROR = 'GET_THREAD_LIST_ERROR';
export const GET_THREAD_LIST_SUCCESS = 'GET_THREAD_LIST_SUCCESS';

export const UPDATE_THREAD_LIST = 'UPDATE_THREAD_LIST';
export const UPDATE_THREAD_IN_LIST = 'UPDATE_THREAD_IN_LIST';

export const ADD_THREAD_MEMBER_LOADING = 'ADD_THREAD_MEMBER_LOADING';
export const ADD_THREAD_MEMBER_ERROR = 'ADD_THREAD_MEMBER_ERROR';
export const ADD_THREAD_MEMBER_SUCCESS = 'GET_THREAD_MEMBER_SUCCESS';
