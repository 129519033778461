import React, { FC, useState, useEffect, useRef } from 'react';
import Flex from '../../../../CommonComponents/Flex';
import Mention from '../../../../Mention';
import { ButtonStyled } from '../../../../CommonComponents/Button/Button.style';
import EmojiPicker from '../../../../CommonComponents/EmojiPicker';
import { MessageData } from '../../../../Common/interfaces/message';
import { updateMessageById } from '../../../../Common/actions';
import { ComposeStyled } from '../../../Footer/styled';
import IconContainer from '../../../../CommonComponents/Decoration/IconContainer';
import Modal from '../../../../CommonComponents/Modal';
import { TypographyStyled } from '../../../../CommonComponents/Typography/Typography.style';
import { useTranslation } from 'react-i18next';

const { SecondaryButton } = ButtonStyled;
const { MessageInputText, OtherInput, InputContainer } = ComposeStyled;
const { Span } = TypographyStyled;

interface Props {
  message: MessageData;
  toggleEditMessage?: (toggle: boolean) => void;
}

const EditMessage: FC<Props> = ({ message, toggleEditMessage }) => {
  const { t } = useTranslation();
  const [messageText, setMessageText] = useState(message.content.text);
  const [isReactionShown, setIsReactionShown] = useState(false);
  const [isEnterToSendActive, setIsEnterToSendActive] = useState(true);
  const composeTextFieldRef: any = useRef();

  useEffect(() => {
    const inputDom: any = document.querySelector('.rc-mentions > textarea');
    inputDom.style.height = '5px';
    inputDom.style.height = inputDom.scrollHeight + 'px';
  }, []);

  const handleChange = e => {
    setMessageText(e);
    const inputDom: any = document.querySelector('.rc-mentions > textarea');
    inputDom.style.height = '5px';
    inputDom.style.height = inputDom.scrollHeight + 'px';
  };

  const handleUpdateMessageText = async () => {
    if (messageText && !messageText.match('^\\s+$')) {
      const requestBody = {
        messageId: message.id,
        text: messageText,
        isUpdated: true,
      };
      await updateMessageById(requestBody);
      toggleEditMessage && toggleEditMessage(false);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey && isEnterToSendActive) {
      e.preventDefault();
      handleUpdateMessageText();
    } else if (e.key === 'Enter' && e.shiftKey && !isEnterToSendActive) {
      handleUpdateMessageText();
    }
  };

  const toggleEmoji = e => {
    e.preventDefault();
    setIsReactionShown(isReactionShown => !isReactionShown);
  };

  const handleEmojiSelect = e => setMessageText(oldValue => oldValue + e.native);

  return (
    <Flex width="100%" margin="10px 0px">
      <MessageInputText>
        <InputContainer>
          <Mention
            mentionRef={composeTextFieldRef}
            mentionVal={messageText}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placement={'top'}
          />
        </InputContainer>
        <OtherInput style={{ justifyContent: 'flex-end' }}>
          <Flex container width="270px" justifyContent="space-between" alignItems="center">
            <Modal visible={isReactionShown} setModalVisibility={() => setIsReactionShown(false)} dimmed={false} />
            <Flex container alignItems="flex-end" height="100%">
              <Span size="sm" fontColor={messageText.length > 1000 ? '#FF3737' : '#444444'}>{`${messageText.length} / 1000`}</Span>
            </Flex>
            <IconContainer
              onClick={() => setIsEnterToSendActive(toggle => !toggle)}
              iconName="select"
              iconWidth="25px"
              iconFill={isEnterToSendActive ? '#08E9FE' : '#CACACA'}
            />
            <Flex container alignItems="flex-end" height="100%">
              <Span size="sm" fontColor="#444444">
                {t('EnterToSend')}
              </Span>
            </Flex>
            <IconContainer onClick={toggleEmoji} iconName="smiley" iconWidth="25px">
              {isReactionShown && (
                <Flex transform="translate(0px, -10px)" handleOnClick={e => e.stopPropagation()}>
                  <EmojiPicker handleEmojiSelect={handleEmojiSelect} />
                </Flex>
              )}
            </IconContainer>
            <IconContainer
              onClick={handleUpdateMessageText}
              iconName="send"
              iconWidth="25px"
              iconFill={messageText ? '#84FFFF' : '#CACACA'}
            />
          </Flex>
        </OtherInput>
      </MessageInputText>
      <Flex container width="100" justifyContent="flex-end" margin="5px 0px 0px 0px">
        <SecondaryButton
          fontSize="md"
          width="110px"
          borderRadius="5px"
          type="button"
          onClick={() => toggleEditMessage && toggleEditMessage(false)}
        >
          CANCEL
        </SecondaryButton>
      </Flex>
    </Flex>
  );
};

export default EditMessage;
