import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function MessageOption({
  style = {},
  fill = '#BDBDBD',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <circle fill="#DEDEDE" enableBackground="new" cx="540" cy="540" r="540" />
      </g>
      <g>
        <circle fill="#D0D0D0" enableBackground="new" cx="540" cy="540" r="375" />
      </g>
      <g>
        <circle fill="#FFFFFF" cx="322.5" cy="517.5" r="69.5" />
        <circle fill="#FFFFFF" cx="539.5" cy="517.5" r="69.5" />
        <circle fill="#FFFFFF" cx="757.5" cy="517.5" r="69.5" />
      </g>
    </svg>
  );
}
