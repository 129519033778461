import styled from 'styled-components/macro';
import { fadeIn } from '../../../CommonComponents/Animation';

const FriendProfileContainer = styled.div`
  animation: ${fadeIn} 500ms;
  background-color: white;
  position: absolute;
  transform: translate(0px, 260px);
  border-radius: 0px 30px 30px 30px;
  color: grey;
  box-shadow: 0px 0px 10px 1px #c0c0c0;
  width: 375px;
`;

interface ProfileImageContainerProps {
  tooltip: string;
}

const ProfileImageContainer = styled.div`
  position: relative;
  width: 100%;
  :hover::after {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
    transform: translate(0px, 0px);
  }
  &:after {
    content: '${(props: ProfileImageContainerProps) => props.tooltip}';
    border-radius: 0px 25px 0px 0px;
    transform: translate(0px, -10px);
    opacity: 0;
    color: white;
    transition: 300ms;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    padding: 15px;
  }
  :hover {
    cursor: pointer;
  }
`;

const ProfileImage = styled.img`
  border-radius: 0px 25px 0px 0px;
  object-fit: cover;
  width: 100%;
  position: relative;
`;

export const FriendProfileStyled = {
  FriendProfileContainer,
  ProfileImageContainer,
  ProfileImage,
};
