import { useState, useEffect } from 'react';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import db from '../../firebase/firebase';
import { isNotEmptyArray } from '../../utils/arrays';

export default function(ownerId: string) {
  const [conversations, setConversations] = useState<Array<any>>([]);

  useEffect(() => {
    if (ownerId) {
      const unsubscribe = db
        .collection(collections.conversationsPerMember)
        .where('ownerId', '==', ownerId)
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            setConversations(
              snapshot.docs.map(conversation => {
                return {
                  conversationId: conversation.data().conversationId,
                };
              })
            );
          }
          return () => {
            unsubscribe();
          };
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return conversations;
}
