import axios from 'axios';
import {
  FILTER_MESSAGES_BY_NAME,
  CLEAR_ALL_CONVERSATION_MESSAGES,
  PUSH_CONVERSATION_TO_CONVERSATION_LIST,
  UPDATE_CONVERSATION_LAST_MESSAGE,
  UPDATE_CONVERSATION_UNREAD_COUNT,
  UPDATE_CONVERSATION_TYPING_MEMBERS,
  UPDATE_CONVERSATION_LIST,
  UPDATE_CONVERSATION_IN_LIST,
} from './actionTypes';
import { MessageData } from '../../Common/interfaces/message';
import { ChannelData, TypingData } from '../../Common/interfaces/channel';

export const getConversationFullInfo = async (conversationId: string, ownerId: string) => {
  try {
    return await axios.get(process.env.REACT_APP_API_URL + `/conversation/`, {
      params: {
        conversationId: conversationId,
        ownerId: ownerId,
      },
    });
  } catch (err) {
    return err;
  }
};

export function filterMessagesByName(name: string) {
  return {
    type: FILTER_MESSAGES_BY_NAME,
    name: name,
  };
}

export const clearChatReducer = () => {
  return {
    type: CLEAR_ALL_CONVERSATION_MESSAGES,
  };
};

export const pushConversationToConversations = (conversation: ChannelData) => {
  return {
    type: PUSH_CONVERSATION_TO_CONVERSATION_LIST,
    conversation: conversation,
  };
};

export const updateConversationLastMessage = (lastMessage: MessageData, conversationId: string) => {
  return {
    type: UPDATE_CONVERSATION_LAST_MESSAGE,
    lastMessage: lastMessage,
    conversationId: conversationId,
  };
};

export const updateConversationUnreadCount = (unreadCount: number, conversationId: string) => {
  return {
    type: UPDATE_CONVERSATION_UNREAD_COUNT,
    unreadCount,
    conversationId,
  };
};

export const updateConversationTypingMembers = (typingMembers: Array<TypingData>, conversationId: string) => {
  return {
    type: UPDATE_CONVERSATION_TYPING_MEMBERS,
    typingMembers,
    conversationId,
  };
};

export const updateConversationList = conversationList => {
  return {
    type: UPDATE_CONVERSATION_LIST,
    conversationList,
  };
};

export const updateConversationInList = conversation => {
  const userChatList = localStorage.getItem('userChatList') || '';
  if (userChatList) {
    const parsedUserChatList = JSON.parse(userChatList);
    const newList = parsedUserChatList.map(conv => {
      if (conv.id === conversation.id) {
        conv = conversation;
      }
      return conv;
    });
    localStorage.setItem('userChatList', JSON.stringify(newList));
  }
  return {
    type: UPDATE_CONVERSATION_IN_LIST,
    conversation,
  };
};

export const updateConversationNotification = async (requestBody, loggedInUserId: string) => {
  return await axios.put(process.env.REACT_APP_API_URL + `/conversations/notification/${loggedInUserId}`, requestBody);
};

export const hideConversation = async (requestBody, loggedInUserId: string) => {
  return await axios.put(process.env.REACT_APP_API_URL + `/conversations/hide/${loggedInUserId}`, requestBody);
};
