import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RepliesStyled } from './styled';
import useGetReplies from '../../../../Thread/useGetReplies';
import { MessageData } from '../../../../Common/interfaces/message';
import { TypographyStyled } from '../../../../CommonComponents/Typography/Typography.style';
import Flex from '../../../../CommonComponents/Flex';
import { isNotEmptyArray } from '../../../../utils/arrays';
import { ThreadListData } from '../../../../Common/interfaces/thread';
import { ChannelData, MembersData } from '../../../../Common/interfaces/channel';
import { setParentThread } from '../../../../Thread/actions';
import { toggleThreadConversationView } from '../../../../Navigation/actions';
import { THREAD_CONVERSATION_VIEW } from '../../../../Common/enums';
import { ImageStyled } from '../../../../CommonComponents/Image/Image.style';
import { useTranslation } from 'react-i18next';
import { UserData } from '../../../../Common/interfaces/user';
import TimeAgo from 'timeago-react';

const { Span } = TypographyStyled;
const { Container } = RepliesStyled;
const { UserAvatar } = ImageStyled;

interface Props {
  message: MessageData;
  activeConversation: ChannelData;
  messageSender: MembersData;
}

const Replies: FC<Props> = ({ message, activeConversation, messageSender }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const threads: Array<ThreadListData> = useSelector(state => state.threadReducer.threads);
  const { replies } = useGetReplies(message && message.id);

  const handleReplyClick = () => {
    const threadIndex = isNotEmptyArray(threads) ? threads.findIndex(thread => thread.parentMessageId === message.id) : -1;
    let parentThread;
    if (threadIndex >= 0) {
      parentThread = threads[threadIndex];
    } else {
      parentThread = {
        id: '',
        parentMessageId: message.id,
        conversationImageUrl: activeConversation.conversationImageUrl,
        conversationId: activeConversation.id,
        conversationName: activeConversation.name,
        conversationMembers: activeConversation.members,
        conversationIsBlocked: activeConversation.isBlocked,
        childMessage: message,
        threadMemberId: loggedInUser.id,
        threadSender: messageSender,
        unreadCount: 0,
      };
    }

    dispatch(setParentThread(parentThread));
    dispatch(toggleThreadConversationView(THREAD_CONVERSATION_VIEW.VISIBLE));
  };

  const getLastMessageUserImageURL = () => {
    if (isNotEmptyArray(activeConversation.members)) {
      const lastReply = replies[replies.length - 1];
      const user = activeConversation.members.filter(member => member.id === lastReply.userId);
      if (isNotEmptyArray(user)) return user[0].profileImageUrl;
    }
  };

  return (
    <>
      {isNotEmptyArray(replies) && (
        <Flex container alignItems="center" handleOnClick={handleReplyClick} margin="0px 0px 0px 5px">
          <Container>
            <UserAvatar src={getLastMessageUserImageURL()} width="25px" height="25px" style={{ margin: '0px 5px 0px 0px' }} />
            <Span fontColor="#40B9F2" size="md" padding="0px 5px 0px 0px">
              {replies.length} {replies.length > 1 ? t('Replies') : t('Reply')}
            </Span>
          </Container>
          <Span fontColor="#939393" size="md" padding="0px 0px 0px 10px">
            <TimeAgo datetime={new Date(replies[replies.length - 1].dateCreated)} live={false} />
          </Span>
        </Flex>
      )}
    </>
  );
};

export default Replies;
