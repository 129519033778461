import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Ban({
  style = {},
  fill = '#ff5069',
  width = '100%',
  className = '',
  viewBox = '0 0 21 21',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g transform="translate(0.007 0)">
          <path
            d="M41.039,9.416a10.324,10.324,0,0,1,7.463,3.2,10.079,10.079,0,0,1,2.976,7.293,10.209,10.209,0,0,1-3.413,7.733,10.436,10.436,0,0,1-14.67-.5,10.152,10.152,0,0,1-2.892-7.7,10.169,10.169,0,0,1,3.422-7.275A10.325,10.325,0,0,1,41.039,9.416Zm-3.6,16.531c.071.04.093.053.118.065.307.158.607.331.923.468a6.009,6.009,0,0,0,2.22.461,7.273,7.273,0,0,0,.861-.031,6.91,6.91,0,0,0,.948-.133A6.841,6.841,0,0,0,45,25.681a7.29,7.29,0,0,0,1.812-1.849A6.955,6.955,0,0,0,47.75,21.8a6.611,6.611,0,0,0,.26-1.775,6.733,6.733,0,0,0-.031-.706c-.034-.322-.081-.644-.142-.963a6.517,6.517,0,0,0-.663-1.74c-.115-.22-.127-.223-.3-.043q-4.617,4.617-9.235,9.232C37.589,25.845,37.533,25.876,37.44,25.947ZM34.9,23.4c3.2-3.2,6.367-6.37,9.545-9.545-.031-.022-.062-.053-.1-.074-.273-.146-.542-.3-.824-.427a5.749,5.749,0,0,0-2.1-.465,7.071,7.071,0,0,0-.923.031,8.886,8.886,0,0,0-.991.146,7.125,7.125,0,0,0-3.865,2.295A7.1,7.1,0,0,0,34.5,17.267a7.444,7.444,0,0,0-.548,2.666,5.606,5.606,0,0,0,.031.644c.019.217.05.434.081.65a5.188,5.188,0,0,0,.415,1.375C34.6,22.872,34.755,23.129,34.9,23.4Z"
            transform="translate(-30.492 -9.416)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
