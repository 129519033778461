import styled from 'styled-components/macro';
import { fadeIn } from '../../CommonComponents/Animation';

const media = {
  extraSmall: '@media(max-width: 600px)',
  small: '@media(min-width: 600px)',
  medium: '@media(min-width: 768px)',
  large: '@media(min-width: 992px)',
  extraLarge: '@media(min-width: 1200px)',
};

export const Container = styled.div`
  font-family: Montserrat-Light !important;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const FirstPanel = styled.div`
  p {
    margin-bottom: 0px;
  }
  font-family: Montserrat-Light !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  padding: 80px;
  ${media.extraLarge} {
    flex-direction: row;
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20%;
    text-align: center;
    ${media.extraLarge} {
      text-align: right;
    }
    animation: ${fadeIn} 300ms linear;
    .feature {
      font-family: Montserrat-Light !important;
      padding: 20px 0px;
      width: 220px;
      display: none;
      ${media.extraLarge} {
        display: block;
      }

      .feature-title {
        font-family: Montserrat-Light !important;
        color: #727272;
        font-size: 30px;
        padding: 25px 0px;
        ${media.medium} {
          font-size: 30px;
        }
        ${media.extraLarge} {
          font-size: 35px;
        }
      }

      .active {
        color: #30ccfe;
        position: relative;
        transition-duration: 300ms;
      }

      .active::after {
        content: '▼';
        display: flex;
        justify-content: center;
        top: 0;
        transform: translateY(-30px);
        font-size: 30px;
        color: #30ccfe;
        padding: 25px 0px;
        position: absolute;
        width: 100%;
        ${media.extraLarge} {
          content: '◀';
          font-size: 35px;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: flex-end;
          transform: translateX(50px);
        }
      }
    }

    .active {
      display: block;
    }

    .feature:hover {
      cursor: pointer;
    }

    .feature-message {
      font-family: Montserrat-Light !important;
      font-size: 15px;
      opacity: 0.5;
      ${media.medium} {
        font-size: 13px;
      }
      ${media.extraLarge} {
        font-size: 15px;
      }
    }
  }

  .middle-panel {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    position: relative;
    .arrow-left {
      display: none;
      ${media.extraLarge} {
        display: block;
        position: absolute;
        left: 10%;
      }
    }
    .arrow-right {
      display: none;
      ${media.extraLarge} {
        display: block;
        position: absolute;
        right: 10%;
      }
    }
    img {
      display: none;
      ${media.extraSmall} {
        max-height: 300px;
      }
      ${media.small} {
        max-height: 350px;
      }
      ${media.medium} {
        max-height: 400px;
      }
      ${media.large} {
        max-height: 450px;
      }
      ${media.extraLarge} {
        max-height: 500px;
      }
    }
    .indicators {
      position: absolute;
      bottom: 0;
      display: flex;
      animation: ${fadeIn} 300ms linear;
      transform: translateY(260px);
      ${media.extraLarge} {
        transform: translateY(50px);
      }
    }
    .visible {
      display: block;
      animation: ${fadeIn} 500ms ease-in-out;
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20%;
    text-align: center;
    ${media.extraLarge} {
      text-align: left;
    }
    animation: ${fadeIn} 300ms linear;
    .feature {
      padding: 20px 0px;
      width: 220px;
      display: none;
      ${media.extraLarge} {
        display: block;
      }

      .feature-title {
        font-family: Montserrat-Light !important;
        color: #727272;
        font-size: 30px;
        padding: 25px 0px;
        ${media.medium} {
          font-size: 30px;
        }
        ${media.extraLarge} {
          font-size: 35px;
        }
      }

      .active {
        color: #30ccfe;
        position: relative;
        transition-duration: 300ms;
      }

      .active::after {
        content: '▼';
        display: flex;
        justify-content: center;
        top: 0;
        transform: translateY(-30px);
        font-size: 30px;
        color: #30ccfe;
        padding: 25px 0px;
        position: absolute;
        width: 100%;
        ${media.extraLarge} {
          content: '▶';
          color: #30ccfe;
          font-size: 35px;
          padding: 25px 0px;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          transform: translateX(-50px);
        }
      }
    }

    .active {
      display: block;
    }

    .feature:hover {
      cursor: pointer;
    }

    .feature-message {
      font-family: Montserrat-Light !important;
      font-size: 15px;
      opacity: 0.5;
      ${media.medium} {
        font-size: 13px;
      }
      ${media.extraLarge} {
        font-size: 15px;
      }
    }
  }
`;

export const SecondPanel = styled.div`
  background-color: #dedede;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #727272;
  ${media.extraLarge} {
    padding: 0px 250px;
  }
  img {
    width: 80%;
    height: 100%;
    ${media.extraLarge} {
      width: 50%;
      height: 50%;
    }
  }
  .title {
    font-family: Montserrat-Light !important;
    font-size: 30px;
    text-align: center;
    padding: 10px;
    ${media.large} {
      font-size: 50px;
    }
    ${media.extraLarge} {
      font-size: 60px;
    }
  }
  .message {
    font-family: Montserrat-Light !important;
    font-size: 15px;
    text-align: center;
    padding: 15px 100px;
    opacity: 0.5;
    ${media.extraLarge} {
      font-size: 25px;
      padding: 15px 150px;
      width: 80%;
    }
  }
  .mb-5 {
    margin-bottom: 30px;
  }
`;

export const ThirdPanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #727272;
  margin-top: 50px;
  ${media.extraLarge} {
    padding: 100px 150px 0px 150px;
  }
  img {
    width: 100%;
    height: 100%;
    ${media.extraLarge} {
      width: 50%;
      height: 50%;
    }
  }
  .title {
    font-family: Montserrat-Light !important;
    font-size: 30px;
    text-align: center;
    padding: 10px;
    ${media.large} {
      font-size: 50px;
    }
    ${media.extraLarge} {
      font-size: 60px;
    }
  }
  .message {
    font-family: Montserrat-Light !important;
    font-size: 15px;
    text-align: center;
    padding: 50px 20p x;
    opacity: 0.5;
    ${media.extraLarge} {
      font-size: 25px;
      padding: 50px 150px;
      width: 80%;
    }
  }
  button {
    font-family: Montserrat-Light !important;
    margin: 20px 0px 50px 0px;
  }
`;
