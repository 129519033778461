import styled from 'styled-components/macro';

const Container = styled.div`
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const SidePanelHeaderStyled = { Container };
