import axios from 'axios';
import { UPDATE_USER_INFO_LOADING, UPDATE_USER_INFO_ERROR, UPDATE_USER_INFO_SUCCESS } from '../actionTypes';

export const isLoading = (bool: boolean) => {
  return {
    type: UPDATE_USER_INFO_LOADING,
    isLoading: bool,
  };
};

export const hasError = () => {
  return {
    type: UPDATE_USER_INFO_ERROR,
    hasError: true,
  };
};
export const isSuccess = newUserObj => {
  localStorage.setItem('loggedInUser', JSON.stringify(newUserObj));
  return {
    type: UPDATE_USER_INFO_SUCCESS,
    newUserObj: newUserObj,
  };
};

export const updateUserInfo = formValues => {
  return async dispatch => {
    dispatch(isLoading(true));
    return await axios.put(process.env.REACT_APP_API_URL + `/users/${formValues.id}`, formValues).then(
      response => {
        dispatch(isSuccess(response.data.user));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
};
