import React from 'react';
import { DecorationStyled } from '../../CommonComponents/Decoration/Decoration.style';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';

const { DefaultBackground } = DecorationStyled;

export default function SideBar() {
  return (
    <DefaultBackground>
      <div className="h-100 d-flex flex-column justify-content-between mx-2 py-3">
        <IconContainer display="flex" justifyContent="center" alignItems="center" iconName="logo" iconWidth="40px" />
        <IconContainer
          display="flex"
          justifyContent="center"
          onClick={() => window.close()}
          alignItems="center"
          iconName="back"
          iconWidth="40px"
          iconFill="white"
        />
      </div>
    </DefaultBackground>
  );
}
