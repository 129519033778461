import axios from 'axios';
import { FETCH_GROUPS_ERROR, FETCH_GROUPS_LOADING, FETCH_GROUPS_SUCCESS } from '../actionTypes';

export function getAllChannelListError(bool: boolean) {
  return {
    type: FETCH_GROUPS_ERROR,
    hasError: bool,
  };
}

export function getAllChannelListLoading(bool: boolean) {
  return {
    type: FETCH_GROUPS_LOADING,
    isLoading: bool,
  };
}

export function getAllChannelListSuccess(payload) {
  return {
    type: FETCH_GROUPS_SUCCESS,
    payload: payload,
  };
}

export function getAllChannelList(id: string) {
  return async dispatch => {
    dispatch(getAllChannelListLoading(true));
    return axios
      .get(process.env.REACT_APP_API_URL + `/conversations`, {
        params: {
          ownerId: id,
        },
      })
      .then(
        response => {
          dispatch(getAllChannelListSuccess(response.data.conversations));
        },
        err => {
          dispatch(getAllChannelListError(true));
        }
      );
  };
}
