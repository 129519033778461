import axios from 'axios';
import { ADD_USER_ERROR, ADD_USER_LOADING, ADD_USER_SUCCESS } from '../actionTypes';

export function addUserError(bool: boolean, errMessage: string) {
  return {
    type: ADD_USER_ERROR,
    hasError: bool,
    errMessage: errMessage,
  };
}

export function addUserLoading(bool: boolean) {
  return {
    type: ADD_USER_LOADING,
    isLoading: bool,
  };
}

export function addUserSuccess() {
  return {
    type: ADD_USER_SUCCESS,
  };
}

export function addUser(formData) {
  return async dispatch => {
    dispatch(addUserLoading(true));
    return await axios.post(process.env.REACT_APP_API_URL + '/friend', formData).then(
      response => {
        dispatch(addUserSuccess());
      },
      err => {
        dispatch(addUserError(true, err.response.data));
      }
    );
  };
}
