import axios from 'axios';
import {
  UPDATE_USER_NOTIFICATION_CONFIG_ERROR,
  UPDATE_USER_NOTIFICATION_CONFIG_LOADING,
  UPDATE_USER_NOTIFICATION_CONFIG_SUCCESS,
} from '../actionTypes';

export function hasError() {
  return {
    type: UPDATE_USER_NOTIFICATION_CONFIG_ERROR,
  };
}

export function isLoading() {
  return {
    type: UPDATE_USER_NOTIFICATION_CONFIG_LOADING,
  };
}

export function isSuccess(payload) {
  localStorage.setItem('userSettings', JSON.stringify(payload));
  return {
    type: UPDATE_USER_NOTIFICATION_CONFIG_SUCCESS,
    payload: payload,
  };
}

export function updateUserNotificationConfig(requestBody, userId: string) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios.put(process.env.REACT_APP_API_URL + `/users/settings/${userId}`, requestBody).then(
      response => {
        dispatch(isSuccess(response.data.userSettings));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
}
