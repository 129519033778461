export function timeout(ms) {
  return new Promise(res => setTimeout(res, ms));
}

export const getDifferenceDurationBetweenDate = (date1, date2) => {
  const dateFrom: any = new Date(date1);
  const dateTo: any = new Date(date2);
  const diffMs = Math.abs(dateFrom - dateTo);
  const diffDays = Math.floor(diffMs / 86400000);
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  const diffMins = Math.abs((dateTo.getTime() - dateFrom.getTime()) / 1000 / 60);
  const diffSecs = Math.abs(dateFrom.getTime() - dateTo.getTime()) / 1000;

  return { diffDays, diffHrs, diffMins, diffSecs };
};

export const getWeekDay = (index: number) => {
  const weekDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
  return weekDay[index];
};

export const getMonth = (index: number) => {
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  return month[index];
};

export const addnth = (date: number) => {
  if (date > 3 && date < 21) return date + 'th';
  switch (date % 10) {
    case 1:
      return date + 'st';
    case 2:
      return date + 'nd';
    case 3:
      return date + 'rd';
    default:
      return date + 'th';
  }
};
