import styled from 'styled-components/macro';

const media = {
  extraSmall: '@media(max-width: 600px)',
  small: '@media(min-width: 600px)',
  medium: '@media(min-width: 768px)',
  large: '@media(min-width: 992px)',
  extraLarge: '@media(min-width: 1200px)',
};

export const Container = styled.div`
  font-family: Montserrat-Light !important;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .title {
    font-family: Montserrat-Light !important;
    font-size: 30px;
    color: #727272;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .message {
    font-family: Montserrat-Light !important;
    font-size: 20px;
    color: #b2b2b2;
    text-align: center;
    margin-bottom: 30px;
  }
  .card-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 70%;
    padding: 50px 0px;
    ${media.large} {
      flex-direction: row;
    }
  }
`;

export const FreeCard = styled.div`
  height: 550px;
  border-radius: 25px;
  box-shadow: 0 3px 6px #00000045;
  width: 350px;
  ${media.medium} {
    width: 420px;
  }
  padding: 10px;
  margin: 30px;
  .card-header {
    font-family: Montserrat-Light !important;
    color: white;
    background-color: #3091b1;
    border-radius: 15px !important;
    padding: 20px;
    width: 100%;
    text-align: center;
    font-size: 25px;
  }
  .card-body {
    font-family: Montserrat-Light !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #727272;
    padding: 10px;
    .title {
      font-size: 60px;
    }
    .items {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
`;

export const StandardCard = styled.div`
  height: 550px;
  border-radius: 25px;
  box-shadow: 0 3px 6px #00000045;
  width: 350px;
  padding: 10px;
  margin: 30px;
  ${media.medium} {
    width: 420px;
  }
  .card-header {
    font-family: Montserrat-Light !important;
    color: white;
    background-color: #3091b1;
    border-radius: 15px !important;
    padding: 20px;
    width: 100%;
    text-align: center;
    font-size: 25px;
  }
  .card-body {
    font-family: Montserrat-Light !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #727272;
    padding: 10px;
    .annual-price {
      font-family: Montserrat-Light !important;
      display: flex;
      .price {
        font-family: Montserrat-Light !important;
        display: flex;
        .amount {
          font-family: Montserrat-Light !important;
          font-size: 100px;
          position: relative;
        }
        .amount::before {
          font-family: Montserrat-Light !important;
          content: '¥';
          position: absolute;
          font-size: 35px;
          left: 0;
          top: 0;
          transform: translate(-20px, 0%);
          opacity: 0.5;
        }
        .sub-amount {
          font-family: Montserrat-Light !important;
          display: flex;
          flex-direction: column;
          .decimal {
            font-family: Montserrat-Light !important;
            font-size: 50px;
            margin-top: 20px;
          }
          .per-month {
            font-family: Montserrat-Light !important;
            font-size: 15px;
          }
        }
        .vertifical-line-with-message {
          font-family: Montserrat-Light !important;
          border-left: 1px solid #727272;
          height: 70%;
          padding-left: 10px;
          margin-left: 10px;
          align-self: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
    .title {
      font-family: Montserrat-Light !important;
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .items {
      font-family: Montserrat-Light !important;
      font-size: 15px;
      margin-bottom: 5px;
    }
  }
`;
