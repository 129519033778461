import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function IosPlaystore({
  style = {},
  fill = '#fff',
  stroke = '#707070',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 145.297 42',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="141.474" height="42" transform="translate(-0.365 -0.105)" fill={fill} stroke={stroke} stroke-width="1" />
        </clipPath>
      </defs>
      <g transform="translate(0.365 0.105)" clip-path="url(#clip-path)">
        <g transform="translate(-0.401 2.162)">
          <g transform="translate(0 0)">
            <g>
              <g>
                <g>
                  <path
                    d="M140.959,109.274a7.51,7.51,0,0,1,3.548-6.28,7.74,7.74,0,0,0-6.034-3.264c-2.524-.266-4.99,1.518-6.28,1.518-1.309,0-3.32-1.48-5.446-1.442a8,8,0,0,0-6.755,4.117c-2.922,5.047-.74,12.485,2.049,16.583,1.4,1.992,3.036,4.231,5.18,4.155,2.087-.076,2.884-1.328,5.408-1.328,2.5,0,3.245,1.328,5.427,1.29,2.258-.038,3.662-2.011,5.028-4.022a17.219,17.219,0,0,0,2.3-4.668,7.262,7.262,0,0,1-4.421-6.66"
                    transform="translate(-118.605 -91.78)"
                    fill="#fff"
                  />
                  <path
                    d="M141.569,94.244a7.4,7.4,0,0,0,1.689-5.275,7.464,7.464,0,0,0-4.838,2.5,7.042,7.042,0,0,0-1.727,5.085,6.171,6.171,0,0,0,4.876-2.315"
                    transform="translate(-123.333 -88.969)"
                    fill="#fff"
                  />
                </g>
                <g transform="translate(38.216 15.198)">
                  <path
                    d="M100.284,93.628H97.362L95.75,88.581H90.171l-1.518,5.047H85.807l5.521-17.152h3.415l5.54,17.152Zm-5.009-7.153L93.833,82c-.152-.474-.436-1.537-.854-3.226h-.057c-.171.74-.436,1.8-.816,3.226l-1.423,4.478Z"
                    transform="translate(-85.807 -76.229)"
                    fill="#fff"
                  />
                  <path
                    d="M120.4,88.907a6.973,6.973,0,0,1-1.727,4.99,4.983,4.983,0,0,1-3.814,1.632,3.845,3.845,0,0,1-3.529-1.746v6.489h-2.751V86.952c0-1.328-.038-2.675-.095-4.06h2.41l.152,1.973h.038a4.881,4.881,0,0,1,7.817-.493,6.515,6.515,0,0,1,1.5,4.535ZM117.607,89a5.023,5.023,0,0,0-.816-2.979,2.81,2.81,0,0,0-2.391-1.214,2.925,2.925,0,0,0-1.84.664,3.174,3.174,0,0,0-1.082,1.765,4.135,4.135,0,0,0-.133.835V90.14a3.3,3.3,0,0,0,.835,2.277,2.985,2.985,0,0,0,4.554-.266,5.214,5.214,0,0,0,.873-3.15Z"
                    transform="translate(-91.745 -77.845)"
                    fill="#fff"
                  />
                  <path
                    d="M139.675,88.907a6.973,6.973,0,0,1-1.727,4.99,4.983,4.983,0,0,1-3.814,1.632,3.845,3.845,0,0,1-3.529-1.746v6.489h-2.751V86.952c0-1.328-.038-2.675-.095-4.06h2.41l.152,1.973h.038a4.881,4.881,0,0,1,7.817-.493,6.606,6.606,0,0,1,1.5,4.535ZM136.867,89a5.023,5.023,0,0,0-.816-2.979,2.787,2.787,0,0,0-2.391-1.214,2.925,2.925,0,0,0-1.84.664,3.174,3.174,0,0,0-1.082,1.765,4.134,4.134,0,0,0-.133.835V90.14a3.38,3.38,0,0,0,.816,2.277,2.985,2.985,0,0,0,4.554-.266,4.971,4.971,0,0,0,.892-3.15Z"
                    transform="translate(-96.794 -77.845)"
                    fill="#fff"
                  />
                  <path
                    d="M161.675,88.74a4.6,4.6,0,0,1-1.518,3.567,6.734,6.734,0,0,1-4.668,1.5,8.266,8.266,0,0,1-4.44-1.063l.645-2.3a7.761,7.761,0,0,0,3.966,1.063,3.65,3.65,0,0,0,2.41-.7,2.286,2.286,0,0,0,.873-1.878,2.42,2.42,0,0,0-.7-1.746,6.855,6.855,0,0,0-2.372-1.328c-3.036-1.119-4.535-2.77-4.535-4.914a4.309,4.309,0,0,1,1.594-3.453,6.339,6.339,0,0,1,4.212-1.347,8.205,8.205,0,0,1,3.89.816l-.7,2.239a6.746,6.746,0,0,0-3.3-.8,3.473,3.473,0,0,0-2.277.7,2.131,2.131,0,0,0,.114,3.226,9.019,9.019,0,0,0,2.5,1.328,8.2,8.2,0,0,1,3.245,2.087,4.33,4.33,0,0,1,1.063,3"
                    transform="translate(-102.894 -76.142)"
                    fill="#fff"
                  />
                  <path
                    d="M175.118,83.925h-3.036v6c0,1.518.531,2.3,1.613,2.3a4.386,4.386,0,0,0,1.214-.133l.076,2.087a6.6,6.6,0,0,1-2.144.3,3.259,3.259,0,0,1-2.542-1.006,4.917,4.917,0,0,1-.911-3.339V83.887H167.6V81.838h1.8V79.58l2.694-.816v3.074h3.036v2.087"
                    transform="translate(-107.229 -76.828)"
                    fill="#fff"
                  />
                  <path
                    d="M191.965,88.957a6.844,6.844,0,0,1-1.632,4.687,5.842,5.842,0,0,1-4.535,1.878,5.6,5.6,0,0,1-4.345-1.8,6.523,6.523,0,0,1-1.613-4.554,6.674,6.674,0,0,1,1.67-4.706,5.833,5.833,0,0,1,4.5-1.84,5.707,5.707,0,0,1,4.383,1.8,6.6,6.6,0,0,1,1.575,4.535Zm-2.846.057a5.526,5.526,0,0,0-.74-2.922,2.843,2.843,0,0,0-4.99,0,5.641,5.641,0,0,0-.74,2.979,5.525,5.525,0,0,0,.74,2.922,2.769,2.769,0,0,0,2.5,1.48,2.737,2.737,0,0,0,2.467-1.5A5.589,5.589,0,0,0,189.119,89.014Z"
                    transform="translate(-110.434 -77.838)"
                    fill="#fff"
                  />
                  <path
                    d="M206.025,85.3a5.981,5.981,0,0,0-.873-.076,2.559,2.559,0,0,0-2.239,1.1,4.143,4.143,0,0,0-.683,2.448v6.489h-2.751V86.775c0-1.423-.019-2.732-.076-3.89h2.391l.095,2.372h.076a4.219,4.219,0,0,1,1.366-1.973,3.351,3.351,0,0,1,1.992-.664c.247,0,.493.019.683.038l.019,2.637"
                    transform="translate(-115.557 -77.838)"
                    fill="#fff"
                  />
                  <path
                    d="M221.141,88.443a7.241,7.241,0,0,1-.095,1.233h-8.254a3.658,3.658,0,0,0,1.2,2.808,4.129,4.129,0,0,0,2.694.854,9.014,9.014,0,0,0,3.339-.588l.436,1.9a10.317,10.317,0,0,1-4.136.759,6.01,6.01,0,0,1-4.516-1.689,6.3,6.3,0,0,1-1.632-4.535,7.041,7.041,0,0,1,1.537-4.649,5.246,5.246,0,0,1,4.326-1.992,4.583,4.583,0,0,1,4.041,1.992,7.154,7.154,0,0,1,1.063,3.909Zm-2.618-.7a3.785,3.785,0,0,0-.531-2.106,2.423,2.423,0,0,0-2.182-1.157,2.615,2.615,0,0,0-2.182,1.119,4.045,4.045,0,0,0-.816,2.144Z"
                    transform="translate(-118.378 -77.818)"
                    fill="#fff"
                  />
                </g>
                <g transform="translate(39.202 0.436)">
                  <path
                    d="M91.984,97.831H90.542l-.8-2.467H86.993l-.759,2.467h-1.4l2.713-8.424h1.67ZM89.517,94.3l-.7-2.2c-.076-.228-.209-.759-.436-1.594H88.36c-.095.361-.209.873-.4,1.594l-.7,2.2Z"
                    transform="translate(-84.83 -88.951)"
                    fill="#fff"
                  />
                  <path
                    d="M101.651,92.568l-2.3,6.091H98.046l-2.239-6.091h1.442l1.044,3.226c.171.531.323,1.044.436,1.537h.038c.095-.436.266-.949.436-1.537l1.025-3.226h1.423"
                    transform="translate(-87.705 -89.779)"
                    fill="#fff"
                  />
                  <path
                    d="M109.017,98.625l-.095-.7h-.038a2.1,2.1,0,0,1-1.784.835,1.755,1.755,0,0,1-1.859-1.784c0-1.5,1.309-2.277,3.548-2.277v-.114c0-.8-.436-1.2-1.271-1.2a2.849,2.849,0,0,0-1.594.455l-.285-.873a3.96,3.96,0,0,1,2.087-.531c1.594,0,2.391.835,2.391,2.524V97.2a7.354,7.354,0,0,0,.095,1.442l-1.2-.019Zm-.19-3.036c-1.5,0-2.258.36-2.258,1.233a.854.854,0,0,0,.93.949,1.3,1.3,0,0,0,1.328-1.233Z"
                    transform="translate(-90.176 -89.745)"
                    fill="#fff"
                  />
                  <path
                    d="M115.678,90.633a.807.807,0,1,1,.038-1.613.8.8,0,0,1,.835.8.824.824,0,0,1-.873.816Zm-.645,1.006h1.347v6.072h-1.347Z"
                    transform="translate(-92.701 -88.85)"
                    fill="#fff"
                  />
                  <path d="M120.151,88.789H121.5V97.65h-1.347Z" transform="translate(-94.081 -88.789)" fill="#fff" />
                  <path
                    d="M128.425,98.618l-.095-.7h-.038a2.105,2.105,0,0,1-1.784.835,1.755,1.755,0,0,1-1.859-1.784c0-1.5,1.309-2.277,3.548-2.277v-.114c0-.8-.436-1.2-1.271-1.2a2.849,2.849,0,0,0-1.594.455l-.285-.892a3.96,3.96,0,0,1,2.087-.531c1.594,0,2.391.835,2.391,2.524v2.239a9.653,9.653,0,0,0,.076,1.442Zm-.19-3.036c-1.5,0-2.258.36-2.258,1.233a.854.854,0,0,0,.93.949,1.3,1.3,0,0,0,1.328-1.233Z"
                    transform="translate(-95.259 -89.738)"
                    fill="#fff"
                  />
                  <path
                    d="M137.586,97.809a2.074,2.074,0,0,1-1.916-1.082h-.019l-.076.949h-1.157c.038-.493.038-1.044.038-1.632V88.815h1.366V92.5h.019a2.091,2.091,0,0,1,1.935-1.006c1.461,0,2.486,1.252,2.486,3.074C140.281,96.423,139.142,97.809,137.586,97.809Zm-.266-5.294a1.539,1.539,0,0,0-1.48,1.613V95.19a1.487,1.487,0,0,0,1.461,1.518c1.006,0,1.613-.835,1.613-2.144C138.9,93.369,138.269,92.515,137.321,92.515Z"
                    transform="translate(-97.817 -88.796)"
                    fill="#fff"
                  />
                  <path d="M145.06,88.789h1.347V97.65H145.06Z" transform="translate(-100.604 -88.789)" fill="#fff" />
                  <path
                    d="M154.928,95.9h-4.06a1.744,1.744,0,0,0,1.916,1.8,4.391,4.391,0,0,0,1.632-.285l.209.949a4.732,4.732,0,0,1-2.03.379,2.831,2.831,0,0,1-3.036-3.074,2.974,2.974,0,0,1,2.884-3.264c1.537,0,2.524,1.157,2.524,2.884a1.269,1.269,0,0,1-.038.607Zm-1.214-.968c0-.949-.474-1.613-1.328-1.613a1.6,1.6,0,0,0-1.48,1.613Z"
                    transform="translate(-101.782 -89.738)"
                    fill="#fff"
                  />
                  <path
                    d="M166.9,98.744a2.887,2.887,0,0,1-2.922-3.131,2.971,2.971,0,0,1,3.036-3.226,2.872,2.872,0,0,1,2.922,3.112A2.987,2.987,0,0,1,166.9,98.744Zm.057-5.351c-.968,0-1.613.911-1.613,2.182,0,1.252.645,2.163,1.594,2.163s1.594-.968,1.594-2.2-.626-2.144-1.575-2.144Z"
                    transform="translate(-105.559 -89.732)"
                    fill="#fff"
                  />
                  <path
                    d="M179.986,98.618h-1.347V95.127c0-1.081-.417-1.613-1.233-1.613a1.4,1.4,0,0,0-1.347,1.48V98.6h-1.347V94.273c0-.531-.019-1.119-.038-1.746h1.176l.057.949h.038a2.194,2.194,0,0,1,1.935-1.063c1.271,0,2.106.968,2.106,2.561v3.643"
                    transform="translate(-108.36 -89.738)"
                    fill="#fff"
                  />
                  <path
                    d="M192.551,93.054h-1.48v2.941c0,.759.266,1.119.8,1.119a2.873,2.873,0,0,0,.607-.057l.038,1.025a3.114,3.114,0,0,1-1.044.152c-1.063,0-1.689-.588-1.689-2.125V93.035h-.892V92.029h.892V90.91l1.328-.4v1.518h1.48l-.038,1.025"
                    transform="translate(-112.083 -89.24)"
                    fill="#fff"
                  />
                  <path
                    d="M201.67,97.676h-1.347V94.2c0-1.1-.417-1.632-1.233-1.632a1.321,1.321,0,0,0-1.347,1.442v3.662H196.4V88.815h1.347v3.643h.019a2.056,2.056,0,0,1,1.821-.987c1.29,0,2.087,1.006,2.087,2.58v3.624"
                    transform="translate(-114.049 -88.796)"
                    fill="#fff"
                  />
                  <path
                    d="M210.993,95.9h-4.06a1.744,1.744,0,0,0,1.916,1.8,4.391,4.391,0,0,0,1.632-.285l.209.949a4.732,4.732,0,0,1-2.03.379,2.831,2.831,0,0,1-3.036-3.074,2.974,2.974,0,0,1,2.884-3.264c1.537,0,2.524,1.157,2.524,2.884A2.046,2.046,0,0,1,210.993,95.9Zm-1.233-.968c0-.949-.474-1.613-1.328-1.613a1.6,1.6,0,0,0-1.48,1.613Z"
                    transform="translate(-116.466 -89.738)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
