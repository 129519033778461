import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import { isNotEmptyArray } from '../../utils/arrays';
import { MessageData } from '../interfaces/message';

const INITIAL_PAGE_SIZE = 20;

export default function useGetAllMessagesByConversationId(conversationId) {
  const [nextPage, setNextPage] = useState(INITIAL_PAGE_SIZE);
  const [messages, setMessages] = useState<Array<MessageData | any>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPageAlready, toggleLastPageAlready] = useState(false);
  const [totalDocumentSize, setTotalDocumentSize] = useState(0);

  useEffect(() => {
    setMessages([]);
    setNextPage(INITIAL_PAGE_SIZE);
    toggleLastPageAlready(false);
    setTotalDocumentSize(0);
  }, [conversationId]);

  useEffect(() => {
    if (conversationId) {
      db.collection(collections.messages)
        .where('conversationId', '==', conversationId)
        .where('status.isDeleted', '==', false)
        .get()
        .then(query => {
          setTotalDocumentSize(query.size - 1);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    if (conversationId && !isLastPageAlready) {
      setIsLoading(nextPage > INITIAL_PAGE_SIZE ? false : true);
      const unsubscribe = db
        .collection(collections.messages)
        .where('conversationId', '==', conversationId)
        .where('status.isDeleted', '==', false)
        .orderBy('dateCreated', 'desc')
        .limit(nextPage)
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            const sizeOfFetchedDocuments = snapshot.docs.length - 1;
            if (totalDocumentSize === sizeOfFetchedDocuments) {
              toggleLastPageAlready(true);
            }
            setMessages(snapshot.docs.map(message => message.data()));
          } else {
            setMessages([]);
          }
          setIsLoading(false);
        });
      return () => {
        unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, nextPage]);

  return { messages, isLoading, nextPage, setNextPage };
}
