import styled from 'styled-components/macro';
import { fadeIn } from '../../../CommonComponents/Animation';

const Container = styled.div`
  display: flex;
  background-color: #ffffff;
  animation: ${fadeIn} ease-in-out 300ms;
  border-radius: 15px;
  align-items: center;
  height: 25px;
  justify-content: space-around;
  transform: translateY(-100%);
`;

const OptionContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  border-radius: 10px;
  width: 200px;
  background-color: white;
  padding: 5px;
  border: solid 1px #cccccc;
  transform: translate(-180px, 10px);
`;

const OptionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100&;
  padding: 5px 0px;
  background-color: white;
  transition: 300ms;
  :hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
`;

const OptionDivider = styled.div`
  width: 100&;
  border-width: 1px;
  border-color: #cccccc;
  border-style: solid;
`;

export const ThreadOptionStyled = { Container, OptionContainer, OptionItem, OptionDivider };
