import axios from 'axios';
import { INVITE_FRIENDS_ERROR, INVITE_FRIENDS_LOADING, INVITE_FRIENDS_SUCCESS } from '../actionTypes';

export function hasError() {
  return {
    type: INVITE_FRIENDS_ERROR,
  };
}

export function isLoading() {
  return {
    type: INVITE_FRIENDS_LOADING,
  };
}

export function isSuccess(payload) {
  return {
    type: INVITE_FRIENDS_SUCCESS,
    payload: payload,
  };
}

export function inviteFriendInGroup(requestBody, groupId: string) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios.post(process.env.REACT_APP_API_URL + `/conversations/members/${groupId}`, requestBody).then(
      response => {
        dispatch(isSuccess(response.data.members));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
}
