import {
  SET_ACTIVE_CONVERSATION_ID,
  SET_CONVERSATION_MESSAGES,
  GET_CONVERSATION_MESSAGES_ERROR,
  GET_CONVERSATION_MESSAGES_LOADING,
  GET_CONVERSATION_MESSAGES_SUCCESS,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_LOADING,
  SEND_MESSAGE_SUCCESS,
  CLEAR_ALL_MESSAGES,
  SEARCH_MESSAGES_ERROR,
  SEARCH_MESSAGES_LOADING,
  SEARCH_MESSAGES_SUCCESS,
  ADD_MESSAGE_TO_CONVERSATION_MESSAGES,
  TOGGLE_IS_FILTERED,
  TOGGLE_PREVIEW_FILE,
  SEND_FILE_ERROR,
  SEND_FILE_LOADING,
  SEND_FILE_SUCCESS,
  DELETE_FILE_ERROR,
  DELETE_FILE_LOADING,
  DELETE_FILE_SUCCESS,
  UPDATE_ACTIVE_CONVERSATION,
} from '../actions/actionTypes';

const initialState = {
  getConversationMessagesIsLoading: false,
  getConversationMessagesHasError: false,
  activeConversationId: '',
  conversationMessages: [],
  createdMessageId: '',
  sendMessageToConversationIsLoading: false,
  sendMessageToConversationHasError: false,
  isFiltered: false,
  isPreviewFile: false,
  sendFileIsLoading: false,
  sendFileHasError: false,
  deleteFileIsLoading: false,
  deleteFileHasError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_CONVERSATION_ID:
      return {
        ...state,
        activeConversationId: action.conversationId,
      };
    case ADD_MESSAGE_TO_CONVERSATION_MESSAGES:
      let tempMessages: any = [...state.conversationMessages];
      tempMessages.unshift(action.tempMessage);
      return {
        ...state,
        conversationMessages: tempMessages,
      };
    case SET_CONVERSATION_MESSAGES:
      return {
        ...state,
        conversationMessages: action.conversationMessages,
      };
    case GET_CONVERSATION_MESSAGES_ERROR:
      return {
        ...state,
        getConversationMessagesHasError: action.hasError,
        getConversationMessagesIsLoading: false,
      };
    case GET_CONVERSATION_MESSAGES_LOADING:
      return {
        ...state,
        getConversationMessagesIsLoading: action.isLoading,
      };
    case GET_CONVERSATION_MESSAGES_SUCCESS:
      return {
        ...state,
        getConversationMessagesIsLoading: false,
        conversationMessages: action.messages,
      };
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendMessageHasError: action.hasError,
      };
    case SEND_MESSAGE_LOADING:
      return {
        ...state,
        sendMessageIsLoading: action.isLoading,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        createdMessageId: action.message.id,
        sendMessageIsLoading: false,
      };
    case SEND_FILE_ERROR:
      return {
        ...state,
        sendFileIsLoading: false,
        sendFileHasError: true,
      };
    case SEND_FILE_LOADING:
      return {
        ...state,
        sendFileIsLoading: true,
      };
    case SEND_FILE_SUCCESS:
      return {
        ...state,
        sendFileIsLoading: false,
      };
    case DELETE_FILE_ERROR:
      return {
        ...state,
        deleteFileIsLoading: false,
        deleteFileHasError: true,
      };
    case DELETE_FILE_LOADING:
      return {
        ...state,
        deleteFileIsLoading: true,
      };
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        deleteFileIsLoading: false,
      };
    case SEARCH_MESSAGES_ERROR:
      return {
        ...state,
        getConversationMessagesHasError: action.hasError,
      };
    case SEARCH_MESSAGES_LOADING:
      return {
        ...state,
        getConversationMessagesIsLoading: action.isLoading,
        isFiltered: true,
      };
    case SEARCH_MESSAGES_SUCCESS:
      return {
        ...state,
        conversationMessages: action.messages,
        getConversationMessagesIsLoading: false,
        isFiltered: true,
      };
    case TOGGLE_IS_FILTERED:
      return {
        ...state,
        isFiltered: action.toggle,
      };
    case TOGGLE_PREVIEW_FILE:
      return {
        ...state,
        isPreviewFile: action.toggle,
      };
    case UPDATE_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversation: action.conversation,
      };
    case CLEAR_ALL_MESSAGES:
      return initialState;
    default:
      return state;
  }
};
