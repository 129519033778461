import React, { FC, ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchMessageStyled } from './styled';
import { searchMessages } from '../../actions/searchMessages';
import { toggleIsFiltered } from '../../actions';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';
import { ChannelData } from '../../../Common/interfaces/channel';
import useGetActiveConversation from '../../../Common/customHooks/useGetActiveConversation';

const { SearchInputContainer, SearchInput } = SearchMessageStyled;

const SearchMessage: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const activeConversation: ChannelData = useGetActiveConversation();
  const getConversationMessagesIsLoading: boolean = useSelector(state => state.conversationReducer.getConversationMessagesIsLoading);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (activeConversation) {
      dispatch(toggleIsFiltered(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation]);

  const handleChange = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchInput && activeConversation) {
      dispatch(searchMessages(activeConversation.id, searchInput));
    } else {
      dispatch(toggleIsFiltered(false));
    }
  };

  const handleClearSearchInput = () => setSearchInput('');

  return (
    <SearchInputContainer>
      {searchInput && (
        <IconContainer margin="0px 5px" onClick={handleClearSearchInput} iconName="close" iconWidth="20px" opacityOnMouseEnter={0.5} />
      )}
      <SearchInput
        type="text"
        placeholder="Search text here"
        value={searchInput}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        disabled={getConversationMessagesIsLoading}
      />
    </SearchInputContainer>
  );
};

export default SearchMessage;
