import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Attach({
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  viewBox = '0 0 25 25',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath>
          <circle cx="9.5" cy="9.5" r="9.5" fill={fill} stroke="#707070" strokeWidth="1" />
        </clipPath>
      </defs>
      <g fill={fill} stroke="#cacaca" strokeWidth="1">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
        <circle cx="12.5" cy="12.5" r="12" fill="none" />
      </g>
      <g transform="translate(3 3)">
        <g transform="translate(3.115 1.52)">
          <g transform="translate(0 0)">
            <path
              d="M24.548,18.377c.289.2.742.522.867.61a.036.036,0,0,1,.008.056l-.132.193S21.984,24,20.3,26.358a4.221,4.221,0,0,1-5.192,1.44,4.134,4.134,0,0,1-1.629-6.279c1.85-2.72,3.743-5.408,5.653-8.085a3.034,3.034,0,0,1,4.983,3.459c-1.577,2.331-3.206,4.634-4.839,6.929a1.9,1.9,0,0,1-3.113-2.159c1.388-2.034,2.817-4.04,4.249-6.09a.04.04,0,0,1,.056-.012l.867.606a.036.036,0,0,1,.008.056c-.088.124-.177.257-.269.385q-1.92,2.744-3.84,5.493c-.409.59-.169,1.3.465,1.288a1.018,1.018,0,0,0,.722-.361q2.371-3.322,4.686-6.68a1.9,1.9,0,1,0-3.125-2.159q-2.829,4.008-5.621,8.04a3.041,3.041,0,1,0,5,3.463c1.621-2.283,3.218-4.586,4.823-6.881.092-.132.189-.269.3-.425A.049.049,0,0,1,24.548,18.377Z"
              transform="translate(-12.662 -12.21)"
              fill="#444"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
