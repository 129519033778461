import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { Container } from './styled';

const multiPlatformImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fmulti-platform.png?alt=media&token=bc56511c-6bf8-4ded-a4d3-382e28ac3c9e';

export default function Download() {
  const { t, i18n } = useTranslation();
  const redirectToAndroidPlaystore = () => window.open('https://play.google.com/store/apps/details?id=com.xclimb.messaging_app&hl=en');
  const redirectToIOSPlaystore = () => window.open('https://apps.apple.com/us/app/id1480710365');

  return (
    <Container>
      <div className="title-container">
        <p className="title">{t('LagoonIsAvailableInEveryPlatform')}</p>
        <p className="sub-title">{t('CommunicateInEveryPossibleWays')}</p>
      </div>
      <div className="button-download-container">
        <button className="button" onClick={redirectToAndroidPlaystore}>
          <IconContainer iconName="android-playstore" iconWidth="150px" />
        </button>
        <button className="button" onClick={redirectToIOSPlaystore}>
          <IconContainer iconName="ios-playstore" iconWidth="150px" />
        </button>
        <Link to="/">
          <button className="button webapp m-0 p-0">{t('WebApp')}</button>
        </Link>
        <button className="button desktop">
          {i18n.language === 'en' ? (
            <span className="coming-soon">{t('ComingSoonDesktop')}</span>
          ) : (
            <>
              <span className="coming-soon text-nowrap">{t('ComingSoonDesktop.1')}</span>
              <span className="coming-soon">{t('ComingSoonDesktop.2')}</span>
            </>
          )}
        </button>
      </div>
      <div className="img-container">
        <img src={multiPlatformImage} alt="..." />
      </div>
    </Container>
  );
}
