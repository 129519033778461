import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import { isNotEmptyArray } from '../../utils/arrays';

export default function useGetAllThreadsByOwnerId(ownerId) {
  const [threadsPerMember, setthreadsPerMember] = useState<Array<any>>([]);

  useEffect(() => {
    if (ownerId) {
      const unsubscribe = db
        .collection(collections.threadsPerMember)
        .where('threadMemberId', '==', ownerId)
        .orderBy('lastDateUpdated', 'desc')
        .onSnapshot(snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            setthreadsPerMember(
              snapshot.docs.map(thread => {
                return {
                  ...thread.data(),
                  id: thread.id,
                };
              })
            );
          }
          return () => {
            unsubscribe();
          };
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return threadsPerMember;
}
