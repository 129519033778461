import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConversationHeaderOptionStyled } from './styled';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import Modal from '../../../CommonComponents/Modal';
import { useTranslation } from 'react-i18next';
import { FOURTH_PANEL_VIEW } from '../../../Common/enums';
import { changeFourthPanelView } from '../../../Navigation/actions';
import Flex from '../../../CommonComponents/Flex';
import { OptionStyled } from '../../../CommonComponents/Options';
import { ChannelData } from '../../../Common/interfaces/channel';
import { setGroup } from '../../../CreateChannel/actions';
import { UserData } from '../../../Common/interfaces/user';
import { leaveGroup } from '../../../Common/actions';
import { setConvesationMessageList, setActiveConversationId } from '../../actions';
import { updateConversationNotification } from '../../../ChatList/actions';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';
import useGetActiveConversation from '../../../Common/customHooks/useGetActiveConversation';

const { ConversationHeaderModalContainer } = ConversationHeaderOptionStyled;
const { Span } = TypographyStyled;
const { Container, Item, Divider } = OptionStyled;

const ConversationHeaderOption = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const activeConversation: ChannelData = useGetActiveConversation();
  const [isOptionsVisible, setOptionVisibility] = useState(false);
  const [isLeavingGroup, setIsLeavingGroup] = useState(false);
  const [notification, setNotification] = useState(activeConversation && activeConversation.notification);

  useEffect(() => {
    if (activeConversation) {
      setNotification(activeConversation.notification);
    }
  }, [activeConversation]);

  const handleInvite = () => {
    setOptionVisibility(false);
    dispatch(setGroup(activeConversation));
    dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.INVITE_FRIEND));
  };

  const handleLeaveGroup = async () => {
    const requestBody = {
      memberId: loggedInUser.id,
    };
    setIsLeavingGroup(true);
    await leaveGroup(requestBody, activeConversation.id);
    setIsLeavingGroup(false);
    setOptionVisibility(false);
    dispatch(setConvesationMessageList([]));
    dispatch(setActiveConversationId(''));
  };

  const handleNotification = async () => {
    setNotification(toggle => !toggle);
    const requestBody = {
      conversationId: activeConversation.id,
      notification: !notification,
    };
    updateConversationNotification(requestBody, loggedInUser.id);
  };

  return (
    <Flex>
      <IconContainer
        onClick={() => setOptionVisibility(toggle => !toggle)}
        iconName="message-option"
        iconWidth="40px"
        opacityOnMouseEnter={0.5}
      />
      <Modal visible={isOptionsVisible} setModalVisibility={setOptionVisibility} dimmed={false} />
      {isOptionsVisible && (
        <ConversationHeaderModalContainer>
          {activeConversation && activeConversation.isGroup && (
            <Container width="200px" transform="translate(-150px, 15px)">
              <Item onClick={handleInvite}>
                <Span size="lg" fontColor="#414042" fontWeight="bold" padding="0px 3px">
                  {t('Invite')}
                </Span>
              </Item>
              <Divider />
              <Item onClick={handleNotification}>
                <Span size="lg" fontColor="#414042" fontWeight="bold" padding="0px 3px">
                  {`${t('Notification')} ${t(notification ? 'Off' : 'On')}`}
                </Span>
              </Item>
              <Divider />
              <Item onClick={handleLeaveGroup}>
                <Span size="lg" fontColor="#414042" fontWeight="bold" padding="0px 3px">
                  {isLeavingGroup ? 'Leaving' : 'Leave Group'}
                </Span>
              </Item>
            </Container>
          )}

          {activeConversation && activeConversation.isDirectMessage && (
            <Container width="200px" transform="translate(-150px, 15px)">
              <Item onClick={handleInvite}>
                <Span size="lg" fontColor="#414042" fontWeight="bold" padding="0px 3px">
                  {t('Invite')}
                </Span>
              </Item>
              <Divider />
              <Item onClick={handleNotification}>
                <Span size="lg" fontColor="#414042" fontWeight="bold" padding="0px 3px">
                  {`${t('Notification')} ${t(notification ? 'Off' : 'On')}`}
                </Span>
              </Item>
            </Container>
          )}
        </ConversationHeaderModalContainer>
      )}
    </Flex>
  );
};

export default ConversationHeaderOption;
