import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyArray, removeElementInsideArray } from '../utils/arrays';
import ChatMessage from './ChatMessage';
import { ChannelData } from '../Common/interfaces/channel';
import { setActiveConversationId } from '../Conversation/actions';
import { changeThirdPanelView } from '../Navigation/actions';
import { THIRD_PANEL_VIEW } from '../Common/enums';
import useGetAllConversationById from '../Common/customHooks/useGetAllConversationById';
import { getAllConversations } from './actions/getAllConversations';
import SkeletonLoading from '../CommonComponents/Skeleton';
import { updateConversationList } from './actions';

export const ChatList: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const chatListState = useSelector(state => state.chatListReducer);
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const {
    messages,
    isFilteredByName,
    filteredMessages,
  }: { messages: ChannelData[]; isFilteredByName: boolean; filteredMessages: ChannelData[] } = chatListState;
  const threads: any = useSelector(state => state.threadReducer.threads);
  const [chatList, setChatList] = useState<Array<ChannelData>>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const conversations = useGetAllConversationById(loggedInUser.id);

  useEffect(() => {
    const userChatList = localStorage.getItem('userChatList') || '';
    const parsedUserChatList = userChatList && JSON.parse(userChatList);
    if (conversations.length !== messages.length) {
      const requestBody = {
        conversationIds: conversations,
      };
      if (isNotEmptyArray(conversations)) {
        dispatch(getAllConversations(requestBody, loggedInUser.id));
      }
    } else {
      const parsedChatListName = isNotEmptyArray(parsedUserChatList) ? parsedUserChatList.map(chat => chat.name === undefined) : [];
      if (parsedChatListName.includes(true)) {
        const requestBody = {
          conversationIds: parsedChatListName,
        };
        dispatch(getAllConversations(requestBody, loggedInUser.id));
      }
      if (conversations.length !== parsedUserChatList.length) {
        dispatch(updateConversationList(parsedUserChatList));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations]);

  useEffect(() => {
    let numberOfUnreadCount = 0;
    if (isNotEmptyArray(chatList)) {
      const arrayOfUnreadCount = chatList.map(conversation => (conversation.notification ? conversation.unreadCount : 0));
      const modArray = removeElementInsideArray(arrayOfUnreadCount, undefined);
      numberOfUnreadCount = numberOfUnreadCount + modArray.reduce((acc, val) => acc + val, 0);
    }
    if (isNotEmptyArray(threads)) {
      const arrayOfUnreadCount = threads.map(threads => threads.unreadCount);
      const modArray = removeElementInsideArray(arrayOfUnreadCount, undefined);
      numberOfUnreadCount = numberOfUnreadCount + modArray.reduce((acc, val) => acc + val, 0);
    }
    setUnreadCount(numberOfUnreadCount);
  }, [threads, chatList, conversations]);

  useEffect(() => {
    if (unreadCount > 0) {
      window.document.title = `(${unreadCount}) - LAGOON`;
    } else {
      window.document.title = 'LAGOON';
    }
  }, [unreadCount]);

  useEffect(() => {
    if (isNotEmptyArray(messages)) {
      if (isFilteredByName) {
        if (isNotEmptyArray(filteredMessages)) {
          filteredMessages.sort((a, b) => {
            if (a.lastMessage && b.lastMessage) return b.lastMessage.dateCreated - a.lastMessage.dateCreated;
            else return 0;
          });
          setChatList(filteredMessages);
        } else {
          setChatList([]);
        }
      } else {
        messages.sort((a, b) => {
          if (a.lastMessage && b.lastMessage) return b.lastMessage.dateCreated - a.lastMessage.dateCreated;
          else return 0;
        });
        setChatList(messages);
        if (messages.length === 1) {
          const message = { ...messages[0] };
          dispatch(setActiveConversationId(message.id));
          dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
        }
      }
    } else {
      setChatList(messages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isFilteredByName, filteredMessages]);

  return (
    <>
      {isNotEmptyArray(chatList) ? (
        chatList.map((message: ChannelData) => {
          return <ChatMessage key={`${message.id}`} message={message} />;
        })
      ) : (
        <SkeletonLoading count={9} />
      )}
    </>
  );
};

export default ChatList;
