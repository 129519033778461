import React, { useState, useEffect } from 'react';
import { DecorationStyled } from './Decoration.style';
import Icon from '../Icon';

const { IconWrapper } = DecorationStyled;

interface IconContainerProps {
  //container Props
  position?: 'relative' | 'absolute' | 'sticky' | 'fixed' | 'initial';
  display?: 'flex' | 'block';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  opacity?: number;
  opacityOnMouseEnter?: number;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  transform?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  //icon Props
  iconWidth: string;
  iconWidthOnMouseEnter?: string;
  iconHeight?: string;
  iconHeightOnMouseEnter?: string;
  iconName: string;
  iconNameOnMouseEnter?: string;
  iconFill?: string;
  iconFillOnMouseEnter?: string;
  iconBorder?: string;
  iconBorderOnMouseEnter?: string;
  iconStyle?: object;
  iconStyleOnMouseEnter?: object;
  iconStroke?: string;
  iconStrokeOnMouseEnter?: string;
  iconClassname?: string;
  iconViewbox?: string;
  //event Listeners
  onClick?: (e: any) => void;
  //others
  disabled?: boolean;
  children?: any;
  tooltipText?: string;
  id?: string;
  dataToggle?: string;
  dataOffset?: any;
  dataDismiss?: string;
  ariaHasPopUp?: boolean | 'true' | 'false' | 'dialog' | 'menu' | 'listbox' | 'tree' | 'grid' | undefined;
  ariaExpanded?: boolean | 'true' | 'false' | undefined;
}

const IconContainer = (props: IconContainerProps) => {
  const [iconFill, setIconFill] = useState(props.iconFill);
  const [iconStyle, setIconStyle] = useState(props.iconStyle);
  const [iconBorder, setIconBorder] = useState(props.iconBorder);
  const [iconStroke, setIconStroke] = useState(props.iconStroke);
  const [iconName, setIconName] = useState(props.iconName);
  const [iconWidth, setIconWidth] = useState(props.iconWidth);
  const [iconHeight, setIconHeight] = useState(props.iconHeight);
  const [opacity, setOpacity] = useState(props.opacity ? props.opacity : 1);
  const [isOverIcon, toggleOverIcon] = useState(false);

  useEffect(() => {
    handleMouseEnter();
    handleMouseLeave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleMouseEnter = () => {
    toggleOverIcon(true);
    if (props.opacityOnMouseEnter) {
      setOpacity(props.opacityOnMouseEnter);
    }
    if (props.iconWidthOnMouseEnter) {
      setIconWidth(props.iconWidthOnMouseEnter);
    }
    if (props.iconHeightOnMouseEnter) {
      setIconHeight(props.iconHeightOnMouseEnter);
    }
    if (props.iconNameOnMouseEnter) {
      setIconName(props.iconNameOnMouseEnter);
    }
    if (props.iconFillOnMouseEnter) {
      setIconFill(props.iconFillOnMouseEnter);
    }
    if (props.iconBorderOnMouseEnter) {
      setIconBorder(props.iconBorderOnMouseEnter);
    }
    if (props.iconStyleOnMouseEnter) {
      setIconStyle(props.iconStyleOnMouseEnter);
    }
    if (props.iconStrokeOnMouseEnter) {
      setIconStroke(props.iconStrokeOnMouseEnter);
    }
  };

  const handleMouseLeave = () => {
    toggleOverIcon(false);
    if (props.opacity) {
      setOpacity(props.opacity);
    } else {
      setOpacity(1);
    }
    if (props.iconWidth) {
      setIconWidth(props.iconWidth);
    }
    if (props.iconHeight) {
      setIconWidth(props.iconHeight);
    }
    if (props.iconName) {
      setIconName(props.iconName);
    }
    if (props.iconFill) {
      setIconFill(props.iconFill);
    }
    if (props.iconBorder) {
      setIconBorder(props.iconBorder);
    }
    if (props.iconStyle) {
      setIconStyle(props.iconStyle);
    }
    if (props.iconStroke) {
      setIconStroke(props.iconStroke);
    }
  };

  return (
    <IconWrapper
      id={props.id}
      data-toggle={props.dataToggle}
      data-offset={props.dataOffset}
      data-dismiss={props.dataDismiss}
      aria-haspopup={props.ariaHasPopUp}
      aria-expanded={props.ariaExpanded}
      tooltipText={isOverIcon ? props.tooltipText : ''}
      position={props.position}
      display={props.display}
      alignItems={props.alignItems}
      justifyContent={props.justifyContent}
      flexDirection={props.flexDirection}
      opacity={opacity}
      width={props.width}
      height={props.height}
      margin={props.margin}
      padding={props.padding}
      transform={props.transform}
      top={props.top}
      bottom={props.bottom}
      left={props.left}
      right={props.right}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={props.onClick}
    >
      <Icon
        name={iconName}
        width={iconWidth}
        height={iconHeight}
        style={iconStyle}
        fill={iconFill}
        border={iconBorder}
        stroke={iconStroke}
        className={props.iconClassname}
      />
      {props.children}
    </IconWrapper>
  );
};

export default IconContainer;
