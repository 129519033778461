import React, { ReactElement } from 'react';

import { Styled } from './styled';

interface Props {
  width?: number;
  height?: number;
  margin?: number;
  color?: string;
}

const { AnimateParent, Animate } = Styled;

export default function LoadingComponent(props: Props): ReactElement {
  const { color, height, width, margin } = props;
  return (
    <AnimateParent>
      <Animate color={color} margin={margin} height={height} width={width}>
        <Animate color={color} margin={margin} height={height} width={width} interval={1} />
        <Animate color={color} margin={margin} height={height} width={width} interval={2} />
        <Animate color={color} margin={margin} height={height} width={width} interval={3} />
      </Animate>
    </AnimateParent>
  );
}
