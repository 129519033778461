import styled from 'styled-components';
import { Active } from '../Common/interfaces';

const dropDownCustomStyle = {
  container: () => ({
    width: '100%',
    borderRadius: '10px',
    height: '50px',
    margin: '0px 5px 0px 0px',
    border: '1px solid #cacaca',
    fontSize: '20px',
    position: 'relative',
  }),
  control: () => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    border: '1px solid transparent',
  }),
  valueContainer: provided => ({
    ...provided,
    width: '90%',
    padding: '0px 20px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    width: '10%',
  }),
};

const Container = styled.div`
  border-radius: 0px 10px 10px 10px;
  box-shadow: 0px 0px 5px 0px #484848;
  background: white;
  padding: 20px;
  width: 480px;
  height: 310px;
  position: absolute;
  transform: translate(500px, 235px);
  transition: 0.3;
  z-index: 1;
`;

const NotificationSettingText = styled.span`
  padding: 10px 20px 10px 20px;
  font-size: 20px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0px 5px 0px 0px;
`;

const ToggleButton = styled.button`
  width: 55px;
  height: 50px;
  font-size: 20px;
  border-radius: 10px;
  border-style: none;
  outline: none;
  color: white;
  background: ${(props: Active) => (props.isActive ? `linear-gradient(0deg, #50fcc7, #3cc9f8)` : '#CACACA')};
  width: 20%;
  transition: 300ms;
`;

const SoundButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 50px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid #cacaca;
  outline: none;
  background: white;
  width: 20%;
  transition: 300ms;
`;

export const NotificationStyled = {
  Container,
  NotificationSettingText,
  ToggleButton,
  dropDownCustomStyle,
  SoundButton,
};
