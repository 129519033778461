import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Sticker({
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  viewBox = '0 0 792 612',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABFCAYAAAD3upAqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAe
zmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0w
TXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRh
LyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDUgNzkuMTYzNDk5LCAyMDE4LzA4LzEz
LTE2OjQwOjIyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3Jn
LzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0i
IiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnBob3Rvc2hv
cD0iaHR0cDovL25zLmFkb2JlLmNvbS9waG90b3Nob3AvMS4wLyIgeG1sbnM6ZGM9Imh0dHA6Ly9w
dXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNv
bS94YXAvMS4wL21tLyIgeG1sbnM6c3RFdnQ9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9z
VHlwZS9SZXNvdXJjZUV2ZW50IyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFw
LzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnRpZmY9Imh0dHA6Ly9ucy5hZG9iZS5jb20v
dGlmZi8xLjAvIiB4bWxuczpleGlmPSJodHRwOi8vbnMuYWRvYmUuY29tL2V4aWYvMS4wLyIgeG1w
OkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOSAoV2luZG93cykiIHhtcDpDcmVh
dGVEYXRlPSIyMDE5LTA2LTA2VDExOjM3OjIyKzA4OjAwIiB4bXA6TWV0YWRhdGFEYXRlPSIyMDE5
LTA2LTI3VDA4OjE0OjI1KzA4OjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxOS0wNi0yN1QwODoxNDoy
NSswODowMCIgcGhvdG9zaG9wOkNvbG9yTW9kZT0iMyIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHht
cE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6YjUwMDNmMDUtZGJiMC04NTQzLTkwNjItNzI3YWQ1YmFk
NjhlIiB4bXBNTTpEb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6M2ZmNzFhNTAtMzQ5
Mi04ZTQ1LTllMjEtMGRiNjM2ZGYyZjk5IiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5k
aWQ6NjhmYWJlNDktMmJlYi1mNDQzLWExOGEtMDc3YjZiZjgzM2UwIiB0aWZmOk9yaWVudGF0aW9u
PSIxIiB0aWZmOlhSZXNvbHV0aW9uPSI3MjAwMDAvMTAwMDAiIHRpZmY6WVJlc29sdXRpb249Ijcy
MDAwMC8xMDAwMCIgdGlmZjpSZXNvbHV0aW9uVW5pdD0iMiIgZXhpZjpDb2xvclNwYWNlPSI2NTUz
NSIgZXhpZjpQaXhlbFhEaW1lbnNpb249IjcwIiBleGlmOlBpeGVsWURpbWVuc2lvbj0iNjkiPiA8
cGhvdG9zaG9wOkRvY3VtZW50QW5jZXN0b3JzPiA8cmRmOkJhZz4gPHJkZjpsaT4wODIyNkVGQTkz
MUUxMzcxRkVCOEZEN0M0NkEwRTY0ODwvcmRmOmxpPiA8cmRmOmxpPjExOEZCRUYyQjE4MTdBQTdC
NDQ4NjdBNTYyOEQ2RkU2PC9yZGY6bGk+IDxyZGY6bGk+MThCNEI5MTkxQzhDMkVCODk0N0M1MkUw
ODlGOUJFMEM8L3JkZjpsaT4gPHJkZjpsaT4xQjM4OEU3QjYwODcyRTk2OUQxRkI0ODk3OEJEQURF
NDwvcmRmOmxpPiA8cmRmOmxpPjI3NTE0RUJGNDJBMzAyODk3MUIwMENCRjMwQ0E3RjEyPC9yZGY6
bGk+IDxyZGY6bGk+Mjc3M0JEN0ZCM0FDN0VDOERFM0Y3MDM0RTNFNTVENDA8L3JkZjpsaT4gPHJk
ZjpsaT4yNzc2MjJCMDE2NTZBQzkxNzZCMTFGMkVCOUM1NTYwNjwvcmRmOmxpPiA8cmRmOmxpPjMy
MjY4ODlBREU3OERDMDEyN0YyMTIyMUQwRTg1NDVEPC9yZGY6bGk+IDxyZGY6bGk+M0NBNTkwMDNG
OTEyMjFCMzg3RDE5REVFMUZBNjk2QjI8L3JkZjpsaT4gPHJkZjpsaT40QUVDMDIzMTJGNUU4QkM4
ODVDNDQ4QTdFMUU5NDc2RDwvcmRmOmxpPiA8cmRmOmxpPjYyOUNBRENBMDlGMDI5NkEzRjVBMTQz
QTlBRTUxRjdGPC9yZGY6bGk+IDxyZGY6bGk+NjlDM0E0ODJCOUE0NjkzRDkxNkFDMjVBQTZEMDNC
MTc8L3JkZjpsaT4gPHJkZjpsaT43QjkwQkNDMTI2MkU1RjY1MTU5RTlBNzFCRjVBMDAxRjwvcmRm
OmxpPiA8cmRmOmxpPjdFRDg1MTM1ODM4QTA1M0Q1MkNFNTgwRDlFOUFCNTNGPC9yZGY6bGk+IDxy
ZGY6bGk+OTRDOUQ1NkEwQzY4MjQxMjNDREExRTY5QzkzNjgzRUU8L3JkZjpsaT4gPHJkZjpsaT45
REZBMzQ5OTU0MTdEM0VFMUJENDJFNkE3RUQyMTk1QzwvcmRmOmxpPiA8cmRmOmxpPkFGOUM4MUYx
QjdGNEM1QkRFQTQ2RjkxREU2N0MzNUUzPC9yZGY6bGk+IDxyZGY6bGk+Q0UzRjVEMDc3MjM1NDRB
RUY1QUNCQ0NCOTE1NkU5MjQ8L3JkZjpsaT4gPHJkZjpsaT5ENjE1QUQyMDlEQ0EwMUVCN0Q0MUQ3
RjY4RTMyQjc1QTwvcmRmOmxpPiA8cmRmOmxpPkQ4QjNBQTI3NjI3RTFGRjVDRTFCRkM5RkQ5N0E5
NDE5PC9yZGY6bGk+IDxyZGY6bGk+RDkxNDY3Nzg1NjI2QTRGMjAxNzRCQUQ3MUY2QzRFNUM8L3Jk
ZjpsaT4gPHJkZjpsaT5ERjc2RjhDNzcxRDY5QzY5NkMzMUI1OTdGNTkxRkZFOTwvcmRmOmxpPiA8
cmRmOmxpPkU1QjY4OTczOEJCMkQwQjYxRDVGMzFFNTJCMDczNDgwPC9yZGY6bGk+IDxyZGY6bGk+
RjBFNzdBQjcxODkwRjIwOEI3OTdBNDVBMEZBRDE2NzE8L3JkZjpsaT4gPHJkZjpsaT5GQUEwRTg5
OEJBQjI3QjQ1M0E4Qzg3OUZDMjBENTMwNDwvcmRmOmxpPiA8cmRmOmxpPkZBQTZDNkUzMjBDMzkz
QkZGMDFCMzcxMjQ4RjA5QjAyPC9yZGY6bGk+IDxyZGY6bGk+YWRvYmU6ZG9jaWQ6cGhvdG9zaG9w
OjFjNGVmYTMyLTg4ZjUtMTFlOS1hYjFmLWY0ZGNkMzQ3OGNhMzwvcmRmOmxpPiA8cmRmOmxpPmFk
b2JlOmRvY2lkOnBob3Rvc2hvcDoyOTY2ZGI1YS1iZTdiLTk2NDItYmRhZS00NWJjMmZiNDU5NzQ8
L3JkZjpsaT4gPHJkZjpsaT5hZG9iZTpkb2NpZDpwaG90b3Nob3A6MzU5MzMwMmItN2JiMC02NDRj
LWFmNjUtNTdjNDM4ZmE4NmVjPC9yZGY6bGk+IDxyZGY6bGk+YWRvYmU6ZG9jaWQ6cGhvdG9zaG9w
OjM3ZjFiNmZhLTEyMDctYWM0MS04ZGIxLWY3ZTg5NDE2N2Q1OTwvcmRmOmxpPiA8cmRmOmxpPmFk
b2JlOmRvY2lkOnBob3Rvc2hvcDozZDA5NTVmMy1mYjY1LTg3NDctODg0My1jMWUxNjVmYjZhZDA8
L3JkZjpsaT4gPHJkZjpsaT5hZG9iZTpkb2NpZDpwaG90b3Nob3A6NjcxM2Y5MjctODhjZC0xMWU5
LTk1ZWYtYzlkY2FiNTJlYWFjPC9yZGY6bGk+IDxyZGY6bGk+YWRvYmU6ZG9jaWQ6cGhvdG9zaG9w
OjcwMzM5MTU0LWM4ZDUtYWE0Mi05NzIyLTNmODY5YzM3ZjM1MDwvcmRmOmxpPiA8cmRmOmxpPmFk
b2JlOmRvY2lkOnBob3Rvc2hvcDo3MTI5NjM5MC04YjJjLTExZTktODA4NC1iZTM2Y2I3NzRiM2I8
L3JkZjpsaT4gPHJkZjpsaT5hZG9iZTpkb2NpZDpwaG90b3Nob3A6N2ZmYWMwNmUtOTBjYi0xMWU5
LWFjNDItZjY3MjQwMjBiN2ZhPC9yZGY6bGk+IDxyZGY6bGk+YWRvYmU6ZG9jaWQ6cGhvdG9zaG9w
Ojg3YTY4YTk4LWVmYWMtMzg0OC1hNDQ2LWFiZjA3ODdhMzQ3YzwvcmRmOmxpPiA8cmRmOmxpPmFk
b2JlOmRvY2lkOnBob3Rvc2hvcDpjN2FjNzgwZS04ZTU3LTExZTktOGQyYi1kZGZhYzBmYTA1MDQ8
L3JkZjpsaT4gPHJkZjpsaT5hZG9iZTpkb2NpZDpwaG90b3Nob3A6ZGY5YTljMjMtMDE3MS0zYTRi
LTgxY2ItNGFmNWFlOTc3OGFhPC9yZGY6bGk+IDxyZGY6bGk+YWRvYmU6ZG9jaWQ6cGhvdG9zaG9w
OmU4ODlmM2E2LTFhZjUtMjU0ZS1hZjcyLTJjMWE1ZDk3ZDk0YjwvcmRmOmxpPiA8cmRmOmxpPmFk
b2JlOmRvY2lkOnBob3Rvc2hvcDplOTAzOTE1My04ZGEyLTU4NDctYjI1Zi02YTQzMTdjNDNhM2M8
L3JkZjpsaT4gPHJkZjpsaT5hZG9iZTpkb2NpZDpwaG90b3Nob3A6ZjllMzhkZTAtMDI4OC1lMDRm
LTljMTctNjI0Y2IzMDI3NmE5PC9yZGY6bGk+IDxyZGY6bGk+YWRvYmU6ZG9jaWQ6cGhvdG9zaG9w
OmZmZDU0OWI1LTk3OTktNWE0Ny05MTY0LTcxM2YxMWM1ZjhmNzwvcmRmOmxpPiA8cmRmOmxpPnht
cC5kaWQ6MDVmYTcyNDEtMDk5ZC1hZDRlLWJiY2ItODQ3Nzk4ZWFhYzg3PC9yZGY6bGk+IDxyZGY6
bGk+eG1wLmRpZDowNzFlMzc0Zi00MTc2LWEzNDgtODgzYi1kMDIwYTE2ZGNmMjY8L3JkZjpsaT4g
PHJkZjpsaT54bXAuZGlkOjBmZmRiNDA4LWZjZDUtNWM0OS1hZWJlLTFiMjU1MDMxZWE5ODwvcmRm
OmxpPiA8cmRmOmxpPnhtcC5kaWQ6MTdkZDZhOGYtYjBkYS1jMDQ1LWJkZTYtNmQxNjY0OTY1Zjg1
PC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDoyYmY1NjMxNi0yOGJlLWI4NDctOTZjOS03YzI3NzYy
MzU1NzY8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlkOjJkMTM1Zjc1LTQ5NDktOTA0Zi05M2VkLTg3
NGNhY2Q5MDI2ZDwvcmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6MzA2NjM1YzgtZTZlZS1lMTRkLWJm
ZjctNzI2MTM0ZTQ3MjQxPC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDozNDQ1ZjdlMi00MzRiLTZi
NDYtODJjYi0zNzcwZmM3ODQ2MDU8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlkOjNiNGQwODNjLTY5
ZmYtZGQ0Zi04OGI1LWFjMmRlZDdkMzMwYjwvcmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6M2UzNDQ5
YjEtYTcxNS1kMzRhLTgxYzAtMGIwN2IyNGU1ZWYzPC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDo0
NDMxYjI2OC01YTlkLTAyNDYtODc2Zi05ZTE0YWNmMzY5ZTc8L3JkZjpsaT4gPHJkZjpsaT54bXAu
ZGlkOjQ5ZTA5NjBmLWEwODUtMzA0ZS04ZTI0LTU3NGRhYzBhMzgzZTwvcmRmOmxpPiA8cmRmOmxp
PnhtcC5kaWQ6NGI4Y2RjYWItMjdmYi0zNzRmLWE2ODctNmRmNzMxZTllZGEzPC9yZGY6bGk+IDxy
ZGY6bGk+eG1wLmRpZDo0Zjk1YjM0Ny05Nzk3LTFhNGMtODU5YS1kMGM5NzBlMGE0NTU8L3JkZjps
aT4gPHJkZjpsaT54bXAuZGlkOjVkZTYyODI3LTVjZjItZjE0Ni05NTRiLWU1NmYxMWRlYzcxNDwv
cmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6NjBkYTJmNmMtZGE5Ny00MzQwLTgxZjgtY2E1Yzg5OGJl
ZWZiPC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDo2NjA4M2VhZS0wYWIxLTZlNDUtOTlmNS1jNWRk
MDM5YTg3ZDY8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlkOjY4ZmFiZTQ5LTJiZWItZjQ0My1hMThh
LTA3N2I2YmY4MzNlMDwvcmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6NmQ4ZDA0ZjUtYzgxNi05MzQw
LWI3YzQtZDU0MTdmNzkwNTgyPC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDo3ODhBMkEyNkQwMjUx
MUU3QTBBRUM4NzlCNjJCQUIxRDwvcmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6N2I3NzZjYTQtMmNh
YS00YTQ5LTllZmItYTM1ODNkNTFmNzJmPC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDo3Y2NjZmZl
Yi1lZWVmLTg4NDYtODNmOC1iODE0YzlkMWIwOTY8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlkOjdm
ZWJiNzFhLTc0NzEtYTE0OC1hMmNlLTg1ZGMxN2ZmNDU5MTwvcmRmOmxpPiA8cmRmOmxpPnhtcC5k
aWQ6OGM0ZDFkNjUtNzNmZC01NDQzLTk1ZjgtZDUwZjIwOTdiZGEwPC9yZGY6bGk+IDxyZGY6bGk+
eG1wLmRpZDo5MWQ2NmU1Ny0xMWM0LTQ4NGEtYWIzNS05MmY3MjFiMzFlYTI8L3JkZjpsaT4gPHJk
ZjpsaT54bXAuZGlkOjk4N2FjZDE0LWRiMDctZGE0Ni05OGMxLWQ4OTI0YmYwZDlmNTwvcmRmOmxp
PiA8cmRmOmxpPnhtcC5kaWQ6OTg4ZGJiZjMtYmI1Yy05MDQyLWIzYzYtZWM5ZGU1ZTJlNmVlPC9y
ZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDphMGQ2NTFiYi05N2VjLThiNDEtOTU0Ny05Njg5YTRkM2Ni
OTQ8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlkOmE4NThmM2Q4LTI3MDYtODY0NS05YzdhLTJlNmY0
YTZkODQ3ZDwvcmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6YTg5M2I0ZTktNTkwYS0wYzRiLWJkODIt
NTgyNzNkMmU2NGUzPC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDpiM2Y3ZTE1Mi1lMzMxLWZlNDct
ODdjYy0xY2U2ZmQ0MzJkNWU8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlkOmJkY2IwYzMzLWUzNDEt
YTg0Zi1iZjk1LTMwMzllZDNlNGMyYjwvcmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6YzU0YzMzM2Mt
ZDhhOS1jNzQyLWFiYWItMzY4MmE4Mjg5NmY0PC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDpkMTNi
OGVhZC1kZmQyLWEwNDItOWVhMC1iOTNkMmIzZDdjZTc8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlk
OmQ3MjU5NjIwLWQ5MzktYWI0Ny1hZDZkLTRjYWQyYjlmOWU3YTwvcmRmOmxpPiA8cmRmOmxpPnht
cC5kaWQ6ZTY3MDhmY2ItNzIyZS0yZDRjLWFhYzMtNzhkOGM2ZGE5NzkwPC9yZGY6bGk+IDxyZGY6
bGk+eG1wLmRpZDplNzFjMTE0OS0zODMyLWFlNDItODhjMS0xMDA1Y2ZmZjU0NTQ8L3JkZjpsaT4g
PHJkZjpsaT54bXAuZGlkOmU5MzAxYmNkLWVlYjAtOTc0YS04NzEwLWY3NzE0MTczNDZhZDwvcmRm
OmxpPiA8cmRmOmxpPnhtcC5kaWQ6ZjJhNjZhN2ItODc0MC1lYzRjLWEwNzQtZDhkZGVkNzBmNjNl
PC9yZGY6bGk+IDxyZGY6bGk+eG1wLmRpZDpmOGVhMTdiYS01YzIyLTg2NDYtYTYzNS03YjIxYTY1
ZGY2MjU8L3JkZjpsaT4gPHJkZjpsaT54bXAuZGlkOmZhYzYxYWY5LWNkMjktOWY0OC1iMGY2LWU4
MWIwZDc4ZTM2NzwvcmRmOmxpPiA8cmRmOmxpPnhtcC5kaWQ6ZmU3Y2I4ZTQtNDI2Yi01ODQzLWEw
MzItMDc1ODlmY2ViMzAyPC9yZGY6bGk+IDwvcmRmOkJhZz4gPC9waG90b3Nob3A6RG9jdW1lbnRB
bmNlc3RvcnM+IDx4bXBNTTpIaXN0b3J5PiA8cmRmOlNlcT4gPHJkZjpsaSBzdEV2dDphY3Rpb249
ImNyZWF0ZWQiIHN0RXZ0Omluc3RhbmNlSUQ9InhtcC5paWQ6NjhmYWJlNDktMmJlYi1mNDQzLWEx
OGEtMDc3YjZiZjgzM2UwIiBzdEV2dDp3aGVuPSIyMDE5LTA2LTA2VDExOjM3OjIyKzA4OjAwIiBz
dEV2dDpzb2Z0d2FyZUFnZW50PSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOSAoV2luZG93cykiLz4g
PHJkZjpsaSBzdEV2dDphY3Rpb249InNhdmVkIiBzdEV2dDppbnN0YW5jZUlEPSJ4bXAuaWlkOjNh
ZDkxNzI2LWYyNjMtOTI0Ni1hZDllLTNmOGM2NWNkOWY0OSIgc3RFdnQ6d2hlbj0iMjAxOS0wNi0w
NlQxMTo0MjoyNSswODowMCIgc3RFdnQ6c29mdHdhcmVBZ2VudD0iQWRvYmUgUGhvdG9zaG9wIEND
IDIwMTkgKFdpbmRvd3MpIiBzdEV2dDpjaGFuZ2VkPSIvIi8+IDxyZGY6bGkgc3RFdnQ6YWN0aW9u
PSJzYXZlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDo1OWQwN2RkZC00NGIwLTJmNGItOGJi
OC1mODczY2FkOWRiMmMiIHN0RXZ0OndoZW49IjIwMTktMDYtMjdUMDg6MTQ6MjUrMDg6MDAiIHN0
RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE5IChXaW5kb3dzKSIgc3RF
dnQ6Y2hhbmdlZD0iLyIvPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0iY29udmVydGVkIiBzdEV2dDpw
YXJhbWV0ZXJzPSJmcm9tIGFwcGxpY2F0aW9uL3ZuZC5hZG9iZS5waG90b3Nob3AgdG8gaW1hZ2Uv
cG5nIi8+IDxyZGY6bGkgc3RFdnQ6YWN0aW9uPSJkZXJpdmVkIiBzdEV2dDpwYXJhbWV0ZXJzPSJj
b252ZXJ0ZWQgZnJvbSBhcHBsaWNhdGlvbi92bmQuYWRvYmUucGhvdG9zaG9wIHRvIGltYWdlL3Bu
ZyIvPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0ic2F2ZWQiIHN0RXZ0Omluc3RhbmNlSUQ9InhtcC5p
aWQ6YjUwMDNmMDUtZGJiMC04NTQzLTkwNjItNzI3YWQ1YmFkNjhlIiBzdEV2dDp3aGVuPSIyMDE5
LTA2LTI3VDA4OjE0OjI1KzA4OjAwIiBzdEV2dDpzb2Z0d2FyZUFnZW50PSJBZG9iZSBQaG90b3No
b3AgQ0MgMjAxOSAoV2luZG93cykiIHN0RXZ0OmNoYW5nZWQ9Ii8iLz4gPC9yZGY6U2VxPiA8L3ht
cE1NOkhpc3Rvcnk+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlk
OjU5ZDA3ZGRkLTQ0YjAtMmY0Yi04YmI4LWY4NzNjYWQ5ZGIyYyIgc3RSZWY6ZG9jdW1lbnRJRD0i
YWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjVmY2FkOTMzLTgzZTAtMDU0NS1hNTU0LTIxNzQ1Zjk3MmQz
NSIgc3RSZWY6b3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjY4ZmFiZTQ5LTJiZWItZjQ0My1h
MThhLTA3N2I2YmY4MzNlMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1w
bWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgrQuTkAABCdSURBVHic7Zx90F9Fdcc/5yaE8JLUpGQg
IGBSEDslQhHKixQtzQygQ3krQscXsIpjaynYGQt/qMWxGcRRESx1Oq3VMVC18tJCdaChoS0oEKOg
lRe1AauIvIQQEh3ywrPf/rF3d8/uvb8nT1po/3Fn9rl7d8+ePfvds+ecvff+HpPEL9IwzU6FM6bu
eanH2h84EjgOeA3wK2B7A7vGZgFGKee0Gexx4CHQGuAe4D5gY6TfwcKmZst/YqXM1Xt6cdPsYwow
L1FaDLwBOIsIyC/NrFs14XnAIYhDME7v6x4DbgeuR6wGnsf8DDUomUdANlgGy8Sx1gFjQ/AHPV3Z
19WyABwGXAC8CVjEjJJNKHv+uf7lwPnA+cgeBj4LthL46UT2cmDLwFK5GcNaYDQCTCVUU25p4/2r
gEuB3wPm5LYda/zPgC3AC5HadgF2B+ZOlKPUvQq4AuxPgL/o88YhYa0xo2lMYzQJGA9EmmALjtme
SJcAF4HNqze2XxkDWAf6Ftha4EHQj8A2grYA2yORzSECsxewBGwZcBToMGDvepK5tDfwYeB8sA8C
f+fFUAIki2SlazI5bm6WvNLp29bWg43ZwbHtBCdg9inQq4dbzkAC47uIG8H+CfguKNoEOWYmN1ar
YpnpL0eA7EzgjcC+tXGt0g2Ii4HHhgZ2lD63/cPc19ANOvg8oaO7Xko0gq/OGpHV1AR2C9jJwBHC
/kzYNwTPx/7WSzedbTFUtz8DdivwLmAZ8E7EfRM0/SzE14FTssj9vFKE4jUmt/epAKOuz72w6iD0
E005WKLZDdlKZJcj24VAS3cb6k4g8DvIbiPY9mzDMh8gAKEfM3SefyyHrrQryZSybSDYZ6A7Grq3
IPvegAfd/tDdgrqLq3HoGt59P1I/B4xkJWP1fZ0XSHazZG8ZaXtMsrcq2MkSd0mFrwcm1uGyIXV9
uat4Ii8bQ3mw7cKuk+xoyVZItr2ZxyxkV0r2EZQ0sMhUj9Vfa42xmeSFyL6CbPlI2w2IY5FdW2sY
FSgTc6udO5+fQ/Z+ZCcieyBv65IvQXb1jHi1GpPVqkKyS6u4h2Q3Ijs21dG3o+4DUve7RI1xbYWH
H7haJdqxMs9epi7fj/FoJyXZXZK9DnU3MKDpLkR2Rd2na+YdISlxTPAuMBtQZ83ss8Dravtm24iB
3OcH9ElDCq3nxcDAyxliFXrle9fXXYvBdPXiGcXg8krgjxvb/KeIx4GrygBO5j7VAd7k9CHg7KZu
KzGQu2m6jjs6ylQueix4tJaHTagfpABcRPSClzRtHwMeBFZN6uxsTG0Myx7lFOCD6d55uvMluynP
I/WTLzvNyBrhsttO1cQdL68x3qgmHtlRJNeuImcvw6XAp/LcIr/ZEp+TWFxHJ0UOt5V8SGNpRRaB
/iqfTEU6Y7wP+CKy5szhlzHxaO7bNBYUhjqCrGMrK9i2UTmuzlI4C5guRuwPnO767Qtcg3FmNe++
sRhfzOWM7gph+2dXCUi2UrKPJ7dWuUZAdH1OPMzdj7j/tOLBueRsX6K3yprgbVAgB2wevFyV5AOk
LoC9A7qHojZ1PS87A3XnFMNLNr7Tuetjkb2jqfsBsj/6X7rV/6+8oZ/P9sbDfQR186q6SmOSiwwd
Cp2h7s8JXae+DplQ9x6FblOMGPv6kPvErKIBBOvrrcopQvX9ksuMPBp3HYoLz3SVGy/Z17tQgz66
vRt1H5Mbm2CvkOxCL+t0GnMS6k5s/P0XkK2a+Qq1sUc3Hf0yZP+G7J4+fwnZnjujEW1c08Y4bvte
jmxdQ/deqVtUjhKTgbm4CZV/HoO44cADAUK96n4lh8Dl/scjO4EY1h+N7E3IDqyDSCsalMcahva5
3GtsDUqHgm2W7LIyjw6C7SXZeTm4pDlERqLucGTL64i1WynZI3LMeoaj0edg1YLLciqbD3LdrHrF
OyTbhUGdj4z9pL12QgG+PYAmebq/J9gD1TEk2LuQzR1qTBH8zaib5ZhsQfZJN4kyWYZ7vKablAdb
y6pTdJTF8mk5VJMaBbs6jQcbz0k+2TborszyRx4HE48SrbsGwa6C01SF7Ha7xPeUTsbpyZcLBOuz
T2HYPtopwVhTLyy1kdvjw9ckS3U0yC49aU0atHfFvRzC0fXt5ShhN0j2VMPrnCRUa2OO6pHzq3lt
raIuD0+wO8gjBjj4tkYj275eO9TV41ca1RUQipY04NhGsJubuZyEbH6tMdFoLu8NVBJyPcFWFZeX
XG5y15btzNhpN7toZzTbU3vjMcg2yNspmvuBcS1gJs0uYYSj94sb+1/fOJB9kR0Ow0PkcX5rAWuA
DUVNq51SXdv6NtWn67RyDE7Hyn/qLehpJSeP4baTlak4QeqDvHnma4D1wF5u3OOAf/fAzAc7tBbJ
7owb2np+vpzozF199VhdI5ilWTCCqA3Zq6E339dvFRzijoEHL1Y9a+h+sOVu/GPAvz4J3RKwfRpg
1gyBSQIlaWcCTAto06Ve0mY+qa+bXH7D4Ppm+rRl/OFVWfShZupesOVu5F+tgEF2EPV7zp+D/aBv
cxPyK+Nn1wBT1dlw4jPZg2mFqz08Mo4a+jEZfD8vPnzHmwmD/cBrDBzYCPUk8HSNldWrlwSwRli/
gqR2TZigALYNgJa2FDqvcQ0vX+dlqEBx5caACfs+MAXM6qv3qIBB3eIaANYDW8Di8xFzAOSBLG/W
ZsBXEGyfajIKZZK5LvXnlfX2BKSjMObnhfA2yr8YinZqK6Z1wCYXf7kF8dt44AQeBT0t2Me3ule0
3YIG9ecSYQ74CmgFjGprAWI+xmoTS6p+KVbIE6qNYQV2TJ8bbKUAAw0OyXLY54Wd10uLEv4uIPU6
48rBsBcqBaS2MfUL9PiSfdwwDjxA1bgAsbisWi+kByErmLkJtLzLfRZaadqppYAmOLg1rNXOnVDu
2Vrb5oGxrHLCAz0cZeBDK3djQFBr7FrNqlxoy3vCfWUraqMqCTPrBVcFYtKgSsnlZlJZglhwW4mt
OfSPac7ApSZBMjfqcqzYTDRmVb9q/tUEW2zGwG5dbAKg4v9cos2ijpzd0g4YaFCzQF5jNknVd0fz
EoOMjYCeRh5xJ4CR3+kc7MzkwHE06VzgteXWIL6regTYDGxrO1ivGb3R2SJxV2op3nsIzMj3QhGq
Hp3Ux38f81Q2lnESC4FZUln92DepruPcbBvBrcRcC1EVqj6LqYARYCuA7w+3bLomAJzmOU1Uo8mV
3amHny8NP4Hz7vrHzfh7YyxEPJ2MaPQK/TXJEJwrbDXCiqevNcYqGsSmGpQOjD0H3quarJXlh9hH
BTQvS3Zmg/WAAIeYmOfcuXpuWZ5HyisSECyQOCDfpzaXYzcbuc/PVApejfFuMHy+iFG57NK/6uO0
pRLIauFgIHCai6s/MmtTtLFPQhX52jrEJon5eXT4deCb3mgl5mXCrSrXwie7VYPjAKsKHhxX7zzM
4F31wIgNQ4jGSFcFwdFN1YPggAnBniSGx0c6pscDf9NIXYppm7gnZpXj1ng5TsMasOrp+VN0nrIa
TWtDgoGrHxM7cuuBnos4AoTKwfReqI2vwNZgFTDHAbsQPxqcZrDJ92lSYw5pkpOajnayY5tpquKg
X0MckO+jubgbhl9U3dG8Qj1I4jcGr1NxNFT0xcZoJOf+DOncPZP65yd/E8abtt9ofqNknbvfLNla
qG0MwL8i2wAszLCKc8G+Vq2cmnhAXjuUga48UYo6lRxbH5Wq1qzWjrkqL+cwTatSbRBlgM0Czs6j
x235dfUfUXeeWLL1wCpvuYWdpf5T95C8U786UK9aOvuEbB+idoQkcLIRKcJOnsR5k+C0qo1+E5je
c+XntWVyjNuagac7XnCodwSCL6f26vuY/npdI9BiZG/NE3BWv/ZO6d4J7EAor13cKxiqBcgA9XQ5
Dqjcq5tFo0p1GgG9eeB2UWO8nwO+ktjUn4HElfpniYdL7GII3ivYXW6A0K9gcNoT77sMimTxS1eM
0GuS6DWq39chapsFP16km6o0sYmPRm2Utzkuvip9ct8jBKdm2WLblwM8kfAo7lqGRVXeivhLw66O
Aa6BtFTYuyU+QV832MoaW0Cv2qruva0QPAQ8IZgLFoi/LvlJ1iIXWVcv4Np4yI9ZHZmtbb8MMTtv
c2wKuMYrYf5k/rXf3kp/YAeYZ/BtwZIyTdsgOMyi0ANTVoPRwjZm/BwsMS0AdutlfZb4jd+A+6hF
r8rtdUB3KnAzwj9lud7g7ERx92FzxrZS77awD5evqwxhC5Fdpd7AtsFbvf3HVLuAknk6IyvsWWGP
S/aEsK1+GwX1W9Zd220TJm0tuTZsgWSfbNz8Vsku8/TQvLsObl8GWCm4t9mvZwouqPZ9s78zOJVw
Doj+vqIbi3Xk+zp+FfA0dK5do3RXCpbmLRMX9NPAA+3vC5qX+pXBekHxG9ntTf1VgmOUNcW1ZQM6
dBw1j0nlFzNbMz4XCs5r6B4VfKgs5Agw5WziYgLZGmQrmvrdwL4EtnSgDd7w+VimH7G8nwbvSvP7
5npsp10uVFApu3fOI7nSxlORfbyhEbI/QLYxyzS+lYz4dWVxxX15RaC7Pbj6gB0QsH8M8PJSR++S
u3LfZ3mafJ/KXUNb5/ToJ2k1TZtG6huz8NuC6wS7+HECXB7gttDQp9SclUb2vOyFIN4m2Q8b43co
2FeRLa2+cBhZPTX1/r7SluHYo3U7bsvzOEWyGyWb17TfItkHJtm2EWAm5p9KdqZkzzbMlgluF3b0
IOga8UpDe+TbnaHMqzehbcQrDTPvBLsJbH7TtlbY24SFOoAklxtgGjVWufZqep/iz4R/5j6Ghhjr
rEL2+/l0nOyL91IUDUmTRK4tAeEN+mBFqeoHwMV+uwu7WvDXwnZtDP1/CDtNRLsy6ukGwDT7labc
pzuA0+i/mXGTnCf4jOBaYL8WWFGD4L9vqWhde+V667Earavyb0rcKdmFuPq+vBbxBsTj7YcBjiYr
wsgn8zV61crF8mrBSRI/rN2cIdmbBd8Q/KGwOb5t+jGG22h0+9Uxlc8HCD4tuEPYEUM6u1XYyYLH
hjyH90NgJhijkSBsrWSvF3aHp+8fKywWXCPZvZK9XbI9aGzCJIMcek2i2iojshX5DkL2UWTflOzd
ks3yxrfnfbVkp0v2TGWcmTzHCpj/Qfov4BTgcvybx5IOB/4W+Bawgvhg3Ubodja9DDgD+GLP+33E
32e36Qni76kuIp+7Zp7yIfLwtTugnD79FvBR4j+1mJSmgPuBu4CvAd8BfkL8lf6kNJs46YN63scT
PwXbbwfyXAu8n7h4O53uP/LFAwbivxu4gLiC+8+AfjvxMeKTwDNEgLYRP+DZjagZi4hvKV82Qxnu
JGrnbTMXe5hebGBSWkQE6O3Elf6/SKuJh8EbGHWmO5deKmBSmkd07ecSf2S654vM/1Hgq8AXiFvz
RUtDYNyHtMoOnubqaNp22ntScLAEOFHS64m2Yglo19weSn/5YCL9jC2OtR7pQdDdSKsJ4W6hzXmc
4H7y1srWyJ34y8ud6ruOh89ZVoBZ9i8bcmdJ/UChDJKYh1QX6kEllIWjGlAhlMFhDtKBSK8ELVUI
+xLCQqQ9Jc1GQUxNPQ88pzD1FGHqRwrhP5HWofC0whSEQLrmRQpTEKZQUANoqECT65Pnl+QLAbpZ
/PiK9xRgfpHq9N/ff9gIWOJV5gAAAABJRU5ErkJggg=="
        transform="matrix(1 0 0 1 361 271)"
      ></image>
    </svg>
  );
}
