import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styled';
import WelcomeContainer from './WelcomeContainer';
import LoginContainer from './LogInContainer';
import LoadingComponent from '../CommonComponents/LoadingComponent';
import Flex from '../CommonComponents/Flex';

const Auth: FC = (): ReactElement => {
  const signInIsLoading: boolean = useSelector(state => state.authReducer.signInIsLoading);
  return (
    <>
      {signInIsLoading && (
        <Flex position="fixed" height="100%" width="100%" background="#fff" zIndex={100}>
          <LoadingComponent color={'#22EBBA'} margin={2} height={27} width={27} />
        </Flex>
      )}
      <Container background={process.env.PUBLIC_URL + '/assets/images/login_background.png'}>
        <WelcomeContainer />
        <LoginContainer />
      </Container>
    </>
  );
};

export default Auth;
