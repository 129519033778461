import { FriendData } from '../../Common/interfaces/friend';
import { isNotEmptyArray } from '../arrays';

export const findUserInFriendList = (userId: string, friendList: Array<FriendData>) => {
  if (isNotEmptyArray(friendList)) {
    const index = friendList.findIndex(friend => friend.friendId === userId);
    if (index >= 0) {
      return { isFriend: true, friend: friendList[index], index: index };
    } else {
      return { isFriend: false, friend: undefined, index: index };
    }
  } else {
    return { isFriend: false, friend: undefined, index: undefined };
  }
};
