import styled from 'styled-components/macro';

const SearchFriendContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CreateChannelStyled = {
  SearchFriendContainer,
};
