import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Download({ style = {}, width = '100%', className = '', viewBox = '0 0 1080 1080' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <ellipse fill="#FFFFFF" cx="540" cy="539.4" rx="540" ry="540.4" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              fill="#D2D2D2"
              d="M761,498.2c0-89.5-53.2-166.4-129.6-201.2c-23.9-10-36.4,8.2-36.4,23v117.6c0,11.5,9.3,19.1,20.7,19.1H658
				c20.4,0,26,15.6,12.6,32.7l-106.7,136c-13.4,17.1-35.3,17.1-48.7,0l-106.7-136c-13.4-17.1-7.7-32.7,12.6-32.7h42.3
				c11.5,0,20.7-7.7,20.7-19.1V320c0-15.3-20.8-29.6-38.1-22.3h-0.1c-59.6,27.9-104.7,81.7-121,146.9
				c-66.5,10.1-117.5,67.3-117.5,136.6c0,76.4,61.9,138.3,138.3,138.3h415c61.1,0,110.7-49.5,110.7-110.7S822.1,498.2,761,498.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path fill="#D2D2D2" d="M650.1,210.2c0-0.2,0.2-0.6,0.2-0.8C650.4,207.1,650.3,207.8,650.1,210.2z" />
          </g>
        </g>
        <g>
          <g>
            <path fill="#D2D2D2" d="M429,209.4c0,0.2,0.2,0.6,0.2,0.9C429.1,207.8,429,207.1,429,209.4z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
