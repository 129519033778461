import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ConversationHeaderStyled } from './styled';
import { getDMIdNameAndProfileImage } from '../../utils/arrays';
import Profile from './Profile';
import ConversationHeaderOption from './Option';
import Flex from '../../CommonComponents/Flex';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import SearchMessage from './SearchMessage';
import { ChannelData } from '../../Common/interfaces/channel';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import useGetActiveConversation from '../../Common/customHooks/useGetActiveConversation';

const { Container, RoundBorderedContainer } = ConversationHeaderStyled;
const { Paragraph } = TypographyStyled;

const ConversationHeader: React.FC = () => {
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const activeConversation: ChannelData = useGetActiveConversation();
  const [userId, setUserId] = useState('');
  const [isSearchInputVisible, toggleSearchInput] = useState(false);

  useEffect(() => {
    if (activeConversation && activeConversation.id) {
      if (activeConversation.isGroup) {
        setUserId(activeConversation.id);
      } else if (activeConversation.isDirectMessage) {
        const { id } = getDMIdNameAndProfileImage(activeConversation.members, loggedInUser);
        setUserId(id);
      }
      toggleSearchInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation]);

  return (
    <Container>
      <RoundBorderedContainer>
        {activeConversation && <Profile activeConversation={activeConversation} id={userId} />}
        <Flex container flexDirection="column" justifyContent="center" alignItems="center">
          <Paragraph fontColor="#414042" size="xl" fontWeight="bold" margin="0px 0px 5px 0px">
            {activeConversation && activeConversation.name}
          </Paragraph>
        </Flex>
        {activeConversation && (
          <Flex container margin="0px 10px 0px 0px" alignItems="center">
            {isSearchInputVisible && <SearchMessage />}
            <IconContainer
              margin="0px 55px 0px 20px"
              onClick={() => toggleSearchInput(toggle => !toggle)}
              iconName="search-chat-box"
              iconWidth="40px"
              opacityOnMouseEnter={0.5}
            />
            <ConversationHeaderOption />
          </Flex>
        )}
      </RoundBorderedContainer>
    </Container>
  );
};

export default ConversationHeader;
