import styled from 'styled-components/macro';

const LogoutContainer = styled.div`
  display: flex;
  margin-top: 190px;
  margin-left: 15px;
`;

const Container = styled.div`
  position: absolute;
  box-shadow: 0px 0px 5px 0px #484848;
  left: 90px;
  transform: translate(10px, 0px);
  border-color: #484848;
  background-color: white;
  border-radius: 20px 20px 20px 0px;
  transition: 0.3;
  padding: 15px 30px;
  width: 330px;
  height: 155px;
  flex-direction: column;
  &:hover {
    cursor: default;
  }
`;

const TitleWrapper = styled.div`
  display: block;
  text-align: center;
  height: 30%;
`;

const ContextWrapper = styled.div`
  display: block;
  text-align: center;
  height: 30%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40%;
`;

export const LogoutStyled = { LogoutContainer, Container, TitleWrapper, ContextWrapper, ButtonWrapper };
