import { HANDLE_MEMBERS, SET_MEMBERS, SET_GROUP, CLEAR_CREATE_CHANNEL_REDUCER } from './actionTypes';

export function handleMembers(member) {
  return {
    type: HANDLE_MEMBERS,
    member: member,
  };
}

export function setMembers(members) {
  return {
    type: SET_MEMBERS,
    members: members,
  };
}

export const setGroup = group => {
  return {
    type: SET_GROUP,
    group,
  };
};

export const clearCreateChannelReducer = () => {
  return {
    type: CLEAR_CREATE_CHANNEL_REDUCER,
  };
};
