import React, { useState, ReactElement, useRef, useEffect } from 'react';
// import { ChromePicker } from 'react-color';
import { uuid } from 'uuidv4';
import { useSelector, useDispatch } from 'react-redux';
import { ComposeStyled } from './styled';
import Flex from '../../CommonComponents/Flex';
import { sendMessage } from '../actions/sendMessage';
import { ChannelData } from '../../Common/interfaces/channel';
import EmojiPicker from '../../CommonComponents/EmojiPicker';
import Mention from '../../Mention';
import Modal from '../../CommonComponents/Modal';
import { isNotEmptyArray } from '../../utils/arrays';
import { clearUnreadCountForConversation, isUserTyping } from '../../Common/actions';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { addMessageToConversationMessages } from '../actions';
import IsTyping from './isTyping';
import { bytesToMegaBytes, renameFile } from '../../utils/files';
import Swal from 'sweetalert2';
import path from 'path';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import useGetConversationPerMemberDataByOwnerId from '../../Common/customHooks/useGetConversationPerMemberDataByOwnerId';
import { useTranslation } from 'react-i18next';
import useGetActiveConversation from '../../Common/customHooks/useGetActiveConversation';
import useSendFile from '../../Common/customHooks/useSendFile';
import useReactGa from '../../Common/customHooks/useReactGa';

const { MessageInputText, OtherInput, InputContainer } = ComposeStyled;
const { Span } = TypographyStyled;

const Footer: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sendEGAEvent } = useReactGa();
  const { uploadFileToStorage } = useSendFile();
  const chatList: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);
  const activeConversation: ChannelData = useGetActiveConversation();
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const [composeValue, setComposeValue] = useState('');
  const [isReactionShown, setIsReactionShown] = useState(false);
  // const [isColorPickerShown, setIsColorPickerShown] = useState(false);
  const [userIsTyping, setUserIsTyping] = useState(false);
  // const [selectedColor, setSelectedColor] = useState('');
  const [isEnterToSendActive, setIsEnterToSendActive] = useState(true);
  const { isTyping } = useGetConversationPerMemberDataByOwnerId(activeConversation, loggedInUser);
  const sendFileRef: any = useRef();
  const composeTextFieldRef: any = useRef();

  useEffect(() => {
    setUserIsTyping(isTyping);
  }, [isTyping]);

  useEffect(() => {
    if (activeConversation) {
      setComposeValue(localStorage.getItem(activeConversation.id) || '');
    }
  }, [activeConversation]);

  const handleChange = e => {
    if (activeConversation) {
      setComposeValue(e);
      const inputDom: any = document.querySelector('.rc-mentions > textarea');
      inputDom.style.height = '5px';
      inputDom.style.height = inputDom.scrollHeight + 'px';
      localStorage.setItem(activeConversation.id, composeTextFieldRef.current.textarea.value);
      if (!userIsTyping) {
        setUserIsTyping(true);
        const requestBody = {
          conversationId: activeConversation.id,
        };
        isUserTyping(requestBody, loggedInUser.id);
      }
    }
  };

  const handleSubmit = async () => {
    composeTextFieldRef.current.focus();
    if (composeValue.length <= 1000) {
      if (activeConversation && composeValue && !composeValue.match('^\\s+$')) {
        setComposeValue('');
        const requestBody = {
          conversationId: activeConversation.id,
          msgSenderIdentifier: 'webapp_' + uuid(),
          userId: loggedInUser.id,
          userName: loggedInUser.displayName,
          content: {
            text: composeValue,
            type: {
              isMessage: true,
              isAttachment: false,
            },
          },
        };
        localStorage.setItem(activeConversation.id, '');
        const inputDom: any = document.querySelector('.rc-mentions > textarea');
        inputDom.style.height = '50px';
        dispatch(addMessageToConversationMessages(requestBody));
        await dispatch(sendMessage(requestBody));
        if (process.env.REACT_APP_CLIENT_ENV === 'production') {
          sendEGAEvent('User Action', 'Sent a message', `id: ${loggedInUser.id}`);
        }
        const messageListContainer: any = document.querySelector('.scrollviewer-container');
        messageListContainer.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey && isEnterToSendActive) {
      e.preventDefault();
      handleSubmit();
    } else if (e.key === 'Enter' && e.shiftKey && !isEnterToSendActive) {
      handleSubmit();
    }
  };

  const toggleEmoji = e => {
    if (activeConversation && !activeConversation.isBlocked) {
      e.preventDefault();
      setIsReactionShown(isReactionShown => !isReactionShown);
    }
  };

  const handleAttachClick = () => activeConversation && !activeConversation.isBlocked && sendFileRef.current.click();

  const handleEmojiSelect = e => activeConversation && !activeConversation.isBlocked && setComposeValue(oldValue => oldValue + e.native);

  const handleFocus = () => {
    if (activeConversation && activeConversation.unreadCount > 0) {
      const requestBody = {
        conversationId: activeConversation.id,
      };
      clearUnreadCountForConversation(loggedInUser.id, requestBody);
    }
  };

  const handlePaste = async e => {
    if (e.clipboardData.files[0]) {
      uploadFile(e.clipboardData.files[0]);
    }
  };

  const handleUploadFile = e => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      uploadFile(file);
      sendFileRef.current.value = [];
    }
  };

  const uploadFile = file => {
    const mb = bytesToMegaBytes(file.size);
    if (mb > 500) {
      Swal.fire({
        icon: 'error',
        iconHtml: '!',
        text: t('TheFileYouAreTryingToSendExceedsThe500MbUploadLimit'),
        confirmButtonText: t('Ok'),
        cancelButtonText: t('Cancel'),
        showCancelButton: true,
        showConfirmButton: false,
        width: '635px',
        customClass: {
          popup: 'file-too-large-popup-class',
          content: 'forgot-password-content-class',
          cancelButton: 'file-too-large-cancel-button-class',
        },
      });
    } else {
      Swal.fire({
        title: t('ChangeTheFileName'),
        input: 'text',
        inputValue: path.basename(file.name, path.extname(file.name)),
        showCancelButton: true,
        confirmButtonText: t('Upload'),
        cancelButtonText: t('Cancel'),
        showLoaderOnConfirm: true,
        preConfirm: newFileName => {
          const renamedFile = renameFile(file, newFileName + path.extname(file.name));
          return uploadFileToStorage(renamedFile, activeConversation.id).catch(err => {
            Swal.showValidationMessage(`Request failed: ${err.response.data.message}`);
          });
        },
      });
    }
  };

  return (
    <Flex width="100%" margin="0px 0px 20px" padding="0px 55px">
      {(activeConversation && !activeConversation.isBlocked) || activeConversation === undefined ? (
        <>
          {isNotEmptyArray(chatList) &&
            chatList.map(
              (chat, index) =>
                activeConversation &&
                activeConversation.id &&
                chat.id === activeConversation.id && <IsTyping key={index} activeConversation={chat} loggedInUser={loggedInUser} />
            )}
          <MessageInputText>
            <InputContainer>
              <Mention
                disabled={activeConversation && activeConversation.isBlocked}
                mentionRef={composeTextFieldRef}
                mentionVal={composeValue}
                onFocus={handleFocus}
                onChange={handleChange}
                onPaste={handlePaste}
                onKeyPress={handleKeyPress}
                placement={'top'}
              />
            </InputContainer>
            <OtherInput>
              <Flex container width="120px" alignItems="center" justifyContent="space-between">
                <IconContainer
                  onClick={handleAttachClick}
                  iconName="attach"
                  iconWidth="25px"
                  iconFill="white"
                  iconFillOnMouseEnter="#CACACA"
                />
                {/* <IconContainer iconName="bold" iconWidth="25px" iconFill="white" iconFillOnMouseEnter="#CACACA" />
                <IconContainer
                  iconName="color-palette"
                  iconWidth="25px"
                  iconFill="white"
                  iconFillOnMouseEnter="#CACACA"
                  onClick={() => setIsColorPickerShown(true)}
                >
                  <Modal visible={isColorPickerShown} dimmed={false} setModalVisibility={() => setIsColorPickerShown(false)} />
                  {isColorPickerShown && (
                    <Flex position="fixed" transform="translate(0px, -115%)">
                      <ChromePicker color={selectedColor} onChange={handleColorPickerCompleteChange} disableAlpha={true} />
                    </Flex>
                  )}
                </IconContainer> */}
                <input ref={sendFileRef} type="file" onChange={handleUploadFile} style={{ display: 'none' }} />
              </Flex>
              <Flex container width="270px" justifyContent="space-between" alignItems="center">
                <Modal visible={isReactionShown} setModalVisibility={() => setIsReactionShown(false)} dimmed={false} />
                <Flex container alignItems="flex-end" height="100%">
                  <Span size="sm" fontColor={composeValue.length > 1000 ? '#FF3737' : '#444444'}>{`${composeValue.length} / 1000`}</Span>
                </Flex>
                <IconContainer
                  onClick={() => setIsEnterToSendActive(toggle => !toggle)}
                  iconName="select"
                  iconWidth="25px"
                  iconFill={isEnterToSendActive ? '#08E9FE' : '#CACACA'}
                />
                <Flex container alignItems="flex-end" height="100%">
                  <Span size="sm" fontColor="#444444">
                    {t('EnterToSend')}
                  </Span>
                </Flex>
                <IconContainer onClick={toggleEmoji} iconName="smiley" iconWidth="25px">
                  {isReactionShown && (
                    <Flex transform="translate(0px, -10px)" handleOnClick={e => e.stopPropagation()}>
                      <EmojiPicker handleEmojiSelect={handleEmojiSelect} />
                    </Flex>
                  )}
                </IconContainer>
                <IconContainer onClick={handleSubmit} iconName="send" iconWidth="25px" iconFill={composeValue ? '#84FFFF' : '#CACACA'} />
              </Flex>
            </OtherInput>
          </MessageInputText>
        </>
      ) : (
        <Flex width="100%" container justifyContent="center">
          <Span size="lg" fontColor="#444444">
            {t('YouCannotSendMessageToThisFriend')}
          </Span>
        </Flex>
      )}
    </Flex>
  );
};

export default Footer;
