import React from 'react';
import Flex from '../CommonComponents/Flex';
import { DecorationStyled } from '../CommonComponents/Decoration/Decoration.style';
import { TypographyStyled } from '../CommonComponents/Typography/Typography.style';
import IconContainer from '../CommonComponents/Decoration/IconContainer';

const { HorizontalDefaultBackground } = DecorationStyled;
const { Span, Paragraph } = TypographyStyled;

export default function TermsOfUse() {
  return (
    <Flex>
      <Flex container width="100%">
        <HorizontalDefaultBackground>
          <Flex container width="100%" padding="10px" alignItems="center" justifyContent="space-between">
            <IconContainer iconName="logo" iconWidth="60px" />
            <Span size="xxl" fontColor="#fff" padding="5px">
              LAGOON Terms of Use
            </Span>
            <Flex />
          </Flex>
        </HorizontalDefaultBackground>
      </Flex>
      <Flex margin="10px">
        <Span fontColor="#444444" size="md">
          These Terms and Conditions of Use are for the use of all products(“Products”) provided by x-climb, Inc. (“x-climb“) to users of
          the Services (“Users“).
        </Span>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          1.Agreement to these Terms and Conditions
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          1.1.All Users need to use the Products in accordance with these Terms and Conditions. Users cannot use the Products unless they
          agree to these Terms and Conditions.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          1.2.Users who are minors can only use the Products by obtaining prior consent from their parents or legal guardians. Furthermore,
          if Users will be using the Products on behalf of, or for the purposes of, a business enterprise, then such business enterprise
          must also agree to these Terms and Conditions prior to using the Products.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          1.3.If there are Separate Terms and Conditions applicable to the Products, Users need to comply with such Separate Terms and
          Conditions also as well as these Terms and Conditions in using the Products.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          2.Modification to these Terms and Conditions
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          x-climb can modify these Terms and Conditions when x-climb deems it to be necessary, within the scope of the purposes of the
          Products. In such case, x-climb will indicate the contents of the modified version of these Terms and Conditions, as well as the
          effective date of the modification, on the Products or on x-climb’s website, or will publicize the same to Users by notifying
          Users in the manner prescribed by x-climb.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          The modified version of these Terms and Conditions can become effective as of the effective date thereof.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          3.Account
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          3.1.When using the Products, Users need to set up an account by registering certain information. Users must register true,
          accurate and complete information, and must revise the same to keep such information up-to-date at all times.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          3.2.If Users register any authentication information when using the Products, they must exercise due care in handling such
          information at their own responsibility to ensure that such information is not used in an unlawful manner. x-climb may treat any
          and all activities conducted under the authentication information as activities that have been conducted by the User with whom the
          authentication information is registered.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          3.3.Any User who has registered for the Products can delete such User’s account and cancel the Products at any time.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          3.4.x-climb reserves the right to delete any account that has been inactive for a period of two (2) years or more since its last
          activation, without any prior notice to the applicable User.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          3.5.Any and all rights of a User to use the Products shall cease to exist when such User’s account has been deleted for any
          reason. Please take note that an account cannot be retrieved even if a User has accidentally deleted their account.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          3.6.Each account in the Products is for exclusive use and belongs solely to the User of such account. Users cannot transfer, lease
          or otherwise dispose their rights to use the Products to any third party, nor may the same be inherited or succeeded to by any
          third party.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          4.Privacy
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          4.1.x-climb places top priority on the privacy of its Users.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          4.2.x-climb will appropriately handle privacy information and personal information of Users in accordance with the x-climb’s
          privacy policy.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          4.3.x-climb promises to exercise the utmost care and attention to its security measures to ensure the safe management of any and
          all information collected from Users.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          5.Provision of the Products
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          5.1.Users shall supply PCs, mobile phone devices, smartphones and other communication devices, operating systems, communication
          methods and electricity, etc. necessary for using the Products at their own responsibility and expense.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          5.2.x-climb reserves the right to limit access to all or part of the Products by Users depending upon conditions that x-climb
          considers necessary, such as the age and identification of User, current registration status, and the like.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          5.3.x-climb reserves the right to modify, at x-climb's discretion, all or part of the Products as x-climb determines necessary
          anytime without any prior notice to Users.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          5.4.x-climb can cease providing all or part of the Products without any prior notice to Users in case of the occurrence of any of
          the following:
        </Paragraph>
        <Flex margin="5px">
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (1)When conducting maintenance or repair of systems;
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (2)When the Products cannot be provided due to force majeure such as an accident (fire, power outage, etc.), act of God, war,
            riot, labor dispute;
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (3)When there is system failure or heavy load on the system;
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (4)When securing the safety of Users or third parties, or in the case of an emergency for the public welfare; or
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (5)When x-climb reasonably determines it to be necessary, other than those set forth in items (1) through (4).
          </Paragraph>
        </Flex>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          6.Advertisements
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          x-climb reserves the right to post advertisements for x-climb or a third party on the Products.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          7.Third-Party Services
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          The Products may contain services or Contents provided by third parties other than x-climb. Such third parties shall bear any and
          all responsibility regarding such services and/or Contents. Furthermore, there may be terms or use or other terms and conditions
          provided by such third party which are applicable to such services and Contents.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          8.Contents
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.1.x-climb grants Users a non-transferable, non-sublicensable, non-exclusive license to use the Subject Contents provided by
          x-climb, solely for the purpose of using the Products.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.2.Users shall abide by the conditions applicable thereto when using the Subject Contents which are subject to conditions of use,
          such as additional fees and periods of use and the like. Even if terms such as “Purchase”, “Sales,” and the like appear on the
          screens for the Products, x-climb shall remain the owner of all intellectual property rights as well as all other rights in the
          Subject Contents offered to Users by x-climb, and such rights shall not be transferred to Users. Users will only be granted usage
          rights as set forth above.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.3.Users shall not use the Subject Contents beyond the scope of the intended use of the Subject Contents in the Products
          (including, without limitation, acts of copying, transmitting, reproducing, and modifying).
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.4.If Users wish to back-up all or part of the Submitted Contents, they will need to do so themselves. x-climb will not undertake
          the obligation of backing up any of the Submitted Contents.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.5.The Products may include functions where multiple Users may edit Contents (post, correct, and delete, etc.). In such case, a
          User who posted his/her Submitted Contents shall grant other Users the right to edit such Submitted Contents.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.6.Users shall retain their rights to their Submitted Contents, and x-climb shall not acquire any such rights; provided, however,
          that, among the Submitted Contents, for those made available to other Users generally (i.e., not just to the User's “friends”),
          the User who posted such Submitted Contents shall be deemed to have granted x-climb a worldwide, perpetual, royalty-free license
          (including the right to amend (such as abbreviating) such Contents to the extent x-climb deems necessary and appropriate, and the
          right to sublicense such usage rights to other third parties working together with x-climb), to use such Contents for services
          and/or promotional purposes.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.7.Users shall not exercise their rights such as author’s moral rights with respect to the Submitted Contents in relation to the
          use by x-climb or a third party under Articles 8.5 and 8.6 above
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.8.x-climb may check and confirm the contents of the Submitted Contents by Users to the extent permissible under laws and
          regulations, when it is necessary for x-climb to confirm compliance with related laws and regulations or the provisions set out in
          these Terms and Conditions, etc.; provided, however, that x-climb is not obligated to conduct such confirmation.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          8.9.If x-climb believes that a User has violated or may violate any applicable laws or regulations or the provisions of these
          Terms and Conditions in relation to the Submitted Contents, or when otherwise reasonably necessary for x-climb’s business, then
          x-climb reserves the right to preclude such User's use of the Products in certain manners, such as deleting the Submitted Contents
          without providing prior notice to the User.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          9.Provision of Subscription Services
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          conditions prescribed by x-climb, wherein the Users pay a certain amount of considerations to use certain Subject Contents
          designated by x-climb for a certain period of time (the “Subscription Services”). Users shall abide by these Terms and Conditions,
          the fees for the Subscription Services, the payment method thereof, as well as other terms and conditions of use posted on the
          Products or on x-climb’s website.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          9.2.Users may take procedures for the cancellation of Subscription Services at any time; provided, however, that even if a User
          takes the procedures for cancellation prior to the intended period of use, the User may not change such period of use, nor may the
          User cancel the purchase of the Subscription Services. In such case, the fees already paid will not be refunded, nor will there be
          any refund on a pro rata basis. However, the foregoing will not apply if laws or regulations require otherwise.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          9.3.If a User does not complete their cancellation procedure by the designated date and time, the period of use of the
          Subscription Services may be automatically renewed in accordance with the terms prescribed by x-climb even after the end of the
          period of use of such Subscription Services.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          10.Restricted Matters
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          x-climb prohibits Users from engaging in any of the following acts when using the Products:
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.1.Acts that violate the laws and regulations, court verdicts, resolutions or orders, or administrative measures that are
          legally binding;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.2.Acts that may be in violation of public order, morals or customs;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.3.Acts that infringe intellectual property rights, such as copyrights, trademarks and patent rights, rights to fame, privacy,
          and all other rights granted by law or by a contract with x-climb and/or a third party;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.4.Acts of posting or transmitting excessively violent or explicit sexual expressions; expressions that amount to child
          pornography or child abuse; expressions that lead to discrimination by race, national origin, creed, gender, social status, family
          origin, etc.; expressions that induce or encourage suicide, self-injurious behavior or drug abuse; or expressions that include
          anti-social content and lead to the discomfort of others;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.5.Acts that lead to the misrepresentation of x-climb and/or a third party or that intentionally spread false information;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.6.Acts of sending the same or similar messages to a large, indefinite number of Users (except for those approved by x-climb),
          indiscriminately adding other Users as friends or to group chats, or any other acts deemed by x-climb to constitute spamming;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.7.Acts of exchanging the right to use the Products or Contents into cash, property or other economic benefits, other than by
          using the method prescribed by x-climb;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.8.Acts of using the Products for sales, marketing, advertising, solicitation or other commercial purposes (except for those
          approved by x-climb); using the Products for the purpose of sexual conduct or obscene acts; using the Products for the purpose of
          meeting or engaging in sexual encounters with an unknown third party; using the Products for the purpose of harassment or libelous
          attacks against other Users; or otherwise using the Products for purposes other than as intended by the Products;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.9.Acts that benefit or involve collaboration with anti-social groups;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.10.Acts that are related to religious activities or invitations to certain religious groups;{' '}
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.11.Acts of unauthorized or improper collection, disclosure, or provision of any other person's personal information, registered
          information, user history, or the like;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.12.Acts of interfering with the servers and/or network systems of the Products; fraudulently manipulating the Products by means
          of bots, cheat tools, or other technical measures; deliberately using defects of the Products; making unreasonable inquires and/or
          undue claims such as repeatedly asking the same questions beyond what is necessary, and other acts of interfering with or
          hindering x-climb's operation of the Products or other Users’ use of the Products;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.13.Acts of decoding the source code of the Products, such as by way of reverse engineering, disassembling or the like, for
          unreasonable purposes or in an unfair manner;
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.14.Acts that aid or encourage any acts stated in Clauses 10.1 to 10.13 above; and
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          10.15.Acts other than those set forth in Clauses 10.1 to 10.14 that x-climb reasonably deems to be inappropriate.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          11.User Responsibility
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          11.1.Users shall use the Products at their own risk, and shall bear any and all responsibilities for actions carried out under and
          the results from the use of the Products.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          11.2.x-climb may suspend the use of all or part of the Products, suspend or delete accounts, cancel any agreement between a User
          and x-climb with respect to the Products (including, without limitation, any agreement based on these Terms and Conditions,
          hereinafter the same) or take any other measure x-climb reasonably determines to be necessary and appropriate without prior notice
          to such User in the case that such User falls under or there is a possibility that such User falls under any of the following
          items:
        </Paragraph>
        <Flex margin="5px">
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (1)A User is in breach of applicable laws and regulations, these Terms and Conditions, or any Separate Terms and Conditions;
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (2)A User is a member of an anti-social group or a related party thereof;
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (3)A User tarnishes x-climb’s credibility by spreading false information, using fraudulent means or force, or other unlawful
            means;
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (4)A User is subject to a petition for attachment, provisional attachment, or auction, ; procedures such as bankruptcy, civil
            rehabilitation, or similar procedures are commenced; or x-climb otherwise reasonably determines that there is uncertainty with
            respect to User’s credibility; or
          </Paragraph>
          <Paragraph fontColor="#444444" size="md" margin="5px 20px">
            (5)The relationship of trust with a User is lost or x-climb otherwise reasonably determines that it would not be appropriate for
            x-climb to provide the Products to User, due to reasons other than as set forth in items (1) to (4) above.
          </Paragraph>
        </Flex>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          11.3.In the case where x-climb suffers loss/damages either directly or indirectly (including, without limitation, attorney fees)
          due to a User’s use of the Products (including, without limitation, cases where x-climb receives a complaint from a third party
          due to such use), the User shall immediately compensate x-climb for such loss/damage in accordance with x-climb’s request.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          12.NO WARRANTY
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          X-CLIMB SHALL PROVIDE NO WARRANTY, EITHER EXPRESSLY OR IMPLIEDLY, WITH RESPECT TO THE PRODUCTS (INCLUDING THE SUBJECT CONTENTS),
          THAT THERE ARE NO DEFECTS (INCLUDING, WITHOUT LIMITATION, FAULTS WITH RESPECT TO SECURITY, ETC., ERRORS OR BUGS, OR VIOLATIONS OF
          RIGHTS) OR AS TO THE SAFETY, RELIABILITY, ACCURACY, COMPLETENESS, EFFECTIVENESS AND FITNESS FOR A PARTICULAR PURPOSE. X-CLIMB WILL
          IN NO WAY BE RESPONSIBLE FOR PROVIDING USERS WITH THE PRODUCTS AFTER DELETING SUCH DEFECTS.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          13.x-climb's LIMITATION OF LIABILITY
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          13.1.X-CLIMB WILL NOT BE LIABLE FOR ANY DAMAGE INCURRED BY USERS FROM THE USE OF THE PRODUCTS, UNLESS SUCH DAMAGE IS ATTRIBUTABLE
          TO THE INTENTIONAL OR GROSSLY NEGLIGENT ACTS OF X-CLIMB; PROVIDED, HOWEVER, THAT, IN THE CASE THAT THE AGREEMENT BETWEEN THE USER
          AND X-CLIMB WITH RESPECT TO THE USE OF THE PRODUCTS FALLS UNDER A CONSUMER CONTRACT AS DEFINED UNDER THE CONSUMER CONTRACT ACT OF
          JAPAN (“CONSUMER CONTRACT”), X-CLIMB WILL BE LIABLE TO PROVIDE COMPENSATION FOR DAMAGE ARISING DUE TO NEGLIGENCE ON THE PART OF
          X-CLIMB (EXCLUDING GROSS NEGLIGENCE) ARISING FROM CONTRACT OR TORT ONLY WITHIN THE RANGE OF (A) THE DAMAGE WHICH IS NORMALLY
          INCURRED (I.E., EXCLUSIVE OF LOST PROFITS AND OTHER DAMAGES ARISING UNDER SPECIAL CIRCUMSTANCES), AND (B) THE AMOUNT OF USAGE FEES
          FOR THE PRODUCTS RECEIVED FROM SUCH USER FOR FEE-BASED PRODUCTS FOR THE MONTH IN WHICH SUCH DAMAGE HAS BEEN INCURRED, AT MAXIMUM.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          13.2.IF A USER INCURS DAMAGE AS A RESULT OF GROSS NEGLIGENCE ON THE PART OF X-CLIMB, X-CLIMB WILL BE RESPONSIBLE TO PROVIDE
          COMPENSATION FOR THE DAMAGE INCURRED BUT ONLY WITHIN THE RANGE OF (A) THE DAMAGE WHICH IS NORMALLY INCURRED (I.E., EXCLUSIVE OF
          LOST PROFITS AND OTHER DAMAGES ARISING UNDER SPECIAL CIRCUMSTANCES), AND (B) THE AMOUNT OF USAGE FEES FOR THE PRODUCTS RECEIVED
          FROM SUCH USER FOR FEE-BASED PRODUCTS FOR THE MONTH IN WHICH SUCH DAMAGE HAS BEEN INCURRED, AT MAXIMUM; PROVIDED, HOWEVER, THAT
          THE FOREGOING SHALL NOT APPLY IF THE AGREEMENT BETWEEN SUCH USER AND X-CLIMB FALLS UNDER THE DEFINITION OF A CONSUMER CONTRACT.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          14.Notification and Contact
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          14.1.For notifications from x-climb to Users regarding the Products, x-climb will use a method that x-climb considers appropriate,
          such as posting in an appropriate place within the Products or on x-climb’s website.
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          14.2.For notifications from Users to x-climb regarding the Products, Users shall use the customer inquiry form available on the
          Products or on x-climb’s website, or through other means designated by x-climb.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" fontWeight="bolder" margin="5px 0px">
          15.Governing Law and Jurisdiction
        </Paragraph>
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          These Terms and Conditions will be governed by the laws of Japan. Any conflicts between Users and x-climb that arise from or in
          connection with the Products shall be resolved under the exclusive jurisdiction of the Tokyo District Court as the court of first
          instance.
        </Paragraph>
      </Flex>

      <Flex margin="20px 10px">
        <Paragraph fontColor="#444444" size="md" margin="5px 0px">
          Date of last update: August 7, 2020
        </Paragraph>
      </Flex>
    </Flex>
  );
}
