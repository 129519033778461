import styled from 'styled-components/macro';

const ScrollViewContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  transition: 300ms;
`;

export const ConversationBodyStyled = {
  ScrollViewContainer,
};
