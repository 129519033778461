import styled from 'styled-components/macro';

const InputContainer = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 10px;
  background: #fff;
  font-size: 20px;
  border-radius: 15px 15px 0 0;
  border: none;
  outline: none;
`;

const MessageInputText = styled.div`
  background: linear-gradient(180deg, #50fcc7, #2fcbff);
  border-radius: 15px;
  padding: 2px;
  width: 100%;
`;

const OtherInput = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 0 0 15px 15px;
  padding: 10px 20px;
  height: 40px;
  width: 100%;
`;

const FilesContainer = styled.div`
  background: white;
`;

export const ComposeStyled = {
  MessageInputText,
  InputContainer,
  FilesContainer,
  OtherInput,
};
