import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { storageRef } from '../../firebase/firebase';
import { uuid } from 'uuidv4';
import { UserData } from '../interfaces/user';
import { bytesToKiloBytes } from '../../utils/files';
import path from 'path';
import { deleteFile } from '../../Conversation/actions/deleteFile';

export default function useSendFile() {
  const dispatch = useDispatch();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);

  const uploadFileToStorage = async (file: any, conversationId: string) => {
    return await axios
      .post(process.env.REACT_APP_API_URL + `/files/${conversationId}`)
      .then(async response => {
        const generatedFileId: string = response.data.file.id;
        const pathRef = storageRef.child(`/conversations/${conversationId}/files/${generatedFileId}/${file.name}`);
        const metaData = {
          contentType: file.type,
          firebaseStorageDownloadTokens: uuid(),
        };
        return await pathRef.put(file, metaData).then(async uploadResponse => {
          if (uploadResponse.state === 'success') {
            const requestBody = {
              fileName: file.name,
              userId: loggedInUser.id,
              userName: loggedInUser.displayName,
              token: metaData.firebaseStorageDownloadTokens,
              fileType: path.extname(file.name).substring(1),
              fileSize: bytesToKiloBytes(file.size),
            };
            return await axios
              .post(process.env.REACT_APP_API_URL + `/files/generateLink/${conversationId}/${generatedFileId}`, requestBody)
              .catch(err => {
                return err.response.message;
              });
          } else {
            await dispatch(deleteFile(generatedFileId, conversationId));
          }
        });
      })
      .catch(error => {
        return error.response;
      });
  };

  const uploadFileToStorageThread = async (file: any, conversationId: string, messageId: string) => {
    return await axios
      .post(process.env.REACT_APP_API_URL + `/files/thread/${messageId}/${conversationId}`)
      .then(async response => {
        const generatedFileId: string = response.data.file.id;
        const pathRef = storageRef.child(`/conversations/${conversationId}/files/${generatedFileId}/${file.name}`);
        const metaData = {
          contentType: file.type,
          firebaseStorageDownloadTokens: uuid(),
        };
        return await pathRef.put(file, metaData).then(async uploadResponse => {
          if (uploadResponse.state === 'success') {
            const requestBody = {
              fileName: file.name,
              userId: loggedInUser.id,
              userName: loggedInUser.displayName,
              token: metaData.firebaseStorageDownloadTokens,
              fileType: path.extname(file.name).substring(1),
              fileSize: bytesToKiloBytes(file.size),
            };
            return await axios
              .post(
                process.env.REACT_APP_API_URL + `/files/generateThreadLink/${messageId}/${conversationId}/${generatedFileId}`,
                requestBody
              )
              .catch(err => {
                return err.response.message;
              });
          } else {
            await axios.delete(process.env.REACT_APP_API_URL + `/files/thread/${messageId}/${conversationId}`, {
              data: {
                fileId: generatedFileId,
              },
            });
          }
        });
      })
      .catch(error => {
        return error.response;
      });
  };

  const deleteFileThreadInStorage = async (messageId, conversationId, fileId) => {
    await axios.delete(process.env.REACT_APP_API_URL + `/files/thread/${messageId}/${conversationId}`, {
      data: {
        fileId: fileId,
      },
    });
  };

  return { uploadFileToStorage, uploadFileToStorageThread, deleteFileThreadInStorage };
}
