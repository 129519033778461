import React, { FC, ReactElement } from 'react';
import { SidePanelStyled } from './styled';
import SidePanelHeader from './Header';
import SidePanelBody from './Body';

const { Conversation } = SidePanelStyled;

const SidePanel: FC = (): ReactElement => {
  return (
    <Conversation>
      <SidePanelHeader />
      <SidePanelBody />
    </Conversation>
  );
};

export default SidePanel;
