import styled from 'styled-components/macro';

const Container = styled.div`
  flex: 0 1 115px;
  height: 115px;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
`;

const RoundBorderedContainer = styled.div`
  border: 2px solid rgb(143, 143, 143, 04);
  margin: 0 55px;
  padding: 5px 0px;
  width: 100%;
  min-height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConversationHeaderStyled = {
  Container,
  RoundBorderedContainer,
};
