import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Select({
  style = {},
  fill = '#08E9FE',
  width = '100%',
  className = '',
  viewBox = '0 0 825 825',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="m823.999746,412.685306c1.4,226.75 -186.89,416.1 -420.44,410.99c-221.73,-4.85 -407.06,-189.71 -401.32,-422.02c5.47,-221.21 190.27,-405.99 422.43,-399.9c220.57,5.8 400.36,187.82 399.33,410.93zm0,0c1.4,226.75 -186.89,416.1 -420.44,410.99c-221.73,-4.85 -407.06,-189.71 -401.32,-422.02c5.47,-221.21 190.27,-405.99 422.43,-399.9c220.57,5.8 400.36,187.82 399.33,410.93z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="m368.799746,588.855306c16.14,-0.3 30.25,-5.55 41.54,-16.81c78.53,-78.33 157.04,-156.67 235.23,-235.34c16.92,-17.02 21.5,-38.05 13.65,-60.9c-14.34,-41.76 -66.35,-52.55 -98.52,-20.48c-61.67,61.48 -123.28,123.02 -184.59,184.86c-5.96,6.01 -8.73,5.22 -14.08,-0.28c-22.38,-22.98 -45.11,-45.63 -67.96,-68.15c-24.01,-23.65 -60.92,-23.78 -83.97,-0.62c-22.83,22.94 -23,59.69 0.32,83.29c38.87,39.34 78.08,78.34 117.29,117.34c11.32,11.24 25.31,16.65 41.09,17.09z"
        />
      </g>
    </svg>
  );
}
