import React, { FC, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ThreadData } from '../../../Common/interfaces/thread';
import Flex from '../../../CommonComponents/Flex';
import { ThreadOptionStyled } from './styled';
import { SELECTED_MESSAGE_OPTION } from '../../../Common/enums';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { UserData } from '../../../Common/interfaces/user';
import { updateThreadMessageById } from '../../../Common/actions';
import EmojiPicker from '../../../CommonComponents/EmojiPicker';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';
import Modal from '../../../CommonComponents/Modal';
import useSendFile from '../../../Common/customHooks/useSendFile';
import { copyToClipBoard } from '../../../utils/strings';
import { useTranslation } from 'react-i18next';

const { Container, OptionContainer, OptionItem, OptionDivider } = ThreadOptionStyled;
const { Span } = TypographyStyled;

interface Props {
  thread: ThreadData;
  toggleEditThread: (toggle: boolean) => void;
  handleEmojiSelect: (emoji: any) => void;
}

const ThreadOption: FC<Props> = ({ thread, toggleEditThread, handleEmojiSelect }) => {
  const { t } = useTranslation();
  const { deleteFileThreadInStorage } = useSendFile();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const isPreviewFile: boolean = useSelector(state => state.conversationReducer.isPreviewFile);
  const [selectedOption, setSelectedOption] = useState(SELECTED_MESSAGE_OPTION.NONE);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isReactionShown, setIsReactionShown] = useState(false);
  const [optionsPlacement, setOptionPlacement] = useState('top');
  const [OptionOneText, setOptionOneText] = useState('CopyMessage');
  const messageOptionRef: any = useRef();
  const isHoveringToIcon = selectedOption === SELECTED_MESSAGE_OPTION.OTHER_OPTION;

  const handleDeleteMessage = async e => {
    e.stopPropagation();
    setIsDeleting(true);
    if (thread.content.type.isAttachment) {
      const requestBody = {
        fileId: thread.id,
      };
      await deleteFileThreadInStorage(thread.messageId, thread.conversationId, requestBody);
    } else {
      const requestBody = {
        messageId: thread.messageId,
        threadId: thread.id,
        text: thread.content.text,
        isDeleted: true,
        isUpdated: true,
      };
      await updateThreadMessageById(requestBody);
    }
    setIsDeleting(false);
  };

  const copyMessageText = () => {
    copyToClipBoard(thread.content.text);
    setOptionOneText('Copied');
    setTimeout(() => setOptionOneText('CopyMessage'), 1000);
  };

  const handleEditMessage = () => {
    setSelectedOption(SELECTED_MESSAGE_OPTION.EDIT);
    toggleEditThread(true);
    setSelectedOption(SELECTED_MESSAGE_OPTION.NONE);
  };

  const handleEmojiClick = () => {
    getElementTopPosition();
    setSelectedOption(SELECTED_MESSAGE_OPTION.REACTION);
    setIsReactionShown(toggle => !toggle);
  };

  const handleReactionPlacement = () => {
    if (optionsPlacement === 'bottom') {
      return 'translateY(390px)';
    } else if (optionsPlacement === 'middle') {
      return 'translateY(200px)';
    } else {
      return 'translateY(0px)';
    }
  };

  const getElementTopPosition = () => {
    const containerElement = messageOptionRef.current;
    if (containerElement) {
      const containerElementTop = containerElement.getBoundingClientRect().top;
      if (containerElementTop < 440) {
        setOptionPlacement('bottom');
      } else if (containerElementTop < 600) {
        setOptionPlacement('middle');
      } else {
        setOptionPlacement('top');
      }
    }
  };

  return (
    <>
      <Modal
        dimmed={false}
        visible={selectedOption !== SELECTED_MESSAGE_OPTION.NONE}
        setModalVisibility={() => {
          setSelectedOption(SELECTED_MESSAGE_OPTION.NONE);
        }}
      />
      {!isPreviewFile && (
        <div ref={messageOptionRef} className="position-relative h-100">
          <Flex container position="absolute" justifyContent="flex-end" width="100%" height="100%">
            <Container>
              <IconContainer
                margin="0px 2px"
                onClick={handleEmojiClick}
                tooltipText={t(selectedOption === SELECTED_MESSAGE_OPTION.REACTION && isHoveringToIcon ? '' : 'AddReaction')}
                iconName="reaction"
                iconWidth="20px"
                iconFill={selectedOption === SELECTED_MESSAGE_OPTION.REACTION ? '#444444' : '#BDBDBD'}
              >
                {isReactionShown && (
                  <Flex transform={handleReactionPlacement()} handleOnClick={e => e.stopPropagation()}>
                    <EmojiPicker handleEmojiSelect={handleEmojiSelect} />
                  </Flex>
                )}
              </IconContainer>
              {thread.userId === loggedInUser.id && (
                <IconContainer
                  margin="0px 2px"
                  onClick={handleEditMessage}
                  iconName="edit-message"
                  iconWidth="20px"
                  tooltipText={t(isHoveringToIcon ? '' : 'EditMessage')}
                  iconFill={selectedOption === SELECTED_MESSAGE_OPTION.EDIT ? '#444444' : '#BDBDBD'}
                />
              )}
              <IconContainer
                margin="0px 2px"
                onClick={() => setSelectedOption(SELECTED_MESSAGE_OPTION.OTHER_OPTION)}
                iconName="option"
                iconWidth="20px"
                tooltipText={t(isHoveringToIcon ? '' : 'Others')}
                iconFill={selectedOption === SELECTED_MESSAGE_OPTION.EDIT ? '#444444' : '#BDBDBD'}
              >
                {selectedOption === SELECTED_MESSAGE_OPTION.OTHER_OPTION && (
                  <OptionContainer>
                    <OptionItem onClick={copyMessageText}>
                      <Span size="md" fontColor="#5E5E5E" fontWeight="bold">
                        {t(OptionOneText)}
                      </Span>
                    </OptionItem>
                    {thread.userId === loggedInUser.id && (
                      <>
                        <OptionDivider />
                        <OptionItem>
                          <Span
                            size="md"
                            fontColor={isDeleting ? '#5E5E5E' : '#FF3737'}
                            fontWeight="bold"
                            onClick={e => !isDeleting && handleDeleteMessage(e)}
                          >
                            {t(isDeleting ? 'Deleting' : 'DeleteMessage')}
                          </Span>
                        </OptionItem>
                      </>
                    )}
                  </OptionContainer>
                )}
              </IconContainer>
            </Container>
          </Flex>
        </div>
      )}
    </>
  );
};

export default ThreadOption;
