import React, { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import Thread from '../Thread';
import { THREAD_CONVERSATION_VIEW } from '../Common/enums';
import Footer from './Footer';
import ConversationHeader from './Header';
import ConversationBody from './Body';
import Flex from '../CommonComponents/Flex';
import DropZone from '../CommonComponents/Dropzone';
import { ChannelData } from '../Common/interfaces/channel';
import { bytesToMegaBytes, renameFile } from '../utils/files';
import Swal from 'sweetalert2';
import path from 'path';
import useGetActiveConversation from '../Common/customHooks/useGetActiveConversation';
import { useTranslation } from 'react-i18next';
import Notification from './Notification';
import useSendFile from '../Common/customHooks/useSendFile';

const Conversation: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { uploadFileToStorage } = useSendFile();
  const threadConversationView: THREAD_CONVERSATION_VIEW = useSelector(state => state.navigationReducer.threadConversationView);
  const activeConversationId: string = useSelector(state => state.conversationReducer.activeConversationId);
  const activeConversation: ChannelData = useGetActiveConversation();
  const [isDragFileEnter, setDragFileEnter] = useState(false);

  const handleOnDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    if (activeConversationId && !activeConversation.isBlocked) {
      setDragFileEnter(true);
    }
  };

  const handleOnDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const dt = e.dataTransfer;
    const files = dt.files;
    if (files[0] && !activeConversation.isBlocked) {
      const file = files[0];
      const mb = bytesToMegaBytes(file.size);
      if (mb > 500) {
        Swal.fire({
          icon: 'error',
          iconHtml: '!',
          text: t('TheFileYouAreTryingToSendExceedsThe500MbUploadLimit'),
          confirmButtonText: t('Ok'),
          cancelButtonText: t('Cancel'),
          showCancelButton: true,
          showConfirmButton: false,
          width: '635px',
          customClass: {
            popup: 'file-too-large-popup-class',
            content: 'forgot-password-content-class',
            cancelButton: 'file-too-large-cancel-button-class',
          },
        });
      } else {
        Swal.fire({
          title: t('ChangeTheFileName'),
          input: 'text',
          inputValue: path.basename(file.name, path.extname(file.name)),
          showCancelButton: true,
          confirmButtonText: t('Upload'),
          cancelButtonText: t('Cancel'),
          showLoaderOnConfirm: true,
          preConfirm: newFileName => {
            const renamedFile = renameFile(file, newFileName + path.extname(file.name));
            return uploadFileToStorage(renamedFile, activeConversation.id).catch(err => {
              Swal.showValidationMessage(`Request failed: ${err.response.data.message}`);
            });
          },
        });
      }
    }
    setDragFileEnter(false);
  };

  const handleOnDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragFileEnter(false);
  };

  return (
    <Flex
      container
      height="100%"
      width="100%"
      flexDirection="column"
      position={isDragFileEnter ? 'relative' : 'inherit'}
      onDragEnter={handleOnDragEnter}
      onDragOver={handleOnDragOver}
    >
      {isDragFileEnter && (
        <Flex
          height="100%"
          width="100%"
          position="absolute"
          onDragEnter={handleOnDragEnter}
          onDragOver={handleOnDragOver}
          onDragLeave={handleOnDragLeave}
          onDrop={handleOnDrop}
        >
          <DropZone />
        </Flex>
      )}
      <ConversationHeader />
      <Flex container flex="1" height="0%" width="100%">
        <Flex flex="7" container flexDirection="column" height="100%" width="100%">
          <Notification />
          <ConversationBody />
          <Footer />
        </Flex>
        {threadConversationView === THREAD_CONVERSATION_VIEW.VISIBLE && (
          <Flex flex="4">
            <Thread />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Conversation;
