export const collections = {
  users: 'users_v1_mark',
  usersSettings: 'usersSettings_v1_mark',
  conversations: 'conversations_v1_mark',
  conversationsPerMember: 'conversationsPerMember_v1_mark',
  threadsPerMember: 'threadsPerMember_v1_mark',
  messages: 'messages_v1_mark',
  friends: 'friends_v1_mark',
  notificationTokens: 'notificationTokens_v1_mark',
};

export const subCollections = {
  members: 'members',
  threads: 'threads',
};
