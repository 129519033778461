import React from 'react';
import IconContainer from '../Decoration/IconContainer';

export default function MessageAttachment({ url, fileType }) {
  if (fileType === 'image') {
    return <img className="rounded-xl" alt="..." src={url} height="100%" width="100%" />;
  } else if (fileType === 'video') {
    return <video className="rounded-xl" src={url} height="100%" width="100%" />;
  } else if (fileType === 'audio') {
    return <IconContainer iconName="audio" iconWidth="75px" />;
  } else if (fileType === 'presentation') {
    return <IconContainer iconName="ppt" iconWidth="75px" />;
  } else if (fileType === 'pdf') {
    return <IconContainer iconName="pdf" iconWidth="75px" />;
  } else if (fileType === 'spreadsheet') {
    return <IconContainer iconName="spreadsheet" iconWidth="75px" />;
  } else if (fileType === 'doc') {
    return <IconContainer iconName="doc" iconWidth="75px" />;
  } else {
    return <IconContainer iconName="unknown-file" iconWidth="75px" />;
  }
}
