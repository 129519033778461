import axios from 'axios';
import { UPDATE_GROUP_PHOTO_ERROR, UPDATE_GROUP_PHOTO_LOADING, UPDATE_GROUP_PHOTO_SUCCESS } from '../actionTypes';

export function hasError() {
  return {
    type: UPDATE_GROUP_PHOTO_ERROR,
  };
}

export function isLoading() {
  return {
    type: UPDATE_GROUP_PHOTO_LOADING,
  };
}

export function isSuccess(conversationImageUrl, groupId) {
  return {
    type: UPDATE_GROUP_PHOTO_SUCCESS,
    conversationImageUrl: conversationImageUrl,
    groupId: groupId,
  };
}

export function updateGroupPhoto(id: string, requestBody: any) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios.post(process.env.REACT_APP_API_URL + `/conversations/setGroupChatImage/${id}`, requestBody).then(
      response => {
        dispatch(isSuccess(response.data.conversations.conversationImageUrl, id));
        return response.data.conversations;
      },
      err => {
        dispatch(hasError());
        return err.response.message;
      }
    );
  };
}
