import { SET_ACCOUNT_SETTINGS_THIRD_PAGE_VIEW } from '../actions/actionTypes';
import { USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW } from '../../Common/enums';

const initialState = {
  thirdPanelView: USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.NONE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_SETTINGS_THIRD_PAGE_VIEW:
      return {
        ...state,
        thirdPanelView: action.view,
      };
    default:
      return state;
  }
};
