import { useState, useEffect } from 'react';
import { collections, subCollections } from '../../firebase/collectionsAndSubcollectionsDir';
import db from '../../firebase/firebase';
import { isNotEmptyArray } from '../../utils/arrays';

export default function(conversationId: string) {
  const [members, setMembers] = useState<Array<any>>([]);

  useEffect(() => {
    if (conversationId) {
      const unsubscribe = db
        .collection(collections.conversations)
        .doc(conversationId)
        .collection(subCollections.members)
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            const fetchedMembers = snapshot.docs.map(m => {
              const copyMember = { ...m.data() };
              if (!copyMember.isRemoved) {
                copyMember.isRemoved = false;
              }
              return copyMember;
            });
            setMembers(fetchedMembers);
          }
          return () => {
            unsubscribe();
          };
        });
    }
  }, [conversationId]);

  return members;
}
