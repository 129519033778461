import styled from 'styled-components/macro';

interface HeaderMessageProps {
  state: string;
}

const determineHeaderMessageBackground = (state: string) => {
  switch (state) {
    case 'info':
      return '#fffcef';
    case 'error':
      return '#FFE1E1';
    case 'success':
      return '#C8FFC4';
    default:
      return '#fffcef';
  }
};

const determineHeaderMessageBorderColor = (state: string) => {
  switch (state) {
    case 'info':
      return '#fbcb0a';
    case 'error':
      return '#FF3737';
    case 'success':
      return '#6EC368';
    case 'warning':
      return '#FBCB0A';
    default:
      return '#fbcb0a';
  }
};

const HeaderMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 10px;
  width: 90%;
  text-align: center;
  background-color: ${(props: HeaderMessageProps) => determineHeaderMessageBackground(props.state)};
  border-radius: 50px;
  border: 1px solid ${(props: HeaderMessageProps) => determineHeaderMessageBorderColor(props.state)};
`;

export const AccountSettingsStyled = { HeaderMessage };
