import styled from 'styled-components/macro';

const Container = styled.div`
  flex: 0 1 115px;
  height: 115px;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
`;

const BorderedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 55px;
  height: 60px;
  width: 100%;
  border-radius: 30px;
  padding: 5px;
  border: 2px solid rgb(143, 143, 143, 04);
`;

const SearchFriendInput = styled.input`
  color: grey;
  background: #fff;
  width: 100%;
  border: none;
  font-size: 20px;
  padding: 0px 10px;
  width: 100%;
  outline: none;
`;

export const CreateChannelHeaderStyled = {
  Container,
  BorderedContainer,
  SearchFriendInput,
};
