import styled from 'styled-components/macro';

const SearchInput = styled.input`
  border: none;
  font-size: 20px;
  outline: none;
  padding: 0px 5px;
  text-align: center;
  color: #444444;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #cacaca;
  height: 40px;
  padding: 0px 10px;
`;

export const SearchMessageStyled = { SearchInputContainer, SearchInput };
