import styled from 'styled-components/macro';

interface Props {
  showUsers: boolean;
}

const ReactionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px 5px 0px 0px;
  background-color: #e1e1e1;
  border-radius: ${(props: Props) => (props && props.showUsers ? '10px' : '25px')};
  transition: 300ms;
  :hover {
    background-color: #cacaca;
    cursor: pointer;
  }
`;

export const ReactionStyled = {
  ReactionsContainer,
};
