export const INIT = 'INIT';
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING';
export const SIGN_IN_HAS_ERROR = 'SIGN_IN_HAS_ERROR';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const SIGN_IS_USING_DEMO_ACCOUNT_LOADING = 'SIGN_IS_USING_DEMO_ACCOUNT_LOADING';
export const SIGN_IS_USING_DEMO_ACCOUNT_ERROR = 'SIGN_IS_USING_DEMO_ACCOUNT_ERROR';
export const SIGN_IS_USING_DEMO_ACCOUNT_SUCCESS = 'SIGN_IS_USING_DEMO_ACCOUNT_SUCCESS';

export const SIGN_OUT_LOADING = 'SIGN_OUT_LOADING';
export const SIGN_OUT_HAS_ERROR = 'SIGN_OUT_HAS_ERROR';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const SIGN_UP_LOADING = 'SIGN_UP_LOADING';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';

export const UPLOAD_USER_IMAGE_LOADING = 'UPLOAD_USER_IMAGE_LOADING';
export const UPLOAD_USER_IMAGE_ERROR = 'UPLOAD_USER_IMAGE_ERROR';
export const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS';

export const UPDATE_USER_INFO_LOADING = 'UPDATE_USER_INFO_LOADING';
export const UPDATE_USER_INFO_ERROR = 'UPDATE_USER_INFO_ERROR';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
