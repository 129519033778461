import { useState, useEffect } from 'react';
import db from '../firebase/firebase';
import { isNotEmptyArray } from '../utils/arrays';
import { collections, subCollections } from '../firebase/collectionsAndSubcollectionsDir';

export default function useGetReplies(messageId: string, isLastIndex?: boolean) {
  const [isLoading, setIsLoading] = useState(true);
  const [replies, setReplies] = useState<Array<any>>([]);
  const [reply, setReply] = useState<any>(undefined);

  useEffect(() => {
    if (messageId) {
      setIsLoading(true);
      const unsubscribe = db
        .collection(collections.messages)
        .doc(messageId)
        .collection(subCollections.threads)
        .where('status.isDeleted', '==', false)
        .orderBy('dateCreated')
        .onSnapshot((querySnapshot: any) => {
          if (isNotEmptyArray(querySnapshot.docs)) {
            if (isLastIndex) {
              setReply(querySnapshot.docs[querySnapshot.docs.length - 1].data());
            } else {
              setReplies(querySnapshot.docs.map(doc => doc.data()));
            }
          } else {
            setReplies([]);
          }
          setIsLoading(false);
          return () => {
            unsubscribe();
          };
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId]);

  return { replies, isLoading, reply };
}
