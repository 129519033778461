import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';

export const initializeFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_MESSAGING_APP_ID,
      measurementId: process.env.REACT_APP_MESSAGING_MEASUREMENT_ID,
    });
  }
};

export const handleTokenRequest = async () => {
  let token;
  try {
    token = await firebase.messaging().getToken();
  } catch (error) {
    console.log(error);
  }
  return token;
};
