import styled from 'styled-components/macro';
import { Display } from '../../../Common/interfaces';

const Container = styled.div`
  opacity: ${(props: Display) => (props.visible ? 1 : 0)};
  transition: 300ms;
  width: 10%;
  position: relative;
`;

const OptionContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  border-radius: 5px;
  width: 130px;
  background-color: white;
  border: solid 1px #cccccc;
  transform: translate(-110px, 10px);
  box-shadow: 0px 0px 3px -1px #444444;
`;

const OptionItem = styled.div`
  display: flex;
  justify-content: center;
  font-family: MPLUSRounded1c-Regular;
  font-size: 20px;
  color: #ff3737;
  font-weight: bold;
  align-items: center;
  margin: 2px;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  transition: 300ms;
  :hover {
    background-color: #ff3737;
    cursor: pointer;
    color: white;
  }
`;

const ConfirmationHideContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 10px 0px 10px 10px;
  width: 270px;
  height: 145px;
  background-color: white;
  border: solid 1px #cccccc;
  transform: translate(-250px, 10px);
  padding: 10px 15px;
  box-shadow: 0px 0px 3px -1px #444444;
`;

export const ChatMessageOptionStyled = {
  OptionContainer,
  OptionItem,
  Container,
  ConfirmationHideContainer,
};
