import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Multiply({
  style = {},
  fill = '#FF3737',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(8.939 8.94)">
        <path
          fill={fill}
          d="M1031.5,64.9L1031.5,64.9c55.1,59.2,51.9,152.1-7.3,207.2l-786.5,733.6
		c-59.2,55.1-152.1,51.9-207.2-7.3l0,0c-55.1-59.2-51.9-152.1,7.3-207.2L824.2,57.6C883.4,2.4,976.3,5.7,1031.5,64.9z"
        />
        <path
          fill={fill}
          d="M1014.9,1015.5L1014.9,1015.5c-57.3,57.3-150.2,57.3-207.5,0L46.9,255
		c-57.3-57.3-57.3-150.2,0-207.5l0,0c57.3-57.3,150.2-57.3,207.5,0L1014.9,808C1072.2,865.6,1072.2,958.4,1014.9,1015.5z"
        />
      </g>
    </svg>
  );
}
