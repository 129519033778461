import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Favorite({ fill, style = {}, width = '100%', className = '', viewBox = '0 0 23 21' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#47fcc4" />
          <stop offset="1" stopColor="#30ccfe" />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M9.289610858917236,1.189699535369873 a2,2 0 0 1 3.657,0 l1.6860000000000002,3.807 A2,2 0 0 0 16.278610858917236,6.177699535369873 L20.418610858917237,6.559699535369873 a2,2 0 0 1 1.121,3.508 l-3.053,2.6270000000000002 a2,2 0 0 0 -0.6450000000000001,1.963 l0.9,3.927 a2,2 0 0 1 -2.95,2.179 L12.118610858917236,18.637699535369872 a2,2 0 0 0 -2,0 L6.442610858917236,20.759699535369872 a2,2 0 0 1 -2.95,-2.179 l0.9,-3.927 a2,2 0 0 0 -0.6450000000000001,-1.963 L0.6946108589172364,10.066699535369873 A2,2 0 0 1 1.8186108589172365,6.559699535369873 L5.958610858917236,6.177699535369873 a2,2 0 0 0 1.6460000000000001,-1.182 z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
