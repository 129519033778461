import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { checkFileType } from '../../utils/files';
import { ButtonStyled } from '../Button/Button.style';
import { useTranslation } from 'react-i18next';
import IconContainer from '../Decoration/IconContainer';
import { downloadFile } from '../../Common/actions';

const { Button } = ButtonStyled;

const FilePreview = ({
  messageId,
  isPreviewOpen,
  togglePreview,
  senderId,
  senderName,
  senderProfileImage,
  permalink,
  lastDateUpdated,
  fileType,
  fileName,
  handleCloseViewFile,
  handleDeleteFile,
  isDeletingFile,
  isBlocked,
}) => {
  const loggedInUserId: string = useSelector(state => state.authReducer.loggedInUser.id);
  const { t } = useTranslation();
  const [isDeleteOptionOpen, toggleDeleteOption] = useState(false);

  const handleDelete = async e => {
    e.stopPropagation();
    await handleDeleteFile();
    const closeModal = document.getElementById('modal-close-icon-' + messageId);
    toggleDeleteOption(false);
    if (closeModal) {
      closeModal.click();
    }
  };

  const renderPreview = () => {
    const type = checkFileType(fileType);
    const decodedGoogleDocsAPI = encodeURI(`https://docs.google.com/gview?url=${permalink}&embedded=true`);
    if (type === 'image') {
      return <img alt={fileName} src={permalink} className="img-fluid h-87vh w-auto" onClick={handleCloseViewFile} />;
    } else if (type === 'video') {
      return <video src={permalink} className="img-fluid h-87vh w-auto" controls />;
    } else if (type === 'presentation' || type === 'spreadsheet' || type === 'doc') {
      return <iframe title={fileName} src={decodedGoogleDocsAPI} height="100%" width="100%" />;
    } else if (type === 'pdf') {
      return <iframe title={fileName} src={permalink} height="100%" width="100%" />;
    } else {
      return <iframe title={fileName} src={permalink} height="100%" width="100%" style={{ backgroundColor: 'white' }} />;
    }
  };

  return (
    <div className="modal" id={'filePreview' + messageId} tabIndex={-1} aria-labelledby={permalink} aria-hidden="true">
      <div className="modal-dialog mw-100 mh-100 vw-100 vh-100 m-0 p-0">
        <div className="modal-content vh-100 bg-transparent">
          <div className="modal-header bg-white">
            <div className="w-100 d-flex px-5 justify-content-between">
              <div className="d-flex">
                <img src={senderProfileImage} className="rounded-circle obj-fit-cover" height="80px" width="80px" alt="user profile" />
                <div className="ml-3 d-flex flex-column text-dark text-large">
                  <span>{fileName}</span>
                  <span>{senderName}</span>
                  <span>{new Date(lastDateUpdated).toLocaleString()}</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="mr-3">
                  <IconContainer onClick={() => downloadFile(permalink, fileName)} iconName="download-with-border" iconWidth="50px" />
                </div>
                {loggedInUserId === senderId && !isBlocked && (
                  <IconContainer onClick={() => toggleDeleteOption(true)} iconName="delete" iconWidth="50px">
                    {isDeleteOptionOpen && (
                      <div className="mt-5 p-4 position-fixed z-index-1 right-160px w-350px bg-white rounded-xl d-flex flex-column justify-content-around align-items-center">
                        <p className="text-danger text-xxl">{t('Delete')}</p>
                        <p className="text-dark text-md">{t('AreYouSureYouWantToDeleteThisFile')}</p>
                        <div className="w-100 d-flex justify-content-around">
                          <Button
                            onClick={e => {
                              e.stopPropagation();
                              toggleDeleteOption(false);
                            }}
                            width="45%"
                            fontSize="lg"
                            borderRadius="20px"
                            type="button"
                            color="#CACACA"
                            border="2px solid #CACACA"
                          >
                            {t('Cancel').toUpperCase()}
                          </Button>
                          <Button
                            onClick={handleDelete}
                            disabled={isDeletingFile}
                            width="45%"
                            fontSize="lg"
                            borderRadius="20px"
                            type="button"
                            color="#FFFFFF"
                            border="none"
                            backgroundColor="#FF3737"
                          >
                            {t(isDeletingFile ? 'Deleting' : 'Ok')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </IconContainer>
                )}
                <div className="ml-5">
                  <IconContainer
                    id={'modal-close-icon-' + messageId}
                    onClick={() => {
                      togglePreview(false);
                      toggleDeleteOption(false);
                    }}
                    dataDismiss="modal"
                    iconName="close"
                    iconWidth="50px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body p-0 d-flex align-items-center justify-content-center">{isPreviewOpen && renderPreview()}</div>
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
