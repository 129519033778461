import styled from 'styled-components/macro';

interface NewMessageProps {
  visible: boolean;
}

interface ThreadProps {
  isActive: boolean;
}

const Conversation = styled.div`
  background: #f4f5f7;
  max-height: 100%;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

const ConversationThread = styled.div`
  background-color: ${(props: ThreadProps) => (props && props.isActive ? '#e9ebef' : '')};
  height: 85px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 30px;
  :hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

const ThreadImg = styled.div`
  margin-right: 30px;
`;

const ThreadPar = styled.p`
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  color: #444444;
  line-height: 85px;
  margin: 0;
  &:hover {
    cursor: default;
  }
`;

const NewMessageContainer = styled.div`
  height: 85px;
  border-bottom: 1px solid #e1e1e1;
  display: ${(props: NewMessageProps) => (props.visible ? 'flex' : 'none')};
  background-color: ${(props: NewMessageProps) => (props.visible ? '#e9ebef' : '')};
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  &:hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

const NewMessageWrapper = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: center;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SidePanelStyled = {
  Conversation,
  ConversationThread,
  ThreadImg,
  ThreadPar,
  NewMessageContainer,
  NewMessageWrapper,
  CloseIconWrapper,
};
