import styled from 'styled-components';

const GroupContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  border: 2px solid rgba(235, 235, 235, 0.5);
`;

const ProfileImage = styled.img`
  width: 100%;
  max-height: 500px;
  object-fit: fill;
`;

const NameContainer = styled.div`
  border-radius: 30px;
  border: 2px solid rgba(143, 143, 143, 0.4);
  width: 100%;
  padding: 0px 30px;
`;

const MemberListContainer = styled.div`
  border-top: 2px solid rgba(235, 235, 235, 0.5);
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

const EditableName = styled.input`
  outline: none;
  border: none;
  font-size: 20px;
  color: #444444;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

export const GroupStyled = {
  GroupContainer,
  ProfileImage,
  NameContainer,
  MemberListContainer,
  EditableName,
};
