import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Gallery({
  fill = '#CACACA',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1059',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M99.7,773.9c-18-42.5-35.9-85-53.8-127.6c-10.3-24.5-20.5-49-30.8-73.5c-2.8-6.8-5.8-13.6-8.2-20.5
			C-7.1,512.8-2,476,23.7,442.6c11-14.3,25.2-24.7,41.8-31.8c14.4-6.1,28.8-12.2,43.2-18.3c0.8-0.3,1.6-0.7,2.4-1
			c3-1.3,3.1-1.4,2.2-4.5c-2.2-7.3-4.3-14.7-5.1-22.4c-4.7-45,11.1-80.8,48.2-107c5.8-4.1,12.1-7.3,18.6-10.1
			c58-24.4,116.1-48.9,174.1-73.4c54.8-23.1,109.5-46.1,164.3-69.2c75.9-32.1,151.9-64.2,227.8-96.3c50.5-21.3,107.7-3.4,137.1,42.9
			c3.1,4.9,5.8,9.9,8,15.2c48.1,113.7,96.2,227.4,144.2,341.1c13.5,31.9,27.4,63.7,40.1,95.9c17.1,43.4,9.9,83-21.6,117.7
			c-10.3,11.4-23,19.7-37.1,25.7c-14.1,6-28.2,12-42.4,18c-0.7,0.3-1.4,0.6-2,0.9c-3.8,1.6-3.8,1.6-2.4,5.4
			c7.2,19.9,8.7,40.3,3.9,60.9c-7,30.3-24.2,53.3-50.5,69.6c-10.1,6.3-21.1,10.5-32,15.1c-182.7,77.2-365.4,154.3-548.2,231.4
			c-19.2,8.1-39.2,12.3-60,8.9c-34.1-5.7-60.8-23-79.4-52.3c-3.3-5.2-6-10.8-8.4-16.5c-30.2-71.6-60.5-143.2-90.7-214.8
			C99.8,773.8,99.8,773.9,99.7,773.9z M594.6,862.9C594.6,862.9,594.6,863,594.6,862.9c91.9-38.7,183.7-77.5,275.5-116.2
			c4.2-1.8,8.3-3.8,12.2-6.2c14.7-9.5,21-24.4,17.5-41.5c-1.4-6.5-3.7-12.7-6.3-18.8c-5.5-12.7-11-25.5-16.3-38.2
			C832.4,536,787.5,430.3,743.3,324.3c-4.4-10.6-8.9-21.2-13.3-31.8c-4.4-10.6-8.6-21.4-13.4-31.8c-6.3-13.4-16.4-22.6-31.2-25.6
			c-10.1-2.1-19.9-0.5-29.4,3.5c-36.7,15.5-73.3,31-110,46.6c-37.2,15.8-74.4,31.6-111.6,47.3c-39,16.5-78,32.9-117,49.3
			c-72.4,30.5-144.9,61-217.3,91.5c-3.3,1.4-6.4,3-9.3,5c-13.9,9.6-21.3,22.6-20.2,39.8c0.4,6.1,1.9,11.9,4.3,17.5
			c13,30.2,25.6,60.6,38.8,90.7c5.9,13.5,11.6,27.1,17.3,40.7c31.6,75,63.5,149.9,94.6,225.1c4.4,10.6,8.8,21.2,13.3,31.9
			c4.8,11.6,9.5,23.2,14.6,34.6c10.1,22.9,32.6,32.6,56.4,24.9c5.2-1.7,10.1-4.1,15.1-6.3c24.5-10.5,49.1-21,73.7-31.4
			C463.9,918.1,529.2,890.5,594.6,862.9z M179.7,362.2c1.4,0.1,2.5-0.7,3.7-1.2c76.8-32.4,153.5-64.8,230.3-97.2
			c74.2-31.3,148.4-62.6,222.6-94c9.3-3.9,19-6.4,29-7.6c21.8-2.7,42.2,2.1,61.6,11.9c23.6,12,41,29.9,51.5,54.4
			c4.1,9.5,8.2,19,12.2,28.4c13.8,32.1,27.4,64.3,41,96.5c30.1,71.3,60.2,142.7,90.3,214c4.4,10.3,8.6,20.7,13,31
			c1.5,3.6,1.6,3.6,5.1,2.1c13.3-5.6,26.7-11.2,39.9-16.9c4.3-1.9,8.5-4.2,12.3-7c12.9-9.6,18.3-22.3,15.6-38.3
			c-0.7-4.4-1.8-8.7-3.5-12.7c-6.4-15.2-12.9-30.4-19.3-45.6c-44.6-105.7-89.2-211.4-133.8-317.1c-4.9-11.7-9.7-23.4-14.7-35.1
			c-4.5-10.6-8.5-21.4-13.5-31.7c-7.9-16.1-24-25.1-41.9-24c-6.4,0.4-12.5,2.1-18.4,4.6c-7.8,3.2-15.4,6.7-23.1,10
			c-14.9,6.4-29.8,12.7-44.8,19c-55.3,23.4-110.5,46.8-165.8,70.2C474.4,198.9,419.7,222,365.1,245c-41.9,17.6-83.7,35.2-125.6,52.8
			c-1.4,0.6-2.8,1-4.1,1.6c-11.3,4.7-22.8,8.9-33.6,14.7c-16.9,9-25.8,26.3-23.2,44.9C178.7,360.1,178.7,361.3,179.7,362.2z"
          />
          <path
            fill={fill}
            d="M580.5,834.3c-65.3,27.6-130.7,55.2-196,82.8c-22.5,9.5-45.1,19.1-67.6,28.7c-5.3,2.3-10.7,4.1-16.5,4.8
			c-19.8,2.4-37.8-13.8-37.5-33.8c0.1-5.8,1.3-11.4,3.2-16.9c5.7-16.7,12.1-33.2,18.8-49.6c12.9-32,24.6-64.5,36.6-96.8
			c9-24.2,18.7-48.1,28-72.1c1.5-3.9,3.5-7.5,5.8-10.9c6-8.6,14.3-12.9,24.7-13.4c5.5-0.3,10.9,0.5,16.3,1.5
			c32.6,6.3,65.1,12.6,97.7,18.9c9.4,1.8,11.1,0.9,15-7.8c9.7-22,19.9-43.8,29.3-65.9c8.9-21.2,17.5-42.6,26.9-63.5
			c8.6-19.1,17.1-38.2,25.7-57.3c1.9-4.2,4.2-8.1,7-11.7c4.9-6.3,11.3-9.4,19.1-9.3c11.1,0.1,21.2,3.4,29.7,10.8
			c30.1,26.3,59.9,52.8,89.6,79.5c33.5,30,66.9,60,100.4,90c6.9,6.2,13.7,12.5,20.6,18.7c6.3,5.7,10.9,12.5,12.8,20.8
			c3.1,13.8-2.6,27.3-14.5,35.4c-3.1,2.1-6.4,3.7-9.8,5.1C757.4,759.6,668.9,797,580.5,834.3z"
          />
          <path
            fill={fill}
            d="M211.2,524.1c11,26.1,2.5,54.5-20.6,69.1c-29.1,18.4-64.5,5.9-78.3-20.7c-8.3-16-10-32.7-3-49.5
			c8.1-19.4,23.3-30.6,43.9-33.3c20.7-2.7,37.5,5.3,50.4,21.5C207,515.3,209.7,519.9,211.2,524.1z"
          />
        </g>
      </g>
    </svg>
  );
}
