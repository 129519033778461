import styled from 'styled-components/macro';
import { fadeIn } from '../../../CommonComponents/Animation';

const Container = styled.div`
  animation: ${fadeIn} 500ms;
  position: absolute;
  border-radius: 0px 25px 25px 25px;
  color: grey;
  box-shadow: 0px 0px 5px 0px #484848;
  padding-bottom: 2%;
  width: 430px;
  background-color: white;
  transform: translate(30px, 5px);
  z-index: 100;
`;

const Scrollable = styled.div`
  height: 400px;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

const CameraIconWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 30px 30px 0px 0px;
  animation: ${fadeIn} 300ms;
  :hover {
    cursor: pointer;
  }
`;

export const UserProfileStyled = {
  Container,
  CameraIconWrapper,
  Scrollable,
};
