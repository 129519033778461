import { GET_USER_ERROR, GET_USER_LOADING, GET_USER_SUCCESS } from '../actionTypes';
import { getUserInfoById } from '../../../Common/actions';

export function getUserError(bool: boolean) {
  return {
    type: GET_USER_ERROR,
    hasError: bool,
  };
}

export function getUserLoading(bool: boolean) {
  return {
    type: GET_USER_LOADING,
    isLoading: bool,
  };
}

export function getUserSuccess(payload) {
  return {
    type: GET_USER_SUCCESS,
    payload: payload,
  };
}

export function getUserInfo(userId: string) {
  return async dispatch => {
    dispatch(getUserLoading(true));
    try {
      const response = await getUserInfoById(userId);
      dispatch(getUserSuccess(response.data.friend));
    } catch (error) {
      dispatch(getUserError(true));
    }
  };
}
