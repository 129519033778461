import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../CommonComponents/Flex';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';

const { Span, Link } = TypographyStyled;

export default function EmailNotificationsForm({ onSubmit, emailNotifications }) {
  const { t } = useTranslation();
  return (
    <>
      <Flex container flexDirection="column" alignItems="center" width="75%">
        <Span size="xxl" fontWeight="bold" fontColor="#444444" margin="25px">
          {t('EmailNotifications')}
        </Span>
        <Span size="xl" fontColor="#444444" margin="25px" center>
          {t('AutomaticallyNotifiedViaEmailFromEveryUnreadMessagesFromLAGOON')}
        </Span>
      </Flex>
      <Flex margin="25px">
        {emailNotifications ? (
          <Link size="xxl" fontColor="#CACACA" textDecoration="underline" onClick={onSubmit}>
            {t('TurnOff')}
          </Link>
        ) : (
          <Link size="xxl" fontColor="#09BBFF" textDecoration="underline" onClick={onSubmit}>
            {t('TurnOn')}
          </Link>
        )}
      </Flex>
      <Flex margin="10px">
        <Span size="lg" fontColor="#444444" center>
          {t(emailNotifications ? 'CurrentStatusOn' : 'CurrentStatusOff')}
        </Span>
      </Flex>
    </>
  );
}
