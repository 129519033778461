import {
  FILTER_FRIENDS_BY_NAME,
  GET_FRIEND_INFO,
  CLEAR_FRIENDS_REDUCER,
  UPDATE_FRIEND_IN_LIST,
  UPDATE_FRIEND_LIST,
  GET_USER_INFO,
} from './actionTypes';

export function filterFriendsByName(name: string) {
  return {
    type: FILTER_FRIENDS_BY_NAME,
    name: name,
  };
}

export function getFriendInfo(selectedFriend) {
  return {
    type: GET_FRIEND_INFO,
    friend: selectedFriend,
  };
}

export function getUserInfo(selectedUser) {
  return {
    type: GET_USER_INFO,
    user: selectedUser,
  };
}

export function clearFriendReducer() {
  return {
    type: CLEAR_FRIENDS_REDUCER,
  };
}

export function updateFriendList(friendList) {
  return {
    type: UPDATE_FRIEND_LIST,
    friendList,
  };
}

export function updateFriendInList(friendNewInfo) {
  return {
    type: UPDATE_FRIEND_IN_LIST,
    friendNewInfo,
  };
}
