import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function UnCheck({
  style = { fill: 'none', stroke: '#BDBDBD', strokeWidth: '25', strokeMiterLimit: '10' },
  fill = 'grey',
  width = '100%',
  className = '',
  viewBox = '0 0 855 855',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="m836.727289,426.251823c1.4,226.75 -186.89,416.1 -420.44,410.99c-221.73,-4.85 -407.06,-189.71 -401.32,-422.02c5.47,-221.21 190.27,-405.99 422.43,-399.9c220.57,5.8 400.36,187.82 399.33,410.93zm0,0c1.4,226.75 -186.89,416.1 -420.44,410.99c-221.73,-4.85 -407.06,-189.71 -401.32,-422.02c5.47,-221.21 190.27,-405.99 422.43,-399.9c220.57,5.8 400.36,187.82 399.33,410.93z" />
    </svg>
  );
}
