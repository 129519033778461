import React, { FC, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CreateChannelBodyStyled } from './styled';
import { isNotEmptyArray } from '../../utils/arrays';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { handleMembers } from '../actions';
import { FriendData } from '../../Common/interfaces/friend';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { ChannelData } from '../../Common/interfaces/channel';

const { SearchFriendListContainer, FriendListCard, FriendListCardWrapper, IconAndNameWrapper } = CreateChannelBodyStyled;
const { UserAvatar } = ImageStyled;
const { Span } = TypographyStyled;

interface Props {
  selectedFriends: Array<FriendData>;
  groupExist?: boolean;
}

const CreateChannelResultList: FC<Props> = ({ selectedFriends, groupExist }): ReactElement => {
  const dispatch = useDispatch();
  const createChannelState = useSelector(state => state.createChannelReducer);
  const { members, group }: { members: Array<FriendData>; group: ChannelData } = createChannelState;

  const checkIfMember = (uid: string) => {
    let isMember = false;
    if (isNotEmptyArray(members)) {
      const index = members.findIndex(member => member.friendId === uid);
      if (index >= 0 && !members[index].isRemoved) {
        isMember = true;
      }
    }
    return isMember;
  };

  const handleMember = friend => {
    let isAlreadyAMember = false;
    if (groupExist) {
      const c_group: ChannelData = { ...group };
      if (isNotEmptyArray(c_group.members)) {
        const index = c_group.members.findIndex(member => member.id === friend.friendId);
        if (index >= 0) {
          isAlreadyAMember = true;
        }
      }
    }
    if (!isAlreadyAMember) {
      dispatch(handleMembers(friend));
    }
  };

  return (
    <SearchFriendListContainer>
      {isNotEmptyArray(selectedFriends) &&
        selectedFriends.map((friend, key) => {
          return (
            <FriendListCard key={key} onClick={() => handleMember(friend)}>
              <FriendListCardWrapper>
                <IconAndNameWrapper>
                  <UserAvatar src={friend.friendProfileImageUrl} width="46" height="46" />
                  <Span fontColor="black" size="md" margin="0px 0px 0px 25px">
                    {friend.friendDisplayName}
                  </Span>
                </IconAndNameWrapper>
                {!checkIfMember(friend.friendId) ? (
                  <IconContainer iconName="uncheck" iconWidth="30px" />
                ) : (
                  <IconContainer iconName="select" iconWidth="30px" iconFill="grey" />
                )}
              </FriendListCardWrapper>
            </FriendListCard>
          );
        })}
    </SearchFriendListContainer>
  );
};

export default CreateChannelResultList;
