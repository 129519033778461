import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  margin: 5px 0px 0px 0px;
  background-color: #e1e1e1;
  border-radius: 25px;
  transition: 300ms;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const RepliesStyled = { Container };
