import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function DownloadWithBorder({
  style = {},
  fill = '#D2D2D2',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      fill={fill}
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M540,44.4c66.8,0,131.7,13.1,192.7,38.9c58.9,24.9,111.9,60.6,157.4,106.1c45.5,45.5,81.2,98.4,106.1,157.4
			c25.8,61,38.9,125.8,38.9,192.7S1021.9,671,996.1,732C971.2,791,935.5,843.9,890,889.4c-45.5,45.5-98.4,81.2-157.4,106.1
			c-61,25.8-125.8,38.9-192.7,38.9s-131.7-13.1-192.7-38.9c-58.9-24.9-111.9-60.6-157.4-106.1C144.5,843.9,108.8,791,83.9,732
			C58.1,671,45,606.2,45,539.4s13.1-131.7,38.9-192.7c24.9-58.9,60.6-111.9,106.1-157.4c45.5-45.5,98.4-81.2,157.4-106.1
			C408.3,57.5,473.2,44.4,540,44.4 M540-0.6C241.8-0.6,0,241.1,0,539.4s241.8,540,540,540s540-241.8,540-540S838.2-0.6,540-0.6
			L540-0.6z"
          />
        </g>
      </g>
      <g display="none">
        <g display="inline">
          <path
            fill="#FFFFFF"
            d="M761.5,498.1c0-122.3-99.2-221.5-221.5-221.5c-103.8,0-190.7,71.5-214.7,167.9
			c-66.5,10.1-117.6,67.4-117.6,136.7c0,76.5,62,138.4,138.4,138.4h110.8c15.3,0,27.7-12.4,27.7-27.7v-76.1
			c0-11.5-9.3-20.8-20.8-20.8h-42.4c-20.4,0-26.1-14-12.6-31.1l106.8-136.1c13.4-17.1,35.4-17.1,48.8,0l106.8,136.1
			c13.4,17.1,7.7,31.1-12.6,31.1h-42.4c-11.5,0-20.8,9.3-20.8,20.8v76.1c0,15.3,12.4,27.7,27.7,27.7h138.4
			c61.2,0,110.8-49.6,110.8-110.8S822.7,498.1,761.5,498.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              fill={fill}
              d="M761,498.2c0-89.5-53.2-166.4-129.6-201.2c-23.9-10-36.4,8.2-36.4,23v117.6c0,11.5,9.3,19.1,20.7,19.1h42.3
				c20.4,0,26,15.6,12.6,32.7l-106.7,136c-13.4,17.1-35.3,17.1-48.7,0l-106.7-136c-13.4-17.1-7.7-32.7,12.6-32.7h42.3
				c11.5,0,20.7-7.7,20.7-19.1V320c0-15.3-20.8-29.6-38.1-22.3c0,0-0.1,0-0.1,0c-59.6,27.9-104.7,81.7-121,146.9
				c-66.5,10.1-117.5,67.3-117.5,136.6c0,76.4,61.9,138.3,138.3,138.3h415c61.1,0,110.7-49.5,110.7-110.7S822.1,498.2,761,498.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path fill={fill} d="M650.1,210.2c0-0.2,0.2-0.6,0.2-0.8C650.4,207.1,650.3,207.8,650.1,210.2z" />
          </g>
        </g>
        <g>
          <g>
            <path fill={fill} d="M429,209.4c0,0.2,0.2,0.6,0.2,0.9C429.1,207.8,429,207.1,429,209.4z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
