import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Back({
  style = {},
  fill = '#42BCF0',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle fill="none" stroke={fill} strokeWidth="50" strokeMiterlimit="10" cx="541" cy="540.9" r="513.1" />
      <g>
        <path
          fill={fill}
          d="M502.1,1078v-50.8c126.1,0,228.8-102.6,228.8-228.8S628.3,569.6,502.1,569.6h-76.3v69.8L212,425.6l213.9-213.9
		v69.8h76.3c106.4,0,206.4,41.4,281.6,116.6s116.6,175.2,116.6,281.6S859,886.1,783.7,961.4C708.5,1036.6,608.5,1078,502.1,1078z"
        />
        <path
          fill={fill}
          d="M783.7,398.2c-75.2-75.2-175.2-116.6-281.6-116.6h-76.3v-69.8L212,425.6h290.1
		c173.1,0,313.5,140.4,313.5,313.5s-140.4,313.5-313.5,313.5v25.4c106.4,0,206.4-41.4,281.6-116.6s116.6-175.2,116.6-281.6
		S859,473.4,783.7,398.2z"
        />
      </g>
    </svg>
  );
}
