import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FreeCard, StandardCard, Container } from './styled';

export default function Pricing() {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <p className="title">{t('SimplePricingWithinYourNeeds')}</p>
      <p className="message">{t('ChooseTheBestPlanForYourNeeds')}</p>
      <div className="card-container">
        <FreeCard className="animate__animated animate__flipInY">
          <div className="card-header">{t('Free').toUpperCase()}</div>
          <div className="card-body">
            <p className="title">{t('Free').toUpperCase()}</p>
            <p className="items">{t('UnlimitedGroupChats')}</p>
            <p className="items">{t('ReactionEmoji')}</p>
            <p className="items">{t('ThreadReply')}</p>
            <p className="items">{t('PricingSharedFiles')}</p>
            <p className="items">{t('ColoredText')}</p>
            <p className="items">{t('TwoGbStorage')}</p>
            <br />
            <br />
            <Link to="/" className="w-75">
              <button className="btn btn-secondary rounded-pill text-white w-100 p-3">{t('StartNow')}</button>
            </Link>
          </div>
        </FreeCard>
        <StandardCard className="animate__animated animate__flipInY">
          <div className="card-header">{t('Standard')}</div>
          <div className="card-body">
            <div className="annual-price">
              <span className="price">
                <span className="amount">6</span>
                <div className="sub-amount">
                  <span className="decimal">50</span>
                  <span className="per-month">/{t('Month')}</span>
                </div>
                <div className="vertifical-line-with-message">
                  {i18n.language === 'en' ? (
                    <>
                      <p>{t('IfBilledAnnualy.1')}</p>
                      <p>{t('IfBilledAnnualy.2')}</p>
                      <p>{t('IfBilledAnnualy.3')}</p>
                    </>
                  ) : (
                    <div className="translateY-15px">
                      <p className="mb-0">{t('IfBilledAnnualy.1')}</p>
                      <p className="mb-0">{t('IfBilledAnnualy.2')}</p>
                      <p className="mb-0">{t('IfBilledAnnualy.3')}</p>
                      <p className="mb-0">{t('IfBilledAnnualy.4')}</p>
                      <p className="mb-0">{t('IfBilledAnnualy.5')}</p>
                      <p className="mb-0">{t('IfBilledAnnualy.6')}</p>
                    </div>
                  )}
                </div>
              </span>
            </div>
            <p className="title">¥750 / {t('Monthly')}</p>
            <p className="items">{t('UnlimitedGroupChats')}</p>
            <p className="items">{t('ReactionEmoji')}</p>
            <p className="items">{t('ThreadReply')}</p>
            <p className="items">{t('PricingSharedFiles')}</p>
            <p className="items">{t('ColoredText')}</p>
            <p className="items mb-3">{t('FiveGbStorage')}</p>
            <a className="w-75" href="https://www.x-climb.com/contact.html" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-secondary rounded-pill text-white w-100 p-3">{t('UpgradeNow')}</button>
            </a>
          </div>
        </StandardCard>
      </div>
    </Container>
  );
}
