import React, { FC } from 'react';
import { ChannelData } from '../../../Common/interfaces/channel';
import { isNotEmptyArray } from '../../../utils/arrays';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { IsTypingStyled } from './styled';
import { UserData } from '../../../Common/interfaces/user';
import useGetAllUsersThatTyping from '../../../Common/customHooks/useGetAllUsersThatTyping';
import useGetActiveConversation from '../../../Common/customHooks/useGetActiveConversation';

const { Span } = TypographyStyled;
const { Container } = IsTypingStyled;

interface Props {
  activeConversation: ChannelData;
  loggedInUser: UserData;
}

const IsTyping: FC<Props> = ({ activeConversation, loggedInUser }) => {
  const activeConversationFromRedux: ChannelData = useGetActiveConversation();
  const typingUsers = useGetAllUsersThatTyping(activeConversation, loggedInUser);

  return (
    <Container visible={activeConversationFromRedux && activeConversationFromRedux.id === activeConversation.id}>
      <Span size="md" fontColor="#939393">
        {isNotEmptyArray(typingUsers) &&
          typingUsers.map((user: any, index: number) => {
            if (user && user.isTyping) {
              return `${`${user.user.displayName} is typing... ${index === typingUsers.length ? ',' : ''}`}`;
            } else {
              return '';
            }
          })}
      </Span>
    </Container>
  );
};

export default IsTyping;
