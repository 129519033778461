import styled from 'styled-components/macro';
import { fadeIn } from '../Animation';

interface TypographyProps {
  fontColor: string;
  fontWeight?: string;
  size: string;
  textDecoration?: string;
  lineHeight?: string;
  center?: boolean;
  width?: string;
  margin?: string;
  padding?: string;
  fadeIn?: boolean;
  isOneLine?: boolean;
}

const determineFontSize = (size: any): number => {
  switch (size) {
    case 'xs':
      return 10;
    case 'sm':
      return 13;
    case 'md':
      return 15;
    case 'lg':
      return 20;
    case 'xl':
      return 25;
    case 'xxl':
      return 30;
    case 'xxxl':
      return 40;
    case 'xxxxl':
      return 50;
    case 'xxxxxl':
      return 65;
    default:
      return size;
  }
};

const Span = styled.span`
  font-size: ${(props: TypographyProps) => props && props.size && determineFontSize(props.size)}px;
  font-weight: ${(props: TypographyProps) => props && props.fontWeight};
  color: ${(props: TypographyProps) => props && props.fontColor};
  line-height: ${(props: TypographyProps) => props && props.lineHeight};
  text-align: ${(props: TypographyProps) => props && props.center && 'center'};
  width: ${(props: TypographyProps) => props && props.width};
  margin: ${(props: TypographyProps) => props && props.margin};
  padding: ${(props: TypographyProps) => props && props.padding};
  transition: 0.3s;
  animation: ${(props: TypographyProps) => props && props.fadeIn && fadeIn} 500ms;
  overflow-wrap: anywhere;
`;

const Paragraph = styled(Span)`
  display: block;
`;

const Link = styled(Span)`
  text-decoration: ${(props: TypographyProps) => props && props.textDecoration};
  &:hover {
    cursor: pointer;
  }
`;

const Pre = styled.pre`
  font-size: ${(props: TypographyProps) => props && props.size && determineFontSize(props.size)}px;
  font-family: MPLUSRounded1c-Regular;
  color: ${(props: TypographyProps) => props && props.fontColor};
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  max-height: ${(props: TypographyProps) => props && props.isOneLine && '20px'};
  overflow: ${(props: TypographyProps) => props && props.isOneLine && 'hidden'};
`;

const BorderedSpan = styled(Span)`
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: grey;
  margin: 10px 0px;
  margin-right: 10px;
  padding-left: 2%;
  width: 90%;
  padding: 10px;
`;

const CursorPointer = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const TypographyStyled = {
  Span,
  Link,
  Pre,
  Paragraph,
  BorderedSpan,
  CursorPointer,
};
