import styled from 'styled-components/macro';

const media = {
  extraSmall: '@media(max-width: 600px)',
  small: '@media(min-width: 600px)',
  medium: '@media(min-width: 768px)',
  large: '@media(min-width: 992px)',
  extraLarge: '@media(min-width: 1200px)',
};

export const Container = styled.div`
  font-family: Montserrat-Light !important;
  width: 100%;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2FNeed%20to%20know%20more%20Background.png?alt=media&token=cb19d932-b407-4c17-a314-83831cdbcb64');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  .title {
    font-family: Montserrat-Light !important;
    color: white;
    margin: 15px;
    text-align: center;
    ${media.extraSmall} {
      font-size: 25px;
    }
    ${media.small} {
      font-size: 25px;
    }
    ${media.medium} {
      font-size: 20px;
    }
    ${media.large} {
      font-size: 50px;
    }
    ${media.extraLarge} {
      font-size: 60px;
    }
  }
  .demo {
    font-family: Montserrat-Light !important;
    background-color: transparent;
    color: white;
    margin: 15px;
    border-radius: 50px;
    font-weight: bolder;
    outline: none;
    margin: 20px;
    ${media.extraSmall} {
      height: 35px;
      width: 150px;
      font-size: 10px;
      border: 1px solid white;
    }
    ${media.small} {
      height: 35px;
      width: 150px;
      font-size: 10px;
      border: 1px solid white;
    }
    ${media.medium} {
      height: 70px;
      width: 305px;
      font-size: 20px;
      border: 2px solid white;
    }
    ${media.large} {
      height: 80px;
      width: 350px;
      font-size: 25px;
      border: 3px solid white;
    }
    ${media.extraLarge} {
      height: 80px;
      width: 350px;
      font-size: 25px;
      border: 3px solid white;
    }
    :hover {
      cursor: pointer;
    }
  }
`;
