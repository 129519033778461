import React, { useState, useEffect } from 'react';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { Container } from './styled';
import { currentLang } from '../../utils/language';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Header() {
  const { t } = useTranslation();
  const [isBurgerOpen, toggleBurger] = useState(false);
  const [isBurgerLanguageOption, toggleBurgerLanguageOption] = useState(false);
  const [language, setLanguage] = useState(currentLang);
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleClick = () => {
    if (language === 'en') {
      setLanguage('ja');
    } else {
      setLanguage('en');
    }
  };

  const handleToggleNavDropdown = () => {
    toggleBurger(isOpen => !isOpen);
    toggleBurgerLanguageOption(false);
  };

  const handleToggleBurgerLanguage = () => {
    toggleBurgerLanguageOption(isOpen => !isOpen);
    const element = document.getElementById('burger-language');
    if (element) {
      element.classList.toggle('with-border-bottom');
    }
  };

  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    toggleBurger(false);
  };

  return (
    <Container>
      <Link to="/lp" className={`active`} onClick={handleNavClick}>
        <IconContainer iconName="logo" iconWidth="40px" />
      </Link>
      <div className="navigation">
        <Link to="/lp/features" className={location.pathname === '/lp/features' ? 'active' : ''} onClick={handleNavClick}>
          {t('Features')}
        </Link>
        <Link to="/lp/pricing" className={location.pathname === '/lp/pricing' ? 'active' : ''} onClick={handleNavClick}>
          {t('Pricing')}
        </Link>
        <Link to="/lp/download" className={location.pathname === '/lp/download' ? 'active' : ''} onClick={handleNavClick}>
          {t('Download')}
        </Link>
      </div>
      <div className="burger-container">
        <IconContainer iconName="thread" iconWidth="40px" onClick={handleToggleNavDropdown} iconFill="transparent" />
      </div>
      <div className="language-container" onClick={handleClick}>
        <IconContainer iconName="world" iconWidth="40px" />
        <span className="pointer">{t('PrevLanguage')}</span>
      </div>
      {isBurgerOpen && (
        <div className="burger-options-container">
          <div className="burger-items">
            {!isBurgerLanguageOption && (
              <>
                <Link to="/lp/features" className="with-border-bottom" onClick={handleNavClick}>
                  {t('Features')}
                </Link>
                <Link to="/lp/pricing" className="with-border-bottom" onClick={handleNavClick}>
                  {t('Pricing')}
                </Link>
                <Link to="/lp/download" className="with-border-bottom" onClick={handleNavClick}>
                  {t('Download')}
                </Link>
              </>
            )}
            <p id="burger-language" onClick={handleToggleBurgerLanguage}>
              {t('Language')}
            </p>
            {isBurgerLanguageOption && (
              <>
                <p>{t('English')}</p>
                <p>{t('Japanese')}</p>
              </>
            )}
          </div>
        </div>
      )}
    </Container>
  );
}
