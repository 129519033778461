import styled from 'styled-components/macro';

interface MessageContainerProps {
  isActive: boolean;
  isRecursive: boolean;
}

const MesssageContainer = styled.div`
  background-color: ${(props: MessageContainerProps) => props && props.isActive && 'whitesmoke'};
  display: flex;
  width: 100%;
  padding: ${(props: MessageContainerProps) => (props && props.isRecursive ? '0px 67px' : '10px 67px 5px 67px')};
  transition-duration: 300ms;
  transition-property: background-color;
  :hover {
    background-color: whitesmoke;
  }
`;

const DateContainer = styled.div`
  height: 40px;
  width: 150px;
  display: flex;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0px #00000029;
  border-radius: 30px;
`;

export const MessageStyled = {
  MesssageContainer,
  DateContainer,
};
