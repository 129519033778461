import React, { ReactElement, useState, useEffect } from 'react';
import { version } from '../../../package.json';
import { Link as RouterLink } from 'react-router-dom';
import { isChrome } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { currentLang } from '../../utils/language';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import signInUsingDemoAccount from '../actions/signInUsingDemoAccount';
import Swal from 'sweetalert2';

export default function WelcomeContainer(): ReactElement {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(currentLang);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleClickLanguage = () => {
    if (language === 'en') {
      setLanguage('ja');
    } else {
      setLanguage('en');
    }
  };

  const redirectToAndroidPlaystore = () => window.open('https://play.google.com/store/apps/details?id=com.xclimb.messaging_app&hl=en');
  const redirectToIOSPlaystore = () => window.open('https://apps.apple.com/us/app/id1480710365');

  const handleCreateDemoAccount = async () => {
    if (isChrome) {
      await dispatch(signInUsingDemoAccount());
    } else {
      Swal.fire({
        icon: 'error',
        title: t('ForTheMeantimeLAGOONWebWorksBestInGoogleChrome'),
        confirmButtonText: t('Ok'),
        width: '635px',
        customClass: {
          popup: 'non-chrome-users-pop-up-class',
          confirmButton: 'swal-confirm-primary-button-class',
        },
      });
    }
  };

  const showVersion = () => {
    console.log(version);
    Swal.fire({
      icon: 'info',
      title: 'Current Version: ' + version,
    });
  };

  return (
    <div className="container-fluid w-100 text-white col-12 col-lg-7 col-xl-8">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="navbar-brand" onClick={showVersion}>
          <IconContainer iconName="logo" iconWidth="55px" />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <IconContainer iconName="thread" iconWidth="55px" iconFill="transparent" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav align-items-center">
            <span className="nav-link text-white text-center large-text">{t('Lagoon')}</span>
            <span onClick={() => window.open('https://www.x-climb.com/')} className="nav-link text-white text-center large-text pointer">
              {t('Company')}
            </span>
            <div className="nav-link d-none d-lg-block pointer" onClick={redirectToIOSPlaystore}>
              <img src={process.env.PUBLIC_URL + '/assets/images/apple_store.png'} className="img-fluid" alt="apple store" />
            </div>
            <div className="nav-link d-none d-lg-block pointer" onClick={redirectToAndroidPlaystore}>
              <img src={process.env.PUBLIC_URL + '/assets/images/google_store.png'} className="img-fluid" alt="android store" />
            </div>
            <span onClick={handleClickLanguage} className="nav-link text-white text-center large-text d-block d-lg-none">
              {t('PrevLanguage')}
            </span>
          </div>
        </div>
      </nav>
      <div className="container-fluid d-flex text-center text-lg-left flex-column mt-5">
        <div className="w-100 d-flex d-lg-none justify-content-around mb-5">
          <img
            src={process.env.PUBLIC_URL + '/assets/images/apple_store.png'}
            className="img-fluid sign-up-download mx-2"
            alt="apple store"
          />
          <img
            src={process.env.PUBLIC_URL + '/assets/images/google_store.png'}
            className="img-fluid sign-up-download mx-2"
            alt="android store"
          />
        </div>
        <p className="sign-up-message-title">{t('WelcomeToLagoon')}</p>
        <p className="sign-up-message-sub-title mb-5">{t('LagoonSlogan')}</p>
        <div className="d-flex justify-content-center justify-content-lg-start">
          <RouterLink to="/lp" target="_blank">
            <button className="btn-outline-primary w-220px btn-normal-text sign-up-know-more">{t('KnowMore')}</button>
          </RouterLink>
          <button className="btn-outline-primary w-220px btn-normal-text d-none d-lg-block" onClick={handleCreateDemoAccount}>
            {t('Demo')}
          </button>
        </div>
      </div>
      <div className="position-fixed d-none d-lg-flex align-items-center bottom-50px pointer" onClick={handleClickLanguage}>
        <IconContainer iconName="world" iconWidth="40px" />
        <span className="btn-normal-text ml-3">{t('PrevLanguage')}</span>
      </div>
    </div>
  );
}
