import * as React from 'react';
import { useField } from 'formik';
import Select from 'react-select';
import { InputStyled } from './Input.style';
import { TypographyStyled } from '../Typography/Typography.style';
import { useTranslation } from 'react-i18next';
import Flex from '../Flex';
import IconContainer from '../Decoration/IconContainer';
import { NotificationStyled } from '../../Notification/style';

const { InputText } = InputStyled;
const { Span } = TypographyStyled;
const { dropDownCustomStyle } = NotificationStyled;

export const TextInput: React.FC<any> = ({ label, inputType, disabled, className, ...props }) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();
  return (
    <>
      {meta.error && meta.touched && (
        <Span fontColor="#c72e44" size="md">
          {t(meta.error)}
        </Span>
      )}
      <InputText
        {...field}
        type={inputType}
        placeholder={label}
        className={className}
        backgroundColor="rgba(0, 0, 0, 0.1)"
        autoComplete="off"
        width="100%"
        borderRadius="45px"
        placeholderColor="white"
        fontSize="30px"
        textAlign="center"
        color="white"
        padding="10px"
        disabled={disabled}
      />
    </>
  );
};

export const ChangePasswordInput: React.FC<any> = ({ label, inputType, ...props }) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();
  return (
    <>
      <div className="row no-gutters flex-column align-items-center justify-items-center">
        <input
          {...field}
          placeholder={t(label)}
          type="password"
          autoComplete="off"
          className={`form-control bg-light rounded-pill text-lg ${meta.touched && meta.error ? 'is-invalid' : ''}`}
        />
        <div className="invalid-feedback col-12">
          {meta.error && meta.touched ? <p>{t(meta.error)}</p> : meta.touched && <IconContainer iconName="check" iconWidth="25px" />}
        </div>
      </div>
    </>
  );
};

export const CustomSelect: React.FC<any> = ({ label, inputType, options, disabled, width, formik, ...props }) => {
  return (
    <Flex width={width}>
      <Select
        name={props.name}
        value={options ? options.find(option => option.value === props.value) : ''}
        options={options}
        disabled={disabled}
        onChange={option => formik.setFieldValue(props.name, option.value)}
        style={dropDownCustomStyle}
      />
    </Flex>
  );
};
