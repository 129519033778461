import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isNotEmptyArray } from '../../utils/arrays';
import { ChannelData } from '../interfaces/channel';

export default function() {
  const chatList: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);
  const activeConversationId: string = useSelector(state => state.conversationReducer.activeConversationId);
  const [activeConversation, setConversation] = useState<any>();

  useEffect(() => {
    if (activeConversationId) {
      if (isNotEmptyArray(chatList)) {
        const conversationIndex = chatList.findIndex(conversation => conversation.id === activeConversationId);
        if (conversationIndex >= 0) {
          setConversation(chatList[conversationIndex]);
        }
      }
    }
  }, [activeConversationId, chatList]);

  return activeConversation;
}
