import React, { ReactElement } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

interface EmojiPickerProps {
  handleEmojiSelect: (emoji: string) => void;
}

export default function EmojiPicker(props: EmojiPickerProps): ReactElement {
  return (
    <Picker
      style={{ position: 'absolute', bottom: '27px', right: '0px' }}
      title="lagoon"
      showPreview={false}
      showSkinTones={false}
      onSelect={props.handleEmojiSelect}
    />
  );
}
