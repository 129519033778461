import styled from 'styled-components/macro';

interface ModalProps {
  visible: boolean;
  dimmed?: boolean;
}

const ModalContainer = styled.div`
  display: ${(props: ModalProps) => (props && props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props: ModalProps) => (props && props.dimmed ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.0)')};
`;

export const ModalStyled = { ModalContainer };
