import {
  INIT,
  SIGN_IN_LOADING,
  SIGN_IN_HAS_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_LOADING,
  SIGN_OUT_HAS_ERROR,
  SIGN_OUT_SUCCESS,
  SIGN_UP_LOADING,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  UPLOAD_USER_IMAGE_LOADING,
  UPLOAD_USER_IMAGE_ERROR,
  UPLOAD_USER_IMAGE_SUCCESS,
  UPDATE_USER_INFO_LOADING,
  UPDATE_USER_INFO_ERROR,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO,
  SIGN_IS_USING_DEMO_ACCOUNT_LOADING,
  SIGN_IS_USING_DEMO_ACCOUNT_ERROR,
  SIGN_IS_USING_DEMO_ACCOUNT_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  isDemoAccount: false,
  loggedInUser: {},
  signInHasError: false,
  signInIsLoading: false,
  signUpHasError: false,
  signUpIsLoading: false,
  signUpIsSuccess: false,
  signUpMessage: '',
  token: '',
  uploadUserImageIsLoading: false,
  uploadUserImageHasError: false,
  updateUserInfoIsLoading: false,
  updateUserInfoHasError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        loggedInUser: action.user,
        token: action.token,
        isDemoAccount: action.isDemoAccount,
      };
    case SIGN_IN_LOADING:
      return {
        ...state,
        signInIsLoading: action.isLoading,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
        isDemoAccount: false,
        loggedInUser: action.user,
        signInHasError: false,
        signInIsLoading: false,
      };
    case SIGN_IN_HAS_ERROR:
      return {
        ...state,
        signInHasError: action.hasError,
        signInIsLoading: false,
        signUpIsSuccess: false,
      };
    case SIGN_IS_USING_DEMO_ACCOUNT_LOADING:
      return {
        ...state,
        signInIsLoading: action.isLoading,
      };
    case SIGN_IS_USING_DEMO_ACCOUNT_ERROR:
      return {
        ...state,
        signInHasError: action.hasError,
        signInIsLoading: false,
        signUpIsSuccess: false,
      };
    case SIGN_IS_USING_DEMO_ACCOUNT_SUCCESS:
      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
        isDemoAccount: true,
        loggedInUser: action.user,
        signInHasError: false,
        signInIsLoading: false,
      };
    case SIGN_UP_LOADING:
      return {
        ...state,
        signUpIsLoading: action.isLoading,
        signUpIsSuccess: false,
        signUpMessage: '',
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpIsLoading: false,
        signUpIsSuccess: true,
        signUpMessage: action.message,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUpHasError: action.hasError,
        signUpIsLoading: false,
        signUpMessage: action.errMessage,
      };
    case UPLOAD_USER_IMAGE_LOADING:
      return {
        ...state,
        uploadUserImageIsLoading: action.isLoading,
      };
    case UPLOAD_USER_IMAGE_ERROR:
      return {
        ...state,
        uploadUserImageHasError: action.hasError,
      };
    case UPLOAD_USER_IMAGE_SUCCESS:
      let userWithUpdatedImage: any = { ...state.loggedInUser };
      userWithUpdatedImage.profileImageUrl = action.profileImageUrl;
      return {
        ...state,
        loggedInUser: userWithUpdatedImage,
        uploadUserImageIsLoading: false,
      };
    case UPDATE_USER_INFO_LOADING:
      return {
        ...state,
        updateUserInfoIsLoading: action.isLoading,
      };
    case UPDATE_USER_INFO_ERROR:
      return {
        ...state,
        updateUserHasError: action.hasError,
      };
    case UPDATE_USER_INFO_SUCCESS:
      let tempUserObject: any = { ...state.loggedInUser };
      tempUserObject.displayName = action.newUserObj.displayName;
      tempUserObject.companyName = action.newUserObj.companyName;
      return {
        ...state,
        loggedInUser: tempUserObject,
        updateUserInfoIsLoading: false,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        loggedInUser: action.userInfo,
      };

    case SIGN_OUT_LOADING:
      return {
        ...state,
        signOutIsLoading: action.isLoading,
      };
    case SIGN_OUT_SUCCESS:
      return initialState;
    case SIGN_OUT_HAS_ERROR:
      return {
        ...state,
        signOutHasError: action.hasError,
        signOutIsLoading: false,
      };
    default:
      return state;
  }
};
