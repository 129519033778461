import React, { useState, FC, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SearchConversationStyled } from './styled';
import { SECOND_PANEL_VIEW } from '../../../Common/enums';
import { filterMessagesByName } from '../../../ChatList/actions';
import { filterFriendsByName } from '../../../FriendList/actions';
import { filterChannelsByName } from '../../../ChannelList/actions';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';

const { SearchInputContainer, SearchInput } = SearchConversationStyled;

const SidePanelSearch: FC<any> = (): ReactElement => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const view: SECOND_PANEL_VIEW = useSelector(state => state.navigationReducer.secondPanelView);

  const handleChange = e => setSearchInput(e.target.value);

  const handleClear = () => {
    setSearchInput('');
    if (view === SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW) {
      handleSearchConversationByName('');
    } else {
      handleSearchFriendOrChannelByName('');
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (view === SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW) {
        handleSearchConversationByName(searchInput);
      } else {
        handleSearchFriendOrChannelByName(searchInput);
      }
    }
  };

  const handleSearchConversationByName = (query: string) => {
    dispatch(filterMessagesByName(query.toLocaleLowerCase()));
  };

  const handleSearchFriendOrChannelByName = (query: string) => {
    dispatch(filterFriendsByName(query.toLocaleLowerCase()));
    dispatch(filterChannelsByName(query.toLocaleLowerCase()));
  };

  return (
    <SearchInputContainer>
      {searchInput && (
        <IconContainer margin="0px 5px" onClick={handleClear} width="10%" iconName="close" iconWidth="20px" iconFill="#B3B7B8" />
      )}
      <SearchInput type="text" value={searchInput} onChange={handleChange} onKeyPress={handleKeyPress} />
    </SearchInputContainer>
  );
};

export default SidePanelSearch;
