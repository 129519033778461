import styled from 'styled-components/macro';

interface ContainerProps {
  background: string;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  padding: 50px;
  background-image: url(${(props: ContainerProps) => props.background});
  background-size: cover;
`;
