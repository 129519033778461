import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Smiley({ style = {}, width = '100%', className = '', viewBox = '0 0 1080 1080' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="540" y1="-5" x2="540" y2="1398.46">
        <stop offset="0" />
        <stop offset=".006" stopColor="#51faca" />
        <stop offset=".195" stopColor="#44e9dd" />
        <stop offset=".453" stopColor="#38d8f0" />
        <stop offset=".718" stopColor="#30cefb" />
        <stop offset="1" stopColor="#2ecbff" />
      </linearGradient>
      <path
        d="M1080 506v68c-.4.9-1.2 1.8-1.3 2.8-1.1 10.6-1.5 21.4-3.2 31.9-3.3 20.2-6.6 40.4-11.1 60.4-7.9 35.3-20.4 69.1-35.9 101.8-16.4 34.6-35.7 67.4-59.7 97.4-11 13.8-22.1 27.5-33.9 40.6-9.8 10.8-20.6 20.7-31.4 30.6-11.2 10.2-22.6 20.3-34.7 29.5-23 17.6-47.2 33.5-72.8 47.1-29.9 15.9-60.9 29.3-93.4 39.3-41.1 12.7-82.9 21.2-125.9 23.4-.9.1-1.9.8-2.8 1.3h-68c-5.4-.8-10.7-1.7-16.1-2.3-12.2-1.4-24.6-2.1-36.7-4.3-16.7-3-33.3-6.8-49.9-10.8-31.5-7.5-61.7-18.9-91-32.5-27.8-12.9-54.8-27.5-79.4-45.8-21-15.6-41.6-31.9-61.2-49.2-14.2-12.6-27.2-26.9-39.5-41.4-13.9-16.4-27.2-33.5-39.6-51.1-17.7-25.2-32.4-52.3-45-80.4C33.7 731.6 22.7 700 15 667.1c-7-29.8-12.2-59.8-13.7-90.4 0-.9-.8-1.9-1.3-2.8v-68c.8-5.4 1.7-10.7 2.3-16.1 1.4-12.2 2.1-24.6 4.3-36.7 3-16.7 6.8-33.3 10.8-49.9 7.6-31.7 19-62 32.7-91.5 13.4-28.7 28.5-56.5 47.4-81.8 15-20.1 30.6-39.7 47.2-58.4 12.7-14.2 26.9-27.2 41.4-39.5 16.4-13.9 33.5-27.2 51.1-39.6 25.2-17.7 52.3-32.4 80.4-45 30.7-13.8 62.3-24.8 95.2-32.5 29.8-7 59.8-12.2 90.4-13.7.9 0 1.9-.8 2.8-1.3h68c5.4.8 10.7 1.7 16.1 2.3 12.2 1.4 24.6 2.1 36.7 4.3 16.7 3 33.3 6.8 49.9 10.8 31.5 7.5 61.7 18.9 91 32.5 27.8 12.9 54.8 27.5 79.4 45.8 21 15.6 41.4 32.1 61.3 49.1 11.2 9.6 21 20.8 31 31.8 10.2 11.2 20.3 22.6 29.5 34.7 17.7 23 33.5 47.2 47.1 72.8 15.9 29.9 29.3 60.9 39.3 93.4 12.7 41.1 21.2 82.9 23.5 125.9 0 .9.8 1.8 1.2 2.7zm-543 520.9c132.2-1.8 245.1-45.6 338.7-135 93.8-89.5 144.6-200.3 150.2-329.8 6-137.4-39-257.7-133.8-357.5-89.6-94.2-200.7-145-330.6-150.5C424 48.3 303.8 93.4 204.1 188.4 110.2 277.9 59.5 389 54.1 518.7c-5.8 137.8 39.5 258.3 135.1 358 95.1 99.2 213.2 147.8 347.8 150.2z"
        fill="url(#b)"
      />
      <linearGradient id="c" gradientUnits="userSpaceOnUse" x1="539.632" y1="0" x2="539.632" y2="1077.079">
        <stop offset="0" />
        <stop offset=".006" stopColor="#51faca" />
        <stop offset=".195" stopColor="#44e9dd" />
        <stop offset=".453" stopColor="#38d8f0" />
        <stop offset=".718" stopColor="#30cefb" />
        <stop offset="1" stopColor="#2ecbff" />
      </linearGradient>
      <path
        d="M529.8 802.9c-31.5-1.9-62.2-7.3-91.7-19.1-22.2-8.9-42.7-20.7-61-35.9-22.6-18.6-41.9-40.4-56.7-65.8-5.5-9.5-10.7-19.3-15.1-29.4-8.6-19.8 3.5-39.3 22.9-42.5 16.3-2.7 27 7.2 32.3 16.5 7.7 13.7 15.2 27.7 24.3 40.5 13.8 19.4 32 34.5 52.7 46.5 18.9 11 39.4 17.8 60.7 22.2 8.7 1.8 17.5 2.6 26.4 3.3 9.2.7 18.6 1.4 27.8 1 22.6-1 44.4-5.6 65.3-14.7 20-8.7 37.6-20.7 54-35 14.8-12.9 26.2-28.1 35.9-45 5.2-8.9 10.4-18 17-25.9 8.6-10.4 22.2-11.4 32.5-7.6 14.8 5.5 20.5 19.7 19.3 30.8-.5 5-2.2 10-4.3 14.6-8.6 18.8-19 36.6-32.3 52.4-13.6 16.1-28.7 30.7-45.9 43.1-21.1 15.2-44 26.9-68.7 34.9-18.1 5.9-36.6 10.1-55.6 11.8-9.9.9-19.8 1.3-29.6 2-3.3.4-6.7.9-10.2 1.3z"
        fill="url(#c)"
      />
      <linearGradient id="d" gradientUnits="userSpaceOnUse" x1="687.851" y1="-2" x2="687.851" y2="1071.017">
        <stop offset="0" />
        <stop offset=".006" stopColor="#51faca" />
        <stop offset=".195" stopColor="#44e9dd" />
        <stop offset=".453" stopColor="#38d8f0" />
        <stop offset=".718" stopColor="#30cefb" />
        <stop offset="1" stopColor="#2ecbff" />
      </linearGradient>
      <path
        d="M687.9 456.7c-34.1 1.7-64.2-29.1-63.5-63.7.6-34.6 27.7-62.6 63.4-62.4 37.7.2 63.2 27.8 63.6 63 .3 35.2-28.5 64.3-63.5 63.1z"
        fill="url(#d)"
      />
      <linearGradient id="e" gradientUnits="userSpaceOnUse" x1="401.28" y1="-2" x2="401.28" y2="1076.9">
        <stop offset="0" />
        <stop offset=".006" stopColor="#51faca" />
        <stop offset=".195" stopColor="#44e9dd" />
        <stop offset=".453" stopColor="#38d8f0" />
        <stop offset=".718" stopColor="#30cefb" />
        <stop offset="1" stopColor="#2ecbff" />
      </linearGradient>
      <path
        d="M338.3 393.1c-.7-35.4 28.5-62.9 62.7-62.5 36.6.4 62.9 26.3 63.3 62.8.4 35.3-25.5 63.4-63.2 64-33.3.4-64.2-27.2-62.8-64.3z"
        fill="url(#e)"
      />
    </svg>
  );
}
