import React, { FC, useState, useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmptyArray } from '../utils/arrays';
import { TypographyStyled } from '../CommonComponents/Typography/Typography.style';
import Flex from '../CommonComponents/Flex';
import { useTranslation } from 'react-i18next';
import { UserData } from '../Common/interfaces/user';
import { FriendData } from '../Common/interfaces/friend';
import useGetAllFriendsByOwnerId from '../Common/customHooks/useGetAllFriendsByOwnerId';
import FriendCard from './FriendCard';
import { getFriendsById } from './actions/getAllFriends';
import SkeletonLoading from '../CommonComponents/Skeleton';
import { updateFriendList } from './actions';

const { Span } = TypographyStyled;

const FriendList: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const friendState = useSelector(state => state.friendReducer);
  const loggedInUser = useSelector<UserData>(state => state.authReducer.loggedInUser);
  const {
    friendList,
    filteredFriends,
    isFilteredByName,
    fetchFriendsIsLoading,
  }: {
    friendList: Array<FriendData>;
    filteredFriends: Array<FriendData>;
    isFilteredByName: boolean;
    fetchFriendsIsLoading: boolean;
  } = friendState;
  const [list, setList] = useState(friendList);
  const rawFriendList = useGetAllFriendsByOwnerId(loggedInUser.id);

  useEffect(() => {
    const userFriendList = localStorage.getItem('userFriendList') || '';
    const parsedFriendList = userFriendList && JSON.parse(userFriendList);

    if (rawFriendList.length !== friendList.length) {
      const requestBody = {
        friendIds: rawFriendList,
      };
      if (isNotEmptyArray(rawFriendList)) {
        dispatch(getFriendsById(requestBody, loggedInUser.id));
      }
    } else {
      dispatch(updateFriendList(parsedFriendList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFriendList]);

  useEffect(() => {
    if (isFilteredByName) {
      setList(filteredFriends);
    } else {
      setList(friendList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilteredByName, friendList]);

  if (fetchFriendsIsLoading) {
    return <SkeletonLoading isWithAvatar count={3} />;
  } else {
    return (
      <>
        {isNotEmptyArray(list) && (
          <Flex margin="10px 0px 0px 30px">
            <Span size="xl" fontColor="#444444" fontWeight="bold">
              {`${t('Friends')} ${list && list.length}`}
            </Span>
          </Flex>
        )}
        {isNotEmptyArray(list) && !!list && list.map(friend => <FriendCard key={friend.friendId} friend={friend} />)}
      </>
    );
  }
};

export default FriendList;
