import React from 'react';
import { useDispatch } from 'react-redux';
import { DecorationStyled } from '../../CommonComponents/Decoration/Decoration.style';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { FriendCardStyled } from './styled';
import { FriendData } from '../../Common/interfaces/friend';
import { changeFourthPanelView } from '../../Navigation/actions';
import { getFriendInfo } from '../actions';
import { FOURTH_PANEL_VIEW } from '../../Common/enums';

const { IconWrapper } = DecorationStyled;
const { UserAvatar } = ImageStyled;
const { Span } = TypographyStyled;
const { Container } = FriendCardStyled;

interface Props {
  friend: FriendData;
}

const FriendCard: React.FC<Props> = ({ friend }) => {
  const dispatch = useDispatch();

  return (
    <Container
      onClick={() => {
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.FRIEND_INFO));
        dispatch(getFriendInfo(friend));
      }}
    >
      <IconWrapper margin="0px 30px 0px 0px">
        <UserAvatar src={friend.friendProfileImageUrl} alt="" width="45" height="45" />
      </IconWrapper>
      <Span size="lg" fontColor="#444444">
        {friend.friendDisplayName}
      </Span>
    </Container>
  );
};

export default FriendCard;
