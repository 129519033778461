import styled from 'styled-components/macro';
import { scaleFadeIn } from '../../CommonComponents/Animation';

const ComingSoonContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${scaleFadeIn} 300ms;
`;

export const ComingSoonStyled = { ComingSoonContainer };
