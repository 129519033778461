import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import { ThreadData } from '../interfaces/thread';

export default function useGetMessageDataById(id) {
  const [message, setMessage] = useState<ThreadData | any>();

  useEffect(() => {
    if (id) {
      getMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getMessage = async () => {
    await db
      .collection(collections.messages)
      .doc(id)
      .onSnapshot((querySnapshot: any) => {
        if (querySnapshot.exists) {
          setMessage(querySnapshot.data());
        }
      });
  };
  return message;
}
