import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const globalContext = window;

export default function AuthFormFooter() {
  const { t } = useTranslation();
  const currentLang = globalContext && globalContext.localStorage.i18nextLng;

  const handleOtherLinkInvocation = link => {
    globalContext.open(link, '_blank');
  };

  return (
    <div className="d-flex flex-wrap justify-content-center text-white">
      {currentLang === 'en' ? (
        <>
          <span className="regular-text mr-2">{t('PrivacyPolicyOthers')}</span>
          <Link to="/termsOfUse" target="_blank" className="regular-text text-decoration-none text-white mr-2">
            {t('TermsOfUse')}
          </Link>
          <span className="regular-text mr-2">{t('And')}</span>
          <span
            onClick={() => handleOtherLinkInvocation('https://www.x-climb.com/privacypolicy_en.html')}
            className="regular-text pointer mr-2"
          >
            {t('PrivacyPolicy')}
          </span>
        </>
      ) : (
        <>
          <span className="regular-text mr-2">{t('PrivacyPolicyOthers.1')}</span>
          <Link to="/termsOfUse" target="_blank" className="regular-text text-decoration-none text-white mr-2">
            {t('TermsOfUse')}
          </Link>
          <span className="regular-text mr-2">{t('And')}</span>
          <span
            onClick={() => handleOtherLinkInvocation('https://www.x-climb.com/privacypolicy_en.html')}
            className="regular-text pointer mr-2"
          >
            {t('PrivacyPolicy')}
          </span>
          <span className="regular-text mr-2">{t('PrivacyPolicyOthers.2')}</span>
        </>
      )}
    </div>
  );
}
