const Theme = {
  backgroundColor: {
    primary: {
      default: '#50fcc7',
      topColor: '#50fcc7',
      bottomColor: '#3cc9f8',
    },
    secondary: '#c9c9c9',
    light: 'white',
    dark: 'black',
    modal: '#212121', //will be used under all modal state
  },
  font: {
    fontColor: {
      default: '#444444',
      primary: 'white',
      secondary: 'white',
      light: 'black',
      dark: 'white',
      darkBlue: '#04516b', //used on the login page in word "Terms and Conditions" under Create Account Link
      darkGrey: '#414042', //used on friend's profile word "Message" inside the button, undread messages, friend's name inside friends list
      convLastResponse: '#888888', //used on messages time i.e. "2 days ago"
      eachMessageTime: '#939393', //used on "10:33AM"
    },
    fontFamily: 'Mplus-1p-light',
  },
};

export default Theme;
