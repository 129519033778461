import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';

const FlexBox = styled.div``;

interface FlexProps {
  handleOnClick?: any;
  handleHover?: any;
  handleUnHover?: any;
  onDragEnter?: any;
  onDragOver?: any;
  onDragLeave?: any;
  onDrop?: any;
  dataLocatorId?: string;
  children?: any;
  className?: string;
  tabIndex?: number;
  container?: boolean;
  /****** Container Props ********/
  position?: 'relative' | 'absolute' | 'sticky' | 'fixed' | 'inherit' | 'static';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'initial' | 'inherit';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  /****** Child Props ********/
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  flex?: string;
  /****** Common Layout Props ********/
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  /****** Custom Style ********/
  background?: string;
  border?: string;
  borderRadius?: string;
  borderTop?: string;
  borderRight?: string;
  borderBottom?: string;
  borderLeft?: string;
  transform?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  zIndex?: number;
  overflow?: string;
  textOverflow?: string;
}

export default function Flex(props: FlexProps): ReactElement {
  return (
    <FlexBox
      className={props.className}
      data-locator-id={props.dataLocatorId}
      tabIndex={props.tabIndex}
      onClick={props.handleOnClick}
      onMouseEnter={props.handleHover}
      onMouseLeave={props.handleUnHover}
      onDragEnter={props.onDragEnter}
      onDragOver={props.onDragOver}
      onDrop={props.onDrop}
      onDragLeave={props.onDragLeave}
      style={{
        position: props.position,
        display: props.container ? 'flex' : 'block',
        justifyContent: props.justifyContent || 'flex-start',
        flexDirection: props.flexDirection || 'row',
        flexGrow: props.flexGrow || 0,
        flexBasis: props.flexBasis || 'auto',
        flexShrink: props.flexShrink || 1,
        flexWrap: props.flexWrap || 'nowrap',
        flex: props.flex || '0 1 auto',
        alignItems: props.alignItems || 'stretch',
        margin: props.margin || '0',
        padding: props.padding || '0',
        width: props.width || 'auto',
        height: props.height || 'auto',
        maxWidth: props.maxWidth || 'none',
        minWidth: props.minWidth || 'none',
        maxHeight: props.maxHeight || 'none',
        background: props.background,
        borderRadius: props.borderRadius,
        border: props.border,
        borderTop: props.borderTop ? props.borderTop : props.border,
        borderRight: props.borderRight ? props.borderRight : props.border,
        borderBottom: props.borderBottom ? props.borderBottom : props.border,
        borderLeft: props.borderLeft ? props.borderLeft : props.border,
        transform: props.transform,
        zIndex: props.zIndex,
        top: props.top,
        right: props.right,
        bottom: props.bottom,
        left: props.left,
        overflow: props.overflow,
        textOverflow: props.textOverflow,
      }}
    >
      {props.children}
    </FlexBox>
  );
}
