import React from 'react';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import Flex from '../../../CommonComponents/Flex';
import { USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW } from '../../../Common/enums';
import { SideMenuStyled } from '../styled';
import { useTranslation } from 'react-i18next';

const { Span } = TypographyStyled;
const { SubItem } = SideMenuStyled;

export default function Plan(props) {
  const { t } = useTranslation();
  const { handleSubItemClick, thirdPanelView } = props;
  return (
    <Flex container flexDirection="column">
      <Span style={{ display: 'none' }} size="xxl" fontColor="#444444" fontWeight="bold" margin="15px 30px">
        {t('Plan')}
      </Span>
      <SubItem
        style={{ display: 'none' }}
        isActive={thirdPanelView === USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.CURRENT_PLAN}
        onClick={() => handleSubItemClick(USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.CURRENT_PLAN)}
      >
        <Span size="xl" fontColor="#444444">
          {t('CurrentPlan')}
        </Span>
      </SubItem>
      <SubItem
        style={{ display: 'none' }}
        isActive={thirdPanelView === USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.CHANGE_PLAN}
        onClick={() => handleSubItemClick(USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.CHANGE_PLAN)}
      >
        <Span size="xl" fontColor="#444444">
          {t('ChangePlan')}
        </Span>
      </SubItem>
    </Flex>
  );
}
