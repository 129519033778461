import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SideBar from './SideBar';
import SideMenu from './SideMenu';
import { USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW } from '../Common/enums';
import ChangePassword from './ChangePassword';
import Language from './Language';
import UsageSituation from './UsageSituation';
import ComingSoon from './ComingSoon';
import EmailNotifications from './EmailNotifications';
import useGetUserInfoById from '../Common/customHooks/useGetUserInfoById';
import { updateUserInfoLocalStorage } from '../Auth/actions';
import { isNotEmptyArray } from '../utils/arrays';
import IconContainer from '../CommonComponents/Decoration/IconContainer';

export const AccountSettings = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [isSidebarOpen, toggleSideBar] = useState(true);
  const { getUserDataFromFirestore } = useGetUserInfoById(userId);
  const thirdPanelView: USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW = useSelector(state => state.accountSettingsReducer.thirdPanelView);
  const sideMenuRef: any = useRef();

  useEffect(() => {
    const getUserData = async () => {
      const users = await getUserDataFromFirestore(userId);
      if (isNotEmptyArray(users)) {
        const user = users[0];
        await dispatch(updateUserInfoLocalStorage(user));
      }
    };
    if (userId) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderThirdPanel = () => {
    if (thirdPanelView === USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.CHANGE_PASSWORD) {
      return <ChangePassword />;
    } else if (thirdPanelView === USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.USAGE_SITUATION) {
      return <UsageSituation />;
    } else if (thirdPanelView === USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.EMAIL_NOTIFICATIONS) {
      return <EmailNotifications />;
    } else if (thirdPanelView === USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.LANGUAGE) {
      return <Language />;
    } else {
      return <ComingSoon />;
    }
  };

  const toggleSideMenu = () => {
    toggleSideBar(isOpen => !isOpen);
  };

  return (
    <div className="w-100vw">
      <div className="row no-gutters h-100vh">
        <div className="d-none d-lg-block">
          <SideBar />
        </div>
        <div ref={sideMenuRef} className={`${isSidebarOpen ? 'col-12 col-lg-3' : 'd-none'} p-0`}>
          <SideMenu toggleSideMenu={toggleSideMenu} isSidebarOpen={isSidebarOpen} />
        </div>
        {!isSidebarOpen && (
          <div className="d-lg-none position-fixed">
            <IconContainer iconName="thread" iconWidth="50px" iconFill="transparent" iconStroke="#444444" onClick={toggleSideMenu} />
          </div>
        )}
        <div className={`${isSidebarOpen ? 'd-none d-lg-block col-lg-8' : 'flex-fill'} p-0 mt-3`}>{renderThirdPanel()}</div>
      </div>
    </div>
  );
};

export default AccountSettings;
