import React from 'react';
import Flex from '../../../../CommonComponents/Flex';
import { TypographyStyled } from '../../../../CommonComponents/Typography/Typography.style';

const { Span } = TypographyStyled;

const SharedFile = ({ fileName }) => {
  return (
    <Flex padding="15px 0px">
      <Span size="lg" fontColor="#444444">
        {fileName}
      </Span>
    </Flex>
  );
};

export default SharedFile;
