import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Sound({
  style = {},
  fill = '#CACACA',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 964',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M518.4,0.8c-13.4-2.8-27.1,2.1-35.3,13L213.7,362.1L54.1,400.8c-37.9,9.3-61.3,47.5-52,85.4l66.6,274.9
	c9.2,37.9,47.5,61.2,85.3,52l159.7-38.7l399,186.3c7.4,3.4,15.7,4.1,23.3,2.3c5.1-1.2,9.9-3.6,14.1-7c10.5-8.6,15.2-22.4,12-35.6
	L545.4,27C542.2,13.9,531.7,3.6,518.4,0.8z"
      />
      <path
        fill={fill}
        d="M768.7,188.6c-16.8-10.1-38.4-4.5-48.5,12.1c-10.1,16.8-4.6,38.4,12.1,48.6c64.4,38.6,109.7,100.6,127.6,174.5
	c17.9,73.9,6,149.7-33.5,213.5c-10.3,16.5-5.1,38.3,11.5,48.6c8.4,5.1,18.1,6.3,26.9,4.2s16.6-7.5,21.7-15.8
	c49.6-79.9,64.5-174.9,42.1-267.3C906.1,314.7,849.4,237.1,768.7,188.6z"
      />
      <path
        fill={fill}
        d="M842.1,68.3c-16.8-10.2-38.4-4.7-48.6,12c-10.1,16.7-4.6,38.4,12,48.5c96.9,58.5,164.9,151.4,191.7,261.6
	c26.8,110.3,8.8,223.9-50.6,320.3c-10.2,16.6-5,38.4,11.6,48.7c8.5,5.1,18,6.3,26.9,4.2c8.8-2.1,16.7-7.5,21.8-15.8
	c69.3-112.5,90.3-245.3,59-374C1034.5,245.3,955.3,136.5,842.1,68.3z"
      />
    </svg>
  );
}
