import React, { FC, useState, useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FavoriteListStyled } from './styled';
import { ImageStyled } from '../CommonComponents/Image/Image.style';
import { isNotEmptyArray } from '../utils/arrays';
import { TypographyStyled } from '../CommonComponents/Typography/Typography.style';
import { FOURTH_PANEL_VIEW } from '../Common/enums';
import { changeFourthPanelView } from '../Navigation/actions';
import SkeletonLoading from '../CommonComponents/Skeleton';
import Flex from '../CommonComponents/Flex';
import { DecorationStyled } from '../CommonComponents/Decoration/Decoration.style';
import { useTranslation } from 'react-i18next';
import { FriendData } from '../Common/interfaces/friend';
import { getFriendInfo } from '../FriendList/actions';

const { FriendContainer } = FavoriteListStyled;
const { UserAvatar } = ImageStyled;
const { IconWrapper } = DecorationStyled;
const { Span } = TypographyStyled;

const FavoriteList: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const friendState = useSelector(state => state.friendReducer);
  const {
    friendList,
    filteredFriends,
    isFilteredByName,
    fetchFriendsIsLoading,
    isHandleFavoriteUserLoading,
    isHandleBlockUserLoading,
    isHandleUpdateFriendDisplayNameLoading,
  }: {
    friendList: Array<FriendData> | null;
    filteredFriends: Array<FriendData>;
    isFilteredByName: boolean;
    fetchFriendsIsLoading: boolean;
    isHandleFavoriteUserLoading: boolean;
    isHandleBlockUserLoading: boolean;
    isHandleUpdateFriendDisplayNameLoading: boolean;
  } = friendState;

  const [list, setList] = useState(friendList);

  useEffect(() => {
    if (isFilteredByName) {
      if (isNotEmptyArray(filteredFriends)) {
        const onlyFavorites = filteredFriends.filter(friend => friend.friendStatus.isFavorite);
        setList(onlyFavorites);
      }
    } else {
      if (isNotEmptyArray(friendList)) {
        const onlyFavorites = friendList!.filter(friend => friend.friendStatus.isFavorite);
        setList(onlyFavorites);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilteredByName, friendList, isHandleFavoriteUserLoading, isHandleBlockUserLoading, isHandleUpdateFriendDisplayNameLoading]);

  return (
    <>
      {isNotEmptyArray(list) && !fetchFriendsIsLoading && (
        <Flex margin="10px 0px 0px 30px">
          <Span size="xl" fontColor="#444444" fontWeight="bold">
            {list!.length > 0 && `${t('Favorites')} ${list!.length}`}
          </Span>
        </Flex>
      )}
      {fetchFriendsIsLoading && <SkeletonLoading count={6} isWithAvatar />}
      {isNotEmptyArray(list) &&
        !fetchFriendsIsLoading &&
        list!.map(friend => {
          if (friend.friendStatus.isFavorite) {
            return (
              <FriendContainer
                key={friend.friendId}
                onClick={() => {
                  dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.FRIEND_INFO));
                  dispatch(getFriendInfo(friend));
                }}
              >
                <IconWrapper margin="0px 30px 0px 0px">
                  <UserAvatar src={friend.friendProfileImageUrl} alt="" width="45" height="45" />
                </IconWrapper>
                <Span size="lg" fontColor="#444444">
                  {friend.friendDisplayName}
                </Span>
              </FriendContainer>
            );
          } else {
            return null;
          }
        })}
    </>
  );
};

export default FavoriteList;
