import React, { useState, useEffect, ReactElement } from 'react';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { DecorationStyled } from '../../CommonComponents/Decoration/Decoration.style';
import { useDispatch } from 'react-redux';
import AuthForm from '../AuthForm';
import { AuthProps } from '../../Common/interfaces';
import { signInUser } from '../actions';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { handleTokenRequest } from '../../firebase/pushNotification';
import { currentLang } from '../../utils/language';
const { HorizontalLine } = DecorationStyled;

export default function LoginContainer(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  const [isSignedInViaSNS, setIsSignedInViaSNS] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      setIsSignedInViaSNS(!!user);
      const deviceToken = (await handleTokenRequest()) || '';
      const userDetected: AuthProps = {
        uid: user?.uid,
        photoUrl: user?.photoURL,
        email: user?.email,
        providerId: user?.providerData[0]?.providerId,
        displayName: user?.displayName,
        deviceToken: deviceToken,
        lang: currentLang,
      };

      if (isSignedInViaSNS) {
        dispatch(signInUser(userDetected));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedInViaSNS]);

  return (
    <div className="d-none d-lg-flex flex-column justify-content-between login-form-container text-white col-5 col-xl-4">
      <p className="large-text float-left text-center w-100">{t('ConnectWith')}</p>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      <div className="d-flex align-items-center">
        <HorizontalLine />
        <span className="mx-3 white-space-no-wrap large-text">{t('Or')}</span>
        <HorizontalLine />
      </div>
      <AuthForm />
    </div>
  );
}
