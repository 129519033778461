import axios from 'axios';
import { SEND_THREAD_MESSAGE_LOADING, SEND_THREAD_MESSAGE_ERROR, SEND_THREAD_MESSAGE_SUCCESS } from '../actionTypes';

export function hasError() {
  return {
    type: SEND_THREAD_MESSAGE_ERROR,
  };
}

export function isLoading() {
  return {
    type: SEND_THREAD_MESSAGE_LOADING,
  };
}

export function isSuccess(payload) {
  return {
    type: SEND_THREAD_MESSAGE_SUCCESS,
    payload: payload,
  };
}

export function sendThreadMessage(requestBody) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios.post(process.env.REACT_APP_API_URL + `/threads`, requestBody).then(
      response => {
        dispatch(isSuccess(response.data.reply));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
}
