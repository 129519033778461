import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Send({
  style = { enableBackground: 'new 0 0 1080 907' },
  fill = '#CACACA',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 907',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          fill={fill}
          d="M753.3,455.4c-59.8-8.4-119.5-16.8-179.3-25.2c-124.6-17.5-249.1-34.9-373.7-52.4c-17-2.4-34-5.3-51.1-7.1
		c-7.5-0.8-10.7-3.5-13.2-9.2C92.6,262.7,49,164.2,5.1,65.7c-8-17.8-7.2-34.2,8.9-48.4C31.2,2,52.7-1.9,76.6,3
		c6,1.3,11.8,3.7,17.4,6.1c316.7,134,633.3,268.1,950,402.1c28.8,12.2,41.5,33,33.8,55.3c-4.3,12.4-14.1,20.3-27.7,26
		C929,543.6,807.9,595,686.8,646.2C487.2,730.8,287.5,815.3,87.8,899.8c-32.4,13.7-61.4,8.8-77.9-13.3c-10.5-14-10.3-28.8-3.6-43.9
		C42.4,761,78.6,679.4,114.8,597.7c7.2-16.3,14-32.7,22-48.6c1.8-3.6,7.5-7.6,12.1-8.2c170.2-24.3,340.4-48.3,510.6-72.3
		c31.3-4.4,62.6-8.6,93.8-12.9C753.3,455.5,753.3,455.5,753.3,455.4z"
        />
      </g>
    </svg>
  );
}
