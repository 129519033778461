import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Flex from '../../../CommonComponents/Flex';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { ButtonStyled } from '../../../CommonComponents/Button/Button.style';
import { useTranslation } from 'react-i18next';
import { isNotEmptyArray } from '../../../utils/arrays';
import { FOURTH_PANEL_VIEW } from '../../../Common/enums';
import { changeFourthPanelView } from '../../../Navigation/actions';
import { setGroup } from '../../../CreateChannel/actions';
import useGetActiveConversation from '../../../Common/customHooks/useGetActiveConversation';
import { currentLang } from '../../../utils/language';

const { Span } = TypographyStyled;
const { ConversationInviteButton } = ButtonStyled;

export default function NoMessage(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.authReducer.loggedInUser);
  const activeConversation = useGetActiveConversation();
  const friendsId =
    activeConversation && activeConversation.members && activeConversation.members.filter(member => member.id !== loggedInUser.id);

  const handleInviteFriend = () => {
    dispatch(setGroup(activeConversation));
    dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.INVITE_FRIEND));
  };

  const renderUpperMessage = () => {
    if (activeConversation) {
      if (activeConversation.isGroup) {
        return t('YouveSuccessfullyCreatedAGroup');
      } else {
        if (isNotEmptyArray(friendsId)) {
          return `${
            currentLang === 'en' ? t('YoureNowFriendwith') + friendsId[0].displayName : friendsId[0].displayName + t('YoureNowFriendwith')
          }`;
        } else if (activeConversation && activeConversation.id) {
          return t('ThisIsYourOwnPersonalSpace');
        } else {
          return;
        }
      }
    } else {
      return;
    }
  };

  const renderLowerMessage = () => {
    let textToDisplay = '';
    const groupsSize = activeConversation.members.length;
    friendsId.map((member, memberIndex) => {
      textToDisplay = textToDisplay + member.displayName + (groupsSize === memberIndex + 1 ? '' : ', ');
      return member;
    });
    return textToDisplay + t('JoinedTheGroup');
  };

  return (
    <Flex container flexDirection="column" height="100%" width="100%" alignItems="center">
      <Flex margin="30px 0px">
        <Span size="lg" fontColor="#939393" fontWeight="bold">
          {renderUpperMessage()}
        </Span>
      </Flex>
      {activeConversation && activeConversation.isGroup && (
        <>
          <Flex margin="0px 0px 30px">
            <Span size="lg" fontColor="#939393" fontWeight="bold">
              {renderLowerMessage()}
            </Span>
          </Flex>
          <Flex>
            <ConversationInviteButton type="button" fontSize="md" borderRadius="25px" onClick={handleInviteFriend}>
              INVITE
            </ConversationInviteButton>
          </Flex>
        </>
      )}
    </Flex>
  );
}
