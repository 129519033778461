import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';

export default function useGetThreadByParentMessageId(parentMessageId) {
  const [threadsPerMember, setThreadsPerMember] = useState();

  useEffect(() => {
    if (parentMessageId) {
      const unsubscribe = db
        .collection(collections.messages)
        .doc(parentMessageId)
        .onSnapshot((snapshot: any) => {
          setThreadsPerMember(snapshot.data());
          return () => {
            unsubscribe();
          };
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return threadsPerMember;
}
