import styled from 'styled-components/macro';

const SearchFriendContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SearchFriendListContainer = styled.div`
  height: 87%;
  max-height: 87%;
  overflow-y: scroll;
`;

const FriendListCard = styled.div`
  display: flex;
  width: 100%;
  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
`;

const FriendListCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px;
`;

const IconAndNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CreateChannelBodyStyled = {
  SearchFriendContainer,
  SearchFriendListContainer,
  FriendListCard,
  FriendListCardWrapper,
  IconAndNameWrapper,
};
