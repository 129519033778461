import styled from 'styled-components/macro';

const MemberCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 300ms;
  :hover {
    background-color: whitesmoke;
  }
`;

export const MemberInfoStyled = { MemberCardContainer };
