import { useEffect } from 'react';
import ReactGA from 'react-ga';

export default function() {
  useEffect(() => {
    if (process.env.REACT_APP_CLIENT_ENV === 'production') {
      ReactGA.initialize('UA-81380414-10');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const sendEGAEvent = (category: string, action: string, label: string) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  return { sendEGAEvent };
}
