import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import SidePanel from '../SidePanel';
import Navigation from '../Navigation';
import FriendDetails from '../FriendList/FriendDetails';
import ChannelDetails from '../ChannelList/ChannelDetails';
import CreateChannel from '../CreateChannel';

import { THIRD_PANEL_VIEW, FOURTH_PANEL_VIEW } from '../Common/enums';
import Conversation from '../Conversation';
import Flex from '../CommonComponents/Flex';
import SharedFiles from '../SharedFiles';
import UserDetails from '../FriendList/UserDetails';

const AppContainer: FC = (): ReactElement => {
  const navigationState = useSelector(state => state.navigationReducer);
  const {
    thirdPanelView,
    fourthPanelView,
  }: {
    thirdPanelView: THIRD_PANEL_VIEW;
    fourthPanelView: FOURTH_PANEL_VIEW;
  } = navigationState;

  const renderFourthPanel = () => {
    switch (fourthPanelView) {
      case FOURTH_PANEL_VIEW.FRIEND_INFO:
        return <FriendDetails />;
      case FOURTH_PANEL_VIEW.GROUP_INFO:
        return <ChannelDetails />;
      case FOURTH_PANEL_VIEW.INVITE_FRIEND:
        return <CreateChannel groupExist />;
      case FOURTH_PANEL_VIEW.USER_INFO:
        return <UserDetails />;
      default:
        return null;
    }
  };

  const renderThirdPanel = () => {
    if (thirdPanelView === THIRD_PANEL_VIEW.CREATE_CHANNEL) {
      return <CreateChannel />;
    } else if (thirdPanelView === THIRD_PANEL_VIEW.CONVERSATION) {
      return <Conversation />;
    } else if (thirdPanelView === THIRD_PANEL_VIEW.SHARED_FILES) {
      return <SharedFiles />;
    }
  };

  return (
    <Flex width="100vw">
      <Flex container height="100vh" width="100vw">
        <Flex>
          <Navigation />
        </Flex>
        <Flex background="#f4f5f7" flex="1">
          <SidePanel />
        </Flex>
        <Flex flex={fourthPanelView !== FOURTH_PANEL_VIEW.HIDDEN ? '2' : '3'}>{renderThirdPanel()}</Flex>
        {fourthPanelView !== FOURTH_PANEL_VIEW.HIDDEN && <Flex flex="1">{renderFourthPanel()}</Flex>}
      </Flex>
    </Flex>
  );
};

export default AppContainer;
