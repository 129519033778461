import styled from 'styled-components/macro';

const SearchInput = styled.input`
  border: none;
  font-size: 20px;
  outline: none;
  padding: 0px 5px;
  width: 90%;
  background-color: transparent;
`;

const SearchInputContainer = styled.div`
  display: flex;
  background-color: rgb(143, 143, 143, 0.25);
  align-items: center;
  border-radius: 25px;
  border: none;
  height: 50px;
  width: 100%;
  padding: 0px 15px;
`;

export const SearchConversationStyled = { SearchInputContainer, SearchInput };
