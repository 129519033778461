import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Bullet({
  fill = '#f8e1e1',
  style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  width = '100%',
  className = '',
  viewBox = '0 0 40 40',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      fill={fill}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="10" cy="10" r="10" fill={fill} />
    </svg>
  );
}
