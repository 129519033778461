import React, { ReactElement } from 'react';
import { SkeletonStyled } from './Skeleton.style';

const { SkeletonWrapper, SkeletonAvatar, SkeletonArea, Skeleton } = SkeletonStyled;

interface Props {
  isWithAvatar?: boolean;
  height?: number;
  count: number;
}

export default function SkeletonLoading(props: Props): ReactElement {
  const { count, height, isWithAvatar } = props;
  return isWithAvatar ? (
    <>
      {Array.from({ length: count }).map((count, index) => (
        <SkeletonWrapper key={index}>
          <SkeletonAvatar />
          <SkeletonArea>
            <Skeleton height={height} w70 />
            <Skeleton height={height} />
            <Skeleton height={height && height / 2} w30 />
          </SkeletonArea>
        </SkeletonWrapper>
      ))}
    </>
  ) : (
    <>
      {Array.from({ length: count }).map((count, index) => (
        <SkeletonWrapper key={index}>
          <SkeletonArea>
            <Skeleton height={height} w70 />
            <Skeleton height={height} />
            <Skeleton height={height && height / 2} w30 />
          </SkeletonArea>
        </SkeletonWrapper>
      ))}
    </>
  );
}
