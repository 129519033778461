export const copyToClipBoard = (value: string) => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const cutMessageString = (string: string | undefined, index: number) => string && string.substr(0, index);

export const urlify = text => {
  let finalUrl;
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  text.replace(urlRegex, url => (finalUrl = url));
  return finalUrl;
};
