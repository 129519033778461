import styled from 'styled-components/macro';
import { fadeIn } from '../../CommonComponents/Animation';

const SearchFriendPanel = styled.div`
  display: flex;
  animation: ${fadeIn} 500ms;
  width: 420px;
  flex-direction: column;
  background-color: white;
  position: absolute;
  border-radius: 0px 20px 20px 20px;
  color: grey;
  transform: translate(110px, 0px);
  padding: 15px 20px 15px 20px;
`;

const SearchFriendText = styled.input`
  border-radius: 10px;
  width: 100%;
  font-size: 20px;
  align-self: center;
  border: 1px solid #cacaca;
  padding: 10px 20px 10px 20px;
  outline: none;
`;

const AddButton = styled.button`
  height: 100%;
  width: 25%;
  font-size: 15px;
  font-weight: bold;
  color: black;
  background-color: white;
  border: 2px solid #cacaca;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  transition: 300ms;
  outline: none;
  :hover {
    cursor: pointer;
    color: white;
    background-color: #cacaca;
  }
`;

const ViewButton = styled(AddButton)`
  background-color: #cacaca;
  color: white;
`;

export const Styled = {
  SearchFriendPanel,
  SearchFriendText,
  AddButton,
  ViewButton,
};
