import React from 'react';
import useGetAllExchangedFilesByConversationId from '../../../Common/customHooks/useGetAllExchangedFilesByConversationId';
import SkeletonLoading from '../../../CommonComponents/Skeleton';
import SharedFile from './SharedFile';
import { isNotEmptyArray } from '../../../utils/arrays';

const SharedFiles = ({ conversation }) => {
  const [sharedFiles, isLoading] = useGetAllExchangedFilesByConversationId(conversation && conversation.id);

  if (isLoading) {
    return <SkeletonLoading count={4} />;
  } else {
    return <>{isNotEmptyArray(sharedFiles) && sharedFiles.map(file => <SharedFile key={file.id} fileName={file.fileName} />)}</>;
  }
};

export default SharedFiles;
