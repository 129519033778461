import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Camera({
  fill = '#939393',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 963 963',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle fill={fill} cx="481.66998291015625" cy="481.6700134277344" r="481.6700134277344" />
      <g>
        <g>
          <defs>
            <rect x="798.99" y="375" width="425.16" height="319.09" />
          </defs>
          <path
            fill="#ffffff"
            d="M619.72,416.4400305175781 v-1.7600000000000002 c0,-9.3 -7.54,-16.83 -16.83,-16.83 h-1 c-9.3,0 -16.83,7.54 -16.83,16.83 v1.7600000000000002 c0,9.3 7.54,16.83 16.83,16.83 h1 C612.1800000000001,433.2800305175781 619.72,425.74003051757813 619.72,416.4400305175781 L619.72,416.4400305175781 zM480.40999999999997,594.2800305175781 c52.16,0.13 94.78,-42.42 94.76,-94.6 c-0.020000000000000004,-52.09 -41.98,-94.21 -94.19,-94.56 c-52.21,-0.35000000000000003 -94.75,41.85 -95.03,94.27 C385.66999999999996,551.4800305175781 428.15,594.1500305175781 480.40999999999997,594.2800305175781 L480.40999999999997,594.2800305175781 zM320.15999999999997,363.68003051757813 v-0.030000000000000006 c0,-4.8100000000000005 3.9,-8.71 8.71,-8.71 h19.91 c4.8100000000000005,0 8.71,3.9 8.71,8.71 l0,0 c0,5.14 4.44,9.26 9.54,8.67 c4.53,-0.52 8.51,-2.5300000000000002 12.26,-7.19 c9.54,-11.91 20.56,-22.65 31.09,-33.76 c8.12,-8.57 17.68,-12.66 30.03,-12.36 c28.76,0.7100000000000001 57.55,0.4 86.32,0.12000000000000001 c8.27,-0.08000000000000002 15.47,2.14 21.2,7.74 c10.39,10.19 21.28,20.1 30.18,31.52 c8.51,10.92 17.81,15.45 31.73,14.29 c15.94,-1.32 32.07,-0.38000000000000006 48.12,-0.29000000000000004 c21.55,0.12000000000000001 35.13,13.79 35.15,35.45 c0.05000000000000001,64.01 0.05000000000000001,128.02 0,192.03 c-0.010000000000000002,19.23 -11.14,34.16 -27.89,37.57 c-2.68,0.55 -5.49,0.6100000000000001 -8.24,0.6100000000000001 c-116.58,0.030000000000000006 -233.16,0.030000000000000006 -349.74,0.020000000000000004 c-22.5,0 -39.21,-16.61 -39.22,-39.05 c-0.030000000000000006,-63.46 -0.030000000000000006,-126.91 0,-190.37 c0.010000000000000002,-20.07 14.65,-35.3 34.85,-36.17 c2.8,-0.12000000000000001 5.6,-0.13 8.51,-0.11000000000000001 C316.21000000000004,372.43003051757813 320.15999999999997,368.5300305175781 320.15999999999997,363.68003051757813 L320.15999999999997,363.68003051757813 z"
          />
        </g>
        <path
          fill="#ffffff"
          d="M403.65999999999997,499.49003051757813 c0.16000000000000003,-42.72 34.49,-76.79 77.23,-76.66 c42.6,0.13 76.97,34.85 76.57,77.33 c-0.41000000000000003,42.74 -34.84,76.68 -77.51,76.42 C437.65999999999997,576.3100305175781 403.5,541.8000305175781 403.65999999999997,499.49003051757813 L403.65999999999997,499.49003051757813 z"
        />
      </g>
    </svg>
  );
}
