import React, { FC, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeMemberFromGroup } from '../../../actions/removeMemberFromGroup';
import { MemberInfoOptionStyled } from './styled';
import { MembersData, ChannelData } from '../../../../Common/interfaces/channel';
import Flex from '../../../../CommonComponents/Flex';
import Modal from '../../../../CommonComponents/Modal';
import IconContainer from '../../../../CommonComponents/Decoration/IconContainer';

const { OptionContainer, OptionItem, Container } = MemberInfoOptionStyled;

interface Props {
  member: MembersData;
  isVisible: boolean;
}

const MemberInfoOption: FC<Props> = ({ member, isVisible }): ReactElement => {
  const group: ChannelData = useSelector(state => state.groupReducer.group);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isModalOpen, toggleModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const handleKickUser = async () => {
    const requestBody = {
      memberId: member.id,
    };
    setDeleting(true);
    await dispatch(removeMemberFromGroup(requestBody, group.id));
    toggleModal(false);
    setDeleting(false);
  };

  return (
    <Container visible={isVisible}>
      <Flex handleOnClick={e => e.stopPropagation()}>
        <IconContainer onClick={() => toggleModal(true)} iconName="kebab" iconWidth="25px" iconFill={isModalOpen ? '#707070' : '#444444'} />
        <Modal visible={isModalOpen} dimmed={false} setModalVisibility={toggleModal} />
        {isModalOpen && (
          <Flex height="0px" width="0px">
            <OptionContainer onClick={handleKickUser}>
              <OptionItem>{t(isDeleting ? 'Leaving' : 'Remove')}</OptionItem>
            </OptionContainer>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

export default MemberInfoOption;
