import React from 'react';
import { ReactTinyLink } from 'react-tiny-link';
import './reactTinyLink.css';
import { TypographyStyled } from '../Typography/Typography.style';
import { urlify } from '../../utils/strings';
import { useTranslation } from 'react-i18next';

const { Span, Pre } = TypographyStyled;

export default function MessageText({ text, hasBeenUpdated }) {
  const { t } = useTranslation();
  const url = urlify(text);

  const determineUrl = () => {
    if (url.includes('lagoon.chat')) {
      return 'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2FLAGOON%20LOGO.jpg?alt=media&token=cb2ac1aa-94f3-48c1-99d3-cb3cc8a2bad9';
    } else {
      return 'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2FImage%20Content%20unavailable.jpg?alt=media&token=42b3ea7b-44f0-42b7-ae44-3f6ca16a6eb1';
    }
  };

  return (
    <Pre size="md" fontColor="#444444" className="mb-0">
      {text}
      {url && (
        <ReactTinyLink
          cardSize="large"
          defaultMedia={determineUrl()}
          showGraphic={true}
          loadSecureUrl={true}
          proxyUrl="https://whispering-basin-75230.herokuapp.com"
          maxLine={2}
          minLine={1}
          url={url}
          width="400px"
        />
      )}
      {hasBeenUpdated && (
        <Span fontColor="#707070" size="md" margin="0px 5px">
          ({t('Edited')})
        </Span>
      )}
    </Pre>
  );
}
