import React, { FC, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { ThreadCardStyled } from './styled';
import { ThreadListData } from '../../Common/interfaces/thread';
import { ChannelData } from '../../Common/interfaces/channel';
import { isNotEmptyArray } from '../../utils/arrays';
import { changeThirdPanelView, toggleThreadConversationView } from '../../Navigation/actions';
import { setParentThread, updateThreadInList } from '../../Thread/actions';
import { THREAD_CONVERSATION_VIEW, THIRD_PANEL_VIEW } from '../../Common/enums';
import Flex from '../../CommonComponents/Flex';
import TimeAgo from 'timeago-react';
import useGetThreadByParentMessageId from '../../Common/customHooks/useGetThreadByParentMessageId';
import { UserData } from '../../Common/interfaces/user';
import SkeletonLoading from '../../CommonComponents/Skeleton';
import { clearUnreadStatusThread } from '../../Common/actions';
import useGetReplies from '../../Thread/useGetReplies';
import UnreadMessageCount from '../../CommonComponents/UnreadMessageCount';
import { NotificationData } from '../../Common/interfaces/notification';
import { getDifferenceDurationBetweenDate } from '../../utils/timings';
import { cutMessageString } from '../../utils/strings';
import { useTranslation } from 'react-i18next';

const { UserAvatar } = ImageStyled;
const { Paragraph } = TypographyStyled;
const { ThreadContainer } = ThreadCardStyled;

interface Props {
  threadRawData: ThreadListData;
}

const ThreadCard: FC<Props> = ({ threadRawData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const notificationSound: NotificationData = useSelector(state => state.notificationReducer.selectedSound);
  const parentThread: ThreadListData = useSelector(state => state.threadReducer.parentThread);
  const messages: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);
  const [lastMessageId, setLastMessageId] = useState('');
  const thread: any = useGetThreadByParentMessageId(threadRawData.parentMessageId);
  const { reply, isLoading } = useGetReplies(threadRawData.parentMessageId, true);
  const notificationAudioRef = useRef<any>();

  useEffect(() => {
    if (!threadRawData.childMessage) {
      threadRawData.childMessage = thread;
      dispatch(updateThreadInList(threadRawData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread, threadRawData]);

  useEffect(() => {
    if (reply && thread) {
      if (reply.userId !== loggedInUser.id && reply.id !== lastMessageId) {
        const { diffSecs } = getDifferenceDurationBetweenDate(new Date(), new Date(reply.dateCreated));
        if (diffSecs < 30 && notificationAudioRef && notificationAudioRef.current) {
          notificationAudioRef.current.play();
        }
        setLastMessageId(reply.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reply]);

  useEffect(() => {
    if (isNotEmptyArray(messages) && threadRawData.childMessage) {
      const threadConversationIndex = messages.findIndex(
        (message: ChannelData) => message.id === threadRawData.childMessage.conversationId
      );
      if (threadConversationIndex >= 0) {
        const conversation: ChannelData = messages[threadConversationIndex];
        const users = messages[threadConversationIndex].members.filter(member => member.id === threadRawData.childMessage.userId);
        if (conversation.isGroup) {
          threadRawData.conversationImageUrl = conversation.conversationImageUrl;
          threadRawData.conversationName = conversation.name;
        } else {
          if (isNotEmptyArray(users)) {
            if (users[0].id === loggedInUser.id) {
              threadRawData.conversationImageUrl = loggedInUser.profileImageUrl;
            } else {
              threadRawData.conversationImageUrl = users[0].profileImageUrl;
            }
          }
          if (threadRawData.childMessage.userId === loggedInUser.id) {
            threadRawData.conversationName = loggedInUser.displayName;
          } else {
            threadRawData.conversationName = threadRawData.childMessage.userName;
          }
        }
        threadRawData.conversationId = conversation.id;
        threadRawData.conversationMembers = conversation.members;
        threadRawData.conversationIsBlocked = conversation.isBlocked;
        threadRawData.conversationIsHidden = conversation.isHidden;
        threadRawData.threadSender = isNotEmptyArray(users) ? (users[0].id === loggedInUser.id ? loggedInUser : users[0]) : loggedInUser;
        dispatch(updateThreadInList(threadRawData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadRawData, messages]);

  const handleClick = () => {
    dispatch(setParentThread(threadRawData));
    dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
    dispatch(toggleThreadConversationView(THREAD_CONVERSATION_VIEW.VISIBLE));
    const requestBody = {
      parentMessageId: threadRawData.parentMessageId,
    };
    threadRawData.unreadCount > 0 && clearUnreadStatusThread(requestBody, loggedInUser.id);
  };

  const renderLastMessage = () => {
    if (threadRawData.childMessage) {
      if (threadRawData.childMessage.content.type.isAttachment) {
        return `${threadRawData.childMessage.userName} ${t('SentAFile')}`;
      } else {
        return `${cutMessageString(threadRawData.childMessage.content.text, 80)}`;
      }
    } else {
      return 'Hi!';
    }
  };

  if (threadRawData.childMessage && threadRawData.conversationImageUrl && threadRawData.conversationName && !isLoading) {
    return (
      <ThreadContainer
        isActive={threadRawData.id === parentThread.id}
        isVisible={!threadRawData.conversationIsHidden}
        onClick={handleClick}
      >
        <audio ref={notificationAudioRef} src={notificationSound.value.path} />
        <Flex container width="90%">
          <Flex margin="0px 30px 0px 0px" width="10%">
            <UserAvatar src={threadRawData.conversationImageUrl} width="45px" height="45px" alt="conversation image" />
          </Flex>
          <Flex maxHeight="100px" width="80%">
            <Flex container justifyContent="space-between">
              <Paragraph
                fontColor="#414042"
                size="lg"
                fontWeight={threadRawData.unreadCount > 0 ? 'bold' : 'normal'}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '180px' }}
              >
                {threadRawData.conversationName}
              </Paragraph>
              <UnreadMessageCount allUnreadCount={threadRawData.unreadCount} />
            </Flex>
            <Paragraph fontColor="#414042" size="md">
              <TimeAgo datetime={threadRawData.childMessage.dateCreated} live={false} />
            </Paragraph>
            <Paragraph fontColor="#414042" lineHeight="30px" size="md" fontWeight={threadRawData.unreadCount > 0 ? 'bold' : 'normal'}>
              <Flex maxHeight="30px" overflow="hidden">
                {renderLastMessage()}
              </Flex>
            </Paragraph>
          </Flex>
        </Flex>
      </ThreadContainer>
    );
  } else {
    return <SkeletonLoading count={1} />;
  }
};

export default ThreadCard;
