import styled from 'styled-components/macro';

const MessageBox = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  transition: 300ms;
  :hover {
    background-color: whitesmoke;
  }
`;

export const ThreadMessageStyled = { MessageBox };
