import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function MessageList({
  fill = '#47e4cf',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 566 562',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="none"
        stroke={fill}
        strokeMiterlimit="10"
        strokeWidth="30"
        d="M286.7 505.5c-11.7-.6-86.6-3.4-109.4 12.9-12.7 9-27 15.8-40.4 23.9-13.4 8-19.3 3.4-20-11-.4-5.2-.8-10.4-.9-15.7-1-35.2-8.9-64-35.6-93.4-93-102.6-82.9-298.6 93.7-377.3C295.4-9.2 442 32.3 511.6 138.5c81.3 124 25.6 287.4-117.9 345.9a275.2 275.2 0 01-107 21z"
      />
      <path
        fill={fill}
        stroke={fill}
        strokeMiterlimit="10"
        d="M459 206.3H178a12 12 0 01-12-12v-7a12 12 0 0112-12h281a12 12 0 0112 12v7a12 12 0 01-12 12z"
      />
      <path
        fill={fill}
        stroke={fill}
        strokeMiterlimit="10"
        d="M404 271.3H123a12 12 0 01-12-12v-7a12 12 0 0112-12h281a12 12 0 0112 12v7a12 12 0 01-12 12z"
      />
      <path
        fill={fill}
        stroke={fill}
        strokeMiterlimit="10"
        d="M426 336.3H145a12 12 0 01-12-12v-7a12 12 0 0112-12h281a12 12 0 0112 12v7a12 12 0 01-12 12z"
      />
    </svg>
  );
}
