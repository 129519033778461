import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function EditMessage({
  style = { fill: '#FFFFFF', strokeWidth: '10', strokeMiterlimit: '10' },
  fill = '#BDBDBD',
  width = '100%',
  className = '',
  viewBox = '0 0 720 720',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <circle fill={fill} cx="359.8699951171875" cy="359.8699951171875" r="359.8699951171875" />
        </g>
        <g>
          <g>
            <path
              style={style}
              d="M517.3800012207031,268.3399865722656 l34.47,-34.47 c17.6,-17.6 17.6,-46.14 0,-63.74 l-2.14,-2.14 c-17.6,-17.6 -46.14,-17.6 -63.74,0 l-36.13,36.13 c-1.4100000000000001,1.4100000000000001 -1.4100000000000001,3.68 0,5.09 l59.13,59.13 C511.2900012207032,270.6699865722656 515.0600012207032,270.6699865722656 517.3800012207031,268.3399865722656 z"
            />
            <path
              style={style}
              d="M419.77000122070314,234.17998657226565 L184.41000122070312,469.54998657226565 l-29.28,86.52 c-2,5.32 3.33,9.98 8.65,8.65 l86.52,-29.28 l233.99,-233.99 c5.3,-5.3 5.3,-13.89 0,-19.18 l-48.07,-48.07 C431.6700012207031,229.63998657226563 424.3100012207031,229.63998657226563 419.77000122070314,234.17998657226565 z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
