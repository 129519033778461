import {
  UPDATE_NOTIFICATION_SOUND,
  GET_USER_NOTIFICATION_CONFIG_ERROR,
  GET_USER_NOTIFICATION_CONFIG_LOADING,
  GET_USER_NOTIFICATION_CONFIG_SUCCESS,
  UPDATE_USER_NOTIFICATION_CONFIG_ERROR,
  UPDATE_USER_NOTIFICATION_CONFIG_LOADING,
  UPDATE_USER_NOTIFICATION_CONFIG_SUCCESS,
  UPDATE_USER_NOTIFICATION_SOUND_ERROR,
  UPDATE_USER_NOTIFICATION_SOUND_LOADING,
  UPDATE_USER_NOTIFICATION_SOUND_SUCCESS,
  TOGGLE_NOTIFICATION_MODAL,
  NOTIFICATION_INIT,
} from '../actions/actionTypes';

const initialState = {
  isNotificationModalOpen: false,
  userNotificationConfig: {
    id: '',
    soundNotifications: {
      isSoundOne: true,
      isSoundTwo: false,
      isSoundFour: false,
      isSoundThree: false,
      isSoundNone: false,
    },
    pushNotification: false,
    messagePreview: false,
  },
  getUserNotificationConfigIsLoading: false,
  getUserNotificationConfigHasError: false,
  updateUserNotificationConfigIsLoading: false,
  updateUserNotificationConfigHasError: false,
  updateUserNotificationSoundIsLoading: false,
  updateUserNotificationSoundHasError: false,
  selectedSound: {
    label: 'Sound 1',
    value: {
      path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone1.mp3',
      backendName: 'isSoundOne',
    },
  },
  sounds: [
    {
      label: 'Sound 1',
      value: {
        path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone1.mp3',
        backendName: 'isSoundOne',
      },
    },
    {
      label: 'Sound 2',
      value: {
        path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone2.mp3',
        backendName: 'isSoundTwo',
      },
    },
    {
      label: 'Sound 3',
      value: {
        path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone3.mp3',
        backendName: 'isSoundThree',
      },
    },
    {
      label: 'Sound 4',
      value: {
        path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone4.mp3',
        backendName: 'isSoundFour',
      },
    },
    {
      label: 'None',
      value: {
        path: process.env.PUBLIC_URL + '/assets/sounds/notifications/none.mp3',
        backendName: 'isSoundNone',
      },
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_INIT:
      return {
        ...state,
        userNotificationConfig: action.userConfig,
      };
    case TOGGLE_NOTIFICATION_MODAL:
      return {
        ...state,
        isNotificationModalOpen: action.toggle,
      };
    case GET_USER_NOTIFICATION_CONFIG_ERROR:
      return {
        ...state,
        userNotificationConfigHasError: action.hasError,
      };
    case GET_USER_NOTIFICATION_CONFIG_LOADING:
      return {
        ...state,
        userNotificationConfigIsLoading: action.isLoading,
      };
    case GET_USER_NOTIFICATION_CONFIG_SUCCESS:
      return {
        ...state,
        userNotificationConfigIsLoading: false,
        userNotificationConfig: action.payload,
      };
    case UPDATE_USER_NOTIFICATION_CONFIG_ERROR:
      return {
        ...state,
        updateUserNotificationConfigIsLoading: action.hasError,
      };
    case UPDATE_USER_NOTIFICATION_CONFIG_LOADING:
      return {
        ...state,
        updateUserNotificationConfigIsLoading: action.isLoading,
      };
    case UPDATE_USER_NOTIFICATION_CONFIG_SUCCESS:
      return {
        ...state,
        userNotificationConfig: action.payload,
        updateUserNotificationConfigIsLoading: false,
      };
    case UPDATE_USER_NOTIFICATION_SOUND_ERROR:
      return {
        ...state,
        updateUserNotificationSoundHasError: action.hasError,
      };
    case UPDATE_USER_NOTIFICATION_SOUND_LOADING:
      return {
        ...state,
        updateUserNotificationSoundIsLoading: action.isLoading,
      };
    case UPDATE_USER_NOTIFICATION_SOUND_SUCCESS:
      const newUserNotifSoundObj = { ...state.userNotificationConfig };
      newUserNotifSoundObj.soundNotifications = action.payload;
      return {
        ...state,
        updateUserNotificationSoundIsLoading: false,
        userNotificationConfig: newUserNotifSoundObj,
      };
    case UPDATE_NOTIFICATION_SOUND:
      return {
        ...state,
        selectedSound: action.notifSound,
      };
    default:
      return state;
  }
};
