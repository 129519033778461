import React, { FC, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidePanelHeaderStyled } from './styled';
import { SECOND_PANEL_VIEW, THIRD_PANEL_VIEW } from '../../Common/enums';
import { changeThirdPanelView } from '../../Navigation/actions';
import AddUser from '../../FriendList/AddUser';
import UserProfile from './UserProfile';
import SidePanelSearch from './SidePanelSearch';

const { Container } = SidePanelHeaderStyled;

const SidePanelHeader: FC = (): ReactElement => {
  const secondPanelView = useSelector(state => state.navigationReducer.secondPanelView);
  const dispatch = useDispatch();
  const [friendPopUp, setFriendPopUp] = useState(false);

  const displayFriendPopUp = () => {
    setFriendPopUp(friendPopUp => !friendPopUp);
  };

  const addButtonView = () => {
    if (secondPanelView === SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW) {
      return dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CREATE_CHANNEL));
    } else if (secondPanelView === SECOND_PANEL_VIEW.CHANNEL_FRIEND_VIEW) {
      return displayFriendPopUp();
    } else {
      return dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
    }
  };

  return (
    <Container>
      <UserProfile />
      <SidePanelSearch />
      <AddUser addButtonView={addButtonView} friendPopUp={friendPopUp} setFriendPopUp={setFriendPopUp} />
    </Container>
  );
};

export default SidePanelHeader;
