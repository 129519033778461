import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Option({
  style = {},
  fill = '#BDBDBD',
  width = '100%',
  className = '',
  viewBox = '0 0 792 835',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle fill={fill} cx="405.95" cy="419.02" r="359.87" />
      <circle fill="#FFFFFF" cx="405.95" cy="198.59" r="72.99" />
      <circle fill="#FFFFFF" cx="405.95" cy="419.02" r="72.99" />
      <circle fill="#FFFFFF" cx="405.95" cy="639.45" r="72.99" />
    </svg>
  );
}
