import React from 'react';
import { DropzoneStyled } from './styled';
import Flex from '../Flex';
import IconContainer from '../Decoration/IconContainer';

const { Container, Text } = DropzoneStyled;

const DropZone = () => {
  return (
    <Container>
      <Flex container flexDirection="column" alignItems="center">
        <IconContainer iconName="gallery" iconWidth="90px" padding="90px" />
        <Text>Drop files here</Text>
      </Flex>
    </Container>
  );
};

export default DropZone;
