import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Pdf({ style = {}, width = '100%', className = '', viewBox = '0 0 159.9 200' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#FE0303"
        d="M15.5,0c31,0,61.9,0,92.9,0c0.3,0.9,1.1,1.5,1.6,2.1C126.3,21,142.6,40,158.9,58.9c0.7,0.8,1.1,1.5,1.1,2.6
	c0,39.1,0,78.3,0,117.4c0,11.9-9.2,21-21.1,21C99.1,200,59.3,200,19.5,200c-4.1,0-7.7-1.3-10.9-3.9c-4-3.3-6.4-7.5-7.7-12.5
	c-1.2-4.3-0.7-8.7-0.9-13.1c4.4,0,8.8,0,13.1,0c0,0.9,0,1.7,0,2.5c0.2,7.7,5.1,12.5,12.8,12.5c13.4,0,26.8,0,40.1,0
	c22.8,0,45.5,0,68.3,0c7.3,0,12.6-4.9,12.7-11.7c0.4-33.2,0.8-66.5,1.3-99.7c0-1.4-0.3-1.8-1.7-1.8c-7.7,0.1-15.5,0-23.2,0
	c-14.2,0-24.3-10.2-24.4-24.5c0-10.8,0-21.6,0-32.5c0-1.5-0.4-1.8-1.8-1.8c-8,0.1-16,0-24,0c-16,0-32,0-48,0
	c-6.3,0-11.6,4.5-11.9,10.3c-0.4,5.5-0.4,141.1-0.2,146.7c-4.4,0.2-8.7,0.3-13.1,0c0-7.1,0.1-144.2,0.3-151.3
	c0.2-7,3.5-12.4,9.2-16.5C11.2,1.7,13.4,1,15.5,0z"
      />
      <path fill="none" stroke="#FF0000" strokeMiterlimit="10" d="M80,100" />
      <text transform="matrix(1 0 0 1 23.9006 128.7313)" display="none" fill="#EF6C00" enableBackground="new" fontSize="60px">
        P
      </text>
      <g>
        <path
          fill="#FE0303"
          d="M103.7,108.8c-0.3-0.4-0.6-0.7-0.8-1c-1.3-1.4-2.9-2.1-4.6-2.4c-2.7-0.5-5.3-0.2-8.1,0.2
		c0.1,0.3,0.3,0.3,0.5,0.4c3.7,2.1,7.7,3.2,12,3c0.6,0,0.6,0,0.4,0.6c-0.5,1.8-2,2.9-3.9,3.1c-2.5,0.2-4.8-0.4-7.1-1.4
		c-2.4-1.1-4.4-2.5-6.4-4c-0.4-0.3-0.7-0.4-1.1-0.2c-3.5,1-7,2.4-10.4,3.6c-1.2,0.5-2.1,1.1-2.8,2.2c-1.9,2.9-3.8,5.5-6.2,8
		c-1.4,1.5-2.8,2.9-4.6,3.7c-0.7,0.4-1.5,0.3-2.2,0.3c-0.5-0.1-0.9-0.4-1.2-0.9c-0.7-1-0.9-2.1-0.9-3.3c-0.2-3.1,1.2-5.1,3.6-6.6
		c1.8-1.1,3.6-1.6,5.7-2c-0.2,0.3-0.5,0.4-0.8,0.6c-2.2,1.3-3.7,3.1-4.7,5.3c-0.2,0.6-0.4,1.2-0.5,1.8c0,0.2-0.1,0.5,0.2,0.7
		c0.3,0.1,0.4-0.2,0.6-0.3c1.6-1.7,3-3.4,4.4-5.2c3.4-4.3,6.7-8.8,9.4-13.6c0.8-1.4,1.5-2.9,2.3-4.3c0.1-0.3,0.1-0.5-0.1-0.7
		c-1.6-3-2.8-6-3.4-9.3c-0.6-2.5-0.6-5-0.4-7.6c0.1-1.1,0.5-2.1,1.1-3c0.8-1.1,1.8-1.6,3.2-1.3c1.8,0.3,3.2,1.1,4,2.7
		c0.7,1.3,0.6,3.4-0.1,4.6c-0.2-0.7-0.3-1.4-0.5-2c-0.3-1-0.7-1.9-1.4-2.6c-0.5-0.4-1-0.7-1.6-0.7s-0.8,0.2-1,0.7
		c-0.3,1.1-0.4,2.2-0.4,3.3c-0.1,3.8,0.6,7.5,2.1,11c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0.1-0.2,0.1-0.3,0.2-0.4
		c0.9-2.7,1.6-5.4,1.6-8.3c0-0.6,0.1-0.6,0.6-0.3c1.1,0.7,1.7,1.9,1.9,3.2c0.4,2.1,0,4-0.5,6c-0.4,1.3-0.8,2.6-1.3,3.7
		c-0.1,0.2-0.1,0.4,0.1,0.6c1.6,2,3.3,3.7,5.1,5.5c0.2,0.2,0.4,0.2,0.7,0.1c2-0.7,4-1.3,6.1-1.7c1.7-0.3,3.3-0.4,5-0.1
		c2.9,0.5,4.7,2.3,5.7,4.9C103.6,106.8,103.8,107.7,103.7,108.8z M74.7,107.7c0.2,0.1,0.4,0,0.5-0.1c2.3-1.1,4.5-2.2,6.9-3.2
		c0.3-0.1,0.3-0.3,0.1-0.5c-1-1.1-2-2.2-3-3.3c-0.2-0.3-0.4-0.2-0.5,0c-0.5,0.9-1,1.8-1.5,2.7C76.5,104.7,75.6,106.2,74.7,107.7z"
        />
      </g>
    </svg>
  );
}
