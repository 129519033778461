import React, { useState, ReactElement, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmojiPicker from '../EmojiPicker';
import { Styled } from './styled';
import Option from './OtherOption';
import { SELECTED_MESSAGE_OPTION, THREAD_CONVERSATION_VIEW } from '../../Common/enums';
import { MessageData } from '../../Common/interfaces/message';
import { toggleThreadConversationView } from '../../Navigation/actions';
import { UserData } from '../../Common/interfaces/user';
import { setParentThread } from '../../Thread/actions';
import { ThreadListData } from '../../Common/interfaces/thread';
import { ChannelData, MembersData } from '../../Common/interfaces/channel';
import { isNotEmptyArray } from '../../utils/arrays';
import IconContainer from '../Decoration/IconContainer';
import Flex from '../Flex';
import { useTranslation } from 'react-i18next';

const { Container } = Styled;

export interface MessageOptionProps {
  activeConversation: ChannelData;
  handleEmojiSelect: (emoji: string) => void;
  toggleEditMessage: (toggle: boolean) => void;
  setIsKebabActive?: any;
  isKebabActive?: boolean;
  message: MessageData;
  setIsMessageOptionVisible?: any;
  setIsMessageKebabActive?: any;
  setActiveMessageOption?: any;
  activeMessageOption?: SELECTED_MESSAGE_OPTION;
  messageSender: MembersData;
  isRecursive: boolean;
}

export default function MessageOption(props: MessageOptionProps): ReactElement {
  const { activeConversation, handleEmojiSelect, message, activeMessageOption, setActiveMessageOption, messageSender, isRecursive } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const threads: Array<ThreadListData> = useSelector(state => state.threadReducer.threads);
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const [isReactionShown, setIsReactionShown] = useState(false);
  const [optionsPlacement, setOptionPlacement] = useState('top');
  const messageOptionRef: any = useRef();
  const isHoveringToIcon = activeMessageOption === SELECTED_MESSAGE_OPTION.OTHER_OPTION;

  const handleThreadClick = () => {
    const threadIndex = isNotEmptyArray(threads) ? threads.findIndex(thread => thread.parentMessageId === message.id) : -1;
    let parentThread;
    if (threadIndex >= 0) {
      parentThread = threads[threadIndex];
    } else {
      parentThread = {
        id: '',
        parentMessageId: message.id,
        conversationImageUrl: activeConversation.conversationImageUrl,
        conversationId: activeConversation.id,
        conversationName: activeConversation.name,
        conversationMembers: activeConversation.members,
        conversationIsBlocked: activeConversation.isBlocked,
        childMessage: message,
        threadMemberId: loggedInUser.id,
        threadSender: messageSender,
        unreadCount: 0,
      };
    }

    dispatch(setParentThread(parentThread));
    setActiveMessageOption(SELECTED_MESSAGE_OPTION.NONE);
    dispatch(toggleThreadConversationView(THREAD_CONVERSATION_VIEW.VISIBLE));

    props.setIsMessageOptionVisible(false);
  };

  const handleEditMessage = () => {
    props.toggleEditMessage(true);
    props.setIsMessageOptionVisible(false);
  };

  const handleReactionIconClick = e => {
    getElementTopPosition();
    setActiveMessageOption(SELECTED_MESSAGE_OPTION.REACTION);
    e.preventDefault();
    setIsReactionShown(isReactionShown => !isReactionShown);
  };

  const handleOtherOptionClick = () => {
    getElementTopPosition();
    setActiveMessageOption(SELECTED_MESSAGE_OPTION.OTHER_OPTION);
  };

  const handleReactionPlacement = () => {
    if (optionsPlacement === 'bottom') {
      return 'translateY(390px)';
    } else if (optionsPlacement === 'middle') {
      return 'translateY(200px)';
    } else {
      return 'translateY(0px)';
    }
  };

  const getElementTopPosition = () => {
    const containerElement = messageOptionRef.current;
    if (containerElement) {
      const containerElementTop = containerElement.getBoundingClientRect().top;
      if (containerElementTop < 440) {
        setOptionPlacement('bottom');
      } else if (containerElementTop < 600) {
        setOptionPlacement('middle');
      } else {
        setOptionPlacement('top');
      }
    }
  };

  return (
    <Container ref={messageOptionRef} isRecursive={isRecursive}>
      <IconContainer
        onClick={handleReactionIconClick}
        tooltipText={t(isReactionShown || isHoveringToIcon ? '' : 'AddReaction')}
        margin="1px 5px"
        iconName="reaction"
        iconWidth="20px"
        iconFill={activeMessageOption === SELECTED_MESSAGE_OPTION.REACTION ? '#444444' : '#BDBDBD'}
      >
        {isReactionShown && (
          <Flex transform={handleReactionPlacement()} handleOnClick={e => e.stopPropagation()}>
            <EmojiPicker handleEmojiSelect={handleEmojiSelect} />
          </Flex>
        )}
      </IconContainer>
      <IconContainer onClick={handleThreadClick} tooltipText={t('StartThread')} margin="1px 5px" iconName="thread" iconWidth="20px" />
      {message.userId === loggedInUser.id && (
        <IconContainer
          onClick={handleEditMessage}
          tooltipText={t(isHoveringToIcon ? '' : 'EditMessage')}
          margin="1px 5px"
          iconName="edit-message"
          iconWidth="20px"
        />
      )}
      <IconContainer
        onClick={handleOtherOptionClick}
        tooltipText={t(isHoveringToIcon ? '' : 'Others')}
        margin="1px 5px"
        iconName="option"
        iconWidth="20px"
        iconFill={activeMessageOption === SELECTED_MESSAGE_OPTION.OTHER_OPTION ? '#444444' : '#BDBDBD'}
      >
        {activeMessageOption === SELECTED_MESSAGE_OPTION.OTHER_OPTION && (
          <Option
            visible={activeMessageOption === SELECTED_MESSAGE_OPTION.OTHER_OPTION}
            isRecursive={props.isRecursive}
            message={message}
            hideOption={props.setIsMessageOptionVisible}
            setActiveMessageOption={setActiveMessageOption}
            optionsPlacement={optionsPlacement}
          />
        )}
      </IconContainer>
    </Container>
  );
}
