import React, { FC, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptionStyled } from '../../../CommonComponents/Options';
import Modal from '../../../CommonComponents/Modal';
import Flex from '../../../CommonComponents/Flex';
import { useTranslation } from 'react-i18next';
import { THIRD_PANEL_VIEW } from '../../../Common/enums';
import { changeThirdPanelView } from '../../../Navigation/actions';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';

const { Container, Item } = OptionStyled;

const Options: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDimmedModal: boolean = useSelector(state => state.navigationReducer.isDimmedModal);
  const [isOpen, toggleOption] = useState(false);

  const handleViewFiles = () => {
    dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.SHARED_FILES));
    toggleOption(false);
  };

  return (
    <Flex position={isDimmedModal ? 'inherit' : 'relative'}>
      <IconContainer onClick={() => toggleOption(true)} iconName="kebab" iconWidth="25px" />
      <Modal dimmed={false} visible={isOpen} setModalVisibility={() => toggleOption(false)} />
      {isOpen && (
        <Container width="150px" transform="translate(-90%, 10px)">
          <Item onClick={handleViewFiles}>{t('ViewFiles')}</Item>
        </Container>
      )}
    </Flex>
  );
};

export default Options;
