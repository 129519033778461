import axios from 'axios';
import { BLOCK_USER_ERROR, BLOCK_USER_LOADING, UPDATE_FRIEND_STATUS } from '../actionTypes';

export function blockUserError(bool: boolean, errMessage: string) {
  return {
    type: BLOCK_USER_ERROR,
    hasError: bool,
    errMessage: errMessage,
  };
}

export function blockUserLoading(bool: boolean) {
  return {
    type: BLOCK_USER_LOADING,
    isLoading: bool,
  };
}

export function blockUserSuccess(friendStatus) {
  return {
    type: UPDATE_FRIEND_STATUS,
    friendStatus: friendStatus,
  };
}

export function blockUser(formData, friendId: string) {
  return async dispatch => {
    dispatch(blockUserLoading(true));
    return await axios.put(process.env.REACT_APP_API_URL + `/friends/${friendId}/block`, formData).then(
      response => {
        dispatch(blockUserSuccess(response.data.friend.friendStatus));
      },
      err => {
        dispatch(blockUserError(true, err.response.data));
      }
    );
  };
}
