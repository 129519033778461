import React, { ReactElement, FC, useState } from 'react';
import * as firebase from 'firebase/app';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutStyled } from './styled';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { ButtonStyled } from '../../CommonComponents/Button/Button.style';
import { useTranslation } from 'react-i18next';
import { signOutUser, signOutDemoUser } from '../../Auth/actions';
import { UserData } from '../../Common/interfaces/user';
import Modal from '../../CommonComponents/Modal';
import Icon from '../../CommonComponents/Icon';
import { NavigationStyled } from '../styled';
import { clearChatReducer } from '../../ChatList/actions';
import { clearConversationReducer } from '../../Conversation/actions';
import { clearFriendReducer } from '../../FriendList/actions';
import { clearChannelReducer } from '../../ChannelList/actions';
import { clearCreateChannelReducer } from '../../CreateChannel/actions';
import { handleTokenRequest } from '../../firebase/pushNotification';
import { changeFourthPanelView, changeThirdPanelView, changeSecondPanelView, toggleThreadConversationView } from '../actions';
import { FOURTH_PANEL_VIEW, THIRD_PANEL_VIEW, SECOND_PANEL_VIEW, THREAD_CONVERSATION_VIEW } from '../../Common/enums';
import useReactGa from '../../Common/customHooks/useReactGa';

const { Container, TitleWrapper, ContextWrapper, ButtonWrapper } = LogoutStyled;
const { NavigationIconWrapper } = NavigationStyled;
const { Span } = TypographyStyled;
const { LogoutButton } = ButtonStyled;

const Logout: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sendEGAEvent } = useReactGa();
  const [logoutVisible, toggleLogout] = useState(false);
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const isDemoAccount: boolean = useSelector(state => state.authReducer.isDemoAccount);

  const signOut = async () => {
    dispatch(toggleThreadConversationView(THREAD_CONVERSATION_VIEW.HIDDEN));
    dispatch(changeSecondPanelView(SECOND_PANEL_VIEW.CHANNEL_DIRECT_VIEW));
    dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
    dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN));
    const deviceToken = await handleTokenRequest();
    const requestBody = {
      deviceToken,
      email: loggedInUser.email,
    };
    dispatch(signOutUser(requestBody));
    if (isDemoAccount) {
      dispatch(signOutDemoUser());
    }
    if (process.env.REACT_APP_CLIENT_ENV === 'production') {
      sendEGAEvent('User Action', 'Logged Out', `id: ${loggedInUser.id}`);
    }
    dispatch(clearChatReducer());
    dispatch(clearConversationReducer());
    dispatch(clearFriendReducer());
    dispatch(clearChannelReducer());
    dispatch(clearCreateChannelReducer());
    const i18nextLng = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('i18nextLng', i18nextLng || '');
    window.document.title = 'LAGOON';
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        console.log(error.toString());
      });
  };

  return (
    <>
      <Modal visible={logoutVisible} dimmed={true} setModalVisibility={toggleLogout} />
      <NavigationIconWrapper view={logoutVisible} onClick={() => toggleLogout(isVisible => !isVisible)}>
        <Icon
          name="logout"
          width="40px"
          fill={logoutVisible ? 'white' : '#46D8D8'}
          style={{
            borderRadius: '50%',
            padding: '10px',
            backgroundColor: logoutVisible ? '#21A3A5' : 'white',
            transform: logoutVisible && 'translate(0px, 1px)',
          }}
        />
      </NavigationIconWrapper>
      {logoutVisible && (
        <Container onClick={e => e.stopPropagation()}>
          <TitleWrapper>
            <Span fontColor="black" size="xxl" fontWeight="bolder" margin="0px 0px 20px">
              {t('Logout')}
            </Span>
          </TitleWrapper>
          <ContextWrapper>
            <Span fontColor="black" size="md" fontWeight="bolder" margin="0px 0px 20px">
              {t('SureSignOut')}
            </Span>
          </ContextWrapper>
          <ButtonWrapper>
            <LogoutButton type="button" onClick={() => toggleLogout(isVisible => !isVisible)}>
              {t('Cancel')}
            </LogoutButton>
            <LogoutButton type="button" onClick={signOut}>
              {t('OK')}
            </LogoutButton>
          </ButtonWrapper>
        </Container>
      )}
    </>
  );
};

export default Logout;
