//SECOND PANEL NAVIGATION
export enum SECOND_PANEL_VIEW {
  CHANNEL_DIRECT_VIEW,
  CHANNEL_FRIEND_VIEW,
  THREAD_VIEW,
}

//THIRD PANEL NAVIGATION
export enum THIRD_PANEL_VIEW {
  CONVERSATION,
  CREATE_CHANNEL,
  SHARED_FILES,
}

//FOURTH PANEL NAVIGATION
export enum FOURTH_PANEL_VIEW {
  GROUP_INFO,
  FRIEND_INFO,
  USER_INFO,
  INVITE_FRIEND,
  HIDDEN,
}

//THREAD CONVERSATION TOGGLE
export enum THREAD_CONVERSATION_VIEW {
  VISIBLE,
  HIDDEN,
}

//USER ACCOUNT SETTINGS THIRD PANEL VIEW
export enum USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW {
  CHANGE_PASSWORD,
  TWO_FACTOR_AUTHENTICATION,
  USAGE_SITUATION,
  EMAIL_NOTIFICATIONS,
  CURRENT_PLAN,
  CHANGE_PLAN,
  LANGUAGE,
  NONE,
}

export enum SELECTED_MESSAGE_OPTION {
  REACTION,
  THREAD,
  EDIT,
  OTHER_OPTION,
  NONE,
}

export enum STATUS {
  SUCCESSFUL,
  UNSUCCESSFUL,
}
