import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Default from './Default';
import Download from './Download';
import Features from './Features';
import Footer from './Footer';
import Header from './Header';
import KnowMore from './KnowMore';
import Pricing from './Pricing';
import useReactGa from '../Common/customHooks/useReactGa';
import { UserData } from '../Common/interfaces/user';

export default function LandingPage() {
  const { sendEGAEvent } = useReactGa();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const routes = [
    {
      path: '/lp',
      component: Default,
      exact: true,
    },
    {
      path: '/lp/features',
      component: Features,
    },
    {
      path: '/lp/pricing',
      component: Pricing,
    },
    {
      path: '/lp/download',
      component: Download,
    },
  ];

  useEffect(() => {
    if (process.env.REACT_APP_CLIENT_ENV === 'production') {
      if (loggedInUser && loggedInUser.id) {
        sendEGAEvent('User Action', 'Accessed the Landing Page', `id: ${loggedInUser.id}`);
      } else {
        sendEGAEvent('User Action', 'Someone accessed the Landing Page', `No id provided`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      {routes.map(({ path, component, exact }, key) => (
        <Route exact={exact} key={key} path={path} component={component} />
      ))}
      <KnowMore />
      <Footer />
    </>
  );
}
