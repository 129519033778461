import React, { useState } from 'react';
import Flex from '../../CommonComponents/Flex';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { AccountSettingsStyled } from '../styled';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import UsageSituationForm from './UsageSituationForm';
import { useTranslation } from 'react-i18next';

const { HeaderMessage } = AccountSettingsStyled;
const { Span } = TypographyStyled;

export default function UsageSituation() {
  const { t } = useTranslation();
  const [usageSituation, setUsageSituation] = useState(true);
  const [storageCapacity] = useState({
    storageLimit: 5,
    storageSize: 4.2,
  });
  const [headerState] = useState({
    state: 'warning',
    message: t('OnceYouveReachedTheMaximumStorageCapacityYouWontBeAleToSendAnyFilesToAnyUser'),
  });

  const handleSubmit = () => {
    setUsageSituation(toggle => !toggle);
  };

  const determineHeaderIconColor = () => {
    if (headerState.state === 'warning') {
      return '#FBCB0A';
    } else {
      return '';
    }
  };

  return (
    <Flex container flexDirection="column" alignItems="center">
      {headerState.message && (
        <HeaderMessage state={headerState.state}>
          <IconContainer iconName="error-circle" iconWidth="45px" iconFill={determineHeaderIconColor()} />
          <Span size="lg" fontColor="#444444">
            {headerState.message}
          </Span>
          <Flex></Flex>
        </HeaderMessage>
      )}
      <UsageSituationForm onSubmit={handleSubmit} usageSituation={usageSituation} storageCapacity={storageCapacity} />
    </Flex>
  );
}
