import styled, { css } from 'styled-components/macro';
import { ElementProps } from '../../Common/interfaces/element';
import { fadeIn, loadingAnimation, rotate360deg } from '../Animation';

interface IconWrapperProps {
  position?: 'relative' | 'absolute' | 'sticky' | 'fixed' | 'initial';
  display?: 'flex' | 'block';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  opacity?: number;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  transform?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  tooltipText?: string;
}

const HorizontalLine = styled.span`
  border-radius: 25px;
  display: flex;
  justify-content: center;
  border: #30eee6;
  border-width: 2px;
  border-style: solid;
  height: 0px;
  width: 100%;
  align-items: center;
`;

const DefaultBackground = styled.div`
  background: #49ecc9; /* Old browsers */
  background: -moz-linear-gradient(top, #49ecc9 0%, #41b9f3 51%, #41b9f3 51%, #41b9f3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #49ecc9 0%, #41b9f3 51%, #41b9f3 51%, #41b9f3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #49ecc9 0%, #41b9f3 51%, #41b9f3 51%, #41b9f3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49ecc9', endColorstr='#41b9f3',GradientType=0 ); /* IE6-9 */
  height: 100%;
  width: 100%;
`;

const HorizontalDefaultBackground = styled.div`
  background: #49ecc9; /* Old browsers */
  background: -moz-linear-gradient(left, #22ebba 0%, #21e2c1 20%, #1fd4cb 45%, #1dc8d5 55%, #1bbedf 80%, #18a9f0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #22ebba 0%,
    #21e2c1 20%,
    #1fd4cb 45%,
    #1dc8d5 55%,
    #1bbedf 80%,
    #18a9f0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #22ebba 0%, #21e2c1 20%, #1fd4cb 45%, #1dc8d5 55%, #1bbedf 80%, #18a9f0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#22ebba', endColorstr='#18a9f0',GradientType=0 ); /* IE6-9 */
  height: 100%;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: ${(props: IconWrapperProps) => props && props.position};
  width: ${(props: IconWrapperProps) => props && props.width};
  height: ${(props: IconWrapperProps) => props && props.height};
  display: ${(props: IconWrapperProps) => props && props.display};
  flex-direction: ${(props: IconWrapperProps) => props && props.flexDirection};
  align-items: ${(props: IconWrapperProps) => props && props.alignItems};
  justify-content: ${(props: IconWrapperProps) => props && props.justifyContent};
  margin: ${(props: IconWrapperProps) => props && props.margin};
  padding: ${(props: IconWrapperProps) => props && props.padding};
  transform: ${(props: IconWrapperProps) => props && props.transform};
  opacity: ${(props: IconWrapperProps) => props && props.opacity};
  animation: ${fadeIn} 300ms;
  transition: 300ms;
  top: ${(props: IconWrapperProps) => props && props.top};
  right: ${(props: IconWrapperProps) => props && props.right};
  bottom: ${(props: IconWrapperProps) => props && props.bottom};
  left: ${(props: IconWrapperProps) => props && props.left};
  :hover {
    cursor: pointer;
    position: ${(props: IconWrapperProps) => props && props.tooltipText && 'relative'};
  }
  &:after {
    ${(props: IconWrapperProps) =>
      props &&
      props.tooltipText &&
      css`
        content: '${props.tooltipText}';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        direction: rtl;
        background-color: #444444;
        padding: 12px;
        color: white;
        border-radius: 25px 25px 0px 25px;
        bottom: calc(100% + 6px);
        transform: translate(-95%, 0px);
        animation: ${fadeIn} ease-in-out 0.65s;
        font-size: 15px;
        transition: 300ms;
        white-space: pre;
      `}
  }
`;

const LoadingBar = styled.div`
  width: ${(props: ElementProps) => (props && props.width ? props.width : '100%')};
  height: ${(props: ElementProps) => (props && props.height ? props.height : '20px')};
  display: ${(props: ElementProps) => props && props.display};
  flex-direction: ${(props: ElementProps) => props && props.flexDirection};
  align-self: ${(props: ElementProps) => props && props.alignSelf};
  justify-self: ${(props: ElementProps) => props && props.justifySelf};
  margin: ${(props: ElementProps) => props && props.margin};
  padding: ${(props: ElementProps) => props && props.padding};
  transform: ${(props: ElementProps) => props && props.translateXY};
  top: ${(props: ElementProps) => props && props.top};
  right: ${(props: ElementProps) => props && props.right};
  bottom: ${(props: ElementProps) => props && props.bottom};
  left: ${(props: ElementProps) => props && props.left};
  position: absolute;
  border-radius: 20px;
  background-image: linear-gradient(-45deg, #00e9ff 25%, #00d8ec 25%, #00d8ec 50%, #00e9ff 50%, #00e9ff 75%, #00d8ec 75%);
  background-color: #d3d3d3;
  background-size: 50px 50px;
  animation: ${loadingAnimation} 2s linear infinite;
`;

const AnimatedContainer = styled.div`
  animation: ${rotate360deg} 10s linear infinite;
`;

export const DecorationStyled = {
  HorizontalLine,
  DefaultBackground,
  HorizontalDefaultBackground,
  IconWrapper,
  LoadingBar,
  AnimatedContainer,
};
