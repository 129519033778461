import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideMenuStyled } from './styled';
import Account from './Account';
import Plan from './Plan';
import Flex from '../../CommonComponents/Flex';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW } from '../../Common/enums';
import { setAccountSettingsThirdPanelView } from '../actions';
import Others from './Others';
import { useTranslation } from 'react-i18next';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';

const { Container } = SideMenuStyled;
const { Span } = TypographyStyled;

export default function SideMenu({ toggleSideMenu, isSidebarOpen }) {
  const { t } = useTranslation();
  const thirdPanelView: USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW = useSelector(state => state.accountSettingsReducer.thirdPanelView);
  const dispatch = useDispatch();

  const openSettingsViewWebApp = (view: USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW) => {
    dispatch(setAccountSettingsThirdPanelView(view));
  };

  const openSettingsViewMobileApp = (view: USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW) => {
    toggleSideMenu(false);
    dispatch(setAccountSettingsThirdPanelView(view));
  };

  useEffect(() => {
    dispatch(setAccountSettingsThirdPanelView(USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW.CHANGE_PASSWORD));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={`${isSidebarOpen ? '' : 'w-65px'} d-lg-none`}>
        <Flex container alignItems="center" borderBottom="1px solid #E1E1E1">
          <div className="d-flex align-items-center">
            <IconContainer iconName="thread" iconWidth="50px" iconFill="transparent" iconStroke="#444444" onClick={toggleSideMenu} />
            {isSidebarOpen && <span className="text-xxl text-dark font-weight-bold">{t('Settings')}</span>}
          </div>
        </Flex>
        {isSidebarOpen && (
          <>
            <Account handleSubItemClick={openSettingsViewMobileApp} thirdPanelView={thirdPanelView} />
            <Plan handleSubItemClick={openSettingsViewWebApp} thirdPanelView={thirdPanelView} />
            <Others handleSubItemClick={openSettingsViewMobileApp} thirdPanelView={thirdPanelView} />
            <div className="d-flex flex-column ml-2">
              <span className="text-xl text-dark ml-4 mt-3" onClick={() => window.close()}>
                {t('Exit')}
              </span>
            </div>
          </>
        )}
      </div>
      <Container className="d-none d-lg-block">
        <Flex container alignItems="center" borderBottom="1px solid #E1E1E1">
          <div className="mx-4 d-flex align-items-center">
            {isSidebarOpen && (
              <Span size="xxxl" fontColor="#444444" fontWeight="bold">
                {t('Settings')}
              </Span>
            )}
          </div>
        </Flex>
        <Account handleSubItemClick={openSettingsViewWebApp} thirdPanelView={thirdPanelView} />
        <Plan handleSubItemClick={openSettingsViewWebApp} thirdPanelView={thirdPanelView} />
        <Others handleSubItemClick={openSettingsViewWebApp} thirdPanelView={thirdPanelView} />
      </Container>
    </>
  );
}
