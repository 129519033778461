//Create Group Chat
export const CREATE_CONVERSATION_ERROR = 'CREATE_CONVERSATION_ERROR';
export const CREATE_CONVERSATION_LOADING = 'CREATE_CONVERSATION_LOADING';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';

export const INVITE_FRIENDS_ERROR = 'INVITE_FRIENDS_ERROR';
export const INVITE_FRIENDS_LOADING = 'INVITE_FRIENDS_LOADING';
export const INVITE_FRIENDS_SUCCESS = 'INVITE_FRIENDS_SUCCESS';

export const HANDLE_MEMBERS = 'HANDLE_MEMBERS';
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_GROUP = 'SET_GROUP';

export const CLEAR_CREATE_CHANNEL_REDUCER = 'CLEAR_CREATE_CHANNEL_REDUCER';
