import styled from 'styled-components/macro';
import { Active, Display } from '../../Common/interfaces';

const ConversationThread = styled.div`
  background-color: ${(props: Active) => (props && props.isActive ? '#e9ebef' : '')};
  height: 85px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 30px;
  :hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

const NewMessageContainer = styled.div`
  height: 110px;
  display: ${(props: Display) => (props.visible ? 'flex' : 'none')};
  background-color: ${(props: Display) => (props.visible ? '#e9ebef' : '')};
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  &:hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

export const SidePanelBodyStyled = {
  ConversationThread,
  NewMessageContainer,
};
