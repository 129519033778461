import styled from 'styled-components/macro';

interface OptionContainerProps {
  visible: boolean;
  placement: string;
  isRecursive: boolean;
  isOwnMessage: boolean;
}

const handleOptionContainerPlacement = (placement, isRecursive, isOwnMessage) => {
  if (placement === 'top') {
    if (isRecursive) {
      if (isOwnMessage) {
        return 'translate(-180px, -100px)';
      } else {
        return 'translate(-180px, -70px)';
      }
    } else {
      if (isOwnMessage) {
        return 'translate(-180px, -100px)';
      } else {
        return 'translate(-180px, -70px)';
      }
    }
  } else if (placement === 'bottom') {
    return 'translate(-180px, 10px)';
  } else {
    return 'translate(-180px, 10px)';
  }
};

const OptionContainer = styled.div`
  display: ${(props: OptionContainerProps) => (props && props.visible ? 'flex' : 'none')};
  position: absolute;
  flex-direction: column;
  border-radius: 10px;
  width: 200px;
  background-color: white;
  padding: 5px;
  border: solid 1px #cccccc;
  transform: ${(props: OptionContainerProps) =>
    props && props.placement && handleOptionContainerPlacement(props.placement, props.isRecursive, props.isOwnMessage)};
`;

const OptionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100&;
  padding: 5px 0px;
  background-color: white;
  transition: 300ms;
  :hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
`;

const OptionDivider = styled.div`
  width: 100&;
  border-width: 1px;
  border-color: #cccccc;
  border-style: solid;
`;

export const OptionStyled = {
  OptionContainer,
  OptionItem,
  OptionDivider,
};
