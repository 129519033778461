import styled from 'styled-components/macro';
import { fadeIn } from '../Animation';

interface UserAvatarWrapperProps {
  margin?: string;
  padding?: string;
  isActive?: boolean;
}

interface UserAvatarProps {
  withHoverEffect?: boolean;
  opacity?: number;
}

const UserAvatar = styled.img`
  object-fit: cover;
  border-radius: 50px;
  transition: 300ms;
  &:hover {
    cursor: pointer;
    opacity: ${(props: UserAvatarProps) => props && props.withHoverEffect && props.opacity && props.opacity};
  }
`;

const UserAvatarWrapper = styled.div`
  margin: ${(props: UserAvatarWrapperProps) => props && props.margin && props.margin};
  padding: ${(props: UserAvatarWrapperProps) => props && props.padding && props.padding};
  transform: ${(props: UserAvatarWrapperProps) => props && props.isActive && 'translate(0px, 1px)'};
  &:hover {
    cursor: pointer;
  }
`;

const UserPhoto = styled.img`
  border-radius: 0px 25px 0px 0px;
  object-fit: cover;
  width: 100%;
  :hover {
    cursor: pointer;
  }
`;

const UserPhotoAddFriend = styled(UserPhoto)`
  border-radius: 30px 0px 30px 30px;
  height: 310px;
  width: 375px;
  transform: translate(-400px, 130px);
  position: absolute;
  animation: ${fadeIn} 500ms;
`;

export const ImageStyled = {
  UserAvatar,
  UserAvatarWrapper,
  UserPhoto,
  UserPhotoAddFriend,
};
