import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  transition-duration: 300ms;
  :hover {
    background-color: #e9ebef;
    cursor: pointer;
  }
`;

export const ChannelCardStyled = {
  Container,
};
