import axios from 'axios';
import { UPLOAD_USER_IMAGE_LOADING, UPLOAD_USER_IMAGE_ERROR, UPLOAD_USER_IMAGE_SUCCESS } from '../actionTypes';

export const uploadUserImageLoading = (bool: boolean) => {
  return {
    type: UPLOAD_USER_IMAGE_LOADING,
    isLoading: bool,
  };
};

export const uploadUserImageHasError = (bool: boolean) => {
  return {
    type: UPLOAD_USER_IMAGE_ERROR,
    hasError: bool,
  };
};
export const uploadUserImageSuccess = profileImageUrl => {
  return {
    type: UPLOAD_USER_IMAGE_SUCCESS,
    profileImageUrl: profileImageUrl,
  };
};

export const uploadUserImage = (userId: string, requestBody) => {
  return async dispatch => {
    dispatch(uploadUserImageLoading(true));
    return await axios.post(process.env.REACT_APP_API_URL + `/users/setProfileImage/${userId}`, requestBody).then(
      response => {
        return dispatch(uploadUserImageSuccess(response.data.users.profileImageUrl));
      },
      err => {
        return dispatch(uploadUserImageHasError(true));
      }
    );
  };
};
