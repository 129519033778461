import React, { FC, useState, useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CreateChannelStyled } from './styled';
import SearchFriend from './SearchFriend';
import { THIRD_PANEL_VIEW, FOURTH_PANEL_VIEW } from '../Common/enums';
import { changeThirdPanelView, changeFourthPanelView } from '../Navigation/actions';
import CreateChannelResultList from './ResultList';
import { createConversation } from './actions/createConversation';
import { UserData } from '../Common/interfaces/user';
import { FriendData } from '../Common/interfaces/friend';
import { setMembers } from './actions';
import { ChannelData } from '../Common/interfaces/channel';
import { inviteFriendInGroup } from './actions/inviteFriendInGroup';
import { removeElementInsideArray } from '../utils/arrays';
import useGetActiveConversation from '../Common/customHooks/useGetActiveConversation';

const { SearchFriendContainer } = CreateChannelStyled;

interface Props {
  groupExist?: boolean;
}

const CreateChannel: FC<Props> = ({ groupExist }): ReactElement => {
  const createChannelState = useSelector(state => state.createChannelReducer);
  const activeConversation: ChannelData = useGetActiveConversation();
  const {
    group,
    members,
  }: {
    group: ChannelData;
    members: Array<FriendData>;
  } = createChannelState;
  const friendList: Array<FriendData> = useSelector(state => state.friendReducer.friendList);
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const [selectedFriends, setSelectedFriend] = useState<Array<FriendData>>(friendList);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setMembers([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedFriend(friendList);
  }, [friendList]);

  useEffect(() => {
    if (groupExist) {
      const copyOfGroup: any = { ...group };
      const modifiedMembers = removeElementInsideArray(
        copyOfGroup.members.map(member => {
          if (!member.isRemoved) {
            return {
              friendId: member.id,
              friendDisplayName: member.displayName,
              friendProfileImageUrl: member.profileImageUrl,
              isRemoved: member.isRemoved,
            };
          }
          return undefined;
        }),
        undefined
      );
      const modifiedFriendList = friendList.map(friend => {
        return {
          friendId: friend.friendId,
          friendDisplayName: friend.friendDisplayName,
          friendOriginalName: friend.friendOriginalName,
          friendProfileImageUrl: friend.friendProfileImageUrl,
        };
      });
      const allList = modifiedMembers.concat(modifiedFriendList);
      const finalList = allList.filter((thing, index, self) => index === self.findIndex(t => t.friendId === thing.friendId));
      setSelectedFriend(finalList);
      dispatch(setMembers(modifiedMembers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const createChannel = () => {
    const requestBody = {
      ownerId: loggedInUser.id,
      ownerDisplayName: loggedInUser.displayName,
      profileImageUrl: loggedInUser.profileImageUrl,
      members: createAddMembersToMemberList(),
    };
    if (requestBody.members.length > 1) {
      dispatch(createConversation(requestBody)).then(() => {
        dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
        dispatch(setMembers([]));
      });
    }
  };

  const createAddMembersToMemberList = () => {
    return members.map(member => {
      return {
        id: member.friendId,
        friendOriginalName: member.friendOriginalName ? member.friendOriginalName : member.friendDisplayName,
        friendProfileImageUrl: member.friendProfileImageUrl,
      };
    });
  };

  const inviteAddMembersToMemberList = () => {
    const originalMembersId = removeElementInsideArray(
      activeConversation.members.map(member => !member.isRemoved && member.id),
      undefined
    );
    return removeElementInsideArray(
      members.map(member => {
        if (!originalMembersId.includes(member.friendId)) {
          return {
            id: member.friendId,
            friendOriginalName: member.friendOriginalName ? member.friendOriginalName : member.friendDisplayName,
            friendProfileImageUrl: member.friendProfileImageUrl,
          };
        } else return undefined;
      }),
      undefined
    );
  };

  const inviteFriend = () => {
    const requestBody = {
      members: inviteAddMembersToMemberList(),
    };
    if (requestBody.members.length >= 1) {
      dispatch(inviteFriendInGroup(requestBody, group.id)).then(() => {
        dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN));
        dispatch(setMembers([]));
      });
    } else {
      dispatch(changeThirdPanelView(THIRD_PANEL_VIEW.CONVERSATION));
      dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN));
      dispatch(setMembers([]));
    }
  };

  return (
    <SearchFriendContainer>
      <SearchFriend
        createChannel={createChannel}
        inviteFriend={inviteFriend}
        groupExist={groupExist}
        setSelectedFriend={setSelectedFriend}
      />
      <CreateChannelResultList selectedFriends={selectedFriends} groupExist={groupExist} />
    </SearchFriendContainer>
  );
};

export default CreateChannel;
