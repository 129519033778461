import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Delete({
  style = {},
  fill = '#FF3737',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M540.1,44.8c66.8,0,131.7,13.1,192.7,38.9c58.9,24.9,111.9,60.6,157.4,106.1c45.5,45.5,81.2,98.5,106.1,157.4
			c25.8,61,38.9,125.9,38.9,192.7s-13.1,131.7-38.9,192.7c-24.9,59-60.6,111.9-106.1,157.4c-45.5,45.5-98.4,81.2-157.4,106.1
			c-61,25.8-125.8,38.9-192.7,38.9s-131.7-13.1-192.7-38.9c-58.9-24.9-111.9-60.6-157.4-106.1c-45.5-45.5-81.2-98.5-106.1-157.4
			c-25.8-61-38.9-125.9-38.9-192.7s13.1-131.7,38.9-192.7c24.9-59,60.6-111.9,106.1-157.4c45.5-45.5,98.4-81.2,157.4-106.1
			C408.4,57.9,473.2,44.8,540.1,44.8 M540.1-0.2C241.8-0.2,0.1,241.7,0.1,540s241.8,540.2,540,540.2s540-241.9,540-540.2
			S838.3-0.2,540.1-0.2L540.1-0.2z"
          />
        </g>
      </g>
      <g display="none">
        <g display="inline">
          <path
            fill="#FFFFFF"
            d="M761.5,498.1c0-122.3-99.2-221.5-221.5-221.5c-103.8,0-190.7,71.5-214.7,167.9
			c-66.5,10.1-117.6,67.4-117.6,136.7c0,76.5,62,138.4,138.4,138.4h110.8c15.3,0,27.7-12.4,27.7-27.7v-76.1
			c0-11.5-9.3-20.8-20.8-20.8h-42.4c-20.4,0-26.1-14-12.6-31.1l106.8-136.1c13.4-17.1,35.4-17.1,48.8,0l106.8,136.1
			c13.4,17.1,7.7,31.1-12.6,31.1h-42.4c-11.5,0-20.8,9.3-20.8,20.8v76.1c0,15.3,12.4,27.7,27.7,27.7h138.4
			c61.2,0,110.8-49.6,110.8-110.8S822.7,498.1,761.5,498.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              fill={fill}
              d="M386.7,759.7c0.6,13.1,12.5,23.4,27,23.4h252.6c14.5,0,26.4-10.3,27-23.4l18-344.4H368.7L386.7,759.7z
				 M597.2,500.9c0-5.5,4.9-9.9,11-9.9h17.6c6.1,0,11,4.4,11,9.9v196.8c0,5.5-4.9,9.9-11,9.9h-17.6c-6.1,0-11-4.4-11-9.9V500.9z
				 M520.3,500.9c0-5.5,4.9-9.9,11-9.9h17.6c6.1,0,11,4.4,11,9.9v196.8c0,5.5-4.9,9.9-11,9.9h-17.6c-6.1,0-11-4.4-11-9.9V500.9
				L520.3,500.9z M443.4,500.9c0-5.5,4.9-9.9,11-9.9h17.6c6.1,0,11,4.4,11,9.9v196.8c0,5.5-4.9,9.9-11,9.9h-17.6
				c-6.1,0-11-4.4-11-9.9V500.9z"
            />
          </g>
          <g>
            <path
              fill={fill}
              d="M723.4,322H606.9V302c0-2.8-2.5-5.1-5.7-5.1H478.9c-3.1,0-5.7,2.3-5.7,5.1V322H356.7c-9.4,0-17,6.9-17,15.4
				v48.2h400.7v-48.2C740.4,328.8,732.8,322,723.4,322z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
