import styled from 'styled-components/macro';

interface IconWrapperProps {
  view?: boolean;
}

const NavigationIconWrapper = styled.div`
  background-color: ${(props: IconWrapperProps) => props && props.view && '#3c92bb'};
  padding: 5px 25px 5px 5px;
  border-radius: 25px 0px 0px 25px;
  transition-duration: 300ms;
  :hover {
    background-color: #3c92bb;
    cursor: pointer;
  }
`;

export const NavigationStyled = {
  NavigationIconWrapper,
};
