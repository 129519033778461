import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { GroupStyled } from './styled';
import { changeFourthPanelView } from '../../Navigation/actions';
import { FOURTH_PANEL_VIEW } from '../../Common/enums';
import Flex from '../../CommonComponents/Flex';
import { isNotEmptyArray, removeElementInsideArray } from '../../utils/arrays';
import MemberInfo from './MemberInfo';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { ChannelData, MembersData } from '../../Common/interfaces/channel';
import { updateGroupName } from '../actions/updateGroupChatName';
import { setGroup } from '../../CreateChannel/actions';
import { updateConversationInList } from '../../ChatList/actions';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { useTranslation } from 'react-i18next';
import { bytesToMegaBytes } from '../../utils/files';
import Swal from 'sweetalert2';
import { DecorationStyled } from '../../CommonComponents/Decoration/Decoration.style';
import useUploadProfile from '../../Common/customHooks/useUploadProfile';

const { Span } = TypographyStyled;
const { UserAvatar } = ImageStyled;
const { GroupContainer, NameContainer, MemberListContainer, EditableName } = GroupStyled;
const { AnimatedContainer } = DecorationStyled;

const ChannelDetails: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { uploadConversationProfile } = useUploadProfile();
  const group: ChannelData = useSelector(state => state.groupReducer.group);
  const [name, setName] = useState(group.name);
  const [image, setImage] = useState(group.conversationImageUrl);
  const [isUploading, toggleUploading] = useState(false);
  const [members, setMembers] = useState<Array<MembersData> | any>(group.members);
  const [isMouseOverContainer, setContainerHoverState] = useState(false);
  const fileUploadRef: any = useRef();

  useEffect(() => {
    if (group.name !== name) {
      setName(group.name);
    }
    if (group.conversationImageUrl !== image) {
      setImage(group.conversationImageUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.name]);

  useEffect(() => {
    setMembers(
      removeElementInsideArray(
        group.members.map(member => !member.isRemoved && member),
        false
      )
    );
    dispatch(updateConversationInList(group));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const handleUploadImage = async e => {
    if (isNotEmptyArray(Array.from(e.target.files))) {
      const file = e.target.files[0];
      const fileSize = bytesToMegaBytes(file.size);
      const fileType = file.type;
      if (fileSize <= 25) {
        if (fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'image/jpeg') {
          toggleUploading(true);
          const response: any = await uploadConversationProfile(file, group.id);
          setImage(response.conversationImageUrl);
          toggleUploading(false);
        } else {
          Swal.fire({
            icon: 'error',
            iconHtml: '!',
            text: t('InvalidFileType'),
            confirmButtonText: t('Ok'),
            cancelButtonText: t('Cancel'),
            showCancelButton: true,
            showConfirmButton: false,
            width: '635px',
            customClass: {
              popup: 'file-too-large-popup-class',
              content: 'forgot-password-content-class',
              cancelButton: 'file-too-large-cancel-button-class',
            },
          });
          setImage(group.conversationImageUrl);
        }
      } else {
        Swal.fire({
          icon: 'error',
          iconHtml: '!',
          text: t('TheFileYouAreTryingToUploadExceedsThe25MbUploadLimit'),
          confirmButtonText: t('Ok'),
          cancelButtonText: t('Cancel'),
          showCancelButton: true,
          showConfirmButton: false,
          width: '635px',
          customClass: {
            popup: 'file-too-large-popup-class',
            content: 'forgot-password-content-class',
            cancelButton: 'file-too-large-cancel-button-class',
          },
        });
        setImage(group.conversationImageUrl);
      }
    }
  };

  const handleGroupNameUpdate = async () => {
    const requestBody = {
      groupConversationName: name,
    };
    await dispatch(updateGroupName(requestBody, group.id));
    const newGroupObj = { ...group };
    newGroupObj.name = name;
    await dispatch(updateConversationInList(newGroupObj));
  };

  const handleInviteFriendClick = () => {
    dispatch(setGroup(group));
    dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.INVITE_FRIEND));
  };

  return (
    <GroupContainer onMouseEnter={() => setContainerHoverState(true)} onMouseLeave={() => setContainerHoverState(false)}>
      <Flex height="15%" container width="100%" justifyContent="center" alignItems="center">
        <IconContainer
          onClick={() => fileUploadRef.current.click()}
          iconName="camera"
          iconWidth="90px"
          iconFill="black"
          iconStyle={{ opacity: '0', position: 'absolute', transition: '300ms' }}
          iconStyleOnMouseEnter={{ opacity: '0.5', position: 'absolute', transition: '300ms' }}
        >
          <input type="file" ref={fileUploadRef} style={{ display: 'none' }} onChange={handleUploadImage} accept="image/*" />
          {isUploading ? (
            <AnimatedContainer style={{ height: '90px', width: '90px' }}>
              <IconContainer iconName="loading" iconWidth="90px" iconFill="transparent" />
            </AnimatedContainer>
          ) : (
            <UserAvatar src={image} alt="" width="90px" height="90px" />
          )}
        </IconContainer>
        <IconContainer
          top="0"
          right="0"
          position="absolute"
          padding="20px"
          onClick={() => dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.HIDDEN))}
          iconName="close"
          iconWidth="35px"
          iconStyle={{ opacity: isMouseOverContainer ? '1' : '0', transition: '300ms' }}
        />
      </Flex>
      <Flex height="12.5%">
        <Flex justifyContent="center" padding="10px 35px 0px 35px">
          <NameContainer>
            <Flex container padding="15px 0px" justifyContent="center">
              <EditableName
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                onBlur={handleGroupNameUpdate}
                spellCheck="false"
              />
            </Flex>
          </NameContainer>
          <Flex container justifyContent="center" margin="10px 0px">
            <Span size="lg" fontColor="#444444">
              {isNotEmptyArray(members) && `${members.length} ${t('Members')}`}
            </Span>
          </Flex>
        </Flex>
      </Flex>
      <Flex height="72.5%">
        <MemberListContainer>
          <Flex padding="25px 35px" handleOnClick={handleInviteFriendClick}>
            <Flex container alignItems="center">
              <IconContainer display="flex" alignItems="center" iconName="add-circle" iconWidth="60px">
                <Span fontColor="black" size="lg" margin="10px">
                  {t('InvitePeople')}
                </Span>
              </IconContainer>
            </Flex>
          </Flex>
          {isNotEmptyArray(group.members) &&
            group.members.map(member => !member.isRemoved && <MemberInfo key={member.id} member={member} />)}
        </MemberListContainer>
      </Flex>
    </GroupContainer>
  );
};

export default ChannelDetails;
