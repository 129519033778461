import { useSelector, useDispatch } from 'react-redux';
import imageCompression from 'browser-image-compression';
import { storageRef } from '../../firebase/firebase';
import { uuid } from 'uuidv4';
import { UserData } from '../interfaces/user';
import { uploadUserImage } from '../../Auth/actions/uploadUserImage';
import { updateGroupPhoto } from '../../ChannelList/actions/updateGroupPhoto';

export default function useUploadProfile() {
  const dispatch = useDispatch();
  const compressedOptions = {
    maxSizeMB: 0.1,
    fileType: 'image/jpg',
  };
  const normalOptions = {
    maxSizeMB: 1,
    fileType: 'image/jpg',
  };

  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);

  const uploadUserProfile = async (file: any) => {
    const originalFile = await imageCompression(file, normalOptions);
    const compressedFile = await imageCompression(file, compressedOptions);
    const fullProfilePathRef = storageRef.child(`/users/${loggedInUser.id}/profileImages/full_profileImage.jpg`);
    const thumbProfilePathRef = storageRef.child(`/users/${loggedInUser.id}/profileImages/thumb_profileImage.jpg`);
    const metaData = {
      contentType: file.type,
      firebaseStorageDownloadTokens: uuid(),
    };
    Promise.all([await fullProfilePathRef.put(originalFile, metaData), await thumbProfilePathRef.put(compressedFile, metaData)]).then(
      uploadResponse => {
        if (uploadResponse[0].state === 'success' && uploadResponse[1].state === 'success') {
          const requestBody = {
            thumbProfileToken: metaData.firebaseStorageDownloadTokens,
            fullProfileToken: metaData.firebaseStorageDownloadTokens,
          };
          dispatch(uploadUserImage(loggedInUser.id, requestBody));
        }
      }
    );
  };

  const uploadConversationProfile = async (file: any, conversationId: string) => {
    const compressedFile = await imageCompression(file, compressedOptions);
    const compressedFilePathRef = storageRef.child(`/conversations/${conversationId}/full_profileImage.jpg`);
    const metaData = {
      contentType: file.type,
      firebaseStorageDownloadTokens: uuid(),
    };
    const uploadResponse = await compressedFilePathRef.put(compressedFile, metaData);
    if (uploadResponse.state === 'success') {
      const requestBody = {
        token: metaData.firebaseStorageDownloadTokens,
      };
      return dispatch(updateGroupPhoto(conversationId, requestBody));
    }
  };

  return { uploadUserProfile, uploadConversationProfile };
}
