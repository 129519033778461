export const FETCH_FRIENDS_ERROR = 'FETCH_FRIENDS_ERROR';
export const FETCH_FRIENDS_LOADING = 'FETCH_FRIENDS_LOADING';
export const FETCH_FIENDS_SUCCESS = 'FETCH_FIENDS_SUCCESS';

export const FILTER_FRIENDS_BY_NAME = 'FILTER_FRIENDS_BY_NAME';

export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_LOADING = 'GET_USER_LOADING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const GET_FRIEND_INFO = 'GET_FRIEND_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';

export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_USER_LOADING = 'ADD_USER_LOADING';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const UPDATE_FRIEND_STATUS = 'UPDATE_FRIEND_STATUS';

export const UNFRIEND_USER_ERROR = 'UNFRIEND_USER_ERROR';
export const UNFRIEND_USER_LOADING = 'UNFRIEND_USER_LOADING';
export const UNFRIEND_USER_SUCCESS = 'UNFRIEND_USER_SUCCESS';

export const BLOCK_USER_ERROR = 'BLOCK_USER_ERROR';
export const BLOCK_USER_LOADING = 'BLOCK_USER_LOADING';

export const FAVORITE_USER_ERROR = 'FAVORITE_USER_ERROR';
export const FAVORITE_USER_LOADING = 'FAVORITE_USER_LOADING';

export const UPDATE_FRIEND_DISPLAY_NAME_ERROR = 'UPDATE_FRIEND_DISPLAY_NAME_ERROR';
export const UPDATE_FRIEND_DISPLAY_NAME_LOADING = 'UPDATE_FRIEND_DISPLAY_NAME_LOADING';
export const UPDATE_FRIEND_DISPLAY_NAME_SUCCESS = 'UPDATE_FRIEND_DISPLAY_NAME_SUCCESS';

export const CLEAR_FRIENDS_REDUCER = 'CLEAR_FRIENDS_REDUCER';
export const UPDATE_FRIEND_LIST = 'UPDATE_FRIEND_LIST';
export const UPDATE_FRIEND_IN_LIST = 'UPDATE_FRIEND_IN_LIST';
