import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Bold({
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  viewBox = '0 0 25 25',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath>
          <circle cx="11.5" cy="11.5" r="11.5" transform="translate(0 0)" fill={fill} stroke="#707070" strokeWidth="1" />
        </clipPath>
      </defs>
      <g fill={fill} stroke="#cacaca" strokeWidth="1">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
        <circle cx="12.5" cy="12.5" r="12" fill="none" />
      </g>
      <g transform="translate(1 1)">
        <g transform="translate(6.595 0.223)">
          <rect width="13.513" height="12.955" transform="translate(0 6.646)" fill="none" />
          <text transform="translate(0.72 16)" fill="#444" fontSize="15" fontWeight="600">
            <tspan x="0" y="0">
              B
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}
