/* eslint-disable no-constant-condition */
import styled, { keyframes } from 'styled-components/macro';

interface BeatProps {
  width?: number;
  height?: number;
  margin?: number;
  interval?: number;
  color?: string;
}

const beat = keyframes`
50% {transform: scale(0.75);opacity: 0.2}
100% {transform: scale(1);opacity: 1}
`;

const Animate = styled.div`
  display: inline-block;
  background-color: ${(props: BeatProps) => props && props.color};
  width: ${(props: BeatProps) => props && props.width}px;
  height: ${(props: BeatProps) => props && props.height}px;
  margin: ${(props: BeatProps) => props && props.margin}px;
  animation: ${beat} 0.7s ${(props: BeatProps) => (props && props.interval === 2 ? '0s' : '0.35s')} infinite linear;
  border-radius: 100%;
  animation-fill-mode: both;
`;

const AnimateParent = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(90deg);
`;

export const Styled = { AnimateParent, Animate };
