import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Check({
  style = {},
  fill = '#49ECC8',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Layer_2_1_">
        <path
          fill={fill}
          d="M404.08,952.34c37.09-0.69,69.51-12.75,95.46-38.63c180.46-180,360.87-360.02,540.54-540.8
		c38.88-39.11,49.41-87.44,31.37-139.94c-32.95-95.96-152.47-120.76-226.39-47.06C703.34,327.19,561.77,468.6,420.88,610.7
		c-13.7,13.81-20.06,12-32.35-0.64c-51.43-52.81-103.66-104.85-156.17-156.6c-55.17-54.35-139.99-54.64-192.96-1.42
		c-52.46,52.71-52.85,137.16,0.74,191.39c89.32,90.4,179.42,180.02,269.52,269.64C335.67,938.9,367.82,951.33,404.08,952.34z"
        />
      </g>
    </svg>
  );
}
