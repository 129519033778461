import styled from 'styled-components';

const FriendContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  border-left: 2px solid rgba(235, 235, 235, 0.5);
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  object-position: 50% 0;
`;

const EditableName = styled.input`
  width: 100%;
  border: 1px solid transparent;
  border-radius: 10px;
  outline: none;
  font-size: 30px;
  color: #444444;
  transition: 300ms;
  :focus {
    padding: 5px 10px;
    border: 1px solid #cacaca;
    cursor: text !important;
  }
  :hover {
    cursor: pointer;
  }
`;

const HorizontalLine = styled.span`
  border-radius: 25px;
  display: flex;
  justify-content: center;
  border: #cacaca;
  border-width: 0.5px;
  border-style: solid;
  height: 0px;
  align-items: center;
`;

export const FriendStyled = {
  FriendContainer,
  ProfileImage,
  EditableName,
  HorizontalLine,
};
