import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Thread({
  style = {},
  fill = '#BDBDBD',
  width = '100%',
  className = '',
  viewBox = '0 0 720 720',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <circle fill={fill} cx="359.8699951171875" cy="359.8699951171875" r="359.8699951171875" />
        <rect x="183.20999145507812" y="238.90997314453125" fill="#FFFFFF" width="353.3299865722656" height="52" />
        <g>
          <rect x="183.20999145507812" y="242.31997680664062" fill="#FFFFFF" width="353.3299865722656" height="52" />
          <rect x="183.20999145507812" y="333.8699951171875" fill="#FFFFFF" width="353.3299865722656" height="52" />
          <rect x="183.20999145507812" y="425.42999267578125" fill="#FFFFFF" width="353.3299865722656" height="52" />
        </g>
      </g>
    </svg>
  );
}
