import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function ColorPalette({
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  viewBox = '0 0 25 25',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fill={fill} stroke="#cacaca" strokeWidth="1">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
        <circle cx="12.5" cy="12.5" r="12" fill="none" />
      </g>
      <g transform="translate(4 4)">
        <g transform="translate(0 0)">
          <circle cx="8.5" cy="8.5" r="8.5" fill="#444" />
        </g>
      </g>
    </svg>
  );
}
