import * as React from 'react';
import { Styled } from './styled';
import { TypographyStyled } from '../Typography/Typography.style';

interface UnreadMessageCountProps {
  allUnreadCount?: number;
}

const { UnreadMessage } = Styled;
const { Span } = TypographyStyled;

export default function UnreadMessageCount(props: UnreadMessageCountProps) {
  const { allUnreadCount } = props;
  const unreadCount = !!allUnreadCount;

  return unreadCount ? (
    <UnreadMessage>
      <Span size="md" fontColor="white">
        {allUnreadCount}
      </Span>
    </UnreadMessage>
  ) : null;
}
