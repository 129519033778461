import React, { useState, ReactElement, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MemberInfoStyled } from './styled';
import { ImageStyled } from '../../../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import Flex from '../../../CommonComponents/Flex';
import { MembersData } from '../../../Common/interfaces/channel';
import MemberInfoOption from './MemberInfoOption';
import { UserData } from '../../../Common/interfaces/user';
import { FriendData } from '../../../Common/interfaces/friend';
import { findUserInFriendList } from '../../../utils/friendList';
import { getFriendInfo, getUserInfo } from '../../../FriendList/actions';
import { changeFourthPanelView } from '../../../Navigation/actions';
import { FOURTH_PANEL_VIEW } from '../../../Common/enums';

const { MemberCardContainer } = MemberInfoStyled;
const { UserAvatar } = ImageStyled;
const { Span } = TypographyStyled;

interface Props {
  member: MembersData;
}

const MemberInfo: FC<Props> = ({ member }): ReactElement => {
  const dispatch = useDispatch();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const friendList: Array<FriendData> = useSelector(state => state.friendReducer.friendList);
  const [isHover, setIsHover] = useState(false);

  const handleAvatarClick = () => {
    if (member.id !== loggedInUser.id) {
      const { friend, index }: any = findUserInFriendList(member.id, friendList);
      if (index >= 0) {
        dispatch(getFriendInfo(friend));
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.FRIEND_INFO));
      } else {
        dispatch(getUserInfo(member));
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.USER_INFO));
      }
    }
  };

  return (
    <MemberCardContainer onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <Flex container alignItems="center" padding="25px 35px">
        <UserAvatar src={member.profileImageUrl} onClick={handleAvatarClick} alt={member.id} height="60" width="60" />
        <Span fontColor="black" size="lg" margin="0px 0px 0px 10px">
          {member.displayName}
        </Span>
      </Flex>
      <MemberInfoOption member={member} isVisible={member.id !== loggedInUser.id && isHover} />
    </MemberCardContainer>
  );
};

export default MemberInfo;
