import axios from 'axios';
import { ADD_THREAD_MEMBER_LOADING, ADD_THREAD_MEMBER_ERROR, ADD_THREAD_MEMBER_SUCCESS } from '../actionTypes';

export function hasError() {
  return {
    type: ADD_THREAD_MEMBER_ERROR,
  };
}

export function isLoading() {
  return {
    type: ADD_THREAD_MEMBER_LOADING,
  };
}

export function isSuccess(payload) {
  return {
    type: ADD_THREAD_MEMBER_SUCCESS,
    payload: payload,
  };
}

export function addThreadMember(requestBody) {
  return async dispatch => {
    dispatch(isLoading());
    return await axios.post(process.env.REACT_APP_API_URL + `/threads/member`, requestBody).then(
      response => {
        dispatch(isSuccess(response.data));
      },
      err => {
        dispatch(hasError());
      }
    );
  };
}
