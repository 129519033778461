import styled from 'styled-components/macro';

const ScrollableContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  height: 90%;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

const Input = styled.input`
  min-height: 50px;
  max-width: 100%;
  width: 100%;
  padding: 10px;
  background: #fff;
  font-size: 15px;
  border-radius: 15px 15px 0 0;
  border: none;
  outline: none;
`;

const ContentEditable = styled.textarea`
  min-height: 50px;
  max-height: 300px;
  max-width: 100%;
  width: 100%;
  padding: 10px;
  background: #fff;
  font-size: 15px;
  border-radius: 15px 15px 0 0;
  border: none;
  outline: none;
  resize: none;
`;

const InputContainer = styled.div`
  min-height: 50px;
  max-width: 100%;
  max-height: 300px;
  width: 100%;
  padding: 10px;
  background: #fff;
  font-size: 20px;
  border-radius: 15px 15px 0 0;
  border: none;
  outline: none;
`;

const MessageInputText = styled.div`
  background: linear-gradient(180deg, #50fcc7, #2fcbff);
  border-radius: 15px;
  padding: 2px;
  width: 100%;
`;

const OtherInput = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 0 0 15px 15px;
  padding: 10px 20px;
  height: 40px;
  width: 100%;
`;

const FilesContainer = styled.div`
  background: white;
`;

const HorizontalLine = styled.span`
  border-radius: 25px;
  display: flex;
  justify-content: center;
  border: #cacaca;
  border-width: 1px;
  border-style: solid;
  height: 0px;
  align-items: center;
`;

const MessageBox = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  transition: 300ms;
  :hover {
    background-color: whitesmoke;
  }
`;

export const ThreadChatListStyled = {
  ScrollableContainer,
  MessageInputText,
  InputContainer,
  ContentEditable,
  FilesContainer,
  HorizontalLine,
  OtherInput,
  Input,
  MessageBox,
};
