import React, { FC, ReactElement } from 'react';
import { ModalStyled } from './styled';

const { ModalContainer } = ModalStyled;

interface Props {
  visible: boolean;
  dimmed?: boolean;
  setModalVisibility: any;
}

const Modal: FC<Props> = ({ visible, dimmed, setModalVisibility }): ReactElement => {
  return (
    <ModalContainer
      visible={visible}
      dimmed={dimmed}
      onClick={e => {
        e.stopPropagation();
        setModalVisibility(false);
      }}
    />
  );
};
export default Modal;
