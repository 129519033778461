import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Settings({
  style = { enableBackground: 'new 0 0 1080 1080' },
  stroke = '#CACACA',
  width = '100%',
  className = '',
  viewBox = '0 0 1080 1080',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <g>
          <ellipse fill="#D2D2D2" cx="540" cy="539.4" rx="540" ry="540.4" />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M728.2,562.7c1-7.7,1.7-15.5,1.7-23.7s-0.7-16-1.7-23.7l51.1-39.9c4.6-3.6,5.8-10.2,2.9-15.5
		l-48.4-83.8c-2.9-5.3-9.4-7.3-14.8-5.3L658.7,395c-12.6-9.7-26.1-17.7-40.9-23.7l-9.2-64.2c-0.7-5.8-5.8-10.2-11.9-10.2h-96.8
		c-6.1,0-11.1,4.4-11.9,10.2l-9.2,64.2c-14.8,6.1-28.3,14.3-40.9,23.7l-60.3-24.2c-5.6-2.2-11.9,0-14.8,5.3l-48.4,83.8
		c-3.1,5.3-1.7,11.9,2.9,15.5l51.1,39.9c-1,7.7-1.7,15.7-1.7,23.7s0.7,16,1.7,23.7l-51.1,39.9c-4.6,3.6-5.8,10.2-2.9,15.5l48.4,83.8
		c2.9,5.3,9.4,7.3,14.8,5.3l60.3-24.2c12.6,9.7,26.1,17.7,40.9,23.7l9.2,64.2c0.7,5.8,5.8,10.2,11.9,10.2h96.8
		c6.1,0,11.1-4.4,11.9-10.2l9.2-64.2c14.8-6.1,28.3-14.3,40.9-23.7l60.3,24.2c5.6,2.2,11.9,0,14.8-5.3l48.4-83.8
		c2.9-5.3,1.7-11.9-2.9-15.5L728.2,562.7z M548.3,623.7c-46.7,0-84.7-38-84.7-84.7s38-84.7,84.7-84.7c46.7,0,84.7,38,84.7,84.7
		S595,623.7,548.3,623.7z"
        />
      </g>
    </svg>
  );
}
