import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Search({
  style = { enableBackground: 'new 0 0 1920 1080' },
  fill = '#DDDDDD',
  width = '100%',
  className = '',
  viewBox = '0 0 822 822',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fill}
        d="M821.8898144531249,411.0669055175781 c1.4,226.75 -186.89,416.1 -420.44,410.99 c-221.73,-4.85 -407.06,-189.71 -401.32,-422.02 C5.6098144531249545,178.8269055175781 190.40981445312502,-5.953094482421875 422.559814453125,0.14690551757811932 C643.1398144531249,5.936905517578111 822.9298144531249,187.96690551757814 821.8898144531249,411.0669055175781 zM821.8898144531249,411.0669055175781 c1.4,226.75 -186.89,416.1 -420.44,410.99 c-221.73,-4.85 -407.06,-189.71 -401.32,-422.02 C5.6098144531249545,178.8269055175781 190.40981445312502,-5.953094482421875 422.559814453125,0.14690551757811932 C643.1398144531249,5.936905517578111 822.9298144531249,187.96690551757814 821.8898144531249,411.0669055175781 z"
      />
      <path
        fill="#FFFFFF"
        d="M147.49981445312505,380.12690551757817 c-0.38000000000000006,-6.57 -0.77,-13.14 -1.15,-19.7 c0,0 0,0 0,-0.010000000000000002 c0.09000000000000002,-0.8800000000000001 0.27,-1.7600000000000002 0.27,-2.64 c-0.030000000000000006,-12.45 1.1400000000000001,-24.79 3.64,-36.97 c10.32,-50.15 36.14,-90.49 77.26,-120.89 c24.16,-17.86 51.24,-29.22 80.86,-34.45 c4.44,-0.78 8.9,-1.47 13.34,-2.2 c0,0 0,0 0,0 c6.57,-0.38000000000000006 13.14,-0.77 19.71,-1.15 c0,0 0,0 0,0 c2.7800000000000002,0.13 5.55,0.31000000000000005 8.33,0.4 c25.53,0.79 49.91,6.4 73.11,16.82 c74.27,33.35 120.53,109.39 116.05,190.68 c-1.09,19.75 -5.17,38.93 -12.29,57.49 c-1.6400000000000001,4.26 -3.39,8.48 -5.3,12.72 c-3.41,7.59 -3.38,9.48 3.8,14.89 c33.75,30 67.48,60.03 101.26,90 c36.59,34.06 43.32,39.36 46.43,50.07 c0.8700000000000001,2.63 1.48,5.34 2.2,8.02 c0,0 0,0.010000000000000002 0,0.010000000000000002 l0.55,9.35 c0,0 0,0.010000000000000002 0,0.010000000000000002 c-0.12000000000000001,0.6300000000000001 -0.29000000000000004,1.25 -0.35000000000000003,1.8800000000000001 c-1.9500000000000002,18.95 -11.46,32.7 -28.64,40.91 c-3.8600000000000003,1.85 -8.17,2.7600000000000002 -12.27,4.11 c0,0 -0.010000000000000002,0 -0.010000000000000002,0 l-9.84,0.5800000000000001 c-0.010000000000000002,0 -0.010000000000000002,0 -0.020000000000000004,0 c-0.31000000000000005,-0.1 -0.6100000000000001,-0.27 -0.92,-0.29000000000000004 c-12.25,-0.7300000000000001 -22.52,-5.73 -31.62,-13.85 c-6.05,-5.41 -98.04,-87.29 -129.15,-114.97 c-0.12000000000000001,-0.11000000000000001 -8.96,-10.15 -19.36,-3.74 c-40.81,25.16 -85.81,33.06 -133.48,25 c-48.66,-8.23 -88.6,-32.07 -119.79,-70.25 c-21.21,-25.97 -34.6,-55.66 -40.44,-88.71 C148.89981445312503,388.8669055175782 148.21981445312497,384.49690551757817 147.49981445312505,380.12690551757817 C147.49981445312505,380.12690551757817 147.49981445312505,380.12690551757817 147.49981445312505,380.12690551757817 z"
      />

      <path
        fill={fill}
        d="M195.85981445312495,367.0769055175781 c4.45,80.5 73.93,143.02 154.12,138.68 c82.06,-4.44 144.39,-73.53 139.93,-155.09 c-4.45,-81.42 -73.96,-143.59 -155.45,-139.03 C253.46981445312497,216.16690551757813 191.35981445312495,285.8269055175781 195.85981445312495,367.0769055175781 z"
      />
    </svg>
  );
}
