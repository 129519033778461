import {
  CREATE_CONVERSATION_ERROR,
  CREATE_CONVERSATION_LOADING,
  CREATE_CONVERSATION_SUCCESS,
  HANDLE_MEMBERS,
  SET_MEMBERS,
  INVITE_FRIENDS_ERROR,
  INVITE_FRIENDS_LOADING,
  INVITE_FRIENDS_SUCCESS,
  SET_GROUP,
  CLEAR_CREATE_CHANNEL_REDUCER,
} from '../actions/actionTypes';
import { isNotEmptyArray } from '../../utils/arrays';
import { FriendData } from '../../Common/interfaces/friend';

const initialState = {
  removeMemberFromGroupHasError: false,
  removeMemberFromGroupIsLoading: false,
  createMessageHasError: false,
  createMessageIsLoading: false,
  inviteFriendsIsLoading: false,
  inviteFriendsHasError: false,
  members: [],
  group: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONVERSATION_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        createMessageIsLoading: false,
      };
    case CREATE_CONVERSATION_LOADING:
      return {
        ...state,
        createMessageIsLoading: action.isLoading,
      };
    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        createMessageIsLoading: false,
      };
    case SET_MEMBERS:
      return {
        ...state,
        members: action.members,
      };
    case HANDLE_MEMBERS:
      let friend: FriendData = action.member;
      let listOfMembers: FriendData[] = [...state.members];
      if (isNotEmptyArray(listOfMembers)) {
        const index = listOfMembers.findIndex(member => member.friendId === friend.friendId);
        if (index < 0) {
          listOfMembers = [...listOfMembers, friend];
        } else {
          listOfMembers = listOfMembers.filter(member => member.friendId !== friend.friendId);
        }
      } else {
        listOfMembers = [...listOfMembers, friend];
      }
      return {
        ...state,
        members: listOfMembers,
      };
    case INVITE_FRIENDS_ERROR:
      return {
        ...state,
        inviteFriendsHasError: true,
      };
    case INVITE_FRIENDS_LOADING:
      return {
        ...state,
        inviteFriendsIsLoading: true,
      };
    case INVITE_FRIENDS_SUCCESS:
      return {
        ...state,
        inviteFriendsIsLoading: false,
      };
    case SET_GROUP:
      return {
        ...state,
        group: action.group,
      };
    case CLEAR_CREATE_CHANNEL_REDUCER:
      return {
        initialState,
      };
    default:
      return state;
  }
};
