import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageData } from '../../../../Common/interfaces/message';
import { MembersData } from '../../../../Common/interfaces/channel';
import EditMessage from '../EditMessage';
import TimeAgo from 'timeago-react';
import { togglePreviewFile } from '../../../actions';
import { deleteFile } from '../../../actions/deleteFile';
import FilePreview from '../../../../CommonComponents/FilePreview';
import { checkFileType, bytesToMegaBytes, bytesToKiloBytes } from '../../../../utils/files';
import { SELECTED_MESSAGE_OPTION } from '../../../../Common/enums';
import IconContainer from '../../../../CommonComponents/Decoration/IconContainer';
import { useTranslation } from 'react-i18next';
import MessageAttachment from '../../../../CommonComponents/MessageAttachment';
import { downloadFile, updateMessageById, updateThreadMessageById } from '../../../../Common/actions';
import MessageText from '../../../../CommonComponents/MessageText';
import useSendFile from '../../../../Common/customHooks/useSendFile';

interface Props {
  isRecursive: boolean;
  message: MessageData;
  user: MembersData;
  isBlocked?: boolean;
  isEditMessageActive?: boolean;
  toggleEditMessage?: (toggle: boolean) => void;
  setIsMessageOptionVisible?: (toggle: boolean) => void;
  setActiveMessageOption?: (option: SELECTED_MESSAGE_OPTION) => void;
}

const Content: FC<Props> = ({
  isRecursive,
  message,
  user,
  isBlocked,
  isEditMessageActive,
  toggleEditMessage,
  setIsMessageOptionVisible,
  setActiveMessageOption,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { deleteFileThreadInStorage } = useSendFile();
  const [selectedFile, setSelectedFile] = useState(false);
  const isDeletingFile: boolean = useSelector(state => state.conversationReducer.deleteFileIsLoading);

  const handleViewFile = e => {
    setSelectedFile(true);
    dispatch(togglePreviewFile(true));
  };

  const handleCloseViewFile = () => {
    setSelectedFile(false);
    dispatch(togglePreviewFile(false));
    setIsMessageOptionVisible && setIsMessageOptionVisible(false);
    setActiveMessageOption && setActiveMessageOption(SELECTED_MESSAGE_OPTION.NONE);
  };

  const handleDownloadFile = async e => {
    e.stopPropagation();
    await downloadFile(message.content.text, message.content.fileName);
  };

  const handleDeleteFile = async () => {
    if (message.messageId) {
      if (message.content.type.isAttachment) {
        const requestBody = {
          fileId: message.id,
        };
        await deleteFileThreadInStorage(message.messageId, message.conversationId, requestBody);
      } else {
        const requestBody = {
          messageId: message.messageId,
          threadId: message.id,
          text: message.content.text,
          isDeleted: true,
          isUpdated: true,
        };
        await updateThreadMessageById(requestBody);
      }
    } else {
      if (message.content.type.isAttachment) {
        await dispatch(deleteFile(message.id, message.conversationId));
      } else {
        const requestBody = {
          messageId: message.id,
          text: message.content.text,
          isDeleted: true,
          isUpdated: true,
        };
        await updateMessageById(requestBody);
      }
    }
    setSelectedFile(false);
    dispatch(togglePreviewFile(false));
  };

  const renderContent = () => {
    if (message && message.content && message.content.type && message.content.type.isMessage) {
      if (isEditMessageActive) {
        return <EditMessage message={message} toggleEditMessage={toggleEditMessage} />;
      } else {
        return <MessageText text={message.content.text} hasBeenUpdated={message.status.isUpdated} />;
      }
    } else if (message && message.content && message.content.type && message.content.type.isAttachment) {
      const fileType = checkFileType(message.content.fileType);
      return (
        <div
          className={`${
            fileType === 'image' || fileType === 'video'
              ? 'w-200px h-200px'
              : 'w-400px h-100px bg-light-1 rounded-lg d-flex align-items-center'
          } pointer`}
          data-toggle="modal"
          data-target={'#filePreview' + message.id}
          onClick={handleViewFile}
        >
          <MessageAttachment url={message.content.text} fileType={fileType} />
          {fileType === 'image' || fileType === 'video' ? null : (
            <div className="text-dark container row no-gutters justify-content-between">
              <div className="d-flex flex-column justify-content-evenly">
                <span className="text-md" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '245px' }}>
                  {message.content.fileName}
                </span>
                <span>
                  {bytesToMegaBytes(message.content.fileSize) > 1
                    ? bytesToMegaBytes(message.content.fileSize).toFixed(2) + 'mb'
                    : bytesToKiloBytes(message.content.fileSize).toFixed(2) + 'kb'}
                </span>
                <span>{t('ClickToView')}</span>
              </div>
              <IconContainer onClick={handleDownloadFile} iconName="download" iconWidth="35px" />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <FilePreview
        isPreviewOpen={selectedFile}
        togglePreview={setSelectedFile}
        messageId={message.id}
        senderId={user.id}
        senderName={user.displayName}
        senderProfileImage={user.profileImageUrl}
        permalink={message.content.text}
        lastDateUpdated={message.lastDateUpdated}
        fileType={message.content.fileType}
        fileName={message.content.fileName}
        isDeletingFile={isDeletingFile}
        handleCloseViewFile={handleCloseViewFile}
        handleDeleteFile={handleDeleteFile}
        isBlocked={isBlocked}
      />
      {isRecursive ? (
        <div className="mt-2 mr-0 mb-2 ml-2 w-100">
          <div className="ql-editor p-0 w-100">{renderContent()}</div>
        </div>
      ) : (
        <div className="ml-2">
          <span className="text-dark font-weight-bold mr-2" style={{ fontSize: '17px' }}>
            {user.displayName}
          </span>
          <span className="text-secondary text-md">
            {!isRecursive && <TimeAgo datetime={new Date(message.dateCreated)} live={false} />}
          </span>
          <div className="ql-editor p-0 mt-2 w-100">{renderContent()}</div>
        </div>
      )}
    </>
  );
};

export default Content;
