import axios from 'axios';
import { UPDATE_FRIEND_DISPLAY_NAME_ERROR, UPDATE_FRIEND_DISPLAY_NAME_LOADING, UPDATE_FRIEND_DISPLAY_NAME_SUCCESS } from '../actionTypes';

export function updateFriendDisplayNameError(bool: boolean, errMessage: string) {
  return {
    type: UPDATE_FRIEND_DISPLAY_NAME_ERROR,
    hasError: bool,
    errMessage: errMessage,
  };
}

export function updateFriendDisplayNameLoading(bool: boolean) {
  return {
    type: UPDATE_FRIEND_DISPLAY_NAME_LOADING,
    isLoading: bool,
  };
}

export function updateFriendDisplayNameSuccess(displayName) {
  return {
    type: UPDATE_FRIEND_DISPLAY_NAME_SUCCESS,
    displayName: displayName,
  };
}

export function updateFriendDisplayName(formData, friendId: string) {
  return async dispatch => {
    dispatch(updateFriendDisplayNameLoading(true));
    return await axios.put(process.env.REACT_APP_API_URL + `/friends/${friendId}`, formData).then(
      response => {
        dispatch(updateFriendDisplayNameSuccess(response.data.friend.friendDisplayName));
      },
      err => {
        dispatch(updateFriendDisplayNameError(true, err.response.data));
      }
    );
  };
}
