import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Close({
  style = {},
  fill = '#DDDAD9',
  width = '100%',
  className = '',
  viewBox = '0 0 1002 1002',
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle fill={fill} cx="500.8500061035156" cy="500.8500061035156" r="500.8500061035156" />
      <g>
        <path
          style={style}
          strokeMiterlimit="10"
          strokeWidth="30"
          stroke="#DDDAD9"
          fill="#FFFFFF"
          d="M592.9900183105469,501.5199816894532 c7.12,-7.78 12.3,-13.88 17.93,-19.53 c31.12,-31.27 62.39,-62.39 93.54,-93.63 c32.28,-32.37 32.08,-48.75 -0.8300000000000001,-81.75 c-2.31,-2.31 -4.62,-4.62 -6.93,-6.93 c-35.26,-35.19 -50.26,-35.28 -85.16,-0.4 c-36.24,36.21 -72.45,72.44 -110.43,110.42 c-39.9,-39.9 -77.68,-78.06 -116,-115.67 c-7.38,-7.24 -16.08,-14.48 -25.56,-17.95 c-23.33,-8.55 -38.42,6.61 -52.67,21.9 c-16.29,17.48 -40.63,31.72 -30.73,61.29 c3.41,10.18 11.91,19.27 19.73,27.24 c37.03,37.75 74.62,74.95 114.04,114.36 c-8.17,8.17 -14.34,14.33 -20.5,20.5 c-33.5,33.51 -66.96,67.06 -100.51,100.52 c-16.57,16.53 -21.84,35.65 -7.01,54.74 c12.4,15.97 27.39,30.39 43.11,43.2 c15.84,12.9 33.61,10.74 49.37,-2.07 c6.94,-5.640000000000001 13.02,-12.37 19.37,-18.72 c35.08,-35.03 70.12,-70.1 106.07,-106.05 c7.46,6.55 13.12,11.03 18.2,16.08 c34.76,34.56 69.08,69.57 104.19,103.77 c20.95,20.41 41.16,20.21 62.54,0.9400000000000001 c6.06,-5.46 11.69,-11.41 17.45,-17.2 c34.94,-35.06 35.03,-50.79 0.43000000000000005,-85.42 C666.4400183105469,574.9399816894531 630.2100183105468,538.7299816894531 592.9900183105469,501.5199816894532 z"
        />
      </g>
    </svg>
  );
}
