import styled from 'styled-components/macro';
import { fadeIn } from '../Animation';

interface OptionContainerProps {
  isRecursive: boolean;
}

const Container = styled.div`
  display: flex;
  transform: ${(props: OptionContainerProps) => (props && props.isRecursive ? 'translateY(-30%)' : 'translateY(-80%)')};
  height: 25px;
  animation: ${fadeIn} ease-in-out 300ms;
  background-color: #ffffff;
  border-radius: 15px;
  align-items: center;
  justify-content: space-around;
`;

export const Styled = {
  Container,
};
