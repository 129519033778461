export const GET_ALL_CONVERSATIONS_ERROR = 'GET_ALL_CONVERSATIONS_ERROR';
export const GET_ALL_CONVERSATIONS_LOADING = 'GET_ALL_CONVERSATIONS_LOADING';
export const GET_ALL_CONVERSATIONS_SUCCESS = 'GET_ALL_CONVERSATIONS_SUCCESS';

export const FILTER_MESSAGES_BY_NAME = 'FILTER_MESSAGES_BY_NAME';

export const CLEAR_ALL_CONVERSATION_MESSAGES = 'CLEAR_ALL_CONVERSATION_MESSAGES';

export const UPDATE_CONVERSATION_LAST_MESSAGE = 'UPDATE_CONVERSATION_LAST_MESSAGE';
export const UPDATE_CONVERSATION_TYPING_MEMBERS = 'UPDATE_CONVERSATION_TYPING_MEMBERS';
export const PUSH_CONVERSATION_TO_CONVERSATION_LIST = 'PUSH_CONVERSATION_TO_CONVERSATION_LIST';
export const UPDATE_CONVERSATION_UNREAD_COUNT = 'UPDATE_CONVERSATION_UNREAD_COUNT';

export const UPDATE_CONVERSATION_LIST = 'UPDATE_CONVERSATION_LIST';
export const UPDATE_CONVERSATION_IN_LIST = 'UPDATE_CONVERSATION_IN_LIST';
