import {
  FETCH_GROUPS_ERROR,
  FETCH_GROUPS_LOADING,
  FETCH_GROUPS_SUCCESS,
  FILTER_GROUPS_BY_NAME,
  GET_GROUP_INFO,
  CLEAR_CHANNEL_REDUCER,
  UPDATE_GROUP_PHOTO_ERROR,
  UPDATE_GROUP_PHOTO_LOADING,
  UPDATE_GROUP_PHOTO_SUCCESS,
  UPDATE_GROUP_OBJECT,
  UPDATE_GROUP_NAME_ERROR,
  UPDATE_GROUP_NAME_LOADING,
  UPDATE_GROUP_NAME_SUCCESS,
  INVITE_USERS_TO_GROUPS_ERROR,
  INVITE_USERS_TO_GROUPS_LOADING,
  INVITE_USERS_TO_GROUPS_SUCCESS,
  REMOVE_MEMBER_FROM_GROUP_ERROR,
  REMOVE_MEMBER_FROM_GROUP_LOADING,
  REMOVE_MEMBER_FROM_GROUP_SUCCESS,
} from '../actions/actionTypes';
import { ChannelData, MembersData } from '../../Common/interfaces/channel';
import { isNotEmptyArray } from '../../utils/arrays';

const initialState = {
  groupList: [],
  filteredChannels: [],
  isFilteredByName: false,
  hasError: false,
  isLoading: false,
  group: {},
  updateGroupImageIsLoading: false,
  updateGroupImageHasError: false,
  updateGroupNameIsLoading: false,
  updateGroupNameHasError: false,
  addGroupMemberIsLoading: false,
  addGroupMemberHasError: false,
};

const removeNonChannelMessages = (listOfChannel: Array<ChannelData>) =>
  isNotEmptyArray(listOfChannel) && listOfChannel.filter(channel => channel.isGroup);

const filterGroupsByName = (name: string, list) => list.filter(friend => friend.name.toLowerCase().includes(name));

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUPS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groupList: removeNonChannelMessages(action.payload),
        isLoading: false,
      };
    case FETCH_GROUPS_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        isLoading: false,
      };
    case FILTER_GROUPS_BY_NAME:
      const filteredChannels = filterGroupsByName(action.name, [...state.groupList]);
      return {
        ...state,
        isFilteredByName: action.name ? true : false,
        filteredChannels: filteredChannels,
      };
    case GET_GROUP_INFO:
      return {
        ...state,
        group: action.group,
      };
    case UPDATE_GROUP_PHOTO_ERROR:
      return {
        ...state,
        updateGroupImageIsLoading: false,
        updateGroupImageHasError: true,
      };
    case UPDATE_GROUP_PHOTO_LOADING:
      return {
        ...state,
        updateGroupImageIsLoading: true,
      };
    case UPDATE_GROUP_PHOTO_SUCCESS:
      let groupWithUpdatedImage: ChannelData | any = { ...state.group };
      groupWithUpdatedImage.conversationImageUrl = action.conversationImageUrl;
      return {
        ...state,
        updateGroupImageIsLoading: false,
        group: groupWithUpdatedImage,
        groupList: state.groupList.map((group: ChannelData) => {
          if (group.id === action.groupId) {
            group.conversationImageUrl = action.conversationImageUrl;
          }
          return group;
        }),
      };
    case UPDATE_GROUP_OBJECT:
      return {
        ...state,
        group: action.groupObject,
        groupList: state.groupList.map((group: ChannelData) => {
          if (group.id === action.groupObject.id) {
            group = action.groupObject;
          }
          return group;
        }),
      };
    case UPDATE_GROUP_NAME_ERROR:
      return {
        ...state,
        updateGroupNameHasError: action.hasError,
      };
    case UPDATE_GROUP_NAME_LOADING:
      return {
        ...state,
        updateGroupNameIsLoading: action.isLoading,
      };
    case UPDATE_GROUP_NAME_SUCCESS:
      return {
        ...state,
        updateGroupNameIsLoading: false,
        groupList: state.groupList.map((group: ChannelData) => {
          if (group.id === action.newObj.id) {
            group.name = action.newObj.name;
          }
          return group;
        }),
      };
    case INVITE_USERS_TO_GROUPS_ERROR:
      return {
        ...state,
        addGroupMemberHasError: action.hasError,
      };
    case INVITE_USERS_TO_GROUPS_LOADING:
      return {
        ...state,
        addGroupMemberIsLoading: action.isLoading,
      };
    case INVITE_USERS_TO_GROUPS_SUCCESS:
      return {
        ...state,
        addGroupMemberIsLoading: false,
      };
    case REMOVE_MEMBER_FROM_GROUP_ERROR:
      return {
        ...state,
        removeMemberFromGroupHasError: action.hasError,
      };
    case REMOVE_MEMBER_FROM_GROUP_LOADING:
      return {
        ...state,
        removeMemberFromGroupIsLoading: action.isLoading,
      };
    case REMOVE_MEMBER_FROM_GROUP_SUCCESS:
      const newGroupObj: ChannelData | any = { ...state.group };
      const newMembers = newGroupObj.members.map((member: MembersData) => {
        if (member.id === action.memberId) {
          member.isRemoved = true;
        }
        return member;
      });
      newGroupObj.members = newMembers;
      return {
        ...state,
        removeMemberFromGroupIsLoading: false,
        group: newGroupObj,
      };
    case CLEAR_CHANNEL_REDUCER:
      return initialState;
    default:
      return state;
  }
};
