import styled from 'styled-components/macro';
import { fadeIn } from '../Animation';

//NOTE : All of my attributes here is currently on static because it's still in the early stage of development and still deciding if
//the designer will provide svg

interface ButtonProps {
  border?: string;
  height?: string;
  width?: string;
  fontSize?: string;
  borderRadius?: string;
  color?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
}

const determineFontSize = (size: string): number | string => {
  switch (size) {
    case 'xs':
      return 10;
    case 'sm':
      return 13;
    case 'md':
      return 15;
    case 'lg':
      return 20;
    case 'xl':
      return 25;
    case 'xxl':
      return 35;
    case 'xxxl':
      return 45;
    case 'hidden':
      return 0;
    default:
      return size;
  }
};

const Button = styled.button`
  height: ${(props: ButtonProps) => props && props.height};
  width: ${(props: ButtonProps) => props && props.width};
  background-color: ${(props: ButtonProps) => (props && props.backgroundColor ? props.backgroundColor : 'white')};
  color: ${(props: ButtonProps) => props && props.color};
  padding: ${(props: ButtonProps) => props && props.padding}px;
  border-radius: ${(props: ButtonProps) => props && props.borderRadius};
  border: ${(props: ButtonProps) => (props && props.border ? props.border : '1px solid #cacaca')};
  font-size: ${(props: ButtonProps) => props && props.fontSize && determineFontSize(props.fontSize)}px;
  font-weight: bolder;
  outline: none;
  margin: ${(props: ButtonProps) => props && props.margin};
  padding: ${(props: ButtonProps) => props && props.padding};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  :disabled {
    opacity: 0.5;
  }
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(
    0deg,
    ${props => props.theme.backgroundColor.primary.topColor},
    ${props => props.theme.backgroundColor.primary.bottomColor}
  );
  border-style: none;
  color: white;
  animation: ${fadeIn} 500ms;
  transition: 300ms;
  :disabled {
    background: linear-gradient(0deg, ${props => props.theme.backgroundColor.secondary}, ${props => props.theme.backgroundColor.secondary});
  }
`;

const OutlineButton = styled(Button)`
  background-color: transparent;
  border-color: white;
  color: white;
  animation: ${fadeIn} 500ms;
`;

const SolidColorButton = styled(Button)`
  background-color: ${(props: ButtonProps) => props && props.backgroundColor};
  border-color: ${(props: ButtonProps) => props && props.backgroundColor};
  color: ${(props: ButtonProps) => props && props.color};
  animation: ${fadeIn} 500ms;
`;

const SecondaryButton = styled(Button)`
  background-color: ${props => props.theme.backgroundColor.secondary};
  border-color: ${props => props.theme.backgroundColor.secondary};
  color: ${props => props.theme.font.fontColor.primary};
  animation: ${fadeIn} 500ms;
`;

const LightButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  color: #414042;
  animation: ${fadeIn} 500ms;
  transition: 300ms;
  :hover {
    background-color: #cacaca;
    color: white;
  }
`;

const LogoutButton = styled.button`
  font-size: 15px;
  font-weight: bolder;
  color: #41d5f1;
  position: relative;
  border: 1px solid #3cc9f8;
  border-radius: 25px;
  background: white;
  padding: 5px;
  width: 40%;
  margin-left: 3px;
  margin-right: 3px;
  outline: none;
  transition: 300ms;
  &:hover {
    cursor: pointer;
    background: linear-gradient(#50fcc7, #3cc9f8);
    border: 1px none transparent;
    color: white;
  }
`;

const ConversationInviteButton = styled(PrimaryButton)`
  height: 30px;
  width: 110px;
  background-color: #3dd2f6;
  background: #3dd2f6;
  border-radius :hover {
    cursor: pointer;
  }
`;

//I used px on the border radius because by using % it's shape becomes oval
//Same as the border radius and border width due to it's not accepting % unit
const UtilityButton = styled.button`
  width: 240px;
  height: 65px;
  background-color: transparent;
  color: white;
  border-color: #6afff6;
  border-style: solid;
  border-width: 3px;
  border-radius: 33.3px;
  outline: none;
  font-size: 25px;
  transition-duration: 300ms;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
  }
`;

const GroupDetailsCloseButton = styled.button`
  border-radius: 20px;
  font-size: 25px;
  width: 100%;
  height: 100%;
  color: #41d5f1;
  font-weight: bold;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #22ebba, #18a9f0);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  :hover {
    cursor: pointer;
  }
`;

export const ButtonStyled = {
  Button,
  SolidColorButton,
  PrimaryButton,
  SecondaryButton,
  LightButton,
  OutlineButton,
  UtilityButton,
  LogoutButton,
  ConversationInviteButton,
  GroupDetailsCloseButton,
};
