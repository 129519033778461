import axios from 'axios';
import { SEND_MESSAGE_ERROR, SEND_MESSAGE_LOADING, SEND_MESSAGE_SUCCESS } from '../actionTypes';

export function sendMessageHasError(bool: boolean) {
  return {
    type: SEND_MESSAGE_ERROR,
    hasError: bool,
  };
}

export function sendMessageIsLoading(bool: boolean) {
  return {
    type: SEND_MESSAGE_LOADING,
    isLoading: bool,
  };
}

export function sendMessageIsSuccess(message) {
  return {
    type: SEND_MESSAGE_SUCCESS,
    message: message,
  };
}

export function sendMessage(requestBody) {
  return async dispatch => {
    dispatch(sendMessageIsLoading(true));
    return await axios.post(process.env.REACT_APP_API_URL + `/messages`, requestBody).then(
      response => {
        dispatch(sendMessageIsSuccess(response.data.messages));
      },
      () => dispatch(sendMessageHasError(true))
    );
  };
}
