import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { ButtonStyled } from '../../../CommonComponents/Button/Button.style';
import { ImageStyled } from '../../../CommonComponents/Image/Image.style';
import { InputStyled } from '../../../CommonComponents/Input/Input.style';
import { UserProfileStyled } from './styled';
import Modal from '../../../CommonComponents/Modal';
import Flex from '../../../CommonComponents/Flex';
import Icon from '../../../CommonComponents/Icon';
import { DecorationStyled } from '../../../CommonComponents/Decoration/Decoration.style';
import { UserData } from '../../../Common/interfaces/user';
import { copyToClipBoard } from '../../../utils/strings';
import { timeout } from '../../../utils/timings';
import { updateUserInfo } from '../../../Auth/actions/updateUserInfo';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';
import { ChannelData } from '../../../Common/interfaces/channel';
import { isNotEmptyArray } from '../../../utils/arrays';
import { updateConversationInList } from '../../../ChatList/actions';
import { bytesToMegaBytes } from '../../../utils/files';
import Swal from 'sweetalert2';
import useUploadProfile from '../../../Common/customHooks/useUploadProfile';
import useReactGa from '../../../Common/customHooks/useReactGa';

const { Span } = TypographyStyled;
const { Button, PrimaryButton, SecondaryButton } = ButtonStyled;
const { UserPhoto, UserAvatar, UserAvatarWrapper } = ImageStyled;
const { Container, CameraIconWrapper, Scrollable } = UserProfileStyled;
const { InputTextWithBorder } = InputStyled;
const { IconWrapper, LoadingBar } = DecorationStyled;

export interface UserObjSchema {
  displayName: string;
  idNumber: number;
  emailAddress: string;
  password: string;
}

const UserProfile: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { sendEGAEvent } = useReactGa();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const chatList: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);
  const dispatch = useDispatch();
  const { uploadUserProfile } = useUploadProfile();
  const [userObj, setUserObj] = useState<UserData>(useSelector(state => state.authReducer.loggedInUser));
  const [tempProfile, setTempProfile] = useState<any>(null);
  const [selectedFiles, setSelectedFiles] = useState<any>(null);
  const [displayPopUp, setDisplayPopUp] = useState(false);
  const [isCopyTextVisible, toggleCopyTextVisible] = useState(false);
  const [isMouseOverProfilePhoto, setMouseOverProfilePhoto] = useState(false);
  const [isMouseOverSettings, setMouseOverSettings] = useState(false);
  const [isUpdatingProfile, toggleUpdatingProfile] = useState(false);
  const fileUploadRef: any = useRef();

  useEffect(() => {
    if (loggedInUser && loggedInUser.id) {
      setUserObj(loggedInUser);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (!displayPopUp) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayPopUp]);

  const handleCloseModal = () => {
    setTempProfile(null);
    setSelectedFiles(null);
    setDisplayPopUp(false);
    resetForm();
  };

  const preSubmit = async (value: UserData) => {
    toggleUpdatingProfile(true);
    setUserObj(value);
    await dispatch(updateUserInfo(value)).then(async () => {
      if (isNotEmptyArray(chatList)) {
        const personalSpace = chatList.filter(chat => chat.id === value.id)[0];
        const c_personalSpace = { ...personalSpace };
        c_personalSpace.name = value.displayName;
        if (selectedFiles) {
          toggleUpdatingProfile(true);
          await uploadUserProfile(selectedFiles);
          if (process.env.REACT_APP_CLIENT_ENV === 'production') {
            sendEGAEvent('User Action', 'Uploaded a user profile', `id: ${loggedInUser.id}`);
          }
        }
        toggleUpdatingProfile(false);
        dispatch(updateConversationInList(c_personalSpace));
      }
      setSelectedFiles(null);
      setDisplayPopUp(false);
      resetForm();
    });
  };

  const handleCopyUserId = async () => {
    copyToClipBoard(values.id.toString());
    toggleCopyTextVisible(true);
    await timeout(2000);
    toggleCopyTextVisible(false);
  };

  const handleUploadImage = async e => {
    if (isNotEmptyArray(Array.from(e.target.files))) {
      const file = e.target.files[0];
      const fileSize = bytesToMegaBytes(file.size);
      const fileType = file.type;
      if (fileSize <= 25) {
        if (fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'image/jpeg') {
          const imageObjectURL = URL.createObjectURL(e.target.files[0]);
          setTempProfile(imageObjectURL);
          setSelectedFiles(e.target.files[0]);
        } else {
          Swal.fire({
            icon: 'error',
            iconHtml: '!',
            text: t('InvalidFileType'),
            confirmButtonText: t('Ok'),
            cancelButtonText: t('Cancel'),
            showCancelButton: true,
            showConfirmButton: false,
            width: '635px',
            customClass: {
              popup: 'file-too-large-popup-class',
              content: 'forgot-password-content-class',
              cancelButton: 'file-too-large-cancel-button-class',
            },
          });
          setTempProfile(null);
          setSelectedFiles(null);
        }
      } else {
        Swal.fire({
          icon: 'error',
          iconHtml: '!',
          text: t('TheFileYouAreTryingToUploadExceedsThe25MbUploadLimit'),
          confirmButtonText: t('Ok'),
          cancelButtonText: t('Cancel'),
          showCancelButton: true,
          showConfirmButton: false,
          width: '635px',
          customClass: {
            popup: 'file-too-large-popup-class',
            content: 'forgot-password-content-class',
            cancelButton: 'file-too-large-cancel-button-class',
          },
        });
        setTempProfile(null);
        setSelectedFiles(null);
      }
    }
  };

  const formik = useFormik({
    initialValues: userObj,
    onSubmit: values => preSubmit(values),
    enableReinitialize: true,
  });

  const { values, handleSubmit, handleChange, touched, errors, dirty, resetForm } = formik;

  return (
    <Flex>
      <Modal visible={displayPopUp} setModalVisibility={handleCloseModal} dimmed={true} />
      <UserAvatarWrapper isActive={displayPopUp} margin="0px 20px 0px 30px" onClick={() => setDisplayPopUp(displayPopUp => !displayPopUp)}>
        <UserAvatar src={tempProfile ? tempProfile : userObj.profileImageUrl} height="50px" width="50px" withHoverEffect opacity={0.5} />
      </UserAvatarWrapper>
      {displayPopUp && (
        <Container
          onClick={e => e.stopPropagation()}
          onMouseEnter={() => setMouseOverProfilePhoto(true)}
          onMouseLeave={() => setMouseOverProfilePhoto(false)}
        >
          <Flex container width="100%">
            <UserPhoto
              src={tempProfile ? tempProfile : userObj.profileImageUrl}
              height="300"
              alt={`${loggedInUser.displayName} profile picture`}
            />
            {isUpdatingProfile && (
              <Flex container position="absolute" width="100%" justifyContent="center" margin="45px 0px 0px 0px">
                <LoadingBar width="60%" />
              </Flex>
            )}
            <input type="file" ref={fileUploadRef} style={{ display: 'none' }} onChange={handleUploadImage} accept="image/*" />
            {isMouseOverProfilePhoto && (
              <CameraIconWrapper onClick={() => !isUpdatingProfile && fileUploadRef.current.click()}>
                {!isUpdatingProfile && <Icon name="camera" width="50" />}
              </CameraIconWrapper>
            )}
          </Flex>
          <Scrollable>
            <IconWrapper
              position="absolute"
              right="0"
              margin="15px 30px"
              onMouseEnter={() => setMouseOverSettings(true)}
              onMouseLeave={() => setMouseOverSettings(false)}
            >
              <Link to="/accountSettings" target="_blank">
                <Icon name="settings" width="45px" style={{ opacity: isMouseOverSettings ? '0.5' : '1', transition: '300ms' }} />
              </Link>
            </IconWrapper>
            <Flex container flexDirection="column" height="100%" padding="45px 30px 0px 30px" justifyContent="space-around">
              <Flex>
                <Span fontColor="#414042" size="lg">
                  {t('DisplayName')}
                </Span>
                {touched.displayName && errors.displayName && (
                  <Span fontColor="#c72e44" size="lg">
                    &nbsp;{errors.displayName}
                  </Span>
                )}
                <InputTextWithBorder
                  width="100%"
                  backgroundColor="white"
                  fontSize="20px"
                  autoComplete="off"
                  placeholder={t('DisplayName')}
                  borderRadius="10px"
                  padding="10px"
                  color="#444444"
                  onChange={handleChange}
                  value={values.displayName}
                  name="displayName"
                />
              </Flex>
              <Flex>
                <Span fontColor="#414042" size="lg">
                  {t('CompanyPositionetc')}
                </Span>
                {touched.companyName && errors.companyName && (
                  <Span fontColor="#c72e44" size="lg">
                    &nbsp;{errors.companyName}
                  </Span>
                )}
                <InputTextWithBorder
                  width="100%"
                  backgroundColor="white"
                  fontSize="20px"
                  autoComplete="off"
                  placeholder={t('CompanyPositionetc')}
                  borderRadius="10px"
                  padding="10px"
                  color="#444444"
                  onChange={handleChange}
                  value={values.companyName}
                  name="companyName"
                />
              </Flex>
              <Flex>
                <Span fontColor="#444444" size="lg" lineHeight="0px">
                  ID Number
                </Span>
                {touched.id && errors.id && (
                  <Span fontColor="#c72e44" size="md">
                    &nbsp;{errors.id}
                  </Span>
                )}
                {isCopyTextVisible && (
                  <Span size="md" fontColor="444444" fadeIn style={{ position: 'absolute', right: '5%' }}>
                    {t('Copied')}
                  </Span>
                )}
                <Flex container alignItems="center" justifyContent="space-between">
                  <InputTextWithBorder
                    width="100%"
                    backgroundColor="whitesmoke"
                    fontSize="20px"
                    placeholder="ID Number"
                    borderRadius="10px"
                    padding="10px"
                    color="#444444"
                    disabled={true}
                    onChange={handleChange}
                    value={values.id}
                    name="id"
                  />
                  <Flex container alignItems="center">
                    <IconContainer
                      transform="translate(-35px, 0px)"
                      position="absolute"
                      onClick={handleCopyUserId}
                      iconName="clipboard"
                      iconWidth="25px"
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <Span fontColor="#444444" size="lg" lineHeight="0px">
                  {t('EmailAddress')}
                </Span>
                {touched.email && errors.email && (
                  <Span fontColor="#c72e44" size="md">
                    &nbsp;{errors.email}
                  </Span>
                )}
                <InputTextWithBorder
                  width="100%"
                  backgroundColor="whitesmoke"
                  fontSize="20px"
                  autoComplete="off"
                  disabled={true}
                  placeholder={t('EmailAddress')}
                  borderRadius="10px"
                  padding="10px"
                  color="#444444"
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                />
              </Flex>
              <Flex container justifyContent="space-between" padding="10px 0px">
                {dirty || selectedFiles ? (
                  <PrimaryButton
                    width="48%"
                    fontSize="lg"
                    borderRadius="10px"
                    onClick={() => handleSubmit()}
                    type="submit"
                    disabled={isUpdatingProfile}
                  >
                    {t('Save')}
                  </PrimaryButton>
                ) : (
                  <SecondaryButton width="48%" fontSize="lg" borderRadius="10px" type="button">
                    {t('Save')}
                  </SecondaryButton>
                )}
                <Button width="48%" fontSize="lg" borderRadius="10px" onClick={handleCloseModal} type="button" color="#444444">
                  {t('Cancel')}
                </Button>
              </Flex>
            </Flex>
          </Scrollable>
        </Container>
      )}
    </Flex>
  );
};

export default UserProfile;
