import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import Flex from '../../CommonComponents/Flex';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import { AccountSettingsStyled } from '../styled';
import EmailNotificationsForm from './EmailNotificationsForm';

const { HeaderMessage } = AccountSettingsStyled;
const { Span } = TypographyStyled;

export default function EmailNotifications() {
  const { t } = useTranslation();
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [headerState] = useState({
    state: 'warning',
    message: t('EmailNotificationIsTurnONByDefaultSoYouCanBeUpdatedOnEveryNewMessages'),
  });

  const handleSubmit = () => {
    setEmailNotifications(toggle => !toggle);
  };

  const determineHeaderIconColor = () => {
    if (headerState.state === 'warning') {
      return '#FBCB0A';
    } else {
      return '';
    }
  };

  return (
    <Flex container flexDirection="column" alignItems="center">
      {headerState.message && (
        <HeaderMessage state={headerState.state}>
          <IconContainer iconName="error-circle" iconWidth="45px" iconFill={determineHeaderIconColor()} />
          <Span size="lg" fontColor="#444444">
            {headerState.message}
          </Span>
          <Flex></Flex>
        </HeaderMessage>
      )}
      <EmailNotificationsForm onSubmit={handleSubmit} emailNotifications={emailNotifications} />
    </Flex>
  );
}
