export const GET_USER_NOTIFICATION_CONFIG_ERROR = 'GET_USER_NOTIFICATION_CONFIG_ERROR';
export const GET_USER_NOTIFICATION_CONFIG_LOADING = 'GET_USER_NOTIFICATION_CONFIG_LOADING';
export const GET_USER_NOTIFICATION_CONFIG_SUCCESS = 'GET_USER_NOTIFICATION_CONFIG_SUCCESS';

export const UPDATE_USER_NOTIFICATION_CONFIG_ERROR = 'UPDATE_USER_NOTIFICATION_CONFIG_ERROR';
export const UPDATE_USER_NOTIFICATION_CONFIG_LOADING = 'UPDATE_USER_NOTIFICATION_CONFIG_LOADING';
export const UPDATE_USER_NOTIFICATION_CONFIG_SUCCESS = 'UPDATE_USER_NOTIFICATION_CONFIG_SUCCESS';

export const UPDATE_USER_NOTIFICATION_SOUND_ERROR = 'UPDATE_USER_NOTIFICATION_SOUND_ERROR';
export const UPDATE_USER_NOTIFICATION_SOUND_LOADING = 'UPDATE_USER_NOTIFICATION_SOUND_LOADING';
export const UPDATE_USER_NOTIFICATION_SOUND_SUCCESS = 'UPDATE_USER_NOTIFICATION_SOUND_SUCCESS';

export const UPDATE_NOTIFICATION_SOUND = 'UPDATE_NOTIFICATION_SOUND';
export const TOGGLE_NOTIFICATION_MODAL = 'TOGGLE_NOTIFICATION_MODAL';
export const NOTIFICATION_INIT = 'NOTIFICATION_INIT';
