import { FriendData } from '../../Common/interfaces/friend';
import { MembersData } from '../../Common/interfaces/channel';
import { UserData } from '../../Common/interfaces/user';
import { UserConfigData } from '../../Common/interfaces/notification';

export const isArray = arr => {
  return Array.isArray(arr);
};

export const isNotEmptyArray = arr => {
  return isArray(arr) && arr.length > 0;
};

export const addUserToMembers = (list: Array<MembersData>, user) => {
  if (isNotEmptyArray(list)) {
    const idIndex = list.findIndex(member => member.id === user.id);
    if (idIndex < 0) {
      list.push(user);
    }
  } else {
    list.push(user);
  }
  return list;
};

export const isUserIncludedInTheFriendList = (list: Array<FriendData>, id: string): boolean => {
  if (isNotEmptyArray(list)) {
    const index = list.findIndex(user => user.friendId === id);
    return index < 0 ? false : true;
  } else {
    return false;
  }
};

export const handleEmoji = (listOfEmoji, emoji) => {
  if (isNotEmptyArray(listOfEmoji)) {
    const copySelectedEmojis = [...listOfEmoji];
    const index = copySelectedEmojis.findIndex(emoj => emoj.id === emoji.id);
    if (index >= 0) {
      copySelectedEmojis[index].count += 1;
      listOfEmoji = copySelectedEmojis;
    } else {
      emoji.count = 1;
      listOfEmoji.push(emoji);
    }
  } else {
    emoji.count = 1;
    listOfEmoji.push(emoji);
  }
  return listOfEmoji;
};

export const getDMIdNameAndProfileImage = (memberList: Array<MembersData>, loggedInUser: UserData) => {
  let name = '';
  let id = '';
  let profileImage = '';
  let isLoggedInUser = false;
  const user = memberList.filter(member => member.id !== loggedInUser.id);
  if (isNotEmptyArray(user)) {
    id = user[0].id;
    name = user[0].displayName!;
    profileImage = user[0].profileImageUrl;
  } else {
    name = loggedInUser.displayName;
    id = loggedInUser.id;
    isLoggedInUser = true;
    profileImage = loggedInUser.profileImageUrl;
  }
  return { name, id, isLoggedInUser, profileImage };
};

export const determineSoundSrc = (userConfig: UserConfigData) => {
  if (userConfig && userConfig.soundNotifications) {
    if (userConfig.soundNotifications.isSoundOne) {
      return {
        label: 'Sound 1',
        value: {
          path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone1.mp3',
          backendName: 'isSoundOne',
        },
      };
    } else if (userConfig.soundNotifications.isSoundTwo) {
      return {
        label: 'Sound 2',
        value: {
          path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone2.mp3',
          backendName: 'isSoundTwo',
        },
      };
    } else if (userConfig.soundNotifications.isSoundThree) {
      return {
        label: 'Sound 3',
        value: {
          path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone3.mp3',
          backendName: 'isSoundThree',
        },
      };
    } else if (userConfig.soundNotifications.isSoundFour) {
      return {
        label: 'Sound 4',
        value: {
          path: process.env.PUBLIC_URL + '/assets/sounds/notifications/tone4.mp3',
          backendName: 'isSoundFour',
        },
      };
    } else {
      return {
        label: 'None',
        value: {
          path: process.env.PUBLIC_URL + '/assets/sounds/notifications/none.mp3',
          backendName: 'isSoundNone',
        },
      };
    }
  }
};

export const getMemberFriendDataFromFriendList = (friendList: Array<FriendData>, member: FriendData) => {
  const friend = friendList.filter(f => f.friendId === member.friendId);
  if (isNotEmptyArray(friend)) return friend[0];
};

export const generatePics = numberOfImages => {
  const images: any = [];

  for (let i = 0; i < numberOfImages; i++) {
    images.push({
      name: 'image' + i,
      url: 'https://picsum.photos/200/300',
    });
  }

  return images;
};

export const removeElementInsideArray = (array: Array<any>, condition: any) => array.filter(element => element !== condition);
