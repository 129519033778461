import React, { FC, useEffect, useState } from 'react';
import Flex from '../../../../CommonComponents/Flex';
import { Emoji } from 'emoji-mart';
import { TypographyStyled } from '../../../../CommonComponents/Typography/Typography.style';
import { ReactionStyled } from './styled';
import { isNotEmptyArray } from '../../../../utils/arrays';
import { ReactionsData } from '../../../../Common/interfaces/message';
import { MembersData } from '../../../../Common/interfaces/channel';

const { Span } = TypographyStyled;
const { ReactionsContainer } = ReactionStyled;

interface Props {
  reactions: Array<ReactionsData>;
  members: Array<MembersData>;
}

const Reactions: FC<Props> = ({ reactions, members }) => {
  const [showUsers, setShowUsers] = useState(false);
  const [hoveredReaction, setHoveredReaction] = useState<ReactionsData | any>(undefined);

  useEffect(() => {
    let elements: any = document.querySelectorAll('.emoji-mart-emoji');
    if (elements.length > 0) {
      elements.forEach(element => (element.style.position = 'inherit'));
    }
  }, [reactions]);

  const getUserDisplayName = (id: string) => {
    const index = members.findIndex(member => member.id === id);
    return members[index].displayName;
  };

  const handleReactionClick = (reaction: ReactionsData) => {
    setShowUsers(toggle => !toggle);
    setHoveredReaction(reaction);
  };

  return (
    <Flex container alignItems="center">
      {isNotEmptyArray(reactions) &&
        reactions.map(reaction => {
          return (
            <ReactionsContainer showUsers={showUsers} key={reaction.name}>
              <Flex tabIndex={0} handleOnClick={() => handleReactionClick(reaction)}>
                <Flex container alignItems="center" tabIndex={0}>
                  <Emoji emoji={reaction.name} size={20} />
                  {reaction.count > 1 && (
                    <Span fontColor="#444444" size="sm" margin="0px 0px 0px 5px" fontWeight="bold">
                      {reaction.count}
                    </Span>
                  )}
                </Flex>
                {showUsers &&
                  hoveredReaction &&
                  hoveredReaction.name === reaction.name &&
                  hoveredReaction.users.map(user => {
                    return (
                      <Flex borderRadius="25px">
                        <Span fontColor="#444444" size="sm" padding="5px">
                          {getUserDisplayName(user)}
                        </Span>
                      </Flex>
                    );
                  })}
              </Flex>
            </ReactionsContainer>
          );
        })}
    </Flex>
  );
};

export default Reactions;
