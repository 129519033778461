import axios from 'axios';
import { CREATE_CONVERSATION_ERROR, CREATE_CONVERSATION_LOADING, CREATE_CONVERSATION_SUCCESS } from '../actionTypes';

export function createConversationError(bool: boolean) {
  return {
    type: CREATE_CONVERSATION_ERROR,
    hasError: bool,
  };
}

export function createConversationLoading(bool: boolean) {
  return {
    type: CREATE_CONVERSATION_LOADING,
    isLoading: bool,
  };
}

export function createConversationSuccess(createdConversation) {
  return {
    type: CREATE_CONVERSATION_SUCCESS,
    createdConversation: createdConversation,
  };
}

export function createConversation(requestBody) {
  return async dispatch => {
    dispatch(createConversationLoading(true));
    return axios.post(process.env.REACT_APP_API_URL + '/conversations', requestBody).then(
      response => {
        dispatch(createConversationSuccess(response.data.conversations));
      },
      err => {
        dispatch(createConversationError(true));
      }
    );
  };
}
