import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function Doc({ style = {}, width = '100%', className = '', viewBox = '0 0 159.9 200' }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#1565C0"
        d="M15.5,0.1c31,0,61.9,0,92.9,0c0.3,0.9,1.1,1.5,1.6,2.1c16.3,18.9,32.6,37.9,48.9,56.8c0.7,0.8,1.1,1.5,1.1,2.6
	c0,39.1,0,78.3,0,117.4c0,11.9-9.2,21-21.1,21c-39.8,0.1-79.6,0.1-119.4,0.1c-4.1,0-7.7-1.3-10.9-3.9c-4-3.3-6.4-7.5-7.7-12.5
	c-1.2-4.3-0.7-8.7-0.9-13.1c4.4,0,8.8,0,13.1,0c0,0.9,0,1.7,0,2.5c0.2,7.7,5.1,12.5,12.8,12.5c13.4,0,26.8,0,40.1,0
	c22.8,0,45.5,0,68.3,0c7.3,0,12.6-4.9,12.7-11.7c0.4-33.2,0.8-66.5,1.3-99.7c0-1.4-0.3-1.8-1.7-1.8c-7.7,0.1-15.5,0-23.2,0
	c-14.2,0-24.3-10.2-24.4-24.5c0-10.8,0-21.6,0-32.5c0-1.5-0.4-1.8-1.8-1.8c-8,0.1-16,0-24,0c-16,0-32,0-48,0
	c-6.3,0-11.6,4.5-11.9,10.3c-0.4,5.5-0.4,141.1-0.2,146.7c-4.4,0.2-8.7,0.3-13.1,0c0-7.1,0.1-144.2,0.3-151.3
	c0.2-7,3.5-12.4,9.2-16.5C11.2,1.8,13.4,1.1,15.5,0.1z"
      />
      <path fill="none" stroke="#FF0000" strokeMiterlimit="10" d="M80,100.1" />
      <text transform="matrix(1 0 0 1 23.9006 128.7313)" display="none" fill="#EF6C00" enableBackground="new" fontSize="60px">
        P
      </text>
      <path
        fill="#1565C0"
        d="M92.5,125.1c-1.4,0-2.6-0.9-3-2.3L80,89.6l-9.5,33.2c-0.7,1.7-2.6,2.4-4.2,1.8c-0.8-0.3-1.4-1-1.8-1.8L52,79.2
	c-0.5-1.6,0.4-3.4,2-3.9c1.6-0.5,3.4,0.4,3.9,2c0,0.1,0,0.1,0.1,0.2l9.5,33.2L77,77.5c0.7-1.7,2.6-2.4,4.2-1.8
	c0.8,0.3,1.4,1,1.8,1.8l9.5,33.2l9.5-33.2c0.4-1.6,2.1-2.6,3.8-2.2c0,0,0,0,0.1,0c1.7,0.5,2.6,2.2,2.1,3.9l-12.5,43.7
	C95.1,124.2,93.9,125.1,92.5,125.1z"
      />
    </svg>
  );
}
