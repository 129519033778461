import axios from 'axios';
import { SET_ACCOUNT_SETTINGS_THIRD_PAGE_VIEW } from './actionTypes';
import { USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW } from '../../Common/enums';

export const setAccountSettingsThirdPanelView = (view: USER_ACCOUNT_SETTINGS_THIRD_PANEL_VIEW) => {
  return {
    type: SET_ACCOUNT_SETTINGS_THIRD_PAGE_VIEW,
    view,
  };
};

export const changeUserPassword = async requestBody => {
  return await axios.post(process.env.REACT_APP_API_URL + '/changePassword', requestBody);
};

export const setAccountSettingsLanguage = async (requestBody, userId) => {
  return await axios.put(process.env.REACT_APP_API_URL + `/users/settings/language/${userId}`, requestBody);
};
