import React, { ReactElement } from 'react';
import { IconProps } from './index';

export default function BulletDash({
  fill = '#30ccfe',
  style = {},
  width = '100%',
  className = '',
  viewBox = '0 0 40 25',
}: IconProps): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} className={className} fill={fill} style={style} height={width} viewBox={viewBox}>
      <rect width="40" height="9" rx="4.5" fill={fill} />
    </svg>
  );
}
