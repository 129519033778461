import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { TypographyStyled } from '../CommonComponents/Typography/Typography.style';
import { NotificationStyled } from './style';
import { useTranslation } from 'react-i18next';
import { updateNotificationSound, notificationInit } from './actions';
import Modal from '../CommonComponents/Modal';
import Icon from '../CommonComponents/Icon';
import Flex from '../CommonComponents/Flex';
import { NavigationStyled } from '../Navigation/styled';
import { NotificationData, UserConfigData } from '../Common/interfaces/notification';
import { getUserNotificationConfig } from './actions/getUserNotificationConfig';
import { UserData } from '../Common/interfaces/user';
import { updateUserNotificationConfig } from './actions/updateUserNotificationConfig';
import { updateUserNotificationSound } from './actions/updateNotificationSound';
import { determineSoundSrc } from '../utils/arrays';
import { handleTokenRequest } from '../firebase/pushNotification';
import { toggleDimmedModal } from '../Navigation/actions';
import IconContainer from '../CommonComponents/Decoration/IconContainer';
import Swal from 'sweetalert2';

const { NavigationIconWrapper } = NavigationStyled;
const { Container, NotificationSettingText, ToggleButton, dropDownCustomStyle, SoundButton } = NotificationStyled;
const { Span } = TypographyStyled;

const NotificationSettings: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationState = useSelector(state => state.notificationReducer);
  const {
    sounds,
    updateUserNotificationConfigIsLoading,
    getUserNotificationConfigIsLoading,
    userNotificationConfig,
    selectedSound,
  }: {
    sounds: Array<NotificationData>;
    getUserNotificationConfigIsLoading: boolean;
    updateUserNotificationConfigIsLoading: boolean;
    userNotificationConfig: UserConfigData;
    selectedSound: NotificationData;
  } = notificationState;
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const [pushNotification, setPushNotification] = useState(userNotificationConfig.pushNotification);
  const [messagePreview, setMessagePreview] = useState(userNotificationConfig.messagePreview);
  const [notifVisible, toggleNotif] = useState(false);
  const [activeSound, setActiveSound] = useState<any>(selectedSound);
  const notificationAudioRef = useRef<any>();

  useEffect(() => {
    const isBrowserNotificationGranted = Notification.permission === 'granted';
    dispatch(notificationInit());
    dispatch(getUserNotificationConfig(loggedInUser.id, isBrowserNotificationGranted));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveSound(selectedSound);
  }, [selectedSound]);

  useEffect(() => {
    const newSoundObj = determineSoundSrc(userNotificationConfig);
    dispatch(updateNotificationSound(newSoundObj));
    setPushNotification(userNotificationConfig.pushNotification);
    setMessagePreview(userNotificationConfig.messagePreview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNotificationConfig]);

  const handleNotification = async () => {
    const notificationPermission = Notification.permission;
    if (notificationPermission === 'granted') {
      const deviceToken = await handleTokenRequest();
      const hasPushNotification = userNotificationConfig.pushNotification ? false : true;

      const requestBody = {
        pushNotification: hasPushNotification,
        deviceToken: deviceToken,
      };
      dispatch(updateUserNotificationConfig(requestBody, loggedInUser.id));
      setPushNotification(isOn => !isOn);
    } else if (notificationPermission === 'default') {
      Notification.requestPermission()
        .then(async res => {
          if (res === 'granted') {
            const deviceToken = await handleTokenRequest();
            const hasPushNotification = userNotificationConfig.pushNotification ? false : true;
            const requestBody = {
              pushNotification: hasPushNotification,
              deviceToken: deviceToken,
            };
            dispatch(updateUserNotificationConfig(requestBody, loggedInUser.id));
            setPushNotification(isOn => !isOn);
          }
        })
        .catch(err => console.error(err));
    } else {
      Swal.fire({
        icon: 'warning',
        iconHtml: '!',
        text: t('PleaseAllowYourBrowserToReceivedNotificationFromUs'),
        imageUrl:
          'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fnotification%20pop%20up.png?alt=media&token=5ca8c0d9-8ffe-4086-b5b1-0c55d1fc828d',
        imageAlt: 'Warning',
        confirmButtonText: t('Ok'),
        showCancelButton: false,
        showConfirmButton: true,
        width: '635px',
        customClass: {
          popup: 'file-too-large-popup-class',
          content: 'forgot-password-content-class',
          confirmButton: 'file-too-large-cancel-button-class',
        },
      });
    }
  };

  const handleNotificationPreview = () => {
    const requestBody = {
      messagePreview: userNotificationConfig.messagePreview ? false : true,
    };
    setMessagePreview(isOn => !isOn);
    dispatch(updateUserNotificationConfig(requestBody, loggedInUser.id));
  };

  const handleUpdateNotification = sound => {
    setActiveSound(sound);
    const requestBody = {
      selectedSound: sound.value.backendName,
    };
    dispatch(updateUserNotificationSound(requestBody, loggedInUser.id));
  };

  const handlePlayNote = () => {
    if (notificationAudioRef && notificationAudioRef.current) {
      notificationAudioRef.current.play();
    }
  };

  const handleOpenNotificationModal = () => {
    toggleNotif(true);
    dispatch(toggleDimmedModal(true));
  };

  const handleCloseNotificationModal = () => {
    toggleNotif(false);
    dispatch(toggleDimmedModal(false));
  };

  return (
    <>
      <Modal visible={notifVisible} dimmed={true} setModalVisibility={handleCloseNotificationModal} />
      <NavigationIconWrapper view={notifVisible} onClick={handleOpenNotificationModal}>
        <Icon
          name="notification"
          width="40px"
          fill={notifVisible ? 'white' : '#46D8D8'}
          style={{
            borderRadius: '50%',
            padding: '10px',
            backgroundColor: notifVisible ? '#21A3A5' : 'white',
            transform: 'translate(0px, 1px)',
          }}
        />
      </NavigationIconWrapper>
      {notifVisible && (
        <Container onClick={e => e.stopPropagation()}>
          <Flex margin={'0px 0px 35px 0px'}>
            <Span fontColor="#444444" size="xxl">
              {t('Notifications')}
            </Span>
          </Flex>
          <Flex container width="100%" height="50px" alignItems="center" margin="0px 0px 20px 0px">
            <NotificationSettingText>{t('Notifications')}</NotificationSettingText>
            <ToggleButton
              isActive={pushNotification}
              onClick={handleNotification}
              disabled={updateUserNotificationConfigIsLoading || getUserNotificationConfigIsLoading}
            >
              {t(pushNotification ? 'On' : 'Off')}
            </ToggleButton>
          </Flex>
          <Flex container width="100%" height="50px" alignItems="center" margin="0px 0px 20px 0px">
            <NotificationSettingText>{t('MessagePreview')}</NotificationSettingText>
            <ToggleButton
              isActive={messagePreview}
              onClick={handleNotificationPreview}
              disabled={updateUserNotificationConfigIsLoading || getUserNotificationConfigIsLoading}
            >
              {t(messagePreview ? 'On' : 'Off')}
            </ToggleButton>
          </Flex>
          <Flex container width="100%" height="50px" alignItems="center" margin="0px 0px 20px 0px">
            <audio ref={notificationAudioRef} src={activeSound.value.path} />
            <Select
              options={sounds}
              onChange={handleUpdateNotification}
              disabled={updateUserNotificationConfigIsLoading || getUserNotificationConfigIsLoading}
              value={activeSound}
              styles={dropDownCustomStyle}
            />
            <SoundButton onClick={handlePlayNote}>
              <IconContainer iconName="sound" iconWidth="30px" />
            </SoundButton>
          </Flex>
        </Container>
      )}
    </>
  );
};

export default NotificationSettings;
