import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconContainer from '../../CommonComponents/Decoration/IconContainer';
import { Button } from '../Default/styled';
import { Container, FirstPanel, SecondPanel, ThirdPanel } from './styled';

const messageImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fchat-list.png?alt=media&token=517646e9-bf00-4c70-acc8-e9dc42e9e1b8';
const threadImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fmobile-group-chat.jpg?alt=media&token=7c56f6c7-7b4d-49d0-9a9b-70dc39ccb850';
const reactionsImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Freactions.jpg?alt=media&token=28679282-4a1b-46fc-b5f8-8e1bd68c0eee';
const threadMessagesImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fthread.jpg?alt=media&token=9d1123ca-dc46-4ee3-89fc-767a5e23a166';
const sharedFilesImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fshared-files.jpg?alt=media&token=d6370827-c02c-425d-b5bf-69fb8baed260';
const textOptions =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Ftext-options.jpg?alt=media&token=2b03da51-2bb6-4343-b43f-09a82f4613a2';
const messageListImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fmessage-list.png?alt=media&token=74e2c8e0-5135-4366-92f0-251c3ca6f701';
const editAccountImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fedit-account.png?alt=media&token=e7ccb629-f91f-4163-9fad-01ef9053c7d8';
const multiPlatformImage =
  'https://firebasestorage.googleapis.com/v0/b/lagoon-3d410.appspot.com/o/web-app-resources%2Fmulti-platform.png?alt=media&token=bc56511c-6bf8-4ded-a4d3-382e28ac3c9e';
export default function Features() {
  const { t } = useTranslation();
  const [selectedFeature, setSelectedFeature] = useState('messages');
  const handleSomething = e => {
    setSelectedFeature(e.currentTarget.dataset.feature);
  };
  const [xDown, setXDown] = useState<any>(null);
  const [yDown, setYDown] = useState<any>(null);
  const [extraLargeScreen, setExtraLargeScreen] = useState<any>(window.matchMedia('(min-width: 1200px)'));
  const isLeftSidePanelVisible =
    selectedFeature === 'messages' || selectedFeature === 'groupChat' || selectedFeature === 'reactions' || !extraLargeScreen.matches;
  const isRightSidePanelVisible =
    selectedFeature === 'threadMessages' || selectedFeature === 'sharedFiles' || selectedFeature === 'textOptions';

  extraLargeScreen.addListener(() => setExtraLargeScreen(window.matchMedia('(min-width: 1200px)')));

  const handleSwipeLeft = () => {
    if (selectedFeature === 'messages') {
      setSelectedFeature('groupChat');
    } else if (selectedFeature === 'groupChat') {
      setSelectedFeature('reactions');
    } else if (selectedFeature === 'reactions') {
      setSelectedFeature('threadMessages');
    } else if (selectedFeature === 'threadMessages') {
      setSelectedFeature('sharedFiles');
    } else if (selectedFeature === 'sharedFiles') {
      setSelectedFeature('textOptions');
    } else if (selectedFeature === 'textOptions') {
      setSelectedFeature('textOptions');
    }
  };

  const handleSwipeRight = () => {
    if (selectedFeature === 'messages') {
      setSelectedFeature('messages');
    } else if (selectedFeature === 'groupChat') {
      setSelectedFeature('messages');
    } else if (selectedFeature === 'reactions') {
      setSelectedFeature('groupChat');
    } else if (selectedFeature === 'threadMessages') {
      setSelectedFeature('reactions');
    } else if (selectedFeature === 'sharedFiles') {
      setSelectedFeature('threadMessages');
    } else if (selectedFeature === 'textOptions') {
      setSelectedFeature('sharedFiles');
    }
  };

  const getTouches = evt => {
    return evt.touches;
  };

  const handleTouchStart = evt => {
    const firstTouch = getTouches(evt)[0];
    setXDown(firstTouch.clientX);
    setYDown(firstTouch.clientY);
  };

  const handleTouchMove = evt => {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        handleSwipeLeft();
      } else {
        /* right swipe */
        handleSwipeRight();
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    setXDown(null);
    setYDown(null);
  };

  return (
    <Container>
      <FirstPanel>
        {isLeftSidePanelVisible && (
          <div className="left-panel">
            <div className={`feature ${selectedFeature === 'messages' && 'active'}`} data-feature="messages" onClick={handleSomething}>
              <p className={`feature-title ${selectedFeature === 'messages' && 'active'}`}>{t('Messages')}</p>
              <p className="feature-message">{t('MessagesContext')}</p>
            </div>
            <div className={`feature ${selectedFeature === 'groupChat' && 'active'}`} data-feature="groupChat" onClick={handleSomething}>
              <p className={`feature-title ${selectedFeature === 'groupChat' && 'active'}`}>{t('GroupChat')}</p>
              <p className="feature-message">{t('GroupChatContext')}</p>
            </div>
            <div className={`feature ${selectedFeature === 'reactions' && 'active'}`} data-feature="reactions" onClick={handleSomething}>
              <p className={`feature-title ${selectedFeature === 'reactions' && 'active'}`}>{t('Reactions')}</p>
              <p className="feature-message">{t('ReactionsContext')}</p>
            </div>
            {!extraLargeScreen.matches && (
              <>
                <div
                  className={`feature ${selectedFeature === 'threadMessages' && 'active'}`}
                  data-feature="threadMessages"
                  onClick={handleSomething}
                >
                  <p className={`feature-title ${selectedFeature === 'threadMessages' && 'active'}`}>{t('ThreadMessages')}</p>
                  <p className="feature-message">{t('ThreadMessagesContext')}</p>
                </div>
                <div
                  className={`feature ${selectedFeature === 'sharedFiles' && 'active'}`}
                  data-feature="sharedFiles"
                  onClick={handleSomething}
                >
                  <p className={`feature-title ${selectedFeature === 'sharedFiles' && 'active'}`}>{t('SharedFiles')}</p>
                  <p className="feature-message">{t('SharedFilesContext')}</p>
                </div>
                <div
                  className={`feature ${selectedFeature === 'textOptions' && 'active'}`}
                  data-feature="textOptions"
                  onClick={handleSomething}
                >
                  <p className={`feature-title ${selectedFeature === 'textOptions' && 'active'}`}>{t('TextOptions')}</p>
                  <p className="feature-message">{t('TextOptionsContext')}</p>
                </div>
              </>
            )}
          </div>
        )}

        <div className="middle-panel">
          {isRightSidePanelVisible && (
            <div className="arrow-left" onClick={() => setSelectedFeature('messages')}>
              <IconContainer iconName="chevron-right" iconWidth="40px" />
            </div>
          )}
          <img
            src={messageImage}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className={selectedFeature === 'messages' ? 'visible' : ''}
            alt="..."
          />
          <img
            src={threadImage}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className={selectedFeature === 'groupChat' ? 'visible' : ''}
            alt="..."
          />
          <img
            src={reactionsImage}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className={selectedFeature === 'reactions' ? 'visible' : ''}
            alt="..."
          />
          <img
            src={threadMessagesImage}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className={selectedFeature === 'threadMessages' ? 'visible' : ''}
            alt="..."
          />
          <img
            src={sharedFilesImage}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className={selectedFeature === 'sharedFiles' ? 'visible' : ''}
            alt="..."
          />
          <img
            src={textOptions}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className={selectedFeature === 'textOptions' ? 'visible' : ''}
            alt="..."
          />
          <div className="indicators">
            <IconContainer
              iconName={selectedFeature === 'messages' ? 'bullet-dash' : 'bullet'}
              iconWidth="20px"
              padding={selectedFeature === 'messages' ? '0px 20px' : '0px 0px'}
            />
            <IconContainer
              iconName={selectedFeature === 'groupChat' ? 'bullet-dash' : 'bullet'}
              iconWidth="20px"
              padding={selectedFeature === 'groupChat' ? '0px 20px' : '0px 0px'}
            />
            <IconContainer
              iconName={selectedFeature === 'reactions' ? 'bullet-dash' : 'bullet'}
              iconWidth="20px"
              padding={selectedFeature === 'reactions' ? '0px 20px' : '0px 0px'}
            />
            <IconContainer
              iconName={selectedFeature === 'threadMessages' ? 'bullet-dash' : 'bullet'}
              iconWidth="20px"
              padding={selectedFeature === 'threadMessages' ? '0px 20px' : '0px 0px'}
            />
            <IconContainer
              iconName={selectedFeature === 'sharedFiles' ? 'bullet-dash' : 'bullet'}
              iconWidth="20px"
              padding={selectedFeature === 'sharedFiles' ? '0px 20px' : '0px 0px'}
            />
            <IconContainer
              iconName={selectedFeature === 'textOptions' ? 'bullet-dash' : 'bullet'}
              iconWidth="20px"
              padding={selectedFeature === 'textOptions' ? '0px 20px' : '0px 0px'}
            />
          </div>
          {isLeftSidePanelVisible && (
            <div className="arrow-right" onClick={() => setSelectedFeature('threadMessages')}>
              <IconContainer iconName="chevron-left" iconWidth="40px" />
            </div>
          )}
        </div>
        {isRightSidePanelVisible && extraLargeScreen.matches && (
          <div className="right-panel">
            <div
              className={`feature ${selectedFeature === 'threadMessages' && 'active'}`}
              data-feature="threadMessages"
              onClick={handleSomething}
            >
              <p className={`feature-title ${selectedFeature === 'threadMessages' && 'active'}`}>{t('ThreadMessages')}</p>
              <p className="feature-message">{t('ThreadMessagesContext')}</p>
            </div>
            <div
              className={`feature ${selectedFeature === 'sharedFiles' && 'active'}`}
              data-feature="sharedFiles"
              onClick={handleSomething}
            >
              <p className={`feature-title ${selectedFeature === 'sharedFiles' && 'active'}`}>{t('SharedFiles')}</p>
              <p className="feature-message">{t('SharedFilesContext')}</p>
            </div>
            <div
              className={`feature ${selectedFeature === 'textOptions' && 'active'}`}
              data-feature="textOptions"
              onClick={handleSomething}
            >
              <p className={`feature-title ${selectedFeature === 'textOptions' && 'active'}`}>{t('TextOptions')}</p>
              <p className="feature-message">{t('TextOptionsContext')}</p>
            </div>
          </div>
        )}
      </FirstPanel>
      <SecondPanel>
        <img src={messageListImage} alt="..." />
        <p className="title">{t('MessageList')}</p>
        <p className="message mb-5">{t('MessageListContext')}</p>
        <br />
        <p className="title">{t('EditAccount')}</p>
        <p className="message">{t('EditAccountContext')}</p>
        <img src={editAccountImage} alt="..." />
      </SecondPanel>
      <ThirdPanel>
        <p className="title">{t('MultiPlatform')}</p>
        <p className="message">{t('MultiPlatformContext')}</p>
        <Link to="/">
          <Button>{t('StartNow').toUpperCase()}</Button>
        </Link>
        <img src={multiPlatformImage} alt="..." />
      </ThirdPanel>
    </Container>
  );
}
