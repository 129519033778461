import React, { FC, ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FriendProfileStyled } from './styled';
import { TypographyStyled } from '../../../CommonComponents/Typography/Typography.style';
import { ImageStyled } from '../../../CommonComponents/Image/Image.style';
import Modal from '../../../CommonComponents/Modal';
import Flex from '../../../CommonComponents/Flex';
import { FriendData } from '../../../Common/interfaces/friend';
import { isNotEmptyArray } from '../../../utils/arrays';
import { getFriendInfo } from '../../../FriendList/actions';
import { changeFourthPanelView } from '../../../Navigation/actions';
import { FOURTH_PANEL_VIEW } from '../../../Common/enums';
import { UserData } from '../../../Common/interfaces/user';
import { ChannelData } from '../../../Common/interfaces/channel';
import { getGroupInfo } from '../../../ChannelList/actions';
import { favoriteUser } from '../../../FriendList/actions/favoriteUser';
import IconContainer from '../../../CommonComponents/Decoration/IconContainer';
import { useTranslation } from 'react-i18next';

const { Span, Paragraph } = TypographyStyled;
const { UserAvatar, UserAvatarWrapper } = ImageStyled;
const { FriendProfileContainer, ProfileImageContainer, ProfileImage } = FriendProfileStyled;

interface Props {
  id: string;
  activeConversation: ChannelData;
}

const Profile: FC<Props> = ({ id, activeConversation }): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loggedInUser: UserData = useSelector(state => state.authReducer.loggedInUser);
  const friendList: Array<FriendData> = useSelector(state => state.friendReducer.friendList);
  const conversations: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);
  const [isModalOpen, toggleModal] = useState(false);
  const [friendInfo, setFriendInfo] = useState<FriendData>();
  const [groupInfo, setGroupInfo] = useState<ChannelData | any>();

  useEffect(() => {
    if (isNotEmptyArray(friendList)) {
      const user = friendList.filter(friend => friend.friendId === id);
      setFriendInfo(user[0]);
    }
    if (isNotEmptyArray(conversations)) {
      const group = conversations.filter(group => group.id === activeConversation.id);
      setGroupInfo(group[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation, id]);

  const handleImageClick = () => {
    if (activeConversation.isDirectMessage || activeConversation.isOwnSpace) {
      if (friendInfo) {
        dispatch(getFriendInfo(friendInfo));
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.FRIEND_INFO));
        toggleModal(false);
      }
    } else {
      if (groupInfo) {
        dispatch(getGroupInfo(groupInfo));
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.GROUP_INFO));
        toggleModal(false);
      }
    }
  };

  const handleFavoriteFriend = () => {
    const requestBody = {
      ownerId: loggedInUser.id,
      isFavorite: !friendInfo!.friendStatus.isFavorite,
    };
    dispatch(favoriteUser(requestBody, friendInfo!.friendId));
  };

  return (
    <>
      <UserAvatarWrapper margin="0px 0px 0px 10px">
        <UserAvatar
          src={activeConversation.conversationImageUrl}
          width="45"
          height="45"
          alt=""
          onClick={() => toggleModal(true)}
          withHoverEffect
          opacity={0.5}
        />
      </UserAvatarWrapper>
      <Modal visible={isModalOpen} setModalVisibility={() => toggleModal(false)} dimmed={false} />
      {isModalOpen && id && (
        <FriendProfileContainer onClick={e => e.stopPropagation()}>
          <Flex container margin="0px 0px 10px 0px" handleOnClick={handleImageClick}>
            <ProfileImageContainer tooltip={t('ViewProfile')}>
              <ProfileImage src={activeConversation.conversationImageUrl} width="375" height="365" alt="" />
            </ProfileImageContainer>
          </Flex>
          <Flex margin="20px 25px 20px 20px">
            <Flex container margin="0px 0px 10px 0px" justifyContent="space-between">
              <Span fontColor="#444444" fontWeight="bold" size="lg">
                {activeConversation.name}
              </Span>
              {activeConversation.isDirectMessage && friendInfo && friendInfo.friendId && (
                <Flex container>
                  <IconContainer
                    margin="0px 15px 0px 0px"
                    onClick={handleFavoriteFriend}
                    iconName="favorite"
                    iconWidth="20px"
                    iconFill={friendInfo!.friendStatus.isFavorite ? 'url(#linear-gradient)' : '#CACACA'}
                  />
                </Flex>
              )}
            </Flex>
            {!activeConversation.isGroup && (
              <Flex>
                <Paragraph size="md" fontColor="#414042">
                  ID: {id}
                </Paragraph>
              </Flex>
            )}
          </Flex>
        </FriendProfileContainer>
      )}
    </>
  );
};

export default Profile;
