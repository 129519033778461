import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import { ThreadData } from '../interfaces/thread';
import { isNotEmptyArray } from '../../utils/arrays';

export default function useGetUserInfoById(id) {
  const [user, setUser] = useState<ThreadData | any>();

  useEffect(() => {
    if (id) {
      const unsubscribe = db
        .collection(collections.users)
        .where('id', '==', id)
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            const list = snapshot.docs.map(user => user.data());
            if (isNotEmptyArray(list)) {
              setUser(list[0]);
            }
          }
          return () => {
            unsubscribe();
          };
        });
    }
  }, [id]);

  const getUserDataFromFirestore = async (userId: string) => {
    return await db
      .collection(collections.users)
      .where('id', '==', userId)
      .get()
      .then((querySnapshot: any) => {
        if (querySnapshot.docs) {
          return querySnapshot.docs.map(doc => {
            return doc.data();
          });
        }
      })
      .catch(error => {
        error.log('Error getting documents: ', error);
      });
  };

  return { user, getUserDataFromFirestore };
}
