import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChannelCardStyled } from './styled';
import { DecorationStyled } from '../../CommonComponents/Decoration/Decoration.style';
import { ImageStyled } from '../../CommonComponents/Image/Image.style';
import { TypographyStyled } from '../../CommonComponents/Typography/Typography.style';
import Flex from '../../CommonComponents/Flex';
import { changeFourthPanelView } from '../../Navigation/actions';
import { getGroupInfo, updateChannelInList } from '../actions';
import { FOURTH_PANEL_VIEW } from '../../Common/enums';
import { ChannelData } from '../../Common/interfaces/channel';
import { removeElementInsideArray, isNotEmptyArray } from '../../utils/arrays';
import { useTranslation } from 'react-i18next';

const { Container } = ChannelCardStyled;
const { IconWrapper } = DecorationStyled;
const { UserAvatar } = ImageStyled;
const { Span, Paragraph } = TypographyStyled;

interface Props {
  channel: ChannelData;
}

const ChannelCard: FC<Props> = ({ channel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const messages: Array<ChannelData> = useSelector(state => state.chatListReducer.messages);

  useEffect(() => {
    if (isNotEmptyArray(messages)) {
      const index = messages.findIndex(message => message.id === channel.id);
      if (index >= 0) {
        dispatch(updateChannelInList(messages[index]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <Container
      onClick={() => {
        dispatch(changeFourthPanelView(FOURTH_PANEL_VIEW.GROUP_INFO));
        dispatch(getGroupInfo(channel));
      }}
    >
      <IconWrapper margin="0px 30px 0px 0px">
        <UserAvatar src={channel.conversationImageUrl} alt="" width="45" height="45" />
      </IconWrapper>
      <Flex>
        <Span size="lg" fontColor="#444444">
          {channel.name}
        </Span>
        <Paragraph size="sm" fontColor="#444444">
          {isNotEmptyArray(channel.members) &&
            removeElementInsideArray(
              channel.members.map(member => !member.isRemoved && member),
              false
            ).length +
              ' ' +
              t('Members')}
        </Paragraph>
      </Flex>
    </Container>
  );
};

export default ChannelCard;
