import { useState, useEffect } from 'react';
import db from '../../firebase/firebase';
import { collections } from '../../firebase/collectionsAndSubcollectionsDir';
import { isNotEmptyArray } from '../../utils/arrays';
import { MessageData } from '../interfaces/message';

export default function useGetLastMessageByConversationId(conversation) {
  const [lastMessage, setLastMessage] = useState<MessageData | any>();

  useEffect(() => {
    if (conversation && conversation.id) {
      const unsubscribe = db
        .collection(collections.messages)
        .where('conversationId', '==', conversation.id)
        .where('status.isDeleted', '==', false)
        .orderBy('dateCreated', 'desc')
        .limit(1)
        .onSnapshot({ includeMetadataChanges: true }, snapshot => {
          if (isNotEmptyArray(snapshot.docs)) {
            const list: Array<MessageData | any> = snapshot.docs.map(message => message.data());
            if (isNotEmptyArray(list)) {
              setLastMessage(list[0]);
            }
          } else {
            setLastMessage({
              content: {
                text: 'Hi!',
                type: {
                  isMessage: true,
                },
              },
              conversationId: conversation.id,
              dateCreated: conversation.dateCreated,
              id: 'fake_' + conversation.id,
              lastDateUpdated: conversation.dateCreated,
              status: { isOriginal: true },
              userId: 'fake_' + conversation.id,
              userName: 'fake_' + conversation.name,
              isDummy: true,
            });
          }
          return () => {
            unsubscribe();
          };
        });
    }
  }, [conversation]);

  return lastMessage;
}
