import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Auth from '../Auth';
import { init } from '../Auth/actions';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppContainer from '../AppContainer';
import AccountSettings from '../AccountSettings';
import TermsOfUse from '../TermsOfUse';
import LandingPage from '../LandingPage';

const authenticatedRoutes = [
  {
    path: '/',
    isExact: true,
    component: AppContainer,
  },
  {
    path: '/accountSettings',
    isExact: false,
    component: AccountSettings,
  },
  {
    path: '/termsOfUse',
    isExact: false,
    component: TermsOfUse,
  },
  {
    path: '/lp',
    isExact: false,
    component: LandingPage,
  },
];

const notAuthenticatedRoutes = [
  {
    path: '/',
    isExact: true,
    component: Auth,
  },
  {
    path: '/accountSettings/:userId',
    component: AccountSettings,
  },
  {
    path: '/termsOfUse',
    isExact: false,
    component: TermsOfUse,
  },
  {
    path: '/lp',
    isExact: false,
    component: LandingPage,
  },
];

export default function Routes() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  useEffect(() => {
    dispatch(init());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notAuthenticatedRouteComponents = notAuthenticatedRoutes.map(({ path, component, isExact }: any, key) => {
    return <Route exact={isExact} path={path} component={component} key={`${key}${component}`} />;
  });

  const authenticatedRouteComponents = authenticatedRoutes.map(({ path, component, isExact }: any, key) => {
    return <Route exact={isExact} path={path} component={component} key={`${key}${component}`} />;
  });

  return (
    <Router>
      <Switch>{isAuthenticated ? authenticatedRouteComponents : notAuthenticatedRouteComponents}</Switch>
    </Router>
  );
}
